import React, { useState, useMemo, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { conversationsSaveStart } from "../../../store/slices/FineTuneSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import Select from 'react-select'
import { getErrorNotificationMessage } from "../../Helper/NotificationMessage";

const ImportConversationsModal = (props) => {
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 1,
    borderRadius: '10px',
    borderColor: '#FF916F',
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const focusedStyle = {
    borderColor: '#FF916F'
  };
  
  const acceptStyle = {
    borderColor: '#FF916F'
  };
  
  const rejectStyle = {
    borderColor: '#FF916F'
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'application/zip': [".zip"],
    },
    maxFiles: 1,
    useFsAccessApi: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )
    },
    onDragEnter: () => {
      setShowMessage(true);
    },
    onDragLeave: () => {
      setShowMessage(false);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #F3F2ED!important",
      borderRadius: "0!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "auto",
      cursor:"pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#50506B80",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#0b0b12de",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#0b0b12de!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#0b0b12de!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
        '&:active': { backgroundColor: isSelected ? "#f7f7f7" : "#fff" }
      };
    },
  };

  const t = useTranslation("import_finetune_modal.conversations")
  const dispatch = useDispatch();
  const conversationsSave = useSelector((state) => state.fineTune.conversationsSave);
  const settingsJson = useSelector((state) => state.admin.settingsJson);
  const [skipRender, setSkipRender] = useState(true);
  const [files, setFiles] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const platforms = [
    {label: "FreshChat", value:1},
    {label: "Zendesk", value:2},
    {label: "LiveChat", value:3},
    {label: "Whatsapp", value:4},
    {label: "Tawk", value:5},
  ]

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name.required")).trim().notOneOf([''])
    .matches(/^[^<>]*$/, t('name.invalid')),
    type: Yup.string().required(t("type.required")),
    platform: Yup.string().when("type", {
      is: "2",
      then: Yup.string().required(t("platform.required")),
      otherwise: Yup.string(),
    }),
    file: Yup.mixed().required(t("file.required")),
    description: Yup.string()
    .matches(/^[^<>]*$/, (t("about.invalid"))),
  });

  const options = [
    {label:t("platform.email_data"), value:1},
    {label:t("platform.chat_data"), value:2},
  ]

  const saveTune = (values) => {
    if (files.length > 0 && files[0].size/1024 > settingsJson.data.data.max_file_size) {
      getErrorNotificationMessage(`Please select file ${files.length > 0 ? `with size less than ${settingsJson.data.data.max_file_size/1000} MB` : ''}`);
      return
    }
    let newValues = values;
    newValues = values.type == 2 ? newValues : {...newValues, platform:0}
    dispatch(
      conversationsSaveStart({
        ...newValues,
        file: files[0],
        bot_id: props.onboardingBot.bot_id ?? 0,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !conversationsSave.loading &&
      Object.keys(conversationsSave.data).length > 0
    ) {
      props.closeImportConversationsModal();
      setFiles([]);
    }
    setSkipRender(false);
  }, [conversationsSave]);

  const removeFile = (fileIndex) => {
    const updatedFiles = files.filter((_, index) => index !== fileIndex);
    setFiles(updatedFiles);
  };

  const getVideoName = (platform_type) => {
    switch(platform_type){
      case 1: return "freshchat-download-chat-transcript.webm";
      case 2: return "zendesk-download-chat-transcript.webm";
      case 3: return "livechat-download-chat-transcript.webm";
      case 4: return "whatsapp.mp4";
      case 5: return "tawk-download-chat-transcript.mp4";
      default : return ""
    }
  }

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.importConversations}
        onHide={props.closeImportConversationsModal}
      >
        <Modal.Body>
          <h4>{t("conversations.heading")}</h4>
          <Button
            className="modal-close"
            onClick={() => {
              setFiles([])
              props.closeImportConversationsModal()
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              viewBox="0 0 11 11"
            >
              <path
                fill="#979BA2"
                d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"
              ></path>
            </svg>
          </Button>
          <Formik
            initialValues={{
              name: "",
              description: "",
              type: "",
              platform: "",
              file: null,
            }}
            validationSchema={validationSchema}
            onSubmit={saveTune}
          >
            {({ setFieldValue, values, isValid }) => (
              <FORM noValidate className="onboarding-form">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("name.label")} <span>*</span>
                  </Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder={t("name.placeholder")}
                    name="name"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="name"
                    className="errorMsg"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("type.label")} <span>*</span>
                  </Form.Label>
                  <Select
                    options={options}
                    styles={customStyles}
                    placeholder={t("type.placeholder")}
                    onChange={(selectOption) => {
                      setFieldValue("type", selectOption.value);
                    }}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="type"
                    className="errorMsg"
                  />
                </Form.Group>
                {values.type === 2 && (
                  <>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                      {t("platform.label")} <span>*</span>
                      </Form.Label>
                      <Select
                        options={platforms}
                        styles={customStyles}
                        placeholder={t("platform.placeholder")}
                        onChange={(selectOption) => {
                          setFieldValue("platform", selectOption.value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="platform"
                        className="errorMsg"
                      />
                    </Form.Group>
                  </>
                )}
                <div className="import-webpage-header-sec mb-3">
                  <Form.Label>
                    {t("doc.file.label")} <span>*</span>
                  </Form.Label>
                  {values.type === 2 &&
                    values.platform != "" &&
                    values.platform != 4 && (
                      <Button
                        className="onboarding-save-btn"
                        onClick={() =>
                          window.open(
                            window.location.origin +
                              `/video/${getVideoName(values.platform)}`,
                            "_blank"
                          )
                        }
                        disabled={values.platform_type === 0}
                      >
                        {t("export_demo")}
                      </Button>
                    )}
                </div>
                <div className="mb-3">
                  {files.length <= 0 && <div className="onboarding-document-upload-card">
                    <div
                      {...getRootProps({
                        style,
                        onDrop: (e) => {
                          setShowMessage(false);
                          let ext = e.dataTransfer.files[0].name.slice((e.dataTransfer.files[0].name.lastIndexOf(".")));
                          if (e.dataTransfer.files.length == 1 && getInputProps().accept.includes(ext) && e.dataTransfer.files[0].type)
                            setFieldValue("file", e.dataTransfer.files);
                          else if(!getInputProps().accept.includes(ext))
                            getErrorNotificationMessage("Invalid File Type");
                          else
                           getErrorNotificationMessage("Please select a single file")
                        },
                        onChange: (e) => {
                          let ext = e.target.files[0].name.slice((e.target.files[0].name.lastIndexOf(".")));
                          if (e.target.files.length == 1 && getInputProps().accept.includes(ext))
                            setFieldValue("file", e.target.files)
                          else if (!getInputProps().accept.includes(ext))
                            getErrorNotificationMessage("Invalid File Type");
                          else
                            getErrorNotificationMessage("Please select a single file")
                        },
                      })}
                    >
                      <input {...getInputProps()} />
                      <div className="onboarding-document-item">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          fill="none"
                          viewBox="0 0 56 56"
                        >
                          <path
                            fill="#0b0b12de"
                            d="M28.344 25.102a.44.44 0 00-.689 0L21.53 32.85a.437.437 0 00.345.705h4.041v13.257c0 .24.197.437.438.437h3.281c.24 0 .438-.197.438-.438v-13.25h4.052a.436.436 0 00.344-.706l-6.125-7.754z"
                          ></path>
                          <path
                            fill="#0b0b12de"
                            d="M44.373 20.054C41.87 13.448 35.487 8.75 28.011 8.75c-7.476 0-13.858 4.692-16.363 11.298C6.962 21.278 3.5 25.55 3.5 30.625c0 6.043 4.895 10.938 10.932 10.938h2.193c.24 0 .438-.197.438-.438v-3.281a.439.439 0 00-.438-.438h-2.193a6.742 6.742 0 01-4.867-2.062 6.778 6.778 0 01-1.909-4.954c.05-1.444.542-2.8 1.433-3.943a6.853 6.853 0 013.615-2.39l2.073-.541.76-2.002a13.427 13.427 0 014.818-6.196 13.24 13.24 0 017.656-2.417c2.762 0 5.409.837 7.656 2.417a13.434 13.434 0 014.818 6.196l.755 1.996 2.067.547a6.812 6.812 0 015.037 6.568c0 1.81-.706 3.516-1.985 4.796a6.731 6.731 0 01-4.791 1.985h-2.193a.439.439 0 00-.438.438v3.281c0 .24.197.438.438.438h2.193c6.038 0 10.932-4.895 10.932-10.938 0-5.07-3.45-9.335-8.127-10.571z"
                          ></path>
                        </svg>
                        <p>
                          {t("doc.file.placeholder")} <br></br>{" "}
                          {t("conversations.file.supported")} <br></br>{" "}
                          {t("doc.file.or")}{" "}
                          <strong>{t("doc.file.browse")}</strong>
                        </p>
                      </div>
                      {showMessage && (
                        <div className="upload-overlay-preview-card">
                          <div className="upload-overlay-preview-sec">
                            <p className="file-upload-name">
                              {t("upload_doc_here")}
                            </p>
                          </div>
                        </div>
                      )} 
                    </div>
                  </div>}
                  <ErrorMessage
                    component={"div"}
                    name="file"
                    className="errorMsg"
                  />
                </div>
                {files &&
                  files.map((file, index) => (
                    <div className="upload-preview-sec" key={file.name}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 18 22"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.0297 5.76641C17.1703 5.90703 17.25 6.09687 17.25 6.29609V20.75C17.25 21.1648 16.9148 21.5 16.5 21.5H1.5C1.08516 21.5 0.75 21.1648 0.75 20.75V1.25C0.75 0.835156 1.08516 0.5 1.5 0.5H11.4539C11.6531 0.5 11.8453 0.579688 11.9859 0.720313L17.0297 5.76641ZM15.5203 6.64062L11.1094 2.22969V6.64062H15.5203ZM4.5 10.2969C4.45027 10.2969 4.40258 10.3166 4.36742 10.3518C4.33225 10.387 4.3125 10.4346 4.3125 10.4844V11.6094C4.3125 11.6591 4.33225 11.7068 4.36742 11.742C4.40258 11.7771 4.45027 11.7969 4.5 11.7969H13.5C13.5497 11.7969 13.5974 11.7771 13.6326 11.742C13.6677 11.7068 13.6875 11.6591 13.6875 11.6094V10.4844C13.6875 10.4346 13.6677 10.387 13.6326 10.3518C13.5974 10.3166 13.5497 10.2969 13.5 10.2969H4.5ZM4.5 13.4844C4.45027 13.4844 4.40258 13.5041 4.36742 13.5393C4.33225 13.5745 4.3125 13.6221 4.3125 13.6719V14.7969C4.3125 14.8466 4.33225 14.8943 4.36742 14.9295C4.40258 14.9646 4.45027 14.9844 4.5 14.9844H8.8125C8.86223 14.9844 8.90992 14.9646 8.94508 14.9295C8.98025 14.8943 9 14.8466 9 14.7969V13.6719C9 13.6221 8.98025 13.5745 8.94508 13.5393C8.90992 13.5041 8.86223 13.4844 8.8125 13.4844H4.5Z"
                          fill="#0b0b12de"
                        />
                      </svg>
                      <div className="w-100">
                        <p className="file-upload-name">{file.name}</p>
                        <span className="file-upload-size">
                          {file.size >= 1024
                            ? (file.size / (1024 * 1024)).toFixed(2) + " MB"
                            : (file.size / 1024).toFixed(2) + " KB"}
                        </span>
                      </div>
                      <div
                        onClick={() => {
                          removeFile(index);
                          setFieldValue("file", null);
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1ZM8 7.151L6.374 5.525C6.26142 5.41242 6.10872 5.34917 5.9495 5.34917C5.79028 5.34917 5.63758 5.41242 5.525 5.525C5.41242 5.63758 5.34917 5.79028 5.34917 5.9495C5.34917 6.10872 5.41242 6.26142 5.525 6.374L7.151 8L5.525 9.626C5.46925 9.68175 5.42503 9.74793 5.39486 9.82076C5.36469 9.8936 5.34917 9.97166 5.34917 10.0505C5.34917 10.1293 5.36469 10.2074 5.39486 10.2802C5.42503 10.3531 5.46925 10.4193 5.525 10.475C5.58075 10.5307 5.64693 10.575 5.71976 10.6051C5.7926 10.6353 5.87066 10.6508 5.9495 10.6508C6.02834 10.6508 6.1064 10.6353 6.17924 10.6051C6.25207 10.575 6.31825 10.5307 6.374 10.475L8 8.849L9.626 10.475C9.68175 10.5307 9.74793 10.575 9.82076 10.6051C9.8936 10.6353 9.97166 10.6508 10.0505 10.6508C10.1293 10.6508 10.2074 10.6353 10.2802 10.6051C10.3531 10.575 10.4193 10.5307 10.475 10.475C10.5307 10.4193 10.575 10.3531 10.6051 10.2802C10.6353 10.2074 10.6508 10.1293 10.6508 10.0505C10.6508 9.97166 10.6353 9.8936 10.6051 9.82076C10.575 9.74793 10.5307 9.68175 10.475 9.626L8.849 8L10.475 6.374C10.5307 6.31825 10.575 6.25207 10.6051 6.17924C10.6353 6.1064 10.6508 6.02834 10.6508 5.9495C10.6508 5.87066 10.6353 5.7926 10.6051 5.71976C10.575 5.64693 10.5307 5.58075 10.475 5.525C10.4193 5.46925 10.3531 5.42503 10.2802 5.39486C10.2074 5.36469 10.1293 5.34917 10.0505 5.34917C9.97166 5.34917 9.8936 5.36469 9.82076 5.39486C9.74793 5.42503 9.68175 5.46925 9.626 5.525L8 7.151Z"
                            fill="#0b0b12de"
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>{t("doc.about.label")}</Form.Label>
                  <Field
                    className="form-control"
                    as="textarea"
                    rows={3}
                    placeholder={t("doc.about.placeholder")}
                    name="description"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="description"
                    className="errorMsg"
                  />
                </Form.Group>
                <div className="onboarding-import-webpage-modal-btn-sec">
                  <Button
                    className="onboarding-cancel-btn"
                    disabled={conversationsSave.buttonDisable}
                    onClick={() => {
                      setFiles([])
                      props.closeImportConversationsModal()
                    }}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    className="onboarding-save-btn"
                    type="submit"
                    // onClick={() => isValid && saveTune(values)}
                    disabled={conversationsSave.buttonDisable}
                  >
                    {conversationsSave.buttonDisable ? t("loading") : t("add")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(ImportConversationsModal);
