import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import { reducers } from './slices';
import { selectedBotMiddlewareSaga } from "./middleware/index"

const saga = createSagaMiddleware();

const store = configureStore({
    reducer: reducers,
    middleware: [saga]
});
saga.run(rootSaga);
saga.run(selectedBotMiddlewareSaga);


export default store;
