import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resendOtpStart, twoStepAuthStart } from "../../store/slices/AdminSlice";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { useTranslation, withTranslation } from "react-multi-lang";

const TFALoginModal = (props) => {

  const t = useTranslation("verification_modal");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const twoStepAuth = useSelector((state)=> state.admin.twoStepAuth)
  const resendOtp = useSelector(state => state.admin.resendOtp);
  const [otp, setOtp] = useState("");
  const [skipRender, setSkipRender] = useState(true);

  const handleSubmit = e => {
    e.preventDefault();
    if (otp)
      if (otp.length === 6)
          dispatch(twoStepAuthStart({
            email: props.showVerify.email,
            verification_code: otp,
          })); 
      else getErrorNotificationMessage(t("otp.length"));
    else getErrorNotificationMessage(t("otp.required"));
  }

  const handleresendOtp = () => {
    dispatch(
      resendOtpStart({
        email: props.showVerify.email,
        code_type: "2fa",
      })
    );
  }

  useEffect(() => {
    if (
      !skipRender &&
      !twoStepAuth.loading &&
      Object.keys(twoStepAuth.data).length > 0
    ) {
      navigate('/');
    }
    setSkipRender(false);
  }, [twoStepAuth]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.showVerify}
        // onHide={props.closeVerify}
      >
        <Modal.Body>
          <h4>Two Step Authentication</h4>
          <Button className="modal-close" onClick={() => props.closeVerify()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              viewBox="0 0 11 11"
            >
              <path
                fill="#979BA2"
                d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"
              ></path>
            </svg>
          </Button>
            <Form onSubmit={handleSubmit}>
            <div className="verification-item">
              <h4>{t("heading")}</h4>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                separator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <div className="verification-btn-sec">
              <span>{t("message")}</span>
              <button
                type="button"
                onClick={() => handleresendOtp()}
                className="resend-link"
                disabled={resendOtp.buttonDisable || twoStepAuth.buttonDisable}
              >
                {resendOtp.buttonDisable ? t("loading") : t("resend")}
              </button>
            </div>
            <div className="verify-btn-sec">
            <Button
                type="submit"
                className="onboarding-btn"
                disabled={twoStepAuth.buttonDisable || resendOtp.buttonDisable}
              >
                {twoStepAuth.buttonDisable ? t("loading") : t("verify")}
              </Button> 
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(TFALoginModal);