import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import CollapseSidebar from "./CollapseSidebar";
import { useSelector } from "react-redux";

export default function AdminSidebar() {
  const sidebar = useSelector((state) => state.common.sidebar);
  const botsList = useSelector((state) => state.bots.botsList);
  const [botCount, setBotCount] = useState(0);

  useEffect(() => {
    if (!botsList.loading && Object.keys(botsList.data).length > 0)
      setBotCount(botsList.data.bots.length ? botsList.data.bots.length : 0);
  }, [botsList]);

  return sidebar ? (
    <CollapseSidebar botCount={botCount} />
  ) : (
    <Sidebar botCount={botCount} />
  );
}
