import React, { useState, useEffect } from "react";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  currrentSubscriptionPlanStart,
  generateStripeIntentStart,
  subscriptionPlansStart,
} from "../../store/slices/SubscriptionSlice";
import { withTranslation, useTranslation } from "react-multi-lang";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import EditAddressModal from "./EditAddressModal";
import UpcomingBills from "./UpcomingBills";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import SubscriptionCard from "./SubscriptionCard";
import SubscriptionConfirmModal from "./SubscriptionConfirmModal";
import EnterpriseSubscriptionCard from "./EnterpriseSubscriptionCard";
import NoSubscriptionFound from "../Helper/NoSubscriptionFound";

const SubscriptionIndex = (props) => {
  const t = useTranslation("subscriptions");
  const dispatch = useDispatch();
  const [skipRender, setSkipRender] = useState(true);
  const navigate = useNavigate();
  const [editAddress, setEditAddress] = useState(false);
  const [subscriptionConfirm, setSubscriptionConfirm] = useState(false);
  const [newPlan, setNewPlan] = useState(null);

  const closeEditAddressModal = () => {
    setEditAddress(false);
  };

  const closeSubscriptionConfirm = () => {
    setSubscriptionConfirm(false);
  };

  const subscriptionPlans = useSelector(
    (state) => state.subscription.subscriptionPlans
  );
  const generateStripeIntent = useSelector(
    (state) => state.subscription.generateStripeIntent
  );
  const currentSubscription = useSelector(
    (state) => state.subscription.currentSubscription
  );

  useEffect(() => {
    dispatch(subscriptionPlansStart());
    dispatch(currrentSubscriptionPlanStart());
  }, []);

  const handleStripeIntent = (plan, type) => {
    if (plan) {
      dispatch(
        generateStripeIntentStart({
          type: type == "monthly" ? 1 : 2,
          subscription_plan_unique_id: plan.subscription_plan_unique_id,
          subscription_plan: plan,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !generateStripeIntent.loading &&
      Object.keys(generateStripeIntent.data).length > 0
    ) {
      localStorage.setItem("payment_page", "subscription");
      if (generateStripeIntent.data.is_needs_to_pay) {
        navigate("/subscription-payment");
      } else {
        getSuccessNotificationMessage(
          "You have subscribed to free subscription successfully"
        );
        navigate("/billings");
      }
    }
    setSkipRender(false);
  }, [generateStripeIntent]);

  return (
    <>
      <div className="admin-ui-body-sec">
        <div className="my-subscription-sec">
          {currentSubscription.loading ? (
            <div className="onboarding-body-sec">
              <Row className="justify-content-md-center">
                <Col md={5}>
                  <Skeleton count={1} height={20} width={250} />
                  <Skeleton className="mb-3" count={1} height={20} width={300} />
                  <Skeleton count={1} height={186} borderRadius={5} />
                  <Skeleton count={5} className="mt-3" height={82} borderRadius={5} />
                </Col>
                <Col md={7}>
                  <div>
                    <Skeleton height={80} />
                    <Skeleton count={1} height={20} width={200} />
                    <Skeleton className="mb-3" count={1} height={20} width={600} />
                    <div className="onboarding-subscription-plans-box">
                      {[...Array(5)].map((data) => (
                        <Skeleton width={225} height={354} borderRadius={5} />
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : Object.keys(currentSubscription.data.current_plan).length > 0 
          ?
    
           (
            <div className="my-subscription-header-sec">
              <div className="my-subscription-header-box">
                <div className="my-subscription-header-info">
                  <div className="my-subscription-header-info-left-sec">
                    <h3>

                      {Object.keys(currentSubscription.data.current_plan)
                        .length > 0
                        ? t("current.heading") +
                        "(" +
                        currentSubscription.data.current_plan.plan_name +
                        ")"
                        : t("current.no_data_heading")}
                    </h3>
                    <p>
                      {Object.keys(currentSubscription.data.current_plan)
                        .length > 0
                        ? t("current.message")
                        : t("current.no_data")}
                    </p>
                  </div>
                  <div className="my-subscription-header-info-right-sec">
                    <div className="my-subscription-header-action-btn-sec">
                      <Link to="#" target="_blank" className="contact-us-btn">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 29 29"
                        >
                          <path
                            fill="#0b0b12de"
                            d="M27.906 20.265c-.036-.038-2.95-2.28-2.95-2.28a3.752 3.752 0 00-5.184.009l-2.3 1.944a15.468 15.468 0 01-8.396-8.407l1.936-2.3a3.753 3.753 0 00.006-5.181S8.78 1.14 8.74 1.103a3.728 3.728 0 00-5.237-.056l-1.39 1.21c-9.347 9.937 14.766 34.063 24.692 24.574l1.104-1.271a3.753 3.753 0 00-.003-5.295z"
                          ></path>
                        </svg> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-phone"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                        </svg>
                        {t("contact_us")}
                      </Link>
                      <Link to="/billings" className="billing-btn">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 12 17"
                        >
                          <path
                            fill="#000"
                            d="M3.333.5A3.337 3.337 0 000 3.833v12a.667.667 0 001.043.55l1.404-.96 1.404.96a.667.667 0 00.753 0l1.4-.96 1.4.96a.667.667 0 00.754 0l1.4-.958 1.4.957A.666.666 0 0012 15.834v-12A3.337 3.337 0 008.667.5H3.333zm4 9.333h-4a.667.667 0 110-1.333h4a.667.667 0 110 1.333zm2-3.333a.667.667 0 01-.666.667H3.333a.667.667 0 010-1.334h5.334a.667.667 0 01.666.667z"
                          ></path>
                        </svg> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-receipt"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2" />
                        </svg>
                        {t("billing")}
                      </Link>
                    </div>
                  </div>
                </div>
                {Object.keys(currentSubscription.data.current_plan).length >
                  0 ? (
                  <div className="my-subscription-body-info">
                    <div className="full-width-resp">
                      {currentSubscription.loading ? (
                        <div className="onboarding-form">
                          <div className="onboarding-body-sec">
                            <Row className="justify-content-md-center">
                              <Col md={10}>
                                <div className="mb-3">
                                  <Skeleton count={1} height={10} width={100} />
                                  <Skeleton count={1} height={40} />
                                </div>
                                <div className="mb-3">
                                  <Skeleton count={1} height={10} width={100} />
                                  <Skeleton count={1} height={40} />
                                </div>
                                <div className="mb-3">
                                  <Skeleton count={1} height={10} width={100} />
                                  <Skeleton count={1} height={150} />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : Object.keys(currentSubscription.data).length > 0 &&
                        Object.keys(currentSubscription.data.current_plan)
                          .length > 0 ? (
                        <UpcomingBills
                          data={currentSubscription.data}
                          setEditAddress={setEditAddress}
                        />
                      ) : null}
                      <div className="my-subscription-body-info-list-box">
                        <div className="my-subscription-body-info-list-card">
                          <div className="my-subscription-body-info-list-icon-sec">
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fill="#0b0b12de"
                                fillRule="evenodd"
                                d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10zM8.513 11.722H9c1.907 0 3.48-1.344 3.653-3.055h.68c.367 0 .667-.275.667-.611 0-.337-.3-.612-.667-.612h-.806a3.267 3.267 0 00-.7-1.222h1.506c.367 0 .667-.275.667-.61 0-.337-.3-.612-.667-.612H6.667C6.3 5 6 5.275 6 5.611c0 .336.3.611.667.611H9c.933 0 1.733.501 2.107 1.222h-4.44C6.3 7.444 6 7.72 6 8.056c0 .336.3.61.667.61h4.64C11.147 9.707 10.173 10.5 9 10.5H7.62c-.527 0-.953.391-.953.874 0 .22.093.44.266.599l4.02 3.838c.127.122.3.189.48.189.587 0 .887-.642.48-1.033l-3.4-3.245z"
                                clipRule="evenodd"
                              ></path>
                            </svg> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#0b0b12de"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="icon icon-tabler icons-tabler-outline icon-tabler-coin-rupee"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                              <path d="M15 8h-6h1a3 3 0 0 1 0 6h-1l3 3"></path>
                              <path d="M9 11h6"></path>
                            </svg>
                          </div>
                          <div className="my-subscription-body-info-list-info-sec">
                            <p>{t("current.subscription")}</p>
                            <h4>
                              {currentSubscription.data.current_plan.amount}{" "}
                              {
                                currentSubscription.data.current_plan
                                  .type_formatted
                              }
                            </h4>{" "}
                          </div>
                        </div>
                        <div className="my-subscription-body-info-list-card">
                          <div className="my-subscription-body-info-list-icon-sec">
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 20 24"
                            >
                              <path
                                fill="#0b0b12de"
                                d="M17.7 23.611H2.5c-.526 0-.95-.454-.95-1.014 0-3.354 2.556-6.082 5.7-6.082h5.7c3.143 0 5.7 2.728 5.7 6.082 0 .56-.425 1.014-.95 1.014zm.475-17.14H17.7c0-2.236-1.705-4.055-3.8-4.055h-2.85V1.403c0-.56-.425-1.014-.95-1.014-.526 0-.95.454-.95 1.014v1.013H6.3c-2.096 0-3.8 1.819-3.8 4.055h-.475C1.239 6.47.6 7.153.6 7.99v2.028c0 .838.639 1.52 1.425 1.52H2.5c0 2.236 1.704 4.055 3.8 4.055h7.6c2.095 0 3.8-1.819 3.8-4.055h.475c.785 0 1.425-.682 1.425-1.52V7.99c0-.838-.64-1.52-1.425-1.52zM8.634 9.556a1.061 1.061 0 010 1.433.918.918 0 01-.672.297.918.918 0 01-.672-.297l-.515-.55-.516.55a.918.918 0 01-.672.297.918.918 0 01-.672-.297 1.061 1.061 0 010-1.433l.516-.55-.516-.551a1.061 1.061 0 010-1.434.907.907 0 011.344 0l.516.55.515-.55a.907.907 0 011.344 0 1.061 1.061 0 010 1.434l-.516.55.516.55zm6.65 0a1.061 1.061 0 010 1.433.918.918 0 01-.672.297.918.918 0 01-.671-.297l-.516-.55-.516.55a.918.918 0 01-.672.297.918.918 0 01-.671-.297 1.06 1.06 0 010-1.433l.515-.55-.515-.551a1.061 1.061 0 010-1.434.907.907 0 011.343 0l.516.55.515-.55a.907.907 0 011.344 0 1.061 1.061 0 010 1.434l-.516.55.516.55z"
                              ></path>
                            </svg> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#0b0b12de"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="icon icon-tabler icons-tabler-outline icon-tabler-robot"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path d="M6 4m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path>
                              <path d="M12 2v2"></path>
                              <path d="M9 12v9"></path>
                              <path d="M15 12v9"></path>
                              <path d="M5 16l4 -2"></path>
                              <path d="M15 14l4 2"></path>
                              <path d="M9 18h6"></path>
                              <path d="M10 8v.01"></path>
                              <path d="M14 8v.01"></path>
                            </svg>
                          </div>
                          <div className="my-subscription-body-info-list-info-sec">
                            <p>{t("current.bots")}</p>
                            <h4>
                              {currentSubscription.data.bots.used}/{" "}
                              {currentSubscription.data.bots.total}
                            </h4>
                          </div>
                        </div>
                        <div className="my-subscription-body-info-list-card">
                          <div className="my-subscription-body-info-list-icon-sec">
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 21 20"
                            >
                              <path
                                fill="#0b0b12de"
                                d="M3.13 2.93a9.997 9.997 0 0114.14 0c3.143 3.14 3.737 7.811 1.88 11.53l1.03 4.529a.822.822 0 01-.984.982l-4.53-1.03C8.078 22.23.2 17.47.2 9.998c0-2.67 1.04-5.18 2.93-7.067zm2.898 9.556h4.353a.821.821 0 100-1.642H6.028a.821.821 0 100 1.642zm0-3.336h8.344a.821.821 0 100-1.642H6.028a.821.821 0 100 1.642z"
                              ></path>
                            </svg> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#0b0b12de"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="icon icon-tabler icons-tabler-outline icon-tabler-brand-hipchat"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path d="M17.802 17.292s.077 -.055 .2 -.149c1.843 -1.425 3 -3.49 3 -5.789c0 -4.286 -4.03 -7.764 -9 -7.764c-4.97 0 -9 3.478 -9 7.764c0 4.288 4.03 7.646 9 7.646c.424 0 1.12 -.028 2.088 -.084c1.262 .82 3.104 1.493 4.716 1.493c.499 0 .734 -.41 .414 -.828c-.486 -.596 -1.156 -1.551 -1.416 -2.29z"></path>
                              <path d="M7.5 13.5c2.5 2.5 6.5 2.5 9 0"></path>
                            </svg>
                          </div>
                          <div className="my-subscription-body-info-list-info-sec">
                            <p>{t("current.messages")}</p>
                            <h4>
                              {currentSubscription.data.messages.used}/{" "}
                              {currentSubscription.data.messages.total}
                            </h4>{" "}
                          </div>
                        </div>
                        <div className="my-subscription-body-info-list-card">
                          <div className="my-subscription-body-info-list-icon-sec">
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 23 22"
                            >
                              <path
                                fill="#0b0b12de"
                                fillRule="evenodd"
                                d="M2.962 10.518h6.279c.855 0 1.55-.687 1.55-1.53V5.072c0-.974-.63-2.115-1.797-2.311a.483.483 0 01-.4-.557.49.49 0 01.565-.395 2.938 2.938 0 011.632.86V.593a2.705 2.705 0 00-2.84-.336 2.635 2.635 0 00-1.498 2.6 2.65 2.65 0 001.938 2.298c.26.072.412.337.34.594a.49.49 0 01-.602.336C6.678 5.686 5.613 4.423 5.48 2.942a3.516 3.516 0 01.006-.692 3.378 3.378 0 00-1.79 1.565 3.344 3.344 0 00-.065 3.034.48.48 0 01-.23.644.493.493 0 01-.653-.227 4.28 4.28 0 01-.392-1.34A2.404 2.404 0 00.933 7.372c-.297.85-.09 1.78.542 2.428.394.404.921.659 1.487.718zM6.03 6.405a.493.493 0 01.683-.11c.219.154.27.456.111.673a1.257 1.257 0 00-.096 1.335 1.29 1.29 0 001.147.688h.023c.267 0 .486.211.49.476a.486.486 0 01-.483.489h-.03c-.851 0-1.62-.46-2.014-1.207a2.21 2.21 0 01.17-2.344zm-3.674 9.67c.056-.46.186-.913.392-1.34a.493.493 0 01.653-.228.48.48 0 01.23.644c-.465.96-.44 2.095.066 3.035a3.38 3.38 0 001.79 1.564 3.521 3.521 0 01-.007-.692c.133-1.48 1.198-2.743 2.649-3.142a.49.49 0 01.602.335.482.482 0 01-.34.594 2.65 2.65 0 00-1.938 2.299 2.634 2.634 0 001.497 2.599 2.706 2.706 0 002.841-.336v-2.076a2.938 2.938 0 01-1.632.86.487.487 0 01-.564-.395.483.483 0 01.4-.557c1.166-.197 1.796-1.338 1.796-2.312v-3.914c0-.844-.695-1.53-1.55-1.53H2.965a2.423 2.423 0 00-1.49.717 2.348 2.348 0 00-.542 2.428 2.41 2.41 0 001.423 1.447zm3.505-2.823a2.265 2.265 0 012.044-1.207c.27.003.485.223.482.488a.486.486 0 01-.489.477h-.023a1.29 1.29 0 00-1.147.687c-.227.43-.19.942.096 1.336a.478.478 0 01-.112.673.49.49 0 01-.682-.11 2.21 2.21 0 01-.17-2.344zm8.067 8.256h-2.159v-5.132c.128.01.257.014.386.014 3.015 0 5.46-2.413 5.46-5.388 0-2.976-2.445-5.388-5.46-5.388-.13 0-.258.004-.386.013V.495h2.159l.422 2.113 2.266.928 1.813-1.2 2.506 2.474L19.72 6.6l.94 2.236 2.14.416v3.499l-2.14.417-.94 2.236 1.216 1.79-2.506 2.474-1.813-1.2-2.266.927-.422 2.113z"
                                clipRule="evenodd"
                              ></path>
                            </svg> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#0b0b12de"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="icon icon-tabler icons-tabler-outline icon-tabler-table-options"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path d="M12 21h-7a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7"></path>
                              <path d="M3 10h18"></path>
                              <path d="M10 3v18"></path>
                              <path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                              <path d="M19.001 15.5v1.5"></path>
                              <path d="M19.001 21v1.5"></path>
                              <path d="M22.032 17.25l-1.299 .75"></path>
                              <path d="M17.27 20l-1.3 .75"></path>
                              <path d="M15.97 17.25l1.3 .75"></path>
                              <path d="M20.733 20l1.3 .75"></path>
                            </svg>
                          </div>
                          <div className="my-subscription-body-info-list-info-sec">
                            <p>{t("current.fine_tune")}</p>
                            <h4>
                              {currentSubscription.data.fine_tunings.used}/{" "}
                              {currentSubscription.data.fine_tunings.total}
                            </h4>
                          </div>
                        </div>
                        <div className="my-subscription-body-info-list-card">
                          <div className="my-subscription-body-info-list-icon-sec">
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 25 24"
                            >
                              <path
                                fill="#0b0b12de"
                                d="M.4 8V7c0-2.757 2.243-5 5-5h1V1a1 1 0 112 0v1h8V1a1 1 0 112 0v1h1c2.757 0 5 2.243 5 5v1H.4zm24 2v9c0 2.757-2.243 5-5 5h-14c-2.757 0-5-2.243-5-5v-9h24zm-12 9a1 1 0 00-1-1h-5a1 1 0 100 2h5a1 1 0 001-1zm7-4a1 1 0 00-1-1h-12a1 1 0 100 2h12a1 1 0 001-1z"
                              ></path>
                            </svg> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#0b0b12de"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="icon icon-tabler icons-tabler-outline icon-tabler-calendar-month"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z" />
                              <path d="M16 3v4" />
                              <path d="M8 3v4" />
                              <path d="M4 11h16" />
                              <path d="M7 14h.013" />
                              <path d="M10.01 14h.005" />
                              <path d="M13.01 14h.005" />
                              <path d="M16.015 14h.005" />
                              <path d="M13.015 17h.005" />
                              <path d="M7.01 17h.005" />
                              <path d="M10.01 17h.005" />
                            </svg>
                          </div>
                          <div className="my-subscription-body-info-list-info-sec">
                            <p>{t("current.expiry")}</p>
                            <h4>
                              {currentSubscription.data.current_plan.expiry_date}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="upcoming-bill-plans-box">
                      <div className="my-subscription-upgrade-tab-header-sec">
                        <div className="my-subscription-upgrade-tab-header-info-left-sec">
                          <h3>{t("upgrade.heading")}</h3>
                          <p>{t("upgrade.message")}</p>
                        </div>
                      </div>

                      <div className="onboarding-subscription-plans-box">
                        {subscriptionPlans.loading ? (
                          <div className="onboarding-subscription-plans-box">
                            {[...Array(5)].map(() => (
                              <Skeleton count={1} height={350} width={235} />
                            ))}
                          </div>

                        ) : Object.keys(subscriptionPlans.data).length > 0 &&
                          subscriptionPlans.data.subscription_plans.length > 0 ? (
                          <>
                            {subscriptionPlans.data.subscription_plans.map(
                              (plan) => (
                                <>
                                  <SubscriptionCard
                                    plan={plan}
                                    handleStripeIntent={handleStripeIntent}
                                    type="monthly"
                                    setSubscriptionConfirm={
                                      setSubscriptionConfirm
                                    }
                                    subscription={
                                      currentSubscription.data?.current_plan &&
                                      Object.keys(
                                        currentSubscription.data?.current_plan
                                      ).length > 0
                                    }
                                    setNewPlan={setNewPlan}
                                  />
                                </>
                              )
                            )}
                            {
                              <EnterpriseSubscriptionCard
                                plan={{
                                  name: "Enterprise",
                                  amount: 0,
                                  type: "enterprise",
                                }}
                                handleStripeIntent={handleStripeIntent}
                                type="monthly"
                                setSubscriptionConfirm={setSubscriptionConfirm}
                                subscription={
                                  currentSubscription.data?.current_plan &&
                                  Object.keys(
                                    currentSubscription.data?.current_plan
                                  ).length > 0
                                }
                                setNewPlan={setNewPlan}
                                buttonDisable={
                                  subscriptionPlans.data.is_enterprise_requested
                                }
                              />
                            }
                          </>
                        ) : (
                          <NoDataFound />
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : <NoSubscriptionFound />
          }
          <div className="my-subscription-body-sec">
            <Tab.Container id="left-tabs-example" defaultActiveKey="monthly">
              <Row>
                <Col sm={12}>

                </Col>
                <Col sm={12}>
                  <div className="my-subscription-upgrade-tab-content-sec">
                    <Tab.Content>
                      <Tab.Pane eventKey="monthly">
                        <div className="my-subscription-upgrade-tab-content"></div>
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="yearly">
                        <div className="my-subscription-upgrade-tab-content">
                          <Row>
                            <Col md={8}>
                              <div className="onboarding-subscription-plans-box">
                                {subscriptionPlans.loading ? (
                                  [...Array(3)].map((i, key) => (
                                    <div className="onboarding-body-sec" key={key}>
                                      <Row className="justify-content-md-center">
                                        <div className="mb-3">
                                          <Skeleton count={3} className="mb-" height={40} />
                                        </div>
                                        <Skeleton className="mb-1" height={200} />
                                      </Row>
                                    </div>
                                  ))
                                ) : Object.keys(subscriptionPlans.data).length >
                                    0 &&
                                  subscriptionPlans.data.subscription_plans
                                    .length > 0 ? (
                                  subscriptionPlans.data.subscription_plans.map(
                                    (plan, key) => (
                                      <div key={key}>
                                        <SubscriptionCard
                                          plan={plan}
                                          handleStripeIntent={handleStripeIntent}
                                          type="yearly"
                                          setSubscriptionConfirm={setSubscriptionConfirm}
                                          subscription={currentSubscription.data?.current_plan && Object.keys(currentSubscription.data?.current_plan).length > 0}
                                          setNewPlan={setNewPlan}
                                        />
                                      </div>
                                    )
                                  )
                                ) : (
                                  <NoDataFound />
                                )}
                              </div>
                            </Col>
                            <Col md={4}>
                              {currentSubscription.loading ? (
                                <div className="onboarding-form">
                                  <div className="onboarding-body-sec">
                                    <Row className="justify-content-md-center">
                                      <Col md={10}>
                                        <div className="mb-3">
                                          <Skeleton
                                            count={1}
                                            height={10}
                                            width={100}
                                          />
                                          <Skeleton count={1} height={40} />
                                        </div>
                                        <div className="mb-3">
                                          <Skeleton
                                            count={1}
                                            height={10}
                                            width={100}
                                          />
                                          <Skeleton count={1} height={40} />
                                        </div>
                                        <div className="mb-3">
                                          <Skeleton
                                            count={1}
                                            height={10}
                                            width={100}
                                          />
                                          <Skeleton count={1} height={150} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              ) : Object.keys(currentSubscription.data).length >
                                  0 &&
                                Object.keys(
                                  currentSubscription.data.current_plan
                                ).length > 0 ? (
                                <UpcomingBills
                                  data={currentSubscription.data}
                                  setEditAddress={setEditAddress}
                                />
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                      </Tab.Pane> */}
                    </Tab.Content>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
      {editAddress && (
        <EditAddressModal
          address={currentSubscription.data.user_billing_address}
          addBot={editAddress}
          closeAddBotModal={closeEditAddressModal}
          setAddBot={setEditAddress}
        />
      )}
      {subscriptionConfirm && (
        <SubscriptionConfirmModal
          plan={currentSubscription.data.current_plan}
          newPlan={newPlan}
          subscriptionConfirm={subscriptionConfirm}
          closeSubscriptionConfirm={closeSubscriptionConfirm}
          handleStripeIntent={handleStripeIntent}
          onboarding={0}
        />
      )}
    </>
  );
};

export default withTranslation(SubscriptionIndex);
