import React from "react";
import { Image, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionSaveStart } from "../../store/slices/SubscriptionSlice";
import { useLocation } from "react-router-dom";

const CouponStatus = (props) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const subscriptionSave = useSelector((state) => state.subscription.subscriptionSave);

  return (
    <>
      <div className="coupon-applied-status-wrapped">
        <div className="coupon-status-img">
          <Image
            className="coming-soon-image"
            src={window.location.origin + "/images/coupon/coupon-success.svg"}
          />
        </div>
        <div className="coupon-status-info">
          <h3>Coupon Applied</h3>
          <p>
            Congratulations! A 100% discount coupon code has been successfully
            applied. Enjoy our services!
          </p>
          <div className="upcoming-btn-wrap">
            <Button className="onboarding-add-fine-tuning-btn" disabled={subscriptionSave.buttonDisable} onClick={() => dispatch(subscriptionSaveStart({
              subscription_plan_unique_id: props.plan.unique_id,
              subscription_plan: props.plan,
              coupon_code: props.couponCode,
              onboarding: location.pathname.includes("onboarding") ? 1 : 0,
              renewal_type: localStorage.getItem("renewal_type"),
            }))}>
              {subscriptionSave.buttonDisable ? "Loading..." : "Subscribe"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default CouponStatus;
