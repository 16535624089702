import React from "react";
import { Image, Button, Container, Row, Col } from "react-bootstrap";

const TelegramDemoSteps = (props) => {
  return (
    <>
    <div className="customize-steps-sec">
      <Container>
        <Row>
          <Col md={12}>
            <div className="customize-steps-btn-sec">
              <h3>Setting Up Telegram Integration with EFI Assist</h3>
              <a
                className="settings-btn"
                download={true}
                target="_blank"
                rel="noreferrer"
                href={
                  window.location.origin +
                  "/installation-steps/Telegram-Installation-Steps.pdf"
                }
              >
                Download Doc
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="customize-steps-list-sec">
              <ol className="customize-steps-list list-unstyled">
                <li>
                  <span>1</span> Open Telegram and search for "BotFather." Click on the first result with a verified symbol.
                </li>
                <li>
                  <span>2</span> Start a conversation with BotFather.
                </li>
                <li>
                  <span>3</span> 
                  <div>
                  In the BotFather chat, type <code>/newbot</code> and send it.
                  </div>
                </li>
                <li>
                  <span>4</span> Give your bot a Name when prompted.
                </li>
                <li>
                  <span>5</span> Provide your bot with a unique username as instructed.
                </li>
                <li>
                  <span>6</span> Once these steps are completed, BotFather will send you a token for your bot. Make sure to store this token securely.
                </li>
                <li>
                  <span>7</span> Next, go to the EFI Assist panel. Select a bot and click on "Source."
                </li>
                <li>
                  <span>8</span> Choose "Telegram" as the platform and paste the token you received from BotFather. Click on "Update Changes," and now EFI Assist is successfully connected to your Telegram Bot.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
};

export default TelegramDemoSteps;
