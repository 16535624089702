import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileStart } from "../../store/slices/AdminSlice";
import AdminHeader from "./Header/AdminHeader";
import AdminSidebar from "./Header/AdminSidebar";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import PageLoader from "../Helper/PageLoader";

const AuthLayout = ({ children }) => <>{children}</>;

const EmptyLayout = ({ children }) => <>{children}</>;

const AdminLayout = ({ children }) => {
  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state.common.sidebar);
  const profile = useSelector((state) => state.admin.profile);

  useEffect(() => {
    if (!Object.keys(profile.data).length > 0) dispatch(profileStart());
  }, []);

  return (
    <>
      {profile.loading ? (
        <PageLoader />
      ) : Object.keys(profile.data).length > 0 ? (
        <div
          className={`admin-ui-sec ${
            !profile.loading &&
            Object.keys(profile.data).length > 0 &&
            profile.data.role == 2
              ? "agent-panel"
              : "user-panel"
          }`}
        >
          <AdminSidebar />
          <div
            className={`admin-ui-main-wrapper ${
              sidebar ? "admin-collapse-main-wrapper" : ""
            }`}
          >
            <AdminHeader />
            {children}
          </div>
        </div>
      ) : (
        <div className="profile-failure-wrapper">
          <SomethingWentWrong
            buttonText="Retry"
            handleClick={() => dispatch(profileStart())}
          />
        </div>
      )}
    </>
  );
};

const EmptyAdminLayout = ({ children }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  useEffect(() => {
    dispatch(profileStart());
  }, []);
  return (
    <>
      {!profile.loading && Object.keys(profile.data).length > 0 ? (
        <div className="main-wrapper">{children}</div>
      ) : null}
    </>
  );
};

export { AuthLayout, EmptyLayout, AdminLayout, EmptyAdminLayout };
