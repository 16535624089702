import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import "./OnBoarding.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageLoader from "../Helper/PageLoader";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentSec from "./StripePaymentSec";
import { Link } from "react-router-dom";
import NavLogo from "../Layout/Header/NavLogo";
import { useTranslation, withTranslation } from "react-multi-lang";
import CouponElement from "../Coupon/CouponElement";
import CouponStatus from "../Coupon/CouponStatus";
import Skeleton from "react-loading-skeleton";
import { checkCouponSuccess, generateStripeIntentStart } from "../../store/slices/SubscriptionSlice";
import SomethingWentWrong from "../Helper/SomethingWentWrong";

const OnBoardingSubscriptionPayment = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("onboarding_subscription")
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState("");
  const [subId, setSubId] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const generateStripeIntent = useSelector((state) => state.subscription.generateStripeIntent);
  const subscriptionSave = useSelector((state)=> state.subscription.subscriptionSave);
  const [couponCode, setCouponCode] = useState("");
  const [fullDiscount, setFullDiscount] = useState(false);
  
  useEffect(() => {
    if (
      Object.keys(generateStripeIntent.data).length <= 0 ||
      (generateStripeIntent.data.subscription_plan &&
        Object.keys(generateStripeIntent.data.subscription_plan).length <= 0)
    ) {
      navigate(-1);
    } else {
      setClientSecret(generateStripeIntent.data.clientSecret);
      if (generateStripeIntent.data.subscription_plan)
        setSubId(generateStripeIntent.data.subscription_plan.unique_id);
    }
    return () => {
      setCouponCode("");
      dispatch(checkCouponSuccess({}));
    }
  }, []);

  useEffect(() => {
    if(!skipRender && !generateStripeIntent.buttonDisable && Object.keys(generateStripeIntent.data).length > 0 && Object.keys(generateStripeIntent.data.subscription_plan).length > 0)
    {
      setClientSecret(generateStripeIntent.data.clientSecret);
      if (generateStripeIntent.data.subscription_plan)
        setSubId(generateStripeIntent.data.subscription_plan.unique_id);
    }
    setSkipRender(false);
  }, [generateStripeIntent]);

  useEffect(() => {
    if(!skipRender && !subscriptionSave.loading && Object.keys(subscriptionSave.data).length > 0) {
      navigate(localStorage.getItem("payment_page") == "onboarding" ? '/settings?tab=fine-tune' : '/billings');
      localStorage.removeItem("renewal_type")
    }
    setSkipRender(false);
  }, [subscriptionSave])

  const appearance = {
    theme: "stripe",
  };

  return ( 
    <>
      <div className="onboarding-success-box">
        <div className="onboarding-subscription-payment-header">
          <div className="onboarding-logo-sec">
            <NavLogo/>
          </div>
          <Container>
            <Row>
              <Col md={12}>
                <div className="back-option-sec" onClick={() => navigate(-1)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 24 24"
                  >
                    <linearGradient
                      id="a"
                      x1="12"
                      x2="12"
                      y1="2.25"
                      y2="21.75"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#252525"></stop>
                      <stop offset="1" stopColor="#252525"></stop>
                    </linearGradient>
                    <g fill="url(#a)" fillRule="evenodd" clipRule="evenodd">
                      <path d="M14.878 4.083a12.604 12.604 0 00-5.756 0 6.761 6.761 0 00-5.039 5.039 12.604 12.604 0 000 5.756 6.761 6.761 0 005.039 5.039c1.893.444 3.863.444 5.756 0a6.761 6.761 0 005.039-5.039 12.603 12.603 0 000-5.756 6.761 6.761 0 00-5.039-5.039zM8.78 2.623a14.103 14.103 0 016.442 0 8.261 8.261 0 016.156 6.156 14.104 14.104 0 010 6.442 8.261 8.261 0 01-6.156 6.156 14.104 14.104 0 01-6.442 0 8.261 8.261 0 01-6.156-6.156 14.104 14.104 0 010-6.442A8.261 8.261 0 018.78 2.623z"></path>
                      <path d="M11.53 15.03a.75.75 0 01-1.06 0l-2.5-2.5a.75.75 0 010-1.06l2.5-2.5a.75.75 0 111.06 1.06l-1.22 1.22h5.19a.75.75 0 010 1.5h-5.19l1.22 1.22a.75.75 0 010 1.06z"></path>
                    </g>
                  </svg>
                  <span>{t("payment.back")}</span>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="onboarding-payment-method-sec">
            {generateStripeIntent.loading ? <>
              <Container>
                <Row className="justify-content-md-center">
                  <Col md={12} xl={5} lg={5} className="resp-mrg-btm-md">
                    <div className="onboarding-purchase-summary-box">
                      <Skeleton height={400} />
                    </div>
                  </Col>
                  <Col md={12} xl={7} lg={7}>
                    <div className="onboarding-payment-method-box">
                      <Skeleton height={400} />
                    </div>
                  </Col>
                </Row>
              </Container>
            </> : Object.keys(generateStripeIntent.data).length > 0 &&
              generateStripeIntent.data.subscription_plan &&
              Object.keys(generateStripeIntent.data.subscription_plan).length >
                0 ? (
              <Container>
                <Row className="justify-content-md-center">
                  <Col md={12} xl={5} lg={5} className="resp-mrg-btm-md">
                    <div className="onboarding-purchase-summary-box">
                      <div className="onboarding-purchase-summary-header-sec">
                        <div className="onboarding-purchase-summary-header-card">
                          <div className="onboarding-purchase-summary-header-left-sec">
                            <h2>{t("payment.heading")}</h2>
                          </div>
                          <div className="onboarding-purchase-summary-header-right-sec">
                            <div className="onboarding-purchase-summary-badge">
                              <h4>
                                {generateStripeIntent.data.type == 1
                                  ? "Monthly"
                                  : "Yearly"}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="onboarding-purchase-summary-choosed-plan-card">
                          <h6>{generateStripeIntent.data.subscription_plan.name}</h6>
                          <p>
                           ₹ {generateStripeIntent.data.subscription_plan.monthly_amount}
                          </p>
                        </div>
                        <ul className="list-unstyled onboarding-subscription-plans-info-list">
                          <li>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="22.76"
                                height="22.3251"
                                rx="11.1625"
                                fill="#252525"
                              />
                              <path
                                d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                                fill="#fff"
                                stroke="#fff"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="22.76"
                                height="22.3251"
                                rx="11.1625"
                                stroke="#fff"
                              />
                            </svg>
                            <span>
                              {generateStripeIntent.data.type ==1 ?
                                generateStripeIntent.data.subscription_plan
                                  .messages : generateStripeIntent.data.subscription_plan
                                  .messages * 12
                              }{" "}
                              {generateStripeIntent.data.subscription_plan
                                  .messages > 1 ? "Messages" : "Message"}
                            </span>
                          </li>
                          <li>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="22.76"
                                height="22.3251"
                                rx="11.1625"
                                fill="#252525"
                              />
                              <path
                                d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                                fill="#fff"
                                stroke="#fff"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="22.76"
                                height="22.3251"
                                rx="11.1625"
                                stroke="#fff"
                              />
                            </svg>
                            <span>
                              {
                                generateStripeIntent.data.subscription_plan
                                  .fine_tunings
                              }{" "}
                              {generateStripeIntent.data.subscription_plan
                                  .fine_tunings > 1 ? "Custom Trainings" : "Custom Training"}
                            </span>
                          </li>
                          <li>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="22.76"
                                height="22.3251"
                                rx="11.1625"
                                fill="#252525"
                              />
                              <path
                                d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                                fill="#fff"
                                stroke="#fff"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="22.76"
                                height="22.3251"
                                rx="11.1625"
                                stroke="#fff"
                              />
                            </svg>
                            <span>
                              {generateStripeIntent.data.subscription_plan.bots}{" "}
                              {generateStripeIntent.data.subscription_plan.bots > 1 ? "Bots" : "Bot"}
                            </span>
                          </li>
                          <li>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="22.76"
                                height="22.3251"
                                rx="11.1625"
                                fill="#252525"
                              />
                              <path
                                d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                                fill="#fff"
                                stroke="#fff"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="22.76"
                                height="22.3251"
                                rx="11.1625"
                                stroke="#fff"
                              />
                            </svg>
                            <span>24/7 Support</span>
                          </li>
                        </ul>
                      </div>
                      <CouponElement plan={generateStripeIntent.data.subscription_plan} couponCode={couponCode} setCouponCode={setCouponCode} setFullDiscount={setFullDiscount} />
                      <div className="onboarding-purchase-summary-total-amount-sec">
                        <h3>Total</h3>
                        <h3>{fullDiscount ? "₹ 0" : generateStripeIntent.data.amount_formatted}</h3>
                      </div>
                      <div className="onboarding-purchase-summary-note-sec">
                        <p>
                          {t("payment.message")}{" "}<Link target="_blank" to="/page/terms">{t("payment.terms")}</Link>.
                        </p>
                        <p>
                          {t("payment.cancel")}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={12} xl={7} lg={7}>
                    <div className="onboarding-payment-method-box">
                      {!fullDiscount ? <div className="onboarding-payment-method-header-sec">
                        <h2>{t("payment.card.heading")}</h2>
                        <p>{t("payment.card.message")}</p>
                      </div> : null}
                      <div className="onboarding-payment-method-body-sec">
                        {!fullDiscount ? <Form className="onboarding-form">
                          <div className="onboarding-payment-method-item">
                            <label htmlFor="radio-card-8" className="radio-card">
                              <input
                                type="radio"
                                defaultChecked={true}
                                name="radio-card"
                                id="radio-card-8"
                              />
                              <div className="card-content-wrapper">
                                <span className="check-icon"></span>
                                <div className="card-content">
                                  <div className="onboarding-payment-method-card">
                                    <div className="onboarding-payment-method-card-header-sec">
                                      <h4>Credit Card</h4>
                                      <Image
                                        className="onboarding-payment-method-card-icon"
                                        src={
                                          window.location.origin +
                                          "/images/onboarding/payment-card-icon.svg"
                                        }
                                      />
                                    </div>
                                    <div className="onboarding-payment-method-card-body-sec">
                                      {clientSecret && (
                                        <Elements
                                          options={{appearance, clientSecret}}
                                          stripe={stripePromise}
                                          key={clientSecret}
                                        >
                                          <StripePaymentSec
                                            couponCode={couponCode}
                                            clientSecret={clientSecret}
                                            subId={subId}
                                            back={() => navigate(-1)}
                                          />
                                        </Elements>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Form>: <CouponStatus plan={generateStripeIntent.data.subscription_plan} couponCode={couponCode} />}
                      </div>
                      <div className="onboarding-payment-method-footer-sec">
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            ) : <SomethingWentWrong
            buttonText="Go Back"
            handleClick={() =>
              navigate(-1)
            }
          />}
          </div>
        </div>
      </div>
    </>)
};

export default withTranslation(OnBoardingSubscriptionPayment);
