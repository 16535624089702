import React from "react";
import {
  Navbar,
  Container,
  Image,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../OnBoarding/OnBoarding.css";
import { useTranslation } from "react-multi-lang";
import OnPremiseRightSection from "./OnPremiseRightSection";

const OnPremiseSuccess = (props) => {

  const t = useTranslation("on_premise")
  const navigate = useNavigate();
  return (
    <>
      <div className="onboarding-box">
        <div className="onboarding-left-sec">
          <div className="onboarding-logo-sec">
            <Navbar expand="lg">
              <Container>
                <Navbar.Brand href="/">
                  <Image
                    className="onboarding-logo"
                    src={
                      window.location.origin + "/images/efi-assist-text-logo.svg"
                    }
                  />
                </Navbar.Brand>
              </Container>
            </Navbar>
          </div>
          <div className="onboarding-steps-card">
            <div className="onboarding-body-sec">
              <Row className="justify-content-md-center">
                <Col md={10}>
                  <div className="on-premise-success-card">
                    <div className="on-premise-success-img-sec">
                      <Image
                        className="on-premise-success-img"
                        src={
                          window.location.origin +
                          "/images/onboarding/on-premise-success-img.svg"
                        }
                      />
                    </div>
                    <div className="on-premise-success-info">
                      <p>
                        {t("success.heading")} <br></br>{t("success.sub_heading")}
                      </p>
                    </div>
                    <div className="on-premise-success-btn-sec">
                      <Button
                        className="onboarding-btn"
                        onClick={() => navigate("/")}
                      >
                        {t("success.home")}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <OnPremiseRightSection />
      </div>
    </>
  );
};

export default OnPremiseSuccess;
