import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  agentCreateFailure,
  agentCreateSuccess,
  agentDeleteFailure,
  agentDeleteSuccess,
  agentListFailure,
  agentListSuccess,
  resetAgentPasswordFailure,
  resetAgentPasswordSuccess,
  sendInviteFailure,
  sendInviteSuccess,
  singleAgentViewFailure,
  singleAgentViewSuccess,
  updateAgentPermissionsFailure,
  updateAgentPermissionsSuccess,
  acceptInviteFailure,
  acceptInviteSuccess,
  updateAgentStatusSuccess,
  updateAgentStatusFailure,
  agentListStart,
} from "../slices/AgentSlice";

function* agentListAPI(action) {
  yield getCommonSaga({
    apiUrl: "agents",
    payload: action.payload,
    successNotify: false,
    success: agentListSuccess,
    failure: agentListFailure,
  });
}

function* agentCreateAPI(action) {
  const agentListData = yield select((state) => state.agent.agentList.data);
  const singleAgentData = yield select((state) => state.agent.singleAgent.data);
  yield getCommonSaga({
    apiUrl: "agents/store",
    payload: action.payload,
    successCallback: true,
    success: (data) => {
      store.dispatch(agentCreateSuccess(data));
      if (Object.keys(agentListData).length > 0) {
        const item = agentListData.agents
          ? agentListData.agents.find(
              (agent) => agent.agent_id === data.agent.agent_id
            )
          : "";
        store.dispatch(
          agentListSuccess({
            ...agentListData,
            agents: item
              ? agentListData.agents.map((agent) =>
                  agent.agent_id === data.agent.agent_id ? data.agent : agent
                )
              : [data.agent, ...agentListData.agents],
            total_agents: agentListData.total_agents + 1,
          })
        );
      }
      if(action.payload.agent_id)
      {
        if (Object.keys(singleAgentData).length > 0) {
          store.dispatch(
            singleAgentViewSuccess({
              ...singleAgentData,
              agent: data.agent,
            })
          );
        }
        store.dispatch(agentListStart())
      }
    },
    failure: agentCreateFailure,
  });
}

function* agentDeleteAPI(action) {
  const agentListData = yield select((state) => state.agent.agentList.data);
  yield getCommonSaga({
    apiUrl: "agents/destroy",
    payload: action.payload,
    successCallback: true,
    success: (data) => {
      store.dispatch(agentDeleteSuccess(data));
      if (Object.keys(agentListData).length > 0) {
        store.dispatch(
          agentListSuccess({
            ...agentListData,
            agents: data.agents,
            total_agents: data.total_agents,
          })
        );
      }
    },
    failure: agentDeleteFailure,
  });
}

function* singleAgentViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "agents/show",
    payload: action.payload,
    successNotify: false,
    success: singleAgentViewSuccess,
    failure: singleAgentViewFailure,
  });
}

function* sendInviteAPI(action) {
  yield getCommonSaga({
    apiUrl: "agents/resend_invite_link",
    payload: action.payload,
    successNotify: true,
    success: sendInviteSuccess,
    failure: sendInviteFailure,
  });
}

function* resetAgentPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "agents/reset_password",
    payload: action.payload,
    successNotify: true,
    success: resetAgentPasswordSuccess,
    failure: resetAgentPasswordFailure,
  });
}

function* updateAgentPermissionsAPI(action) {
  const singleAgentData = yield select((state) => state.agent.singleAgent.data);
  yield getCommonSaga({
    apiUrl: "agents/update_agent_access",
    payload: action.payload,
    successCallback: true,
    success: (data) => {
      store.dispatch(updateAgentPermissionsSuccess(data));
      if (Object.keys(singleAgentData).length > 0) {
        store.dispatch(
          singleAgentViewSuccess({
            ...singleAgentData,
            role_accesses: data.role_accesses,
          })
        );
      }
    },
    failure: updateAgentPermissionsFailure,
  });
}

function* acceptInviteAPI(action) {
  yield getCommonSaga({
    apiUrl: "agents/accept_invitation",
    payload: action.payload,
    successNotify: true,
    success: acceptInviteSuccess,
    failure: acceptInviteFailure,
  });
}

function* updateAgentStatusAPI(action) {
  const singleAgentData = yield select((state) => state.agent.singleAgent.data);
  const agentListData = yield select((state) => state.agent.agentList.data);
  yield getCommonSaga({
    apiUrl: "agents/status",
    payload: action.payload,
    successCallback: true,
    success: (data) => {
      store.dispatch(updateAgentStatusSuccess(data));
      if (Object.keys(singleAgentData).length > 0) {
        store.dispatch(
          singleAgentViewSuccess({
            ...singleAgentData,
            agent: {...singleAgentData.agent, status: !singleAgentData.agent.status},
          })
        );
      }
      if (Object.keys(agentListData).length > 0) {
        store.dispatch(
          agentListSuccess({
            ...agentListData,
            agents: agentListData.agents.map((agent) => agent.agent_id == data.agent.agent_id ? data.agent : agent),
          })
        );
      }
    },
    failure: updateAgentStatusFailure,
  });
}

export default function* AgentSaga() {
  yield all([
    yield takeLatest("agent/agentListStart", agentListAPI),
    yield takeLatest("agent/agentCreateStart", agentCreateAPI),
    yield takeLatest("agent/agentDeleteStart", agentDeleteAPI),
    yield takeLatest("agent/singleAgentViewStart", singleAgentViewAPI),
    yield takeLatest("agent/sendInviteStart", sendInviteAPI),
    yield takeLatest("agent/acceptInviteStart", acceptInviteAPI),
    yield takeLatest("agent/resetAgentPasswordStart", resetAgentPasswordAPI),
    yield takeLatest(
      "agent/updateAgentPermissionsStart",
      updateAgentPermissionsAPI
    ),
    yield takeLatest("agent/updateAgentStatusStart", updateAgentStatusAPI),
  ]);
}
