import React, { useState, useEffect } from "react";
import { Image, Row, Col, Button, Form } from "react-bootstrap";
import Toggle from "react-styled-toggle";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { profileStart, updateEmailCodeStart, updateNotificationPreferenceStart } from "../../store/slices/AdminSlice";
import { updateProfileStart } from "../../store/slices/AdminSlice";
import * as Yup from "yup";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import ProfileLoader from "../Helper/ProfileLoader";
import DeleteProfileModal from "./DeleteProfileModal";
import ChangePasswordModal from "./ChangePasswordModal";
import OnBoardingVerificationModal from "../OnBoarding/OnboardingVerificationModal";
import TFAModal from "./TFAModal";

const ProfileIndex = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("profile");
  const profile = useSelector((state) => state.admin.profile);
  const updateProfile = useSelector((state) => state.admin.updateProfile);
  const updateEmailCode = useSelector((state) => state.admin.updateEmailCode);
  const updateEmail = useSelector((state) => state.admin.updateEmail);
  const updateNotificationPreference = useSelector((state) => state.admin.updateNotificationPreference)
  const [newDeleteProfile, setNewDeleteProfile] = useState(false);
  const [newTFAModal, setNewTFAModal] = useState(false);
  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [showVerify, setShowVerify] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [profileDetail, setProfileDetail] = useState({
    name: "",
    email: ""
  });

  const closeNewDeleteProfileModal = () => {
    setNewDeleteProfile(false);
  };

  const closeNewTFAModal = () => {
    setNewTFAModal(false);
  };

  const [newChangePassword, setNewChangePassword] = useState(false);

  const closeNewChangePasswordModal = () => {
    setNewChangePassword(false);
  };

  const handleUpdateProfileImage = (image) => {
    if (image && !profile.loading && Object.keys(profile.data).length > 0)
      dispatch(
        updateProfileStart({
          picture: image,
          name: profile.data.name,
          email: profile.data.email,
        })
      );
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name.required"))
      .test(
        "len",
        t("name.length"),
        (val) => val && val.toString().length < 255
      ).matches(/^[^<>]*$/, t("name.invalid"))
      .trim().notOneOf(['']),
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
  });

  const handleEditName = (values) => {
    dispatch(updateProfileStart(values));
  };

  const handleEditEmail = (values) => {
    if(values.email){
      setNewEmail(values.email);
      dispatch(updateEmailCodeStart(values));
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !updateProfile.loading &&
      Object.keys(updateProfile.data).length > 0
    ) {
      setEditName(false);
    }
    setSkipRender(false);
  }, [updateProfile]);

  useEffect(() => {
    if (
      !skipRender &&
      !updateEmailCode.loading &&
      Object.keys(updateEmailCode.data).length > 0 
    ) {
        setShowVerify(newEmail);
    }
    setSkipRender(false);
  }, [updateEmailCode]);

  useEffect(() => {
    if (
      !skipRender &&
      !updateEmail.loading &&
      Object.keys(updateEmail.data).length > 0
    ) {
      setEditEmail(false);
    }
    setSkipRender(false);
  }, [updateEmail]);

  const [tfA, setTFA] = useState(false);
  const [notifications, setNotifications] = useState(false);

  useEffect(()=> {
    if (!profile.loading &&
      Object.keys(profile.data).length > 0
    ) {
      setProfileDetail({
        name: profile.data.name,
        email: profile.data.email,
      })
      setTFA(profile.data.tfa_status ? true : false);
      setNotifications(profile.data.email_notification ? true : false);
    }
  }, [profile])

  useEffect(()=> {
    if(!skipRender && !updateNotificationPreference.loading && Object.keys(updateNotificationPreference.data).length> 0 && Object.keys(updateNotificationPreference.data.user).length > 0)
    {
      setNotifications(updateNotificationPreference.data.user.email_notification ? true : false);
    }
  }, [updateNotificationPreference])


  return profile.loading ? (
    <ProfileLoader />
  ) : Object.keys(profile.data).length > 0 ? (
    <>
      <div className="admin-ui-body-sec profile-body-sec">
        <Formik
          initialValues={{
            name: profile.data.name,
            email: profile.data.email,
          }}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, isValid }) => (
            <FORM className="onboarding-form">
              <div className="profile-new-header-sec">
                <div className="profile-new-header-left-sec">
                  <div className="admin-ui-profile-img-change-sec">
                    <>
                      {!profile.loading &&
                      Object.keys(profile.data).length > 0 &&
                      profile.data.picture ? (
                        <CustomLazyLoad
                          className="admin-ui-profile-img-change"
                          src={updateProfile.buttonDisable ? window.location.origin + "/images/image-pulse.svg" : profile.data.picture}
                        />
                      ) : (
                        <Image
                          className="admin-ui-profile-img-change"
                          src={updateProfile.buttonDisable ? window.location.origin + "/images/image-pulse.svg" : 
                            window.location.origin +
                            "/images/profile/profile-img.png"
                          }
                        />
                      )}
                      <label className="admin-ui-profile-img-edit-icon">
                        <input
                          type="file"
                          accept="image/png, image/jpeg,, image/jpg"
                          size="60"
                          disabled={updateProfile.buttonDisable}
                          onChange={(e) =>
                            handleUpdateProfileImage(e.target.files[0])
                          }
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="38"
                          height="38"
                          fill="none"
                          viewBox="0 0 38 38"
                        >
                          <rect
                            width="36.107"
                            height="36.106"
                            x="0.805"
                            y="0.5"
                            fill="#D5D5D5"
                            rx="18.053"
                          ></rect>
                          <path
                            fill="#0b0b12de"
                            fillRule="evenodd"
                            d="M14.348 12.578a3.393 3.393 0 013.386-3.188h2.768c1.805 0 3.28 1.41 3.386 3.188l2.032.165a2.474 2.474 0 012.232 2.007c.48 2.54.517 5.145.106 7.698l-.097.605a2.755 2.755 0 01-2.497 2.308l-1.942.158a56.89 56.89 0 01-9.208 0l-1.942-.158a2.755 2.755 0 01-2.497-2.308l-.097-.605a22.318 22.318 0 01.106-7.698 2.474 2.474 0 012.232-2.007l2.032-.165z"
                            clipRule="evenodd"
                          ></path>
                          <path
                            fill="#D5D5D5"
                            fillRule="evenodd"
                            d="M15.368 18.14a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0z"
                            clipRule="evenodd"
                          ></path>
                          <rect
                            width="36.107"
                            height="36.106"
                            x="0.805"
                            y="0.5"
                            stroke="#D1D3D4"
                            rx="18.053"
                          ></rect>
                        </svg>
                      </label>
                    </>
                  </div>
                </div>
                <div className="profile-new-header-right-sec">
                  <div className="profile-form-flex">
                    {!editName ? (
                      <h3>
                        <span>{profile.data.name}</span>
                        <Button
                          className="profile-form-svg-btn edit-icon"
                          onClick={() => setEditName(true)}
                        >
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path d="M18 19v4.7a4.968 4.968 0 001.879-1.164l2.656-2.658A4.954 4.954 0 0023.7 18H19a1 1 0 00-1 1zM7.172 13.828A4 4 0 006 16.657V18h1.343a4 4 0 002.829-1.172L21.5 5.5a2.121 2.121 0 00-3-3z"></path>
                            <path d="M24 4.952a4.087 4.087 0 01-1.08 1.962L11.586 18.243A5.961 5.961 0 017.343 20H6a2 2 0 01-2-2v-1.343a5.957 5.957 0 011.758-4.242L17.086 1.086A4.078 4.078 0 0119.037 0H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h11v-5a3 3 0 013-3h5V5v-.048z"></path>
                          </svg> */}
                          <svg  xmlns="http://www.w3.org/2000/svg"  width="26"  height="26"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-edit"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
                        </Button>
                      </h3>
                    ) : (
                      <>
                        <Row>
                          <Col md={12}>
                            <Form.Group className="mb-0">
                              <Field
                                className="form-control"
                                type="text"
                                placeholder={t("name.placeholder")}
                                name="name"
                                value={profileDetail.name}
                                onChange={(e) => {
                                  setProfileDetail({
                                    ...profileDetail,
                                    name: e.target.value,
                                  })
                                  setFieldValue("name", e.target.value)
                                }}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="name"
                                className="errorMsg"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button
                          className="profile-form-svg-btn"
                          disabled={
                            updateProfile.buttonDisable ||
                            profileDetail.name == "" ||
                            profileDetail.name === profile.data.name
                          }
                          onClick={() => isValid && handleEditName(values)}
                          type="submit"
                        >
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#58D073"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm-.091 15.419a2.001 2.001 0 01-2.823-.005l-2.782-2.696 1.393-1.437 2.793 2.707 5.809-5.701 1.404 1.425-5.793 5.707z"></path>
                          </svg> */}
                          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24" viewBox="0 0 24 24"  fill="#58D073"  class="icon icon-tabler icons-tabler-filled icon-tabler-circle-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" /></svg>
                        </Button>
                        <Button
                          className="profile-form-svg-btn"
                          onClick={() => {
                            setEditName(false);
                            setProfileDetail({
                              name: profile.data.name,
                              email: profile.data.email,
                            })
                          }}
                          type="button"
                        >
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#dc3545"
                            x="0"
                            y="0"
                            enableBackground="new 0 0 512 512"
                            version="1.1"
                            viewBox="0 0 512 512"
                            xmlSpace="preserve"
                          >
                            <path d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256C511.847 114.678 397.322.153 256 0zm85.333 311.189c8.669 7.979 9.229 21.475 1.25 30.144-7.979 8.669-21.475 9.229-30.144 1.25-.434-.399-.85-.816-1.25-1.25L256 286.165l-55.168 55.168c-8.475 8.185-21.98 7.95-30.165-.525-7.984-8.267-7.984-21.373 0-29.64L225.835 256l-55.168-55.168c-8.185-8.475-7.95-21.98.525-30.165 8.267-7.984 21.373-7.984 29.64 0L256 225.835l55.189-55.168c7.979-8.669 21.475-9.229 30.144-1.25 8.669 7.979 9.229 21.475 1.25 30.144-.399.434-.816.85-1.25 1.25L286.165 256l55.168 55.189z"></path>
                          </svg> */}
                          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="#dc3545"  class="icon icon-tabler icons-tabler-filled icon-tabler-circle-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" /></svg>
                        </Button>
                      </>
                    )}
                  </div>
                  <div className="profile-form-flex">
                    {!editEmail ? (
                      <h4>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-mail"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" /><path d="M3 7l9 6l9 -6" /></svg>
                        <span>{profile.data.email}&nbsp;</span>
                        <Button
                          className="profile-form-svg-btn edit-icon"
                          onClick={() => setEditEmail(true)}
                        >
                           <svg  xmlns="http://www.w3.org/2000/svg"  width="26"  height="26"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-edit"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
                        </Button>
                      </h4>
                    ) : (
                      <>
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Field
                                className="form-control"
                                type="email"
                                placeholder={t("email.placeholder")}
                                name="email"
                                value={profileDetail.email}
                                onChange={(e) => {
                                  setProfileDetail({
                                    ...profileDetail,
                                    email: e.target.value,
                                  })
                                  setFieldValue("email", e.target.value)
                                }}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="email"
                                className="errorMsg"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button
                          className="profile-form-svg-btn"
                          disabled={
                            updateEmailCode.buttonDisable ||
                            profileDetail.email == "" ||
                            profileDetail.email === profile.data.email
                          }
                          type="button"
                          onClick={() => {
                            handleEditEmail(values);
                          }}
                        >
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#58D073"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm-.091 15.419a2.001 2.001 0 01-2.823-.005l-2.782-2.696 1.393-1.437 2.793 2.707 5.809-5.701 1.404 1.425-5.793 5.707z"></path>
                          </svg> */}
                          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24" viewBox="0 0 24 24"  fill="#58D073"  class="icon icon-tabler icons-tabler-filled icon-tabler-circle-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" /></svg>
                        </Button>
                        <Button
                          className="profile-form-svg-btn"
                          onClick={() => {
                            setEditEmail(false);
                            setProfileDetail({
                              name: profile.data.name,
                              email: profile.data.email,
                            })
                          }}
                          type="button"
                        >
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#dc3545"
                            x="0"
                            y="0"
                            enableBackground="new 0 0 512 512"
                            version="1.1"
                            viewBox="0 0 512 512"
                            xmlSpace="preserve"
                          >
                            <path d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256C511.847 114.678 397.322.153 256 0zm85.333 311.189c8.669 7.979 9.229 21.475 1.25 30.144-7.979 8.669-21.475 9.229-30.144 1.25-.434-.399-.85-.816-1.25-1.25L256 286.165l-55.168 55.168c-8.475 8.185-21.98 7.95-30.165-.525-7.984-8.267-7.984-21.373 0-29.64L225.835 256l-55.168-55.168c-8.185-8.475-7.95-21.98.525-30.165 8.267-7.984 21.373-7.984 29.64 0L256 225.835l55.189-55.168c7.979-8.669 21.475-9.229 30.144-1.25 8.669 7.979 9.229 21.475 1.25 30.144-.399.434-.816.85-1.25 1.25L286.165 256l55.168 55.189z"></path>
                          </svg> */}
                          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="#dc3545"  class="icon icon-tabler icons-tabler-filled icon-tabler-circle-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" /></svg>
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="profile-new-notification-security-sec">
                <div className="profile-new-security-card">
                  <h4>
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 21 25"
                    >
                      <path
                        fill="#D5D5D5"
                        d="M17.117 9.317V7.883a7.048 7.048 0 10-14.096 0v1.434A5.034 5.034 0 000 13.924v6.041A5.04 5.04 0 005.034 25h10.07a5.04 5.04 0 005.034-5.035v-6.04a5.034 5.034 0 00-3.02-4.608zm-6.041 8.635a1.007 1.007 0 11-2.014 0v-2.014a1.007 1.007 0 112.014 0v2.014zm4.027-9.062H5.034V7.883a5.035 5.035 0 0110.07 0V8.89z"
                      ></path>
                    </svg> */}
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="26"  height="26"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-lock"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z" /><path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" /><path d="M8 11v-4a4 4 0 1 1 8 0v4" /></svg>
                    {t("password.label")}
                  </h4>
                  <h5>{t("password.heading")}</h5>
                  <p>{t("password.content")}</p>
                  <div className="profile-new-security-btn-sec">
                    <Button
                      className="contact-us-btn"
                      onClick={() => setNewChangePassword(true)}
                    >
                      {t("change_password.label")}
                    </Button>
                  </div>
                </div>
                <div className="profile-new-payment-delete-account-box">
                  <h4>
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 23"
                    >
                      <path
                        fill="#D5D5D5"
                        d="M2.77 5.98c0-1.092.324-2.159.933-3.067A5.534 5.534 0 016.188.88a5.556 5.556 0 016.036 1.197 5.503 5.503 0 011.2 6.016 5.524 5.524 0 01-2.04 2.477 5.552 5.552 0 01-6.991-.688A5.516 5.516 0 012.769 5.98zm5.538 7.36a8.33 8.33 0 00-5.872 2.428A8.275 8.275 0 000 21.62a.918.918 0 00.923.92h14.77a.925.925 0 00.922-.92 8.275 8.275 0 00-2.436-5.852 8.33 8.33 0 00-5.871-2.428zm13.316-2.203l2.117-2.11a.919.919 0 00-.656-1.559.925.925 0 00-.65.259l-2.116 2.11-2.117-2.11a.925.925 0 00-1.564.653.918.918 0 00.26.647l2.116 2.11-2.117 2.11a.92.92 0 001.006 1.507.923.923 0 00.3-.207l2.116-2.11 2.117 2.11A.925.925 0 0024 13.894a.919.919 0 00-.26-.647l-2.116-2.11z"
                      ></path>
                    </svg> */}
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="26"  height="26"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-user-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M6 21v-2a4 4 0 0 1 4 -4h3.5" /><path d="M22 22l-5 -5" /><path d="M17 22l5 -5" /></svg>
                    {t("delete_account.label")}
                  </h4>
                  <h5>{t("delete_account.heading")}</h5>
                  <p>{t("delete_account.content")}</p>
                  <div className="profile-new-security-btn-sec">
                    <Button
                      className="delete-account-btn"
                      onClick={() => setNewDeleteProfile(true)}
                    >
                      {t("delete_account.label")}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="profile-new-notification-security-box">
                <div className="profile-new-notification-card">
                  <h4>
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 20 25"
                    >
                      <path
                        fill="#D5D5D5"
                        d="M16.574 2.96L10.315.885a.995.995 0 00-.629 0L3.427 2.96A4.993 4.993 0 000 7.706v5.171c0 6.563 7.005 10.577 9.152 11.65 0 0 .467.307.85.307.383 0 .791-.24.791-.24 2.16-.869 9.207-4.28 9.207-11.717v-5.17a4.992 4.992 0 00-3.426-4.747zm-5.573 9.666v3.209a1 1 0 11-2 0v-3.21a2.5 2.5 0 112 0z"
                      ></path>
                    </svg> */}
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="26"  height="26"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-shield-lock"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" /><path d="M12 11m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M12 12l0 2.5" /></svg>
                    {t("security.label")}
                  </h4>
                  <div className="profile-new-notification-security-switch">
                    <Toggle
                      width={40}
                      height={20}
                      sliderWidth={12}
                      sliderHeight={12}
                      translate={19}
                      checked={tfA}
                      backgroundColorChecked="#1f73b7"
                      backgroundColorUnchecked="#d5d5d5"
                      labelRight={t("security.heading")}
                      onChange={() => setNewTFAModal(true)}
                    />
                  </div>
                  <p>{t("security.content")}</p>
                </div>
                <div className="profile-new-notification-card">
                  <h4>
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M4.068 18h15.656a3 3 0 002.821-4.021l-2.852-7.885A8.323 8.323 0 0011.675 0a8.321 8.321 0 00-8.123 6.516l-2.35 7.6A3 3 0 004.068 18zM7.1 20a5 5 0 009.8 0z"></path>
                    </svg> */}
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="26"  height="26"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-bell"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" /><path d="M9 17v1a3 3 0 0 0 6 0v-1" /></svg>
                    {t("notification_preferences.label")}
                  </h4>
                  <div className="profile-new-notification-security-switch">
                    <Toggle
                      width={40}
                      height={20}
                      sliderWidth={12}
                      sliderHeight={12}
                      translate={19}
                      checked={notifications}
                      disabled={updateNotificationPreference.buttonDisable}
                      backgroundColorChecked="#1f73b7"
                      backgroundColorUnchecked="#d5d5d5"
                      labelRight="Email Notifications"
                      onChange={()=> dispatch(updateNotificationPreferenceStart({
                        email_notification: 1,
                      }))}
                    />
                  </div>
                  <p>{t("notification_preferences.heading")}</p>
                  <p>{t("notification_preferences.content")}</p>
                </div>
              </div>
            </FORM>
          )}
        </Formik>
      </div>
      {newDeleteProfile && (
        <DeleteProfileModal
          newEditProfile={newDeleteProfile}
          closeNewEditProfileModal={closeNewDeleteProfileModal}
          setNewEditProfile={setNewDeleteProfile}
          profile={profile.data}
        />
      )}
      {newTFAModal && (
        <TFAModal
          newEditProfile={newTFAModal}
          closeNewEditProfileModal={closeNewTFAModal}
          setNewEditProfile={setNewTFAModal}
        />
      )}
      {newChangePassword && (
        <ChangePasswordModal
          newChangePassword={newChangePassword}
          closeNewChangePasswordModal={closeNewChangePasswordModal}
          setNewChangePassword={setNewChangePassword}
        />
      )}
      {showVerify ? (
        <OnBoardingVerificationModal
          forgotPassword={2}
          showVerify={showVerify}
          closeVerify={() => setShowVerify(false)}
        />
      ) : null}
    </>
  ) : (
    <SomethingWentWrong
      buttonText="Retry"
      handleClick={() => dispatch(profileStart())}
    />
  );
};

export default withTranslation(ProfileIndex);
