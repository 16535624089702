import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
} from "react-bootstrap";
import { useTranslation, withTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { botDeleteStart } from "../../store/slices/BotSlice";

const DeleteBotModal = (props) => {

  const t = useTranslation("bot_creation.delete_bot");
  const dispatch = useDispatch();
  const botDelete = useSelector((state) => state.bots.botDelete);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (
      !skipRender &&
      !botDelete.loading &&
      Object.keys(botDelete.data).length > 0
    ) {
      props.closeDeleteBotModal();
    }
    setSkipRender(false);
  }, [botDelete]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.deleteBot}
        onHide={props.closeDeleteBotModal}
      >
        <Modal.Body>
          <h4>{t("heading")}</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeDeleteBotModal()}
          >
            <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10zM9.17 14.83l5.66-5.66M14.83 14.83L9.17 9.17"
      ></path>
    </svg>
          </Button>
          <div className="delete-bot-card">
            <h4>{t("message")}</h4>
            <p>{t("content")}</p>
          </div>
          <div className="onboarding-import-webpage-modal-btn-sec mt-3">
            <Button
              className="onboarding-cancel-btn"
              onClick={() => props.closeDeleteBotModal()}
            >
              {t("cancel")}
            </Button>
            <Button
              className="onboarding-save-btn"
              type="submit"
              onClick={() =>
                dispatch(botDeleteStart({ bot_id: props.deleteBot }))
              }
              disabled={botDelete.buttonDisable}
            >
              {botDelete.buttonDisable ? t("loading") : t("confirm")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(DeleteBotModal);
