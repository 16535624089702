import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import "../OnBoarding/OnBoarding.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation, withTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { onPromiseCompanySaveStart } from "../../store/slices/OnboardingSlice";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import axios from "axios";
import NavLogo from "../Layout/Header/NavLogo";
import OnPremiseRightSection from "./OnPremiseRightSection";

const OnPremiseInstallation = (props) => {
  const t = useTranslation("on_premise");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const onPromiseCompanySave = useSelector(
    (state) => state.onboarding.onPromiseCompanySave
  );

  const [skipRender, setSkipRender] = useState(true);
  const [defaultCountry, setDefaultCountry] = useState("");

  useEffect(() => {
    const getUserLocation = async () => {
      try {
        const response = await axios.get("http://ip-api.com/json/");
        setDefaultCountry(response.data.countryCode);
      } catch (error) {
        // If the API call fails, you can set a fallback country code here.
        setDefaultCountry("IN");
      }
    };
    getUserLocation();
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name.required"))
      .matches(/^[^<>]*$/, t('name.invalid'))
      .trim().notOneOf(['']),
    email: Yup.string().required(t("email.required"))
      .matches(
        /^(?!\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        t("email.invalid")
      ).trim().notOneOf(['']),
    company_name: Yup.string().required(t("company_name.required"))
      .matches(/^[^<>]*$/, t('company_name.invalid'))
      .trim().notOneOf(['']),
    website: Yup.string()
      .required(t("company_website.required"))
      .matches(/^(?!https?|http:\/\/|https:\/\/).*$/u, t("company_website.http"))
      .matches(/^(?=\S*$)/u, t("company_website.white_space"))
      // .matches(/^[^\s]+(\.[^\s]+)+$/u, t("company_website.invalid"))
      .matches(/^[^\s]+([-.][^\s]+)*\.[^\s]{2,}(:[0-9]+)?(\/.*)?$/u, t("company_website.invalid"))
      .matches(/^[^<>]*$/, t('company_website.invalid')),
    contact: Yup.string()
      .required(t("contact.required"))
      .matches(/^(?=.*[0-9])/, t("contact.space_values_not_valid")),
    about: Yup.string()
    .required(t("about.required"))
    .matches(/^[^<>]*$/, t('about.invalid'))
  });

  const handleSubmit = (values) => {
    if (validateMobileNumber(values.contact)) {
      const intlNo = formatPhoneNumberIntl(values.contact);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      dispatch(
        onPromiseCompanySaveStart({
          ...values,
          website: "https://" + values.website,
          contact: mobile,
          country_code: countryCode,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !onPromiseCompanySave.loading &&
      Object.keys(onPromiseCompanySave.data).length > 0
    )
      props.setStep(2);
    setSkipRender(false);
  }, [onPromiseCompanySave]);

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError(
          "contact",
          t("contact.not_a_phone_number")
        );
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("contact", t("contact.invalid_number"));
        return false;
      } else {
        // formRef.current.setFieldError("contact", t("contact.invalid_number"));
        return true;
      }
    } else {
      formRef.current.setFieldError("contact", t("contact.required"));
      return false;
    }
  };

  return (
    <>
      <div className="onboarding-sec">
        <div className="onboarding-box">
          <div className="onboarding-left-sec">
            <div className="onboarding-logo-sec">
              <NavLogo/>
            </div>
            <div className="onboarding-steps-card">
              <div className="onboarding-header-sec">
                <Row className="justify-content-md-center">
                  <Col md={12}>
                    <div className="onboarding-onpremise-title-sec">
                      <h2>{t("heading")}</h2>
                      <p>{t("message")}</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  company_name: "",
                  website: "",
                  about: "",
                  contact: "",
                }}
                validationSchema={validationSchema}
                // onSubmit={handleSubmit}
                innerRef={formRef}
              >
                {({ errors, values, touched, setFieldValue, setFieldError, isValid, validateField }) => (
                  <FORM className="onboarding-form">
                    <div className="onboarding-body-sec">
                      <Row className="justify-content-md-center">
                        <Col md={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              {t("name.label")} <span>*</span>
                            </Form.Label>
                            <Field
                              className="form-control"
                              type="text"
                              placeholder={t("name.placeholder")}
                              name="name"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="name"
                              className="errorMsg"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                            {t("email.label")} <span>*</span>
                            </Form.Label>
                            <Field
                              className="form-control"
                              type="email"
                              placeholder={t("email.placeholder")}
                              name="email"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="email"
                              className="errorMsg"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                            {t("company_name.label")} <span>*</span>
                            </Form.Label>
                            <Field
                              className="form-control"
                              type="text"
                              placeholder={t("company_name.placeholder")}
                              name="company_name"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="company_name"
                              className="errorMsg"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                            {t("contact.label")} <span>*</span>
                            </Form.Label>
                            <PhoneInput
                              defaultCountry={defaultCountry}
                              placeholder={t("contact.placeholder")}
                              // value={values.contact}
                              onChange={(value) =>
                                validateMobileNumber(value) ? setFieldValue("contact", value) : setFieldValue("contact", "")
                              }
                              onBlur={(e) => {
                                validateMobileNumber(e.target.value) ? setFieldValue("contact", e.target.value) : setFieldValue("contact", "")
                              }}
                              international
                              countryCallingCodeEditable={false}
                              className={`${
                                touched.contact && errors.contact
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="contact"
                              className="errorMsg"
                            />
                          </Form.Group>
                          <Form.Label>
                          {t("company_website.label")} <span>*</span>
                          </Form.Label>
                          <div className="mb-3">
                            <InputGroup>
                              <InputGroup.Text id="basic-addon1">
                                https://
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder={t("company_website.placeholder")}
                                aria-label="Website"
                                aria-describedby="basic-addon1"
                                name="website"
                              />
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="website"
                              className="errorMsg"
                            />
                          </div>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>
                            {t("about.label")} <span>*</span>
                            </Form.Label>
                            <Field
                              className="form-control"
                              as="textarea"
                              rows={3}
                              placeholder={t("about.placeholder")}
                              name="about"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="about"
                              className="errorMsg"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <div className="onboarding-footer-sec">
                      <Row className="justify-content-md-center">
                        <Col md={12}>
                          <div className="onboarding-on-premise-btn-sec">
                            <Button
                              className="onboarding-back-btn"
                              onClick={() => navigate(-1)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#0b0b12de"
                                  d="M20 11H6.83l2.88-2.88A.997.997 0 008.3 6.71L3.71 11.3a.996.996 0 000 1.41L8.3 17.3a.997.997 0 101.41-1.41L6.83 13H20c.55 0 1-.45 1-1s-.45-1-1-1z"
                                ></path>
                              </svg>
                              {t("back")}
                            </Button>
                            <Button
                              className="onboarding-btn"
                              type="submit"
                              disabled={onPromiseCompanySave.buttonDisable}
                              onClick={()=> isValid && validateMobileNumber(values.contact) && handleSubmit(values)}
                            >
                              {onPromiseCompanySave.buttonDisable
                                ? t("loading")
                                : t("next")}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
          </div>
          {/* <OnPremiseRightSection/>                      */}
        </div>
      </div>
    </>
  );
};

export default withTranslation(OnPremiseInstallation);
