import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

const CustomTrainingGraph = (props) => {


    ChartJS.register(ArcElement, Tooltip, Legend);

      const data = {
        labels: ['Document', 'Website', 'FAQs', 'Chat Data'],
        datasets: [
          {
            label: '# of Votes',
            data: [32, 18, 10, 40],
            backgroundColor: [
              '#9D89D0',
              '#2A334F',
              '#70C55B',
              '#FBD07A',
            ],
          },
        ],
      };

  return (
    <>
      <Doughnut data={data} 
        width={'300px'}
        height={'300px'}
        options={{ maintainAspectRatio: false }}
      />
    </>
  );
};

export default CustomTrainingGraph;
