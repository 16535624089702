import React from "react";
import { Image, Button } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";

const NoBotExists = (props) => {
  const t = useTranslation("bot_creation");
  const profile = useSelector((state) => state.admin.profile);

  return (
    <>
      <div className="no-bot-exists-card">
        <div className="no-bot-exists-img-sec">
          <Image
            className="no-bot-exists-img"
            src={
              window.location.origin + "/images/no-data/no-bot-exists-img.gif"
            }
          />
        </div>
        <div className="no-bot-exists-info">
          <h4>{t("no_bots.heading")}</h4>
          {profile.data.role == 1 && (
            <>
              <p>{t("no_bots.message")}</p>
              <div className="no-bot-exists-btn-sec">
                <Button
                  className="onboarding-add-fine-tuning-btn"
                  onClick={() => props.setAddBot(true)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6 12h12M12 18V6"></path></svg>
                  <span>{t("add_bot")}</span>
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default withTranslation(NoBotExists);
