import React, { useEffect, useState } from "react";
import {
  Image,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import "./OnBoarding.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import OnboardingStepsContent from "./OnboardingStepsContent";
import useBot from "../../hooks/useBot";
import NavLogo from "../Layout/Header/NavLogo";

const ChooseSolutionIndex = (props) => {

  const {botType, setBotType} = props;
  const { widgets } = useBot();
  const t = useTranslation("on_premise");
  const updateWidgetType = useSelector((state) => state.onboarding.updateWidgetType);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (
      !skipRender &&
      !updateWidgetType.loading &&
      Object.keys(updateWidgetType.data).length > 0
    )
      props.setStep(2);
    setSkipRender(false);
  }, [updateWidgetType]);

  return (
    <>
      <div className="onboarding-box">
        <div className="onboarding-left-sec">
          <div className="onboarding-logo-sec">
            <NavLogo />
          </div>
          <div className="onboarding-steps-card">
            <div className="onboarding-body-sec">
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <Form className="onboarding-form pt-0">
                    <div
                      className="onboarding-choose-solution-box"
                      onChange={(e) => setBotType(e.target.value)}
                    >
                      {widgets.map((widget, key) => (
                        <>
                          <label for={widget.value} key={key} className="radio-card">
                            <input
                              type="radio"
                              name="radio-card"
                              id={widget.value}
                              value={widget.value}
                              checked={botType == widget.value}
                            />
                            <div className="card-content-wrapper">
                              <span className="check-icon"></span>
                              <div className="card-content">
                                <Image
                                  className="onboarding-choose-solution-logo-icon"
                                  src={widget.picture}
                                />
                              </div>
                            </div>
                          </label>
                        </>
                      ))}
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
            <div className="onboarding-footer-sec">
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="onboarding-on-premise-btn-sec justify-content-center">
                    
                    <div></div>
                    <Button
                      className="onboarding-btn"
                      onClick={() => {
                        props.setStep(2);
                      }}
                      disabled={
                        botType == "" ||
                        botType == null ||
                        updateWidgetType.buttonDisable
                      }
                    >
                      {updateWidgetType.buttonDisable ? t("loading") : t("next")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {/* <OnboardingStepsContent step={0} /> */}
      </div>
    </>
  );
};

export default ChooseSolutionIndex;
