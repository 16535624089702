import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./OnBoarding.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import {
  botsStoreStart,
} from "../../store/slices/BotSlice";
import Skeleton from "react-loading-skeleton";
import OnboardingStepsContent from "./OnboardingStepsContent";
import NavLogo from "../Layout/Header/NavLogo";

const BotCreationIndex = (props) => {
  const t = useTranslation("bot_creation");
  const dispatch = useDispatch();
  const botsStore = useSelector((state) => state.bots.botsStore);
  const onboardingbot = useSelector((state) => state.onboarding.onboardingbot);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name.required")).trim().notOneOf(['']),
    website: Yup.string().required(t("website.required"))
      .matches(
        /^(?!https?|http:\/\/|https:\/\/).*/,t("website.http"))
      .matches(/^(?=\S*$)/, t("website.white_space"))
      .matches(
        /^[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,}(:[0-9]+)?(\/.*)?$/i,
        t("website.invalid")),
  });

  const handleBotsStore = (values) => {
    if (!onboardingbot.loading && Object.keys(onboardingbot.data).length > 0) {
      if (
        values.name === onboardingbot.data.name &&
        values.website === onboardingbot.data.website.replace("https://", "") &&
        values.description === onboardingbot.data.description
        && props.botType === onboardingbot.data.widget_type
      ) {
        props.setStep(3);
      } else
        dispatch(
          botsStoreStart({
            ...values,
            website: "https://" + values.website,
            widget_type: props.botType,
          })
        );
    } else
      dispatch(
        botsStoreStart({
          ...values,
          website: "https://" + values.website,
          widget_type: props.botType,
        })
      );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !botsStore.loading &&
      Object.keys(botsStore.data).length > 0
    ) {
      props.setStep(3);
    }
    setSkipRender(false);
  }, [botsStore]);

  return (
    <>
      <div className="onboarding-box">
        <div className="onboarding-left-sec">
          <div className="onboarding-logo-sec">
            <NavLogo />
          </div>
          <div className="onboarding-steps-card">
            <div className="onboarding-header-sec">
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="onboarding-onpremise-title-sec">
                    <h2>{t("heading")}</h2>
                    <p>{t("message")}</p>
                  </div>
                </Col>
              </Row>
            </div>
            {onboardingbot.loading ? (
              <div className="onboarding-form">
                <div className="onboarding-body-sec">
                  <Row className="justify-content-md-center">
                    <Col md={12}>
                      <div className="mb-3">
                        <Skeleton count={1} height={10} width={100} />
                        <Skeleton count={1} height={40} />
                      </div>
                      <div className="mb-3">
                        <Skeleton count={1} height={10} width={100} />
                        <Skeleton count={1} height={40} />
                      </div>
                      <div className="mb-3">
                        <Skeleton count={1} height={10} width={100} />
                        <Skeleton count={1} height={150} />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <Formik
                initialValues={
                  Object.keys(onboardingbot.data).length > 0
                    ? {
                        ...onboardingbot.data,
                        website: onboardingbot.data.website.replace(
                          "https://",
                          ""
                        ),
                        onboarding: 1,
                      }
                    : {
                        name: "",
                        website: "",
                        description: "",
                        onboarding: 1,
                      }
                }
                validationSchema={validationSchema}
                onSubmit={handleBotsStore}
              >
                {({ setFieldValue, values }) => (
                  <FORM noValidate className="onboarding-form">
                    <div className="onboarding-body-sec">
                      <Row className="justify-content-md-center">
                        <Col md={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              {t("name.label")} <span>*</span>
                            </Form.Label>
                            <Field
                              className="form-control"
                              type="text"
                              placeholder={t("name.placeholder")}
                              name="name"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="name"
                              className="errorMsg"
                            />
                          </Form.Group>
                          <Form.Label>
                            {t("website.label")} <span>*</span>
                          </Form.Label>
                          <div className="mb-3">
                            <InputGroup>
                              <InputGroup.Text id="basic-addon1">
                                https://
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                placeholder={t("website.placeholder")}
                                aria-label="Website"
                                aria-describedby="basic-addon1"
                                name="website"
                              />
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="website"
                              className="errorMsg"
                            />
                          </div>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>{t("about.label")}</Form.Label>
                            <Field
                              className="form-control"
                              as="textarea"
                              rows={3}
                              placeholder={t("about.placeholder")}
                              name="description"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <div className="onboarding-footer-sec">
                      <Row className="justify-content-md-center">
                        <Col md={12}>
                          <div className="onboarding-on-premise-btn-sec">
                            <Button
                              className="onboarding-back-btn"
                              onClick={() => props.setStep(1)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#0b0b12de"
                                  d="M20 11H6.83l2.88-2.88A.997.997 0 008.3 6.71L3.71 11.3a.996.996 0 000 1.41L8.3 17.3a.997.997 0 101.41-1.41L6.83 13H20c.55 0 1-.45 1-1s-.45-1-1-1z"
                                ></path>
                              </svg>
                              {t("back")}
                            </Button>
                            <Button
                              className="onboarding-btn"
                              type="submit"
                              disabled={botsStore.buttonDisable}
                            >
                              {botsStore.buttonDisable
                                ? t("loading")
                                : t("next")}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </FORM>
                )}
              </Formik>
            )}
          </div>
        </div>
        {/* <OnboardingStepsContent step={1} /> */}
      </div>
    </>
  );
};

export default BotCreationIndex;
