import React from "react";
import { Image } from "react-bootstrap";

const PageLoader = ({ main = false }) => {
  return (
    <div
      className="main-wrapper"
      style={{
        width: "100%",
        display: "flex",
        height: "100vh",
      }}
    >
      <Image
        src="/images/loader.gif"
        style={{ margin: "auto" }}
        alt="loader"
        width={200}
        height={200}
      />
    </div>
  );
};

export default PageLoader;
