import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";

const NoSubscriptionFound = () => {

  const t = useTranslation("helper.no_data_found");
  return (
    <>
      <div className="no-bot-exists-card">
        <div className="no-bot-exists-img-sec">
          <Image
            className="no-bot-exists-img"
            src={
              window.location.origin + "/images/no-data/no-subscription-found.gif"
            }
          />
        </div>
        <div className="no-bot-exists-info">
          <h4>No Subscription Found!</h4>
        </div>
      </div>
    </>
  );
};

export default NoSubscriptionFound;
