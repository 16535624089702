import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import "./DelayMessage.css";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { delayMessageSaveStart } from "../../store/slices/FineTuneSlice";
import { useTranslation } from "react-multi-lang";

const DelayMessageEditModal = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("settings.delay_message.edit_modal");
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const delayMessageSave = useSelector(
    (state) => state.fineTune.delayMessageSave
  );
  const [skipRender, setSkipRender] = useState(true);

  const handleSubmit = (values) => {
    dispatch(delayMessageSaveStart({ ...values, bot_id: selectedBot.bot_id, delay_message_id: props.modalShow.id ? props.modalShow.id : "" }));
  };

  const validationSchema = Yup.object().shape({
    delay_message: Yup.string().required(t("message.required"))
    .test(
      "check-delay-message",
      t("message.length"),
      (value) => {
        if (value) {
          return value.length <= 300;
        } else {
          return true;
        }
      }
    ).matches(/^[^<>]*$/, t("message.invalid")),

  });

  useEffect(() => {
    if (
      !skipRender &&
      !delayMessageSave.loading &&
      Object.keys(delayMessageSave.data).length > 0
    ) {
      props.onHide();
    }
    setSkipRender(false);
  }, [delayMessageSave]);

  return (
    <>
      <Modal
        show={props.modalShow}
        onHide={props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-dialog-center import-webpage-modal delay-text-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.modalShow.id ? t("edit_heading") : t("add_heading")}
          </Modal.Title>
          <Button
            className="modal-close"
            onClick={props.onHide}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 11 11"><path fill="#979BA2" d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"></path></svg>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              delay_message: props.modalShow.delay_message ? props.modalShow.delay_message : "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values, isValid }) => (
              <FORM className="onboarding-form">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("message.label")}</Form.Label>
                  <Field
                    className="form-control"
                    as="textarea"
                    rows={5}
                    placeholder={t("message.placeholder")}
                    name="delay_message"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="delay_message"
                    className="errorMsg"
                  />
                </Form.Group>
                <div className="onboarding-import-webpage-modal-btn-sec">
                  <Button
                    onClick={props.onHide}
                    disabled={delayMessageSave.buttonDisable}
                    className="onboarding-cancel-btn"
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    disabled={delayMessageSave.buttonDisable || (props.modalShow.id && values.delay_message === props.modalShow.delay_message)}
                    className="onboarding-save-btn"
                    type="submit"
                  >
                    {delayMessageSave.buttonDisable
                      ? t("loading")
                      : props.modalShow.id ? t("update") : t("save")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default DelayMessageEditModal;
