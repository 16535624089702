import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import DatePicker, { DateObject } from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePickerHeader from "react-multi-date-picker/plugins/date_picker_header";
import CustomTrainingGraph from "./CustomTrainingGraph";

const AnalyticsIndex = (props) => {
  const [value, setValue] = useState([
    new DateObject().setDay(15),
    new DateObject().add(1, "month").setDay(15),
  ]);

  return (
    <>
      <div className="admin-ui-body-sec">
        <div className="analytics-page-sec">
          <div className="analytics-page-header-sec">
            <div className="analytics-page-header-box">
              <div className="analytics-page-header-card">
                <h6>Total Custom Training</h6>
                <h3>87 / 100</h3>
              </div>
              <div className="analytics-page-header-card">
                <h6>Total Chats</h6>
                <h3>1,249 / 2000</h3>
              </div>
              <div className="analytics-page-header-card">
                <h6>Total Bots</h6>
                <h3>89/ 100</h3>
              </div>
              <div className="analytics-page-header-card">
                <h6>Total Agents</h6>
                <h3>78</h3>
              </div>
            </div>
          </div>
          <div className="analytics-page-body-sec">
            <h2 className="analytics-title-sec">Analytics</h2>
            <div className="analytics-page-body-box">
              <div className="analytics-page-body-card">
                <div className="analytics-page-body-card-top-sec">
                  <h4>
                    Custom Training
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        fill="#D0D1D2"
                        height="20"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.5 6.5a1.5 1.5 0 01-3 0 1.5 1.5 0 013 0zM24 19v-6.66A12.209 12.209 0 0012.836.028 12 12 0 00.029 12.854C.471 19.208 6.082 24 13.083 24H19a5.006 5.006 0 005-5zM12.7 2.024A10.2 10.2 0 0122 12.34V19a3 3 0 01-3 3h-5.917C7.049 22 2.4 18.1 2.025 12.716A10 10 0 0112.016 2c.227 0 .456.009.684.024zM14 18v-6a2 2 0 00-2-2h-1a1 1 0 000 2h1v6a1 1 0 002 0z"></path>
                      </svg>
                    </span>
                  </h4>
                  <div className="analytics-page-date-picker">
                    <DatePicker
                      render={<InputIcon />}
                      range
                      plugins={[<DatePickerHeader />]}
                      value={value}
                      onChange={setValue}
                    />
                  </div>
                </div>
                <div className="analytics-page-body-card-bottom-sec">
                  <CustomTrainingGraph />
                </div>
              </div>
              <div className="analytics-page-body-card">
                <div className="analytics-page-body-card-top-sec">
                  <h4>
                    Bots
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        fill="#D0D1D2"
                        height="20"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.5 6.5a1.5 1.5 0 01-3 0 1.5 1.5 0 013 0zM24 19v-6.66A12.209 12.209 0 0012.836.028 12 12 0 00.029 12.854C.471 19.208 6.082 24 13.083 24H19a5.006 5.006 0 005-5zM12.7 2.024A10.2 10.2 0 0122 12.34V19a3 3 0 01-3 3h-5.917C7.049 22 2.4 18.1 2.025 12.716A10 10 0 0112.016 2c.227 0 .456.009.684.024zM14 18v-6a2 2 0 00-2-2h-1a1 1 0 000 2h1v6a1 1 0 002 0z"></path>
                      </svg>
                    </span>
                  </h4>
                  <div className="analytics-page-date-picker">
                    <div className="analytics-page-body-remaining-status">
                      <h3>
                        21 <span>Remaining</span>
                      </h3>
                    </div>
                    <DatePicker
                      render={<InputIcon />}
                      range
                      plugins={[<DatePickerHeader />]}
                      value={value}
                      onChange={setValue}
                    />
                  </div>
                </div>
                <div className="analytics-page-body-card-bottom-sec">
                  <CustomTrainingGraph />
                </div>
              </div>
              <div className="analytics-page-body-card">
                <div className="analytics-page-body-card-top-sec">
                  <h4>
                    Chat
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        fill="#D0D1D2"
                        height="20"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.5 6.5a1.5 1.5 0 01-3 0 1.5 1.5 0 013 0zM24 19v-6.66A12.209 12.209 0 0012.836.028 12 12 0 00.029 12.854C.471 19.208 6.082 24 13.083 24H19a5.006 5.006 0 005-5zM12.7 2.024A10.2 10.2 0 0122 12.34V19a3 3 0 01-3 3h-5.917C7.049 22 2.4 18.1 2.025 12.716A10 10 0 0112.016 2c.227 0 .456.009.684.024zM14 18v-6a2 2 0 00-2-2h-1a1 1 0 000 2h1v6a1 1 0 002 0z"></path>
                      </svg>
                    </span>
                  </h4>
                  <div className="analytics-page-date-picker">
                    <DatePicker
                      render={<InputIcon />}
                      range
                      plugins={[<DatePickerHeader />]}
                      value={value}
                      onChange={setValue}
                    />
                  </div>
                </div>
                <div className="analytics-page-body-card-bottom-sec">
                  <CustomTrainingGraph />
                </div>
              </div>
              <div className="analytics-page-body-card">
                <div className="analytics-page-body-card-top-sec">
                  <h4>
                    Agent
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        fill="#D0D1D2"
                        height="20"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.5 6.5a1.5 1.5 0 01-3 0 1.5 1.5 0 013 0zM24 19v-6.66A12.209 12.209 0 0012.836.028 12 12 0 00.029 12.854C.471 19.208 6.082 24 13.083 24H19a5.006 5.006 0 005-5zM12.7 2.024A10.2 10.2 0 0122 12.34V19a3 3 0 01-3 3h-5.917C7.049 22 2.4 18.1 2.025 12.716A10 10 0 0112.016 2c.227 0 .456.009.684.024zM14 18v-6a2 2 0 00-2-2h-1a1 1 0 000 2h1v6a1 1 0 002 0z"></path>
                      </svg>
                    </span>
                  </h4>
                  <div className="analytics-page-date-picker">
                    <div className="analytics-page-body-remaining-status">
                      <h3>
                        21 <span>Remaining</span>
                      </h3>
                    </div>
                    <DatePicker
                      render={<InputIcon />}
                      range
                      plugins={[<DatePickerHeader />]}
                      value={value}
                      onChange={setValue}
                    />
                  </div>
                </div>
                <div className="analytics-page-body-card-bottom-sec">
                  <CustomTrainingGraph />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsIndex;
