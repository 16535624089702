import React from "react";
import { Image, Button, Container, Row, Col } from "react-bootstrap";

const MessengerDemoSteps = (props) => {
  return (
    <>
        <div className="customize-steps-sec">
      <Container>
        <Row>
          <Col md={12}>
            <div className="customize-steps-btn-sec">
              <h3>Setting Up Messenger Integration with EFI Assist</h3>
              <a
                className="settings-btn"
                download={true}
                target="_blank"
                rel="noreferrer"
                href={
                  window.location.origin +
                  "/installation-steps/Messenger-Installation-Steps.pdf"
                }
              >
                Download Doc
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="customize-steps-list-sec">
              <ol className="customize-steps-list list-unstyled">
                <li>
                  <span>1</span>
                  <div>
                  Log in to your Facebook account at <a href="https://facebook.com/" target="_blank" rel="noreferrer">https://facebook.com/</a>.
                  </div> 
                </li>
                <li>
                  <span>2</span>
                  <div>
                  Visit <a href="https://developers.facebook.com/" target="_blank" rel="noreferrer">https://developers.facebook.com/</a> and click on "My Apps."
                  </div>
                </li>
                <li>
                  <span>3</span> 
                  <div>
                  Create a new app:
                    <h6>Click on "Create App."</h6>
                    <h6>Choose "Other" and click "Next."</h6>
                    <h6>Select "Business" and click "Next."</h6>
                    <h6>Provide a name for your app.</h6>
                    <h6>Choose a Business account (required for later steps).</h6>
                    <h6>Click "Create app."</h6>
                  </div>
                </li>
                <li>
                  <span>4</span> 
                  <div>
                  Add Messenger to your app:
                    <h6>On the "Add products to your app" page, select "Messenger" and click "Set up."</h6>
                  </div>
                </li>
                <li>
                  <span>5</span> 
                  <div>
                  Obtain your Facebook Page ID and Access Token:
                    <h6>In the next page, navigate to the "Access Tokens" section.</h6>
                    <h6>Add your Facebook Page by clicking "Add or remove Pages."</h6>
                    <h6>Follow the instructions in the popup to add your page to the app.</h6>
                    <h6>Copy your Facebook Page ID and store it safely.</h6>
                    <h6>After adding the page, click "Generate Token."</h6>
                    <h6v>Check "I understand" in the popup and copy the Access Token. Store it securely.</h6v>
                  </div>
                </li>
                <li>
                  <span>6</span> 
                  <div>
                  Configure EFI Assist panel:
                    <h6>Access EFI Assist panel, choose a bot, and click "Source."</h6>
                  </div>
                </li>
                <li>
                  <span>7</span> 
                  <div>
                  Connect Messenger to EFI Assist:
                    <h6>Select "Messenger" and paste the Facebook Page ID and Token from your Facebook App.</h6>
                    <h6>Click "Update Changes" and then copy the Webhook URL.</h6>
                  </div>
                </li>
                <li>
                  <span>8</span> 
                  <div>
                  Configure Facebook App:
                    <h6>Return to the Facebook App page.</h6>
                    <h6>In the webhook section, click "Add Callback URL."</h6>
                    <h6>In the popup, paste the Webhook URL from the previous step into the "Callback URL" textbox.</h6>
                    <h6>Type "EFI Assist" in the "Verify token" textbox.</h6>
                    <h6>Click "Verify & Save."</h6>
                    <h6>Click "Add subscriptions," select "messages" in the popup, and then click "Save."</h6>
                  </div>
                </li>
                <li>
                  <span>9</span> 
                  <div>
                  Complete integration:
                    <h6>Your Facebook Page is now connected with EFI Assist.</h6>
                  </div>
                </li>
                <li>
                  <span>10</span> 
                  <div>
                  Obtain Messenger script for user conversations:
                    <h6>Visit <a href="https://business.facebook.com/" target="_blank" rel="noreferrer">https://business.facebook.com/</a>.</h6>
                    <h6>Click "Inbox" in the sidebar, then go to "Settings" > "View all settings."</h6>
                  </div>
                </li>
                <li>
                  <span>11</span> 
                  <div>
                  Set up Chat Plugin:
                    <h6>Click "Chat Plugin" > "Set up" > "Standard."</h6>
                  </div>
                </li>
                <li>
                  <span>12</span> 
                  <div>
                  Finalize integration on your website:
                    <h6>Set the language and add the domain where the script will be added.</h6>
                    <h6>Copy the provided script.</h6>
                    <h6>Click "Next" and then "Done."</h6>
                  </div>
                </li>
                <li>
                  <span>13</span> 
                  <div>
                  Add the script to your website and initiate conversation.
                    <h6>Congratulations, you've successfully integrated EFI Assist with Messenger!</h6>
                  </div>
                </li>
              </ol>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
};

export default MessengerDemoSteps;
