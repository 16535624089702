import React, { useEffect, useRef, useState } from "react";
import { Form, Button, InputGroup, OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "react-styled-toggle";
import Skeleton from "react-loading-skeleton";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation, withTranslation } from "react-multi-lang";
import {
  botsStoreStart,
  singleBotViewStart,
} from "../../../store/slices/BotSlice";
import NoDataFound from "../../Helper/NoDataFound";
import Select from "react-select";
import useBot from "../../../hooks/useBot";
import DelayMessage from "../../Delaymessages/DelayMessage";

const ChatCustomize = (props) => {
  const [tabdelay, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!tabdelay);

  const t = useTranslation("settings.customise");
  const dispatch = useDispatch();
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const singleBotView = useSelector((state) => state.bots.singleBotView);
  const botsStore = useSelector((state) => state.bots.botsStore);
  const { promptOptions, handlePrompt } = useBot();
  const [promptLoading, setPromptLoading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const formRef = useRef(null);

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #F3F2ED!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "45px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#50506B80",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#212529",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#212529!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#212529!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        '&:active': { backgroundColor: isSelected ? "#f7f7f7" : "#fff" },
        '&:hover': { backgroundColor: "#f7f7f7" }
      };
    },
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name.required"))
      .matches(/^[^<>]*$/, t("name.invalid"))
      .trim()
      .notOneOf([""]),
    website: Yup.string()
      .required(t("website.required"))
      .matches(/^(?!https?|http:\/\/|https:\/\/).*/,t("website.http"))
      .matches(/^(?=\S*$)/, t("website.white_space"))
      .matches(/^[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,}(:[0-9]+)?(\/.*)?$/i, t("website.invalid"))
      .matches(/^[^<>]*$/, t('website.invalid')),
    support_email: Yup.string().email(t("support.invalid")),
    // welcome_message: Yup.string().required(t("welcome.required")),
    error_message: Yup.string().required(t("error.required"))
      .matches(/^[^<>]*$/, t('error.invalid')),
    prompt_title: Yup.string().required(t("prompt_title.required")),
    description: Yup.string().matches(/^[^<>]*$/, t("about.invalid")).matches(/^(?=\S*$)/u, t("about.white_space")),
    bot_instructions: Yup.string().matches(/^[^<>]*$/, t("error.invalid")),
  });

  const handleBotsStore = (values) => {
    dispatch(
      botsStoreStart({
        ...values,
        website: "https://" + values.website,
      })
    );
  };

  useEffect(() => {
    if (Object.keys(selectedBot).length > 0)
      dispatch(singleBotViewStart({ bot_id: selectedBot.bot_id }));
  }, [selectedBot]);

  const handleBotInstruction = async (selectPrompt, setFieldValue) => {
    setPromptLoading(true);
    let res = await handlePrompt(selectPrompt);
    if (res && res.status_code != 500) {
      setFieldValue("bot_instructions", res.prompt);
      setFieldValue("prompt_title", selectPrompt.domain);
    } else {
      setFieldValue("bot_instructions", "");
      setFieldValue("prompt_title", "");
    };
    setPromptLoading(false);
  };

  useEffect(() => {
    if(!skipRender && !botsStore.loading && Object.keys(botsStore.data).length > 0){
      formRef.current.setFieldValue("name", botsStore.data.bot.name.trim());
    }
    setSkipRender(false);
  }, [botsStore]);

  return (
    <>
      <div className="new-chat-customize-sec">
        <div className="new-source-script-header-sec align-items-center">
          <div className="new-source-script-header-left-sec">
            {tabdelay ? (
              <Button variant="ghost" onClick={showSidebar}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="10"
                  fill="none"
                  viewBox="0 0 12 10"
                >
                  <path
                    fill="#212529"
                    fillRule="evenodd"
                    d="M4.769 9.796L.146 5.34a.467.467 0 010-.678L4.77.204a.741.741 0 011.02 0 .679.679 0 010 .983L1.835 5l3.954 3.813a.68.68 0 010 .983.74.74 0 01-1.02 0z"
                    clipRule="evenodd"
                  ></path>
                  <path
                    fill="#212529"
                    d="M11 6a1 1 0 100-2v2zM1 6h10V4H1v2z"
                  ></path>
                </svg>
                {t("back_to_customise")}
              </Button>
            ) : (
              <h3 className="mb-0">{t("customise")}</h3>
            )}
          </div>
          <div className="new-source-script-header-right-sec">
            <div className="my-subscription-header-action-btn-sec btn-grid-auto">
              <Button
                type="button"
                className="contact-us-btn"
                onClick={() => (!tabdelay ? showSidebar() : setModalShow(true))}
              >
                {!tabdelay ? t("delay_message") : t("add_delay_message")}
                &nbsp;
                <span>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <div className="prompt-tooltip-body">
                        <p>{t("delay_message_info")}</p>
                      </div>
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 512 512"
                      fill="#D5D5D5"
                    >
                      <path d="M256 512a256 256 0 100-512 256 256 0 100 512zm0-384c13.3 0 24 10.7 24 24v112c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zm-32 224a32 32 0 1164 0 32 32 0 11-64 0z"></path>
                    </svg>
                  </OverlayTrigger>
                </span>
              </Button>
            </div>
          </div>
        </div>
        {singleBotView.loading && !tabdelay ? (
          [...Array(10)].map((i, key) => (
            <div className="settings-content-loader" key={key}>
              <Skeleton className="mb-1" height={20} width="20%" />
              <Skeleton height={50} width="100%" />
            </div>
          ))
        ) : Object.keys(singleBotView.data).length > 0 ? (
          <Formik
            initialValues={{
              bot_id: singleBotView.data.bot.bot_id,
              name: singleBotView.data.bot.name.trim(),
              website: singleBotView.data.bot.website.replace("https://", ""),
              description: singleBotView.data.bot.description,
              welcome_message: singleBotView.data.bot.welcome_message,
              error_message: singleBotView.data.bot.error_message,
              fallback_message: singleBotView.data.bot.fallback_message,
              support_email: singleBotView.data.bot.support_email,
              select_prompt: singleBotView.data.bot.prompt_title,
              prompt_title: singleBotView.data.bot.prompt_title,
              bot_instructions: singleBotView.data.bot.bot_instructions,
              widget_type: singleBotView.data.bot.widget_type,
              general_ai: singleBotView.data.bot.general_ai,
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
            // onSubmit={handleBotsStore}
          >
            {({ setFieldValue, values, isValid }) => (
              <FORM
                className={
                  tabdelay ? "onboarding-form hidden" : "onboarding-form show"
                }
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("name.label")}</Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder={t("name.placeholder")}
                    name="name"
                    value={values.name}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                      props.setBotDetails({
                        ...props.botDetails,
                        name: e.target.value,
                      });
                    }}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="name"
                    className="errorMsg"
                  />
                </Form.Group>
                <div className="mb-3">
                  <Form.Label>{t("website.label")}</Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                      https://
                    </InputGroup.Text>
                    <Field
                      className="form-control"
                      placeholder={t("website.placeholder")}
                      aria-label="Website"
                      aria-describedby="basic-addon1"
                      name="website"
                      onChange={(e) => {
                        setFieldValue("website", e.target.value);
                        props.setBotDetails({
                          ...props.botDetails,
                          website: "https://" + e.target.value,
                        });
                      }}
                    />
                  </InputGroup>
                  <ErrorMessage
                    component={"div"}
                    name="website"
                    className="errorMsg"
                  />
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("about.label")}</Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    as="textarea"
                    placeholder={t("about.placeholder")}
                    name="description"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="description"
                    className="errorMsg"
                  />
                </Form.Group>
                {/* <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("welcome.label")}</Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder={t("welcome.placeholder")}
                    name="welcome_message"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="welcome_message"
                    className="errorMsg"
                  />
                </Form.Group> */}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("error.label")} &nbsp;
                    <span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <div className="prompt-tooltip-body">
                            <p>{t("error.info")}</p>
                          </div>
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 512 512"
                          fill="#D5D5D5"
                        >
                          <path d="M256 512a256 256 0 100-512 256 256 0 100 512zm0-384c13.3 0 24 10.7 24 24v112c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zm-32 224a32 32 0 1164 0 32 32 0 11-64 0z"></path>
                        </svg>
                      </OverlayTrigger>
                    </span>
                  </Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder={t("error.placeholder")}
                    name="error_message"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="error_message"
                    className="errorMsg"
                  />
                </Form.Group>
                {/* <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("fallback.label")}</Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder={t("fallback.placeholder")}
                    name="fallback_message"
                  />
                </Form.Group> */}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("support.label")} &nbsp;
                    <span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <div className="prompt-tooltip-body">
                            <p>{t("support.info")}</p>
                          </div>
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 512 512"
                          fill="#D5D5D5"
                        >
                          <path d="M256 512a256 256 0 100-512 256 256 0 100 512zm0-384c13.3 0 24 10.7 24 24v112c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zm-32 224a32 32 0 1164 0 32 32 0 11-64 0z"></path>
                        </svg>
                      </OverlayTrigger>
                    </span>
                  </Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    name="support_email"
                    placeholder={t("support.placeholder")}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="support_email"
                    className="errorMsg"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("select_prompt")} &nbsp;
                    <span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <div className="prompt-tooltip-body">
                            <p>{t("prompt_info")}</p>
                          </div>
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 512 512"
                          fill="#D5D5D5"
                        >
                          <path d="M256 512a256 256 0 100-512 256 256 0 100 512zm0-384c13.3 0 24 10.7 24 24v112c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zm-32 224a32 32 0 1164 0 32 32 0 11-64 0z"></path>
                        </svg>
                      </OverlayTrigger>
                    </span>
                  </Form.Label>
                  <Select
                    options={promptOptions}
                    styles={customStyles}
                    value={{
                      label: values.select_prompt,
                      value: values.select_prompt,
                    }}
                    onChange={(selectOption) => {
                      setFieldValue("select_prompt", selectOption.value);
                      if (selectOption.value != "Others") {
                        handleBotInstruction(
                          {
                            domain: selectOption.value,
                            bot_name: values.name,
                            company: values.description,
                          },
                          setFieldValue
                        );
                      }
                    }}
                  />
                </Form.Group>
                {values.select_prompt === "Others" && (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>{t("prompt_title.label")}</Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder={t("prompt_title.placeholder")}
                      name="prompt_title"
                      onChange={(e) => {
                        setFieldValue("prompt_title", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="prompt_title"
                      className="errorMsg"
                    />
                  </Form.Group>
                )}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("instruction.label")} &nbsp;
                    <span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <div className="prompt-tooltip-body">
                            <p>{t("instruction.info")}</p>
                          </div>
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 512 512"
                          fill="#D5D5D5"
                        >
                          <path d="M256 512a256 256 0 100-512 256 256 0 100 512zm0-384c13.3 0 24 10.7 24 24v112c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zm-32 224a32 32 0 1164 0 32 32 0 11-64 0z"></path>
                        </svg>
                      </OverlayTrigger>
                    </span>
                  </Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    as="textarea"
                    rows="5"
                    placeholder={t("instruction.placeholder")}
                    name="bot_instructions"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="bot_instructions"
                    className="errorMsg"
                  />
                </Form.Group>
                <div className="allow-ai-auto-reply-switch">
                  <Toggle
                    width={40}
                    height={20}
                    sliderWidth={12}
                    sliderHeight={12}
                    translate={19}
                    backgroundColorChecked="#1f73b7"
                    backgroundColorUnchecked="#D5D5D5"
                    labelRight={t("general_ai")}
                    name="general_ai"
                    checked={values.general_ai}
                    onChange={(e) => {
                      setFieldValue("general_ai", e.target.checked ? 1 : 0);
                    }}
                  />
                </div>
                <div className="ai-auto-note-area">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 512 512"
                    fill="#D5D5D5"
                  >
                    <path d="M256 512a256 256 0 100-512 256 256 0 100 512zm0-384c13.3 0 24 10.7 24 24v112c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zm-32 224a32 32 0 1164 0 32 32 0 11-64 0z"></path>
                  </svg>
                  <p>
                    {t("general_ai_info")}
                  </p>
                </div>
                <div className="new-settings-btn-sec mb-3">
                  <Button
                    className="settings-btn"
                    type="submit"
                    disabled={botsStore.buttonDisable || promptLoading}
                    onClick={() => isValid && handleBotsStore(values)}
                  >
                    {botsStore.buttonDisable ? t("loading") : t("update")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        ) : (
          <NoDataFound />
        )}
        {tabdelay && <div className={tabdelay ? "delay-message-area show " : "delay-message-area hidden"}>
          <DelayMessage modalShow={modalShow} setModalShow={setModalShow} />
        </div>}
      </div>
    </>
  );
};

export default withTranslation(ChatCustomize);
