import React, { useEffect, useState } from "react";
import { Image, InputGroup, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import Pagination from "../Helper/Pagination";
import {
  subscriptionListStart,
  billingInvoiceDownloadStart,
} from "../../store/slices/SubscriptionSlice";
import { profileStart } from "../../store/slices/AdminSlice";
import Skeleton from "react-loading-skeleton";
import { Field, Form as FORM, Formik } from "formik";
import NoBillingExist from "../Helper/NoBillingExist";
import { useTranslation } from 'react-multi-lang'

const BillingsIndex = (props) => {
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #0b0b12de!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor:"pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#0b0b12de",
      fontSize: "1.1em",
      fontWeight: "500",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#0b0b12de",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0b0b12de!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#0b0b12de!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#0b0b12de",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      '&:active': { backgroundColor: isSelected ? "#f7f7f7" : "#fff" }
      };
    },
  };

  const t = useTranslation("billings");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const profile = useSelector((state) => state.admin.profile);
  const subscriptionList = useSelector(
    (state) => state.subscription.subscriptionList
  );
  const billingInvoiceDownload = useSelector(
    (state) => state.subscription.billingInvoiceDownload
  );
  const [skipRender, setSkipRender] = useState(true);
  const [filterStatus, setFilterStatus] = useState(false);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    order_by: searchParams.get("order_by") ? searchParams.get("order_by") : "",
  });

  const options = [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
    { value: "price_hl", label: "Price High to Low" },
    { value: "price_lh", label: "Price Low to High" },
  ];

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(
        subscriptionListStart({
          ...filter,
          skip: 12 * (page - 1),
          take: 12,
        })
      );
    }
  }, [profile, page, filter]);

  useEffect(() => {
    if(!skipRender && !billingInvoiceDownload.loading && Object.keys(billingInvoiceDownload.data).length > 0){
      const link = document.createElement("a");
      link.href = billingInvoiceDownload.data.url;
      link.setAttribute("target", '_blank'); //or any other extension
      link.setAttribute("download", `order_.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  }, [billingInvoiceDownload])

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
    setSkipRender(false);
  }, [searchParams.get("page")]);

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    params += searchParams.get("order_by")
      ? `order_by=${searchParams.get("order_by")}&`
      : "";
    navigate(`/billings?${params}page=${selected + 1}`);
  };

  useEffect(() => {
    let option = options.find((option) => option.value == searchParams.get("order_by"));
    if(option)
    {
      setFilterStatus(option)
    }
  }, [searchParams.get("order_by")]);

  return (
    <>
      {profile.loading ? (
        <PageLoader />
      ) : Object.keys(profile.data).length > 0 ? (
        <>
          <div className="admin-ui-body-sec">
            <div className="new-billings-sec">
              <div className="new-billings-header-sec">
                <div className="new-billings-header-left-sec">
                  <h3>{t("payment_history")}</h3>
                  <div className="new-billing-search-sec">
                    <Formik
                      initialValues={{
                        search_key: searchParams.get("search_key")
                          ? searchParams.get("search_key")
                          : "",
                        order_by: searchParams.get("order_by")
                          ? searchParams.get("order_by")
                          : filter.order_by,
                      }}
                      onSubmit={(val) => {
                        setFilter({
                          ...filter,
                          search_key: val.search_key.replace("#", ""),
                        });
                        navigate(searchParams.get("order_by") ? `/billings?search_key=${val.search_key.replace('#',"")}&order_by=${searchParams.get("order_by")}` : `/billings?search_key=${val.search_key.replace('#',"")}`);
                      }}
                    >
                      {({ values, setFieldValue, resetForm }) => (
                        <FORM className="form">
                          <InputGroup className="mb-0">
                            <Field
                              placeholder={t("search")}
                              type="text"
                              className="form-control"
                              name="search_key"
                            />
                            {searchParams.get("search_key") && (
                            <InputGroup.Text id="basic-addon2" className={values.search_key && "after-search-input-text"}>
                                <button
                                  type="button"
                                  className="search-btn"
                                  onClick={() => {
                                    setFieldValue("search_key", "");
                                    setFilter({
                                      ...filter,
                                      search_key: "",
                                    });
                                    navigate(searchParams.get("order_by") ? `/billings?order_by=${searchParams.get("order_by")}` : `/billings`);
                                  }}
                                >
                                  {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    fill="#3E4351"
                                    data-name="Layer 1"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M16.707 8.707L13.414 12l3.293 3.293-1.414 1.414L12 13.414l-3.293 3.293-1.414-1.414L10.586 12 7.293 8.707l1.414-1.414L12 10.586l3.293-3.293 1.414 1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-2 0c0-5.514-4.486-10-10-10S2 6.486 2 12s4.486 10 10 10 10-4.486 10-10z"></path>
                                  </svg> */}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      stroke="#3E4351"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.5"
                                      d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10zM9.17 14.83l5.66-5.66M14.83 14.83L9.17 9.17"
                                    ></path>
                                  </svg>
                                </button>
                            </InputGroup.Text>
                            )}
                            <InputGroup.Text id="basic-addon2">
                              <button className="search-btn" type="submit">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      stroke="#3E4351"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.5"
                                      d="M11.5 21a9.5 9.5 0 100-19 9.5 9.5 0 000 19zM22 22l-2-2"
                                    ></path>
                                </svg>
                              </button>
                            </InputGroup.Text>
                          </InputGroup>
                        </FORM>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="new-billings-header-right-sec">
                  <Select
                    options={options}
                    styles={customStyles}
                    isClearable
                    defaultValue={filterStatus ? filterStatus : null}
                    isSearchable={false}
                    onChange={(selectedOption) => {
                      if(selectedOption)
                      {
                        setFilter({
                        ...filter,
                        order_by: selectedOption.value,
                        });
                        navigate(filter.search_key ? `/billings?search_key=${filter.search_key}&order_by=${selectedOption.value}` : `/billings?order_by=${selectedOption.value}`);
                      }
                      else{
                        setFilter({
                          ...filter,
                          order_by: "",
                        });
                        navigate(filter.search_key ? `/billings?search_key=${filter.search_key}` : "/billings");
                      }
                    }}
                    placeholder={
                      <div className="placeholder-flex">
                        <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 25 24"
                            >
                              <path
                                stroke="#171717"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit="10"
                                strokeWidth="1.5"
                                d="M3.85 2h8.9c.74 0 1.35.61 1.35 1.35v1.48c0 .54-.34 1.21-.67 1.55l-2.9 2.56c-.4.34-.67 1.01-.67 1.55v2.9c0 .4-.27.94-.61 1.15l-.94.61c-.88.54-2.09-.07-2.09-1.15v-3.57c0-.47-.27-1.08-.54-1.42l-2.56-2.7c-.34-.34-.61-.94-.61-1.35V3.41C2.5 2.61 3.11 2 3.85 2z"
                              ></path>
                              <path
                                stroke="#171717"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                                d="M2.5 12v3c0 5 2 7 7 7h6c5 0 7-2 7-7V9c0-3.12-.78-5.08-2.59-6.1-.51-.29-1.53-.51-2.46-.66M13.5 13h5M11.5 17h7"
                              ></path>
                            </svg>
                        {t("filter")}
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="new-billings-body-sec">
                <div className="new-billings-table-sec">
                  {subscriptionList.loading ? (
                    [...Array(3)].map((i, key) => (
                      <div key={key}>
                        <Skeleton count={1} height={50} />
                        <Skeleton count={1} height={50} />
                        <Skeleton count={1} height={50} />
                      </div>
                    ))
                  ) : Object.keys(subscriptionList.data).length > 0 ? (
                    <>
                      {subscriptionList.data.subscriptions &&
                      subscriptionList.data.subscriptions.length > 0 ? (
                        <div className="new-dashboard-recent-chat-list-table-sec">
                          <Table responsive borderless>
                            {/* <thead>
                              <tr>
                                <th>{t("s_no")}</th>
                                <th>{t("payment_id")}</th>
                                <th>{t("date")}</th>
                                <th>{t("plan")}</th>
                                <th>{t("amount")}</th>
                                <th>{t("status")}</th>
                                <th>{t("actions")}</th>
                              </tr>
                            </thead> */}
                            <tbody>
                              {subscriptionList.data.subscriptions.map(
                                (subscription, key) => (
                                  <tr key={key}>
                                    <td>
                                      <div className="invoice-checkbox">
                                        #{key+1}
                                      </div>
                                    </td>
                                    <td>{subscription.payment_id}</td>
                                    <td>{subscription.paid_date}</td>
                                    <td>
                                      <div className="billing-plan-details">
                                        <Image
                                          className="billing-plan-icon"
                                          src={
                                            window.location.origin +
                                            "/images/billings/base-plan.svg"
                                          }
                                        />
                                        {subscription.plan_name ?? "N/A"}
                                      </div>
                                    </td>
                                    <td>
                                      <p>{subscription.amount}</p>
                                      {subscription.coupon_amount ? (<p className="coupon-amount">Coupon Amount: (- {subscription.coupon_amount_formatted})</p>) : null}
                                    </td>
                                    <td>{subscription.subscription_status}</td>
                                    <td>
                                      {billingInvoiceDownload.buttonDisable && 
                                      billingInvoiceDownload.data.subscription_unique_id == subscription.unique_id ? (
                                        <Image
                                          className="btn-loader"
                                          src={
                                            window.location.origin +
                                            "/images/loader/btn-loader.gif"
                                          }
                                        />
                                      ) : (
                                        <Button className="billing-download-btn" onClick={() => dispatch(billingInvoiceDownloadStart({subscription_unique_id: subscription.unique_id}))}>
                                          {/* <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="#0d6efd"
                                            viewBox="0 0 24 24"
                                          >
                                            <g data-name="01 align center">
                                              <path d="M12.032 19a2.991 2.991 0 002.122-.878l3.919-3.922-1.414-1.41-3.633 3.634L13 0h-2l.026 16.408-3.62-3.62L5.992 14.2l3.919 3.919a2.992 2.992 0 002.121.881z"></path>
                                              <path d="M22 16v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v5a3 3 0 003 3h18a3 3 0 003-3v-5z"></path>
                                            </g>
                                          </svg> */}
                                          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#ff916f"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-download"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" /><path d="M7 11l5 5l5 -5" /><path d="M12 4l0 12" /></svg>
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </div>
                      ) : (
                        <NoBillingExist />
                      )}
                      <div className="new-billings-footer-sec">
                        <div className="new-billings-pagination-sec">
                          <Pagination
                            page={page}
                            pageCount={Math.ceil(
                              subscriptionList.data.total_subscriptions / 12
                            )}
                            handlePageClick={handlePageClick}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <SomethingWentWrong
                      buttonText="Retry"
                      handleClick={() => dispatch(subscriptionListStart())}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SomethingWentWrong
          buttonText="Retry"
          handleClick={() => dispatch(profileStart())}
        />
      )}
    </>
  );
};

export default BillingsIndex;
