import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";

const NotChatFound = () => {

  const t = useTranslation("helper.no_chat_found");
  return (
    <>
      <div className="no-bot-exists-card no-chat-found-card">
        <div className="no-bot-exists-img-sec">
          <Image
            className="no-bot-exists-img"
            src={
              window.location.origin + "/images/no-data/no-chat-exists-img.svg"
            }
          />
        </div>
        <div className="no-bot-exists-info">
          <h4>{t("heading")}</h4>
        </div>
      </div>
    </>
  );
};

export default NotChatFound;
