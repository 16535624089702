import AdminSlice from "./AdminSlice";
import CategorySlice from "./CategorySlice";
import SubscriptionSlice from "./SubscriptionSlice";
import ChatSlice from "./ChatSlice";
import FineTuneSlice from "./FineTuneSlice";
import SettingsSlice from "./SettingsSlice";
import SupportTicketSlice from "./SupportTicketSlice";
import OnboardingSlice from "./OnboardingSlice";
import BotSlice from "./BotSlice";
import CommonSlice from "./CommonSlice";
import DashboardSlice from "./DashboardSlice";
import FaqSlice from "./FaqSlice";
import PageSlice from "./PageSlice";
import AgentSlice from "./AgentSlice";

export const reducers = {
    admin: AdminSlice,
    category: CategorySlice,
    subscription: SubscriptionSlice,
    chat: ChatSlice,
    fineTune: FineTuneSlice,
    settings: SettingsSlice,
    supportTicket: SupportTicketSlice,
    onboarding: OnboardingSlice,
    bots: BotSlice,
    common: CommonSlice,
    dashboard: DashboardSlice,
    faq: FaqSlice,
    page: PageSlice,
    agent: AgentSlice,
};