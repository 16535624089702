import React from "react";
import { Image, Button, Container, Row, Col } from "react-bootstrap";

const ZenDeskDemoSteps = (props) => {
  return (
    <>
      <div className="customize-steps-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="customize-steps-btn-sec">
                <h3>Setting Up Zendesk Integration with EFI Assist</h3>
                <a
                  className="settings-btn"
                  download={true}
                  target="_blank"
                  rel="noreferrer"
                  href={
                    window.location.origin +
                    "/installation-steps/Zendesk-Installation-Steps.pdf"
                  }
                >
                  Download Doc
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="customize-steps-list-sec">
                <p className="note">
                  Go to th url{" "}
                  <a href="https://www.zendesk.com/register" target="_blank">
                    (https://www.zendesk.com/register)
                  </a>
                  . Create account and login to your account.{" "}
                </p>
                <ul className="customize-steps-list list-unstyled">
                  <li>
                    <span>1</span>
                    Click on “Zendesk Products” and select “Chat”.
                  </li>
                  <li>
                    <span>2</span>
                    Click on “Manage Web Widget”
                  </li>
                  <li>
                    <span>3</span>
                    Go to Apps & Integrations {">"} APIs {">"} Conversation API.
                  </li>
                  <li>
                    <span>4</span>
                    Click on “Create API Key”.
                  </li>
                  <li>
                    <span>5</span>
                    <div>
                      Give a name for the api key & click Next.
                      {/* <h6>
                        <em>Note:</em>
                        If the API key (access token) is unavailable or visiting the page for first time, click Generate token displayed adjacent to the Your API Key field.
                      </h6> */}
                    </div>
                  </li>
                  <li>
                    <span>6</span>
                    Copy the APP ID, Key ID & Secret Key.
                  </li>
                  <li>
                    <span>7</span>
                    Go to the EFI Assist panel. Select your bot and fill the
                    required fields.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="customize-steps-key-list-note-sec">
                <div className="customize-steps-key-list-note-box">
                  <div className="customize-steps-key-list-note-card">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#171717"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M9.51 4.23l8.56 4.28c3.84 1.92 3.84 5.06 0 6.98l-8.56 4.28c-5.76 2.88-8.11.52-5.23-5.23l.87-1.73c.22-.44.22-1.17 0-1.61l-.87-1.74C1.4 3.71 3.76 1.35 9.51 4.23zM5.44 12h5.4"
                      ></path>
                    </svg>
                    <p>
                      <span>Widget Type : </span>
                      Zendesk
                    </p>
                  </div>
                  <div className="customize-steps-key-list-note-card">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#171717"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M9.51 4.23l8.56 4.28c3.84 1.92 3.84 5.06 0 6.98l-8.56 4.28c-5.76 2.88-8.11.52-5.23-5.23l.87-1.73c.22-.44.22-1.17 0-1.61l-.87-1.74C1.4 3.71 3.76 1.35 9.51 4.23zM5.44 12h5.4"
                      ></path>
                    </svg>
                    <p>
                      <span>Zendesk Key ID : </span>
                      (Key Id Copied from Step 6).
                    </p>
                  </div>
                  <div className="customize-steps-key-list-note-card">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#171717"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M9.51 4.23l8.56 4.28c3.84 1.92 3.84 5.06 0 6.98l-8.56 4.28c-5.76 2.88-8.11.52-5.23-5.23l.87-1.73c.22-.44.22-1.17 0-1.61l-.87-1.74C1.4 3.71 3.76 1.35 9.51 4.23zM5.44 12h5.4"
                      ></path>
                    </svg>
                    <p>
                      <span>Zendesk Secret Key : </span>
                      (Secret Key Copied from Step 6).
                    </p>
                  </div>
                  <div className="customize-steps-key-list-note-card">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#171717"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M9.51 4.23l8.56 4.28c3.84 1.92 3.84 5.06 0 6.98l-8.56 4.28c-5.76 2.88-8.11.52-5.23-5.23l.87-1.73c.22-.44.22-1.17 0-1.61l-.87-1.74C1.4 3.71 3.76 1.35 9.51 4.23zM5.44 12h5.4"
                      ></path>
                    </svg>
                    <p>
                      <span>Zendesk APP ID : </span>
                      (App Id Copied from Step 6).
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ZenDeskDemoSteps;
