import React, { useState, useEffect } from "react";
import "../OnBoarding/OnBoarding.css";
import OnPremiseInstallation from "./OnPremiseInstallation";
import OnPremiseSuccess from "./OnPremiseSuccess";

const OnPremiseIndex = (props) => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    setStep(1);
  }, []);

  return (
    <>
      <div className="onboarding-new-sec">
        {step == 1 && (
          <div className="step-1">
            <OnPremiseInstallation setStep={setStep} />
          </div>
        )}

        {step == 2 && (
          <div className="step-2">
            <OnPremiseSuccess setStep={setStep} />
          </div>
        )}
      </div>
    </>
  );
};

export default OnPremiseIndex;
