import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import NoDataFound from "../Helper/NoDataFound";
import useDesign from "../../hooks/useDesign";
import { useTranslation, withTranslation } from "react-multi-lang";
import { useDispatch } from "react-redux";
import { selecteInboxBot } from "../../store/slices/ChatSlice";
import TimeElapsed from "../Chat/TimeElapsed";

const DashBoardRecentChatIndex = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("dashboard.recent_chats_table")
  const { getChatTypeName } = useDesign();

  return (
    <>
      <div className="new-dashboard-recent-chat-list-sec">
        <div className="new-dashboard-recent-chat-list-header-sec">
          <h3>{t("label")}</h3>
          {props.chats.length > 0 ? (
          <Link to="/inbox" onClick={()=> dispatch(selecteInboxBot({
            label: "All Chats",
            value: "",
          }))} className="view-all-link">
            {t("view_all")}
          </Link>
          ) : null}
        </div>
        <div className="new-dashboard-recent-chat-list-body-sec">
          <div className="new-dashboard-recent-chat-list-table-sec">
            {props.chats.length > 0 ? (
              <Table responsive borderless>
                {/* <thead>
                  <tr>
                    <th>{t("s_no")}</th>
                    <th>{t("ref_id")}</th>
                    <th>{t("date")}</th>
                    <th>{t("chat_type")}</th>
                  </tr>
                </thead> */}
                <tbody>
                  {props.chats.map((chat, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        <div className="new-dashboard-recent-chat-list-refer-id-sec">
                          <h4>{chat.chat_unique_id}</h4>
                        </div>
                      </td>
                      <td>
                        <div className="new-dashboard-recent-chat-list-refer-id-sec">
                          <ul className="new-dashboard-recent-chat-list-refer-id-list list-unstyled">
                            <li>
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#FF916F"
                                viewBox="0 0 24 24"
                              >
                                <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                                <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                              </svg> */}
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path fill="#FF916F" d="M9.99 22.78c-.6 0-1.17-.3-1.56-.83l-1.2-1.6c0 .01-.05-.02-.07-.02h-.37c-3.42 0-5.54-.93-5.54-5.54v-4c0-4.21 2.57-5.31 4.73-5.5.24-.03.52-.04.81-.04h6.4c3.62 0 5.54 1.92 5.54 5.54v4c0 .29-.01.57-.05.84-.18 2.13-1.28 4.7-5.49 4.7h-.4l-1.24 1.62c-.39.53-.96.83-1.56.83zM6.79 6.75c-.23 0-.45.01-.66.03-2.32.2-3.38 1.47-3.38 4.01v4c0 3.43 1.06 4.04 4.04 4.04h.4c.45 0 .96.25 1.24.61l1.2 1.61c.22.3.5.3.72 0l1.2-1.6c.29-.39.75-.62 1.24-.62h.4c2.54 0 3.81-1.07 4-3.35.03-.24.04-.46.04-.69v-4c0-2.79-1.25-4.04-4.04-4.04h-6.4z"></path><path fill="#FF916F" d="M9.99 14.19c-.56 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.44 1-1 1zM13.19 14.19c-.56 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zM6.8 14.19c-.56 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path><path fill="#FF916F" d="M17.94 16.29c-.2 0-.4-.08-.54-.23a.727.727 0 01-.2-.61c.03-.21.04-.43.04-.66v-4c0-2.79-1.25-4.04-4.04-4.04H6.8c-.23 0-.45.01-.66.03a.76.76 0 01-.61-.2.762.762 0 01-.23-.6c.18-2.16 1.29-4.73 5.5-4.73h6.4c3.62 0 5.54 1.92 5.54 5.54v4c0 4.21-2.57 5.31-4.73 5.5h-.07zM6.92 5.25h6.27c3.62 0 5.54 1.92 5.54 5.54v3.87c1.7-.42 2.5-1.67 2.5-3.87v-4c0-2.79-1.25-4.04-4.04-4.04h-6.4c-2.2 0-3.44.8-3.87 2.5z"></path></svg>
                              {t("chat_messages")}
                              <span className="count">
                                {chat.chat_messages_count}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </td>
                      <td>
                        <p>{chat.age_timestamp ? <TimeElapsed timestamp={chat.age_timestamp} /> : null}</p>
                      </td>
                      <td>
                        <div className={getChatTypeName(chat.widget_type)}>
                          {chat.widget_type_formatted}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation(DashBoardRecentChatIndex);
