import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  botsListFailure,
  botsListSuccess,
  botsStoreFailure,
  botsStoreSuccess,
  singleBotViewFailure,
  singleBotViewSuccess,
  sourceConfigurationSaveFailure,
  sourceConfigurationSaveSuccess,
  botDeleteFailure,
  botDeleteSuccess,
  botScriptUpdateSuccess,
  botScriptUpdateFailure,
} from "../slices/BotSlice";
import { selectedBotSuccess } from "../slices/AdminSlice"

function* botsListAPI(action) {
  yield getCommonSaga({
    apiUrl: "bots",
    payload: action.payload,
    successNotify: false,
    success: botsListSuccess,
    failure: botsListFailure,
  });
}

function* botsStoreAPI(action) {
  const botsListData = yield select(state => state.bots.botsList.data);
  yield getCommonSaga({
    apiUrl: "bots/store",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(botsStoreSuccess(data));
      if (Object.keys(botsListData).length > 0) {
        const item = botsListData.bots ? botsListData.bots.find(bot => bot.bot_id === action.payload.bot_id) : "";
        store.dispatch(botsListSuccess({
          ...botsListData,
          bots: item ? botsListData.bots.map(bot => bot.bot_id === action.payload.bot_id ? data.bot : bot)
          : [data.bot, ...botsListData.bots],
          total_bots: action.payload.bot_id ? botsListData.total_bots : botsListData.total_bots + 1,
        }));
      }
    },
    failure: botsStoreFailure,
  });
}

function* singleBotViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "bots/show",
    payload: action.payload,
    successNotify: false,
    success: singleBotViewSuccess,
    failure: singleBotViewFailure,
  });
}

function* botDeleteAPI(action) {
  const botsList = yield select(state => state.bots.botsList.data);
  const selectedBot = yield select(state => state.admin.selectedBot);
  yield getCommonSaga({
    apiUrl: "bots/destroy",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(botDeleteSuccess({ data: data }));
      if (Object.keys(botsList).length > 0)
        store.dispatch(botsListSuccess(data))
      if (Object.keys(selectedBot).length > 0 && selectedBot.bot_id == action.payload.bot_id)
        store.dispatch(selectedBotSuccess({}));
    },
    failure: botDeleteFailure
  })
}

function* sourceConfigurationSaveAPI(action) {
  const selectedBot = yield select(state => state.admin.selectedBot);
  yield getCommonSaga({
    apiUrl: "bots/source_configuration",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: sourceConfigurationSaveSuccess,
    success: data => {
      store.dispatch(sourceConfigurationSaveSuccess(data));
      if (Object.keys(selectedBot).length > 0)
        store.dispatch(selectedBotSuccess({
          ...selectedBot,
          widget_type: action.payload.widget_type
        }))
    },
    failure: sourceConfigurationSaveFailure,
  });
}

function* botScriptUpdateAPI(action) {
  yield getCommonSaga({
    apiUrl: "bots/bot_script_update",
    payload: action.payload,
    successNotify: true,
    success: botScriptUpdateSuccess,
    failure: botScriptUpdateFailure,
  });
}

export default function* OnboardingSaga() {
  yield all([
    yield takeLatest('bots/botsListStart', botsListAPI),
    yield takeLatest('bots/botsStoreStart', botsStoreAPI),
    yield takeLatest('bots/singleBotViewStart', singleBotViewAPI),
    yield takeLatest('bots/botDeleteStart', botDeleteAPI),
    yield takeLatest('bots/sourceConfigurationSaveStart', sourceConfigurationSaveAPI),
    yield takeLatest('bots/botScriptUpdateStart', botScriptUpdateAPI),
  ]);
}

