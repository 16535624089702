import React, { useEffect, useState } from "react";
import {
  Table,
  Dropdown,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  conversationsListStart,
  moreConversationsListStart,
  fineTuneListStart,
  customTrainingListStart,
} from "../../../store/slices/FineTuneSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../../Helper/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";
import DeleteConversationsModal from "./DeleteConversationsModal";
import { useTranslation } from "react-multi-lang";

const ConversationsIndex = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("settings.conversations")
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const conversationsList = useSelector(
    (state) => state.fineTune.conversationsList
  );
  const conversationsDelete = useSelector(
    (state) => state.fineTune.conversationsDelete
  );
  const [deleteConversations, setDeleteConversations] = useState(false);

  const closeDeleteConversationsModal = () => {
    setDeleteConversations(false);
  };

  useEffect(() => {
    dispatch(conversationsListStart({ bot_id: selectedBot.bot_id, skip: 0, take: 12 }));
    dispatch(customTrainingListStart({ bot_id: selectedBot.bot_id }));
  }, [selectedBot]);

  const fetchMoreData = () => {
    dispatch(
      moreConversationsListStart({
        bot_id: selectedBot.bot_id,
        skip: conversationsList.data.conversational_data.length,
        take: 12,
      })
    );
  };

  const getStatusName = (status) => {
    switch (status) {
      case 1:
        return "progress-badge";
      case 2:
        return "trained-badge";
      case 3:
        return "failed-badge";
      default:
        return "uploaded-badge";
    }
  };

  return (
    <div className="new-settings-finetune-table-sec">
      <div className="new-finetune-faq-table-box-1">
        {conversationsList.loading ? (
          <div className="onboarding-fine-tunings-files-box">
            {[...Array(4)].map((i, key) => (
              <Skeleton height={50} key={key} />
            ))}
          </div>
        ) : Object.keys(conversationsList.data).length > 0 &&
          conversationsList.data.conversational_data &&
          conversationsList.data.conversational_data.length > 0 ? (
          <InfiniteScroll
            dataLength={conversationsList.data.conversational_data.length}
            next={fetchMoreData}
            hasMore={
              conversationsList.data.conversational_data.length <
              conversationsList.data.total_conversational_data
            }
            loader={[...Array(3)].map((i) => (
              <Skeleton height={80} className="mb-3" key={i} />
            ))}
          >
            <div className="new-dashboard-recent-chat-list-table-sec mt-4">
              <Table responsive="md" borderless>
                {/* <thead>
                  <tr>
                    <th>{t("file_name")}</th>
                    <th>{t("created")}</th>
                    <th>{t("type")}</th>
                    <th>{t("status")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead> */}
                <tbody>
                  {conversationsList.data.conversational_data.map((model, i) => (
                    <tr key={i}>
                      <td>{model.name}</td>
                      <td>
                        <span>{model.created_at}</span>
                      </td>
                      <td>
                        <div className="conversation-data-status">
                          <div className="uploaded-badge">
                            {model.platform_formatted}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="conversation-data-status">
                          <div className={getStatusName(model.training_status)}>
                            {model.training_status_formatted}
                          </div>
                        </div>
                      </td>
                      <td className="recent-chat-menu-btn">
                        {conversationsDelete.buttonDisable &&
                        conversationsDelete.data.conversational_data_id ==
                          model.id ? (
                          <Image
                            className="btn-loader"
                            style={{marginLeft: '1em'}}
                            src={
                              window.location.origin +
                              "/images/loader/btn-loader.gif"
                            }
                          />
                        ) : (
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <div className="recent-chat-menu-icon-sec">
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="20"  height="20"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-dots"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /></svg>
                              </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href={model.file} target="_blank">
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-eye"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
                                {t("view")}
                              </Dropdown.Item>
                              {model.training_status != 1 && (
                                <Dropdown.Item
                                  disabled={conversationsDelete.buttonDisable}
                                  onClick={() => setDeleteConversations(model.id)}
                                >
                                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-trash"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                                  {conversationsDelete.buttonDisable
                                    ? t("loading")
                                    : t("delete")}
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </InfiniteScroll>
        ) : (
          <NoDataFound />
        )}
      </div>
      {deleteConversations && (
        <DeleteConversationsModal
          deleteConversations={deleteConversations}
          closeDeleteConversationsModal={closeDeleteConversationsModal}
          setDeleteConversations={setDeleteConversations}
        />
      )}
    </div>
  );
};

export default ConversationsIndex;
