import React, { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { saveWidgetReminderStart } from "../../store/slices/AdminSlice";

const UpcomingMain = (props) => {

  const { widgetReminderData, setWidgetReminderData } = props;
  const dispatch = useDispatch();
  const saveWidgetReminder = useSelector((state) => state.admin.saveWidgetReminder);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!skipRender && !saveWidgetReminder.loading && Object.keys(saveWidgetReminder.data).length > 0) {
        setWidgetReminderData({
            ...widgetReminderData,
            status: 1,
        })
    }
    setSkipRender(false);
  }, [saveWidgetReminder]);

  return (
    <>
      <div className="upcoming-img-frame">
        <Image
          className="coming-soon-image"
          src={window.location.origin + "/images/upcoming/coming-soon.gif"}
          type="image/png"
        />
      </div>
      <div className="upcoming-info-frame">
        <h4>{widgetReminderData.widget_type_formatted} Launching Soon: Get Ready!</h4>
        <div className="upcoming-btn-wrap">
          <Button
            className="onboarding-add-fine-tuning-btn"
            onClick={() => dispatch(saveWidgetReminderStart({ widget_type: widgetReminderData.widget_type, status: 1 }))}
            disabled={saveWidgetReminder.buttonDisable}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 24 24"
            >
              <path
                fill="#000"
                d="M20.859 15.331l-3.772 6.155a5.235 5.235 0 01-3.87 2.477 5.315 5.315 0 01-.628.037 5.212 5.212 0 01-3-.955A4.741 4.741 0 012.9 16.479l-1.315-1.313a5.264 5.264 0 01.955-8.2L8.307 3.4a8.859 8.859 0 0110.327.551l1.659-1.659a1 1 0 111.414 1.414L20.05 5.364a8.951 8.951 0 01.809 9.967zM8.065 21.647l-3.719-3.72a2.721 2.721 0 00.463 3.264 2.827 2.827 0 003.256.456zm9.921-15.6A6.887 6.887 0 009.369 5.1L3.592 8.666A3.265 3.265 0 003 13.752l7.29 7.291a3.265 3.265 0 005.093-.6l3.755-6.125a6.937 6.937 0 00-1.152-8.276zM19.265 24a1 1 0 01-.591-1.808 8.633 8.633 0 003.315-5.407 1 1 0 111.953.43 10.7 10.7 0 01-4.088 6.593 1 1 0 01-.589.192zM1 5.739a1 1 0 01-.8-1.594A10.692 10.692 0 016.913.02a1 1 0 11.4 1.96A8.636 8.636 0 001.8 5.334a1 1 0 01-.8.405z"
                data-original="#000000"
              ></path>
            </svg>
            <span>{saveWidgetReminder.buttonDisable ? "Loading": "Click to be Notified"}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default UpcomingMain;
