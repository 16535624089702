import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
} from "react-bootstrap";
import "./OnBoarding.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fineTuneListStart,
  fineTuneDeleteStart,
} from "../../store/slices/FineTuneSlice";
import NoDataFound from "../Helper/NoDataFound";
import { useTranslation, withTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import Select from "react-select";
import ImportWebpageModal from "./ImportWebpageModal";
import ImportDocumentModal from "./ImportDocumentModal";
import { onboardingCreatedbotStart } from "../../store/slices/OnboardingSlice";
import OnboardingStepsContent from "./OnboardingStepsContent";
import NavLogo from "../Layout/Header/NavLogo";

const FineTuningsFilesIndex = (props) => {
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: '100%',
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "30%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      borderRadius: "10px!important",
      border: "1px solid #F3F2ED!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: 'max-content',
      fontSize: "0.9em",
      cursor:"pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#FF916F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#212529!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#212529!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#212529",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
        '&:active': { backgroundColor: isSelected ? "#f7f7f7" : "#fff" }
      };
    },
  };

  const t = useTranslation("onboarding_fine_tuning");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fineTuneList = useSelector((state) => state.fineTune.fineTuneList);
  const fineTuneDelete = useSelector((state) => state.fineTune.fineTuneDelete);
  const onboardingBot = useSelector((state) => state.onboarding.onboardingbot);
  const fineTuneSave = useSelector((state) => state.fineTune.fineTuneSave);
  const [skipRender, setSkipRender] = useState(true);
  const [importOption, setImportOption] = useState("");
  const [importWebpage, setImportWebpage] = useState(false);
  const [importDocument, setImportDocument] = useState(false);

  useEffect(() => {
    if (
      !skipRender &&
      !onboardingBot.loading &&
      Object.keys(onboardingBot.data).length > 0
    ) {
      dispatch(
        fineTuneListStart({ bot_id: onboardingBot.data.bot_id, take: 20 })
      );
    }
  }, [onboardingBot]);

  const deleteFile = (fine_tune_model_id) => {
    dispatch(fineTuneDeleteStart({ fine_tune_model_id: fine_tune_model_id, onboarding: 1, bot_id: onboardingBot.data.bot_id }));
  };

  const options = [
    { value: 1, label: t("web.heading") },
    { value: 2, label: t("doc.heading") },
  ];

  const closeImportWebpageModal = () => {
    setImportWebpage(false);
  };

  const closeImportDocumentModal = () => {
    setImportDocument(false);
  };

  useEffect(() => {
    dispatch(onboardingCreatedbotStart());
  }, []);

  const handleImportInputChange = (selectedOption) => {
    // setImportOption(selectedOption);
    if (selectedOption.value == 1) setImportWebpage(true);
    else if (selectedOption.value == 2) setImportDocument(true);
  };

  useEffect(() => {
    if (
      !skipRender &&
      !fineTuneSave.loading &&
      Object.keys(fineTuneSave.data).length > 0 &&
      Object.keys(onboardingBot.data).length > 0
    ) {
      setImportOption("");
      dispatch(
        fineTuneListStart({ bot_id: onboardingBot.data.bot_id, take: 20 })
      );
    }
    setSkipRender(false);
  }, [fineTuneSave]);

  return (
    <>
      <div className="onboarding-box">
        <div className="onboarding-left-sec">
          <div className="onboarding-logo-sec">
            <NavLogo/>
          </div>
          <div className="onboarding-steps-card">
            <div className="onboarding-body-sec">
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="onboarding-fine-tunings-files-sec">
                    <div className="onboarding-fine-tunings-files-header-sec">
                      {!fineTuneList.loading &&
                        Object.keys(fineTuneList.data).length > 0 &&
                        fineTuneList.data.fine_tune_models &&
                        fineTuneList.data.fine_tune_models.length === 0 && (
                          <Select
                            options={options}
                            value={importOption}
                            styles={customStyles}
                            isSearchable={false}
                            placeholder={<div>{t("add")}</div>}
                            onChange={(selectedOption) => {
                              handleImportInputChange(selectedOption);
                            }}
                          />
                        )}
                    </div>
                    <div className="onboarding-fine-tunings-files-body-sec">
                      <div className="onboarding-fine-tunings-files-box">
                        {fineTuneList.loading || onboardingBot.loading ? (
                          <>
                            {[...Array(4)].map((i, key) => (
                              <Skeleton count={1} height={50} key={key} />
                            ))}
                          </>
                        ) : Object.keys(fineTuneList.data).length > 0 &&
                          fineTuneList.data.fine_tune_models &&
                          fineTuneList.data.fine_tune_models.length > 0 ? (
                          fineTuneList.data.fine_tune_models.map((model) => (
                            <div className="onboarding-fine-tunings-files-card">
                              <div className="onboarding-fine-tunings-files-name">
                                {model.name}{" "}
                                {model.file_type == "link" && (
                                  <Link
                                    to={model.import_url}
                                    target="_blank"
                                  >
                                    ({model.import_url.replace("https://", "")})
                                  </Link>
                                )}
                              </div>
                              <div className="onboarding-fine-tunings-files-action-btn-sec">
                                {model.file_type == "link" ? (
                                  <Link
                                    to={model.import_url}
                                    target="_blank"
                                    className="onboarding-fine-tunings-download-btn"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="#FF916F"
                                      x="0"
                                      y="0"
                                      enableBackground="new 0 0 512.19 512.19"
                                      version="1.1"
                                      viewBox="0 0 512.19 512.19"
                                      xmlSpace="preserve"
                                    >
                                      <circle cx="256.095" cy="256.095" r="85.333"></circle>
                                      <path d="M496.543 201.034C463.455 147.146 388.191 56.735 256.095 56.735S48.735 147.146 15.647 201.034c-20.862 33.743-20.862 76.379 0 110.123 33.088 53.888 108.352 144.299 240.448 144.299s207.36-90.411 240.448-144.299c20.862-33.744 20.862-76.38 0-110.123zM256.095 384.095c-70.692 0-128-57.308-128-128s57.308-128 128-128 128 57.308 128 128c-.071 70.663-57.337 127.929-128 128z"></path>
                                  </svg>
                                    {t("view")}
                                  </Link>
                                ) : (
                                  <a
                                    className="onboarding-fine-tunings-download-btn"
                                    download={true}
                                    target="_blank"
                                    href={model.file}
                                    rel="noreferrer"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      fill="#FF916F"
                                      height="20"
                                      data-name="Layer 1"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M17.974 7.146a1.028 1.028 0 01-.742-.569c-1.552-3.271-5.143-5.1-8.735-4.438A7.946 7.946 0 002.113 8.64a8.13 8.13 0 00.033 2.89c.06.309-.073.653-.346.901a5.51 5.51 0 00-1.801 4.07c0 3.032 2.467 5.5 5.5 5.5h11c4.136 0 7.5-3.364 7.5-7.5 0-3.565-2.534-6.658-6.026-7.354zm-2.853 7.562l-2.707 2.707a1.993 1.993 0 01-1.405.584l-.009.002-.009-.002a1.993 1.993 0 01-1.405-.584l-2.707-2.707a.999.999 0 111.414-1.414L10 15.001v-5a1 1 0 112 0v5l1.707-1.707a.999.999 0 111.414 1.414z"></path>
                                    </svg>
                                    {t("download")}
                                  </a>
                                )}
                                <Button
                                  className="onboarding-fine-tunings-delete-btn"
                                  onClick={() => deleteFile(model.id)}
                                  disabled={fineTuneDelete.buttonDisable}
                                >
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      fill="#E2434D"
                                      height="20"
                                      x="0"
                                      y="0"
                                      enableBackground="new 0 0 512 512"
                                      version="1.1"
                                      viewBox="0 0 512 512"
                                      xmlSpace="preserve"
                                    >
                                    <path d="M448 85.333h-66.133C371.66 35.703 328.002.064 277.333 0h-42.667c-50.669.064-94.327 35.703-104.533 85.333H64c-11.782 0-21.333 9.551-21.333 21.333S52.218 128 64 128h21.333v277.333C85.404 464.214 133.119 511.93 192 512h128c58.881-.07 106.596-47.786 106.667-106.667V128H448c11.782 0 21.333-9.551 21.333-21.333S459.782 85.333 448 85.333zM234.667 362.667c0 11.782-9.551 21.333-21.333 21.333-11.783 0-21.334-9.551-21.334-21.333v-128c0-11.782 9.551-21.333 21.333-21.333 11.782 0 21.333 9.551 21.333 21.333v128zm85.333 0c0 11.782-9.551 21.333-21.333 21.333-11.782 0-21.333-9.551-21.333-21.333v-128c0-11.782 9.551-21.333 21.333-21.333 11.782 0 21.333 9.551 21.333 21.333v128zM174.315 85.333c9.074-25.551 33.238-42.634 60.352-42.667h42.667c27.114.033 51.278 17.116 60.352 42.667H174.315z"></path>
                                  </svg>
                                  {fineTuneDelete.buttonDisable &&
                                  fineTuneDelete.data.fine_tune_model_id ===
                                    model.id
                                    ? t("loading")
                                    : t("delete")}
                                </Button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <NoDataFound />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="onboarding-footer-sec">
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="onboarding-on-premise-btn-sec">
                    <Button
                      className="onboarding-back-btn"
                      onClick={() => props.setStep(3)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#0b0b12de"
                          d="M20 11H6.83l2.88-2.88A.997.997 0 008.3 6.71L3.71 11.3a.996.996 0 000 1.41L8.3 17.3a.997.997 0 101.41-1.41L6.83 13H20c.55 0 1-.45 1-1s-.45-1-1-1z"
                        ></path>
                      </svg>
                      {t("back")}
                    </Button>
                    <div className="new-chat-inbox-header-right-sec">
                      {!onboardingBot.loading && !fineTuneList.loading && Object.keys(fineTuneList.data).length > 0 && !fineTuneList.data?.fine_tune_models?.length > 0 ? <Button
                        className="onboarding-btn"
                        disabled={fineTuneList.loading || onboardingBot.loading || fineTuneDelete.buttonDisable}
                        onClick={() => navigate("/onboarding-subscription-plans")}
                        hidden={fineTuneList.data?.fine_tune_models?.length > 0}
                      >
                        {t("skip")}
                      </Button>:null}
                      <Button
                        className="onboarding-btn"
                        disabled={
                          fineTuneList.loading || onboardingBot.loading || fineTuneDelete.buttonDisable || 
                          (Object.keys(fineTuneList.data).length <= 0 ||
                            !fineTuneList.data.fine_tune_models ||
                            fineTuneList.data.fine_tune_models.length <= 0)
                        }
                        onClick={() => navigate("/onboarding-subscription-plans")}
                      >
                        {t("next")}
                      </Button>
                    </div>  
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {/* <OnboardingStepsContent step={2} /> */}
      </div>
      {!onboardingBot.loading && (
        <>
          <ImportWebpageModal
            importWebpage={importWebpage}
            closeImportWebpageModal={closeImportWebpageModal}
            setImportWebpage={setImportWebpage}
            onboardingBot={onboardingBot.data}
            onboarding={1}
            step={props.step}
            setStep={props.setStep}
          />
          <ImportDocumentModal
            importDocument={importDocument}
            closeImportDocumentModal={closeImportDocumentModal}
            setImportDocument={setImportDocument}
            onboardingBot={onboardingBot.data}
            onboarding={1}
            step={props.step}
            setStep={props.setStep}
          />
        </>
      )}
    </>
  );
};

export default withTranslation(FineTuningsFilesIndex);
