import React, { useEffect, useState } from "react";
import {
  Nav,
  Row,
  Col,
  Button,
  Tab,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./OnBoarding.css";
import { useDispatch, useSelector } from "react-redux";
import {
  generateStripeIntentStart,
  subscriptionPlansStart,
} from "../../store/slices/SubscriptionSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";
import { useTranslation, withTranslation } from "react-multi-lang";
import OnboardingStepsContent from "./OnboardingStepsContent";
import SubscriptionCard from "../Subscription/SubscriptionCard";
import NavLogo from "../Layout/Header/NavLogo";
import EnterpriseSubscriptionCard from "../Subscription/EnterpriseSubscriptionCard";
import SubscriptionConfirmModal from "../Subscription/SubscriptionConfirmModal";

const OnBoardingSubscriptionPlans = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("onboarding_subscription");
  const subscriptionPlans = useSelector(
    (state) => state.subscription.subscriptionPlans
  );
  const generateStripeIntent = useSelector(
    (state) => state.subscription.generateStripeIntent
  );
  const profile = useSelector((state) => state.admin.profile);
  const [skipRender, setSkipRender] = useState(true);
  const [paymentIntent, setPaymentIntent] = useState({
    subscription_plan_unique_id: "",
    type: "",
  });
  const [subscriptionConfirm, setSubscriptionConfirm] = useState(false);
  const [newPlan, setNewPlan] = useState(null);

  const closeSubscriptionConfirm = () => {
    setSubscriptionConfirm(false);
  }

  useEffect(() => {
    localStorage.setItem('payment_page','onboarding');
    dispatch(subscriptionPlansStart());
  }, []);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if (profile.data.onboarding_step >= 6) navigate("/");
    }
  }, [profile]);

  const handleStripeIntent = (plan, type) => {
    if (plan) {
      dispatch(
        generateStripeIntentStart({
          type: type == "monthly" ? 1 : 2,
          subscription_plan_unique_id: plan.subscription_plan_unique_id,
          subscription_plan: plan,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !generateStripeIntent.loading &&
      Object.keys(generateStripeIntent.data).length > 0
    ) {
      if (generateStripeIntent.data.is_needs_to_pay) {
        navigate("/subscription-payment");
      } else {
        navigate("/settings?tab=fine-tune");
      }
    }
    setSkipRender(false);
  }, [generateStripeIntent]);

  return (
    <>
      <div className="onboarding-box">
        <div className="onboarding-left-sec">
          <div className="onboarding-logo-sec">
            <NavLogo/>
          </div>
          <div className="onboarding-steps-card">
            <div className="onboarding-subscription-plans-sec">
              {subscriptionPlans.loading ? (
                <>
                  {/* <Skeleton
                    count={1}
                    height={50}
                    width={200}
                    className="m-2"
                  /> */}
                  <div className="d-flex">
                    {[...Array(3)].map((i, key) => (
                      <div key={key}>
                        <Skeleton
                        count={1}
                        height={320}
                        width={220}
                        className={"m-2"}
                      />
                      </div>
                    ))}
                  </div>
                </>
              ) : Object.keys(subscriptionPlans.data).length > 0 ? (
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="monthly"
                >
                  <Row>
                    {/* <Col sm={12}>
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="monthly">{t("plans.monthly")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="yearly">
                            <h4>{t("plans.yearly")}</h4>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col> */}
                    <Col sm={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="monthly">
                          <div className="onboarding-subscription-plans-box">
                            {Object.keys(subscriptionPlans.data).length > 0 &&
                            subscriptionPlans.data.subscription_plans.length >
                              0 ? <>   
                              {subscriptionPlans.data.subscription_plans.map(
                                (plan,key) => (
                                  <div key={key}>
                                    <SubscriptionCard
                                      plan={plan}
                                      handleStripeIntent={handleStripeIntent}
                                      type="monthly"
                                      setSubscriptionConfirm={setSubscriptionConfirm}
                                      setNewPlan={setNewPlan}
                                      onboarding={1}
                                    />
                                  </div>
                                )
                              )}
                              <EnterpriseSubscriptionCard
                                plan={{
                                  name: "Enterprise",
                                  price: "Custom",
                                  yearly_save_note_percentage: "",
                                  subscription_plan_unique_id: "",
                                }}
                                type="monthly"
                                onboarding={1}
                                buttonDisable={subscriptionPlans.data.is_enterprise_requested}
                              />
                              </>
                            : (
                              <NoDataFound />
                            )}
                          </div>
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="yearly">
                          <div className="onboarding-subscription-plans-box">
                            {Object.keys(subscriptionPlans.data).length > 0 &&
                            subscriptionPlans.data.subscription_plans.length >
                              0 ? (
                              subscriptionPlans.data.subscription_plans.map(
                                (plan) => (
                                  <>
                                    <SubscriptionCard
                                      plan={plan}
                                      handleStripeIntent={handleStripeIntent}
                                      type="yearly"
                                    />
                                  </>
                                )
                              )
                            ) : (
                              <NoDataFound />
                            )}
                          </div>
                        </Tab.Pane> */}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              ) : (
                <NoDataFound />
              )}
            </div>
            <div className="onboarding-footer-sec">
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="onboarding-on-premise-btn-sec">
                    <Button
                      className="onboarding-back-btn"
                      onClick={() => navigate('/onboarding-steps')}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#0b0b12de"
                          d="M20 11H6.83l2.88-2.88A.997.997 0 008.3 6.71L3.71 11.3a.996.996 0 000 1.41L8.3 17.3a.997.997 0 101.41-1.41L6.83 13H20c.55 0 1-.45 1-1s-.45-1-1-1z"
                        ></path>
                      </svg>
                      {t("payment.back")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {/* <OnboardingStepsContent step={4} /> */}
      </div>
      {subscriptionConfirm && (
        <SubscriptionConfirmModal
          newPlan={newPlan}
          subscriptionConfirm={subscriptionConfirm}
          closeSubscriptionConfirm={closeSubscriptionConfirm}
          handleStripeIntent={handleStripeIntent}
          onboarding={1}
        />
      )}
    </>
  );
};

export default withTranslation(OnBoardingSubscriptionPlans);
