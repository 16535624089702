import React, { useEffect, useState } from "react";
import { Button, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { checkCouponStart, checkCouponSuccess, generateStripeIntentStart } from "../../store/slices/SubscriptionSlice";

const CouponElement = (props) => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();

  const generateStripeIntent = useSelector((state) => state.subscription.generateStripeIntent);
  const checkCoupon = useSelector((state) => state.subscription.checkCoupon);
  const [skipRender, setSkipRender] = useState(true);

  const handleSubmit = (values) => {
    if(values.coupon_code)
    {
      dispatch(checkCouponStart({
        coupon_code: values.coupon_code,
        subscription_plan_unique_id: generateStripeIntent.data.subscription_plan.unique_id,
      }))
    }
  };

  useEffect(() => {
    if(!skipRender && !checkCoupon.loading && Object.keys(checkCoupon.data).length > 0){
      props.setCouponCode(checkCoupon.data.coupon.coupon_code);
      if(checkCoupon.data.coupon.remaining_amount == 0){
        props.setFullDiscount(true);
      }
      else {
        dispatch(
          generateStripeIntentStart({
            type: 1,
            subscription_plan_unique_id: generateStripeIntent.data.subscription_plan.unique_id,
            subscription_plan: generateStripeIntent.data.subscription_plan,
            coupon_code: checkCoupon.data.coupon.coupon_code,
          })
        );
      }
      setStep(3);
    }
    setSkipRender(false)
  }, [checkCoupon])

  useEffect(() => {
    if(!checkCoupon.buttonDisable && Object.keys(checkCoupon.data).length > 0){
      props.setCouponCode(checkCoupon.data.coupon.coupon_code);
      setStep(3);
    }
  }
  , [])

  return (
    <>
      <div className="coupon-section-wrapped">
        <div className="coupon-header-frame">
          <div className="coupon-header-info">
            <h4>{generateStripeIntent.data.subscription_plan.name}</h4>
            <p>
              Billed{" "}
              {generateStripeIntent.data.type == 1 ? "Monthly" : "Yearly"}
            </p>
          </div>
          <div className="coupon-header-price">
            <h3>₹ {generateStripeIntent.data.subscription_plan.monthly_amount}</h3>
          </div>
        </div>
        {step == 1 && (
          <div className="coupon-apply-frame">
            <Button className="coupon-apply-btn" onClick={() => setStep(2)}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#0b0b12de"
                >
                  <path d="M8.321 23.999a3.115 3.115 0 01-2.92-2.023l-.743-1.98a1.121 1.121 0 00-.655-.654l-1.979-.743a3.128 3.128 0 01-1.786-1.727c-.33-.795-.314-1.7.042-2.483l.875-1.925a1.114 1.114 0 000-.925L.28 9.612c-.355-.783-.371-1.688-.042-2.483s.98-1.425 1.786-1.727l1.98-.743c.302-.114.541-.353.654-.655l.743-1.979A3.128 3.128 0 017.128.239c.795-.33 1.7-.314 2.483.042l1.925.875c.295.135.632.134.925 0h.001l1.925-.875A3.133 3.133 0 0116.87.239c.795.329 1.425.98 1.727 1.786l.743 1.98c.114.302.353.541.655.654l1.979.743a3.128 3.128 0 011.786 1.727c.33.795.314 1.7-.042 2.483l-.875 1.925a1.114 1.114 0 000 .925l.875 1.926c.355.783.371 1.688.042 2.483s-.98 1.425-1.786 1.727l-1.98.743a1.121 1.121 0 00-.654.655l-.743 1.979a3.128 3.128 0 01-1.727 1.786 3.129 3.129 0 01-2.483-.042l-1.925-.875a1.11 1.11 0 00-.925 0l-1.925.876a3.128 3.128 0 01-1.291.279zM8.322 2c-.145 0-.29.029-.429.086-.29.12-.51.348-.62.641l-.742 1.979a3.119 3.119 0 01-1.825 1.825l-1.979.742c-.293.11-.521.331-.641.62-.12.29-.115.607.015.892l.875 1.925a3.116 3.116 0 010 2.581l-.874 1.924c-.13.285-.135.602-.015.891.12.29.348.51.641.62l1.979.743a3.116 3.116 0 011.825 1.825l.742 1.979c.11.293.331.521.62.641.29.121.606.114.891-.015l1.925-.875a3.113 3.113 0 012.581 0l1.924.874c.286.129.602.136.891.015.29-.12.51-.348.62-.641l.743-1.979a3.116 3.116 0 011.825-1.825l1.979-.742c.293-.11.521-.331.641-.62.12-.29.115-.606-.015-.891l-.875-1.925a3.116 3.116 0 010-2.581l.874-1.924c.13-.285.135-.602.015-.891-.12-.29-.348-.51-.641-.62l-1.979-.743a3.116 3.116 0 01-1.825-1.825l-.742-1.979c-.11-.293-.331-.521-.62-.641s-.605-.115-.891.015l-1.925.875a3.12 3.12 0 01-2.581 0l-1.924-.875A1.127 1.127 0 008.322 2z"></path>
                  <path d="M9 11c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zM15 17c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zM8.999 17a1 1 0 01-.799-1.6l6-8a1 1 0 011.6 1.2l-6 8a.998.998 0 01-.801.4z"></path>
                </svg>
              </span>
              Add Coupon Code
            </Button>
          </div>
        )}
        {step == 2 && (
          <div className="coupon-apply-frame">
            <Formik
              initialValues={{
                coupon_code: "",
              }}
              validationSchema={Yup.object().shape({
                coupon_code: Yup.string().matches(/^[a-zA-Z0-9]*$/, "Invalid Code"),
              })}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ setFieldValue, values }) => (
                <FORM className="coupon-form">
                  <InputGroup className="">
                    <Field
                      className="form-control"
                      type="text"
                      placeholder="Enter Coupon Code"
                      name="coupon_code"
                      onChange={(e)=> setFieldValue("coupon_code", e.target.value.trim())}
                    />
                    <InputGroup.Text id="basic-addon2">
                      <Button
                        type="submit"
                        disabled={values.coupon_code == "" || checkCoupon.buttonDisable}
                        className="apply-coupon-code"
                        // onClick={() => setStep(3)}
                      >
                        {checkCoupon.buttonDisable ? "Loading" : "Apply"}
                      </Button>
                    </InputGroup.Text>
                  </InputGroup>
                  <ErrorMessage
                    component={"div"}
                    name="coupon_code"
                    className="errorMsg"
                  />
                </FORM>
              )}
            </Formik>
          </div>
        )}
        {step == 3 && (
          <>
            <div className="coupon-applied-card">
              <div className="coupon-applied-status">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 512 512"
                  >
                    <g fillRule="evenodd" clipRule="evenodd">
                      <path
                        fill="#FF916F"
                        d="M256 0C114.8 0 0 114.8 0 256s114.8 256 256 256 256-114.8 256-256S397.2 0 256 0z"
                        data-original="#4bae4f"
                      ></path>
                      <path
                        fill="#000"
                        d="M379.8 169.7c6.2 6.2 6.2 16.4 0 22.6l-150 150c-3.1 3.1-7.2 4.7-11.3 4.7s-8.2-1.6-11.3-4.7l-75-75c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0l63.7 63.7 138.7-138.7c6.2-6.3 16.4-6.3 22.6 0z"
                        data-original="#ffffff"
                      ></path>
                    </g>
                  </svg>
                </span>
                <h4>Coupon Applied</h4>
              </div>
              <div className="coupon-applied-action">
                <Button
                  className="apply-coupon-code"
                  onClick={() => {
                    setStep(2);
                    props.setCouponCode("");
                    props.setFullDiscount(false);
                    dispatch(
                      generateStripeIntentStart({
                        type: 1,
                        subscription_plan_unique_id: props.plan.unique_id,
                        subscription_plan: props.plan,
                      })
                    );
                    dispatch(checkCouponSuccess({}));
                  }}
                >
                  Remove
                </Button>
              </div>
            </div>
            <div className="coupon-applied-code-card">
              <h4>Coupon Code</h4>
              <h3>{checkCoupon.data.coupon.coupon_code}</h3>
            </div>
            <div className="coupon-discount-card">
              <h4>Coupon Discount</h4>
              <h3>- {checkCoupon.data.coupon.coupon_applied_amount}</h3>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default CouponElement;
