import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../../store/slices/CommonSlice";
import {
  Navbar,
  Container,
  Nav,
  Dropdown,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  profileStart,
  selectedBotSuccess,
} from "../../../store/slices/AdminSlice";
import { selecteInboxBot } from "../../../store/slices/ChatSlice";
import useLogout from "../../../hooks/useLogout";
import useSocket from "../../../hooks/useSocket";
import {
  setChatSocket,
  setAudioNotifyCount,
} from "../../../store/slices/ChatSlice";
import { botsListStart } from "../../../store/slices/BotSlice";
import { socketUrl } from "../../../Environment";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import CustomLazyLoad from "../../Helper/CustomLazyLoad";
import { toast } from "react-toastify";
import { useTranslation } from "react-multi-lang";

const bmg = new Audio(window.location.origin + "/audio/new-chat-notify.mp3");
bmg.loop = true;

const AdminHeader = (props) => {

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      borderRadius: "10px",
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #E8EEF0!important",
      borderRadius: "10px",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#0b0b12de",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "500",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#4b4b4b!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#4b4b4b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        "&:active": { backgroundColor: isSelected ? "#f7f7f7" : "#fff" },
      };
    },
  };

  //Don't remove this, used to get audio notification from socket for all the pages
  const { subscribe } = useSocket();
  const t = useTranslation("layout.header")
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logout } = useLogout();
  const profile = useSelector((state) => state.admin.profile);
  const socket = useSelector((state) => state.chat.socket);
  const botsList = useSelector((state) => state.bots.botsList);
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const selectedInboxBot = useSelector((state) => state.chat.selectedInboxBot);
  const audioNotifyCount = useSelector((state) => state.chat.audioNotifyCount);
  const chatList = useSelector((state) => state.chat.chatList);

  const [bots, setBots] = useState([]);
  const [inboxBots, setInboxBots] = useState([]);
  const [selectedBotDetail, setSelectedBotDetail] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Audio Controls
  const [audio, setAudio] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  // Audio Controls End

  const handleDropdownToggle = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length == 0) {
      dispatch(profileStart());
    }
  }, []);

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length > 0) {
      if (!botsList.buttonDisable && !Object.keys(botsList.data).length > 0) {
        dispatch(botsListStart());
      }
      if (profile.data.onboarding_step < 6) navigate("/login");
      else {
        const webSocket = new WebSocket(
          `${socketUrl}?key=${profile.data.api_key}&user_id=${profile.data.role === 2 ? profile.data.client_id : profile.data.user_id}`
        );
        dispatch(setChatSocket(webSocket));
        dispatch(setAudioNotifyCount(profile.data.audio_notify_count));
      }
    }
  }, [profile]);

  useEffect(() => {
    if (!skipRender && !profile.buttonDisable && Object.keys(profile.data).length > 0) {
      if (pathname != "/subscription" && pathname != "/billings")
        dispatch(botsListStart()
        );
    }
    setSkipRender(false);
  }, [pathname]);

  useEffect(() => {
    console.log("socket", socket)
    return () => {
      if (socket && socket.readyState == 1) {
        socket.close();
      }
    };
  }, [socket]);

  // useEffect(() => {
  //   if (
  //     !botsList.loading &&
  //     Object.keys(botsList.data).length > 0 &&
  //     botsList.data.bots.length > 0
  //   ) {
  //     let bots = botsList.data.bots.filter(bot => bot.status == 1).map((bot) => ({
  //       label: bot.name,
  //       value: bot.bot_id,
  //     }));
  //     setInboxBots([{label: "All Chats", value: ""},...bots]);
  //     if (Object.keys(selectedInboxBot).length > 0 && selectedInboxBot.value) {
  //       let bot = bots.find((bot) => bot.bot_id == selectedInboxBot.value);
  //       dispatch(
  //         selecteInboxBot(
  //           bot
  //             ? {
  //                 label: bot.name,
  //                 value: bot.bot_id,
  //               }
  //             : {
  //                 label: "All Chats",
  //                 value: "",
  //               }
  //         )
  //       );
  //     }
  //     setBots(bots);
  //     if (selectedBot && Object.keys(selectedBot).length > 0) {
  //       let bot = bots.find((bot) => bot.value == selectedBot.bot_id);
  //       setSelectedBotDetail(bot || bots[0]);
  //     } else {
  //       setSelectedBotDetail(bots[0]);
  //       dispatch(selectedBotSuccess(botsList.data.bots.filter(bot => bot.status == 1)[0]));
  //     }
  //   }
  // }, [botsList]);

  useEffect(() => {
    if (
      !botsList.loading &&
      Object.keys(botsList.data).length > 0 &&
      botsList.data.bots.length > 0
    ) {
      let bots = botsList.data.bots.map((bot) => ({
        label: bot.name,
        value: bot.bot_id,
      }));
      setInboxBots([
        {
          label: "All Chats",
          value: "",
        },
        ...bots
      ]);
      // if(pathname == "/inbox") {
      if (Object.keys(selectedInboxBot).length > 0 && selectedInboxBot.value) {
        let bot = botsList.data.bots.find((bot) => bot.bot_id == selectedInboxBot.value);
        dispatch(selecteInboxBot(bot ? {
          label: bot.name,
          value: bot.bot_id,
        } : {
          label: "All Chats",
          value: "",
        }));
      }
      // }
      setBots(bots);
      if (Object.keys(selectedBot).length > 0) {
        let bot = botsList.data.bots.find((bot) => bot.bot_id == selectedBot.bot_id);
        setSelectedBotDetail({
          label: bot.name,
          value: bot.bot_id,
        });
      } else {
        setSelectedBotDetail(bots[0]);
        dispatch(selectedBotSuccess(botsList.data.bots[0]));
      }
    }
  }, [botsList]);

  const handleChangeBot = (option) => {
    if (option.value) {
      setSelectedBotDetail(option);
      let bot = botsList.data.bots.find((bot) => bot.bot_id == option.value);
      dispatch(selectedBotSuccess(bot));
    }
    dispatch(
      selecteInboxBot({
        label: option.label,
        value: option.value,
      })
    );
  };

  const getPageName = () => {
    switch (pathname) {
      case "/":
        return "Dashboard";
      case "/settings":
        return "";
      case "/bots":
        return "Bots";
      case "/subscription":
        return "Subscriptions";
      case "/billings":
        return "Billings";
      case "/inbox":
        return "";
      case "/agents":
        return "Your Agents";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (audioNotifyCount > 0) {
      if (audio && !isPlaying) {
        bmg.play();
        setIsPlaying(true);
      }
    } else {
      bmg.pause();
      setIsPlaying(false);
    }
  }, [audioNotifyCount, audio]);

  const handleLogout = () => {
    if (audio && isPlaying) {
      bmg.pause();
      setIsPlaying(false);
    }
    logout();
  };

  // useEffect(() => {
  //   toast.info(
  //     <div>
  //       <span>{t("audio_note")}</span>
  //       <Button
  //         className="admin-your-bot-list-btn mt-3"
  //         onClick={() => setAudio(true)}
  //       >
  //         {t("confirm")}
  //       </Button>
  //     </div>,
  //     {
  //       position: "top-right",
  //       closeButton: true,
  //       autoClose: false,
  //       theme: "colored",
  //       style: {
  //         background: "#fff",
  //         fontSize: "13px",
  //         fontWeight: "bold",
  //         color:"#000",
  //       },
  //     }
  //   );
  // }, []);

  useEffect(() => {
    if (
      // !skipRender &&
      !profile.buttonDisable &&
      Object.keys(profile.data).length > 0 &&
      profile.data.role == 2 &&
      profile.data.role_access &&
      Object.keys(profile.data.role_access).length > 0
    ) {
      if (
        (pathname == "/settings" &&
          !profile.data.role_access?.custom_training_doc &&
          !profile.data.role_access?.custom_training_link &&
          !profile.data.role_access?.source &&
          !profile.data.role_access?.customize &&
          !profile.data.role_access?.faq &&
          !profile.data.role_access?.conversational_data) ||
        (pathname == "/inbox" && !profile.data.role_access?.inbox) ||
        pathname == "/agents" ||
        pathname == "/subscription" ||
        pathname == "/billings" ||
        pathname == "/simulator"
      )
        navigate("/");
    }
    // setSkipRender(false);
  }, [pathname, profile]);

  return (
    <div className="admin-ui-header-sec">
      <Navbar expand="lg">
        <Container fluid>
          <div className="admin-ui-title-sec">
            <div
              className="admin-ui-collapse-btn"
              onClick={() => dispatch(toggleSidebar())}
            >
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#0b0b12de"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M24 3.5c0 .83-.67 1.5-1.5 1.5h-21C.67 5 0 4.33 0 3.5S.67 2 1.5 2h21c.83 0 1.5.67 1.5 1.5zM6.5 20h-5c-.83 0-1.5.67-1.5 1.5S.67 23 1.5 23h5c.83 0 1.5-.67 1.5-1.5S7.33 20 6.5 20zm8-9h-13c-.83 0-1.5.67-1.5 1.5S.67 14 1.5 14h13c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z"></path>
              </svg> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#0b0b12de" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-menu-deep"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 6h16" /><path d="M7 12h13" /><path d="M10 18h10" /></svg>
            </div>
            <h2>{getPageName()}</h2>
            <div
              className="my-seventh-step"
              style={{ minWidth: "125px", minHeight: "49px" }}
            >
              {pathname == "/settings" ? (
                botsList.loading ? (
                  <Skeleton height={45} width={150} />
                ) : Object.keys(botsList.data).length > 0 &&
                  botsList.data.bots.length > 0 ? (
                  bots.length > 0 ? (
                    <div className="admin-header-bot-select">
                      <Select
                        options={bots}
                        styles={customStyles}
                        value={selectedBotDetail}
                        isSearchable={false}
                        placeholder={<div>Select Bot</div>}
                        onChange={(option) => {
                          handleChangeBot(option);
                        }}
                      />
                    </div>
                  ) : null
                ) : null
              ) : null}
              {pathname == "/inbox" ? (
                botsList.loading &&
                  Object.keys(selectedInboxBot).length <= 0 ? (
                  <Skeleton height={45} width={150} />
                ) : Object.keys(botsList.data).length > 0 &&
                  botsList.data.bots.length > 0 ? (
                  bots.length > 0 ? (
                    <div className="admin-header-bot-select">
                      <Select
                        options={inboxBots}
                        styles={customStyles}
                        value={selectedInboxBot}
                        isSearchable={false}
                        placeholder={<div>Select Bot</div>}
                        onChange={(option) => {
                          handleChangeBot(option);
                        }}
                      />
                    </div>
                  ) : null
                ) : null
              ) : null}
            </div>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              width="30"
              height="30"
              fill="#FF916F"
            >
              <path d="M18.5 1h-13A5.506 5.506 0 000 6.5v12C0 21.533 2.467 24 5.5 24h13c3.033 0 5.5-2.467 5.5-5.5v-12C24 3.467 21.533 1 18.5 1zM21 18.5c0 1.378-1.122 2.5-2.5 2.5h-13A2.503 2.503 0 013 18.5v-12C3 5.122 4.122 4 5.5 4h13C19.878 4 21 5.122 21 6.5v12zm-2-11A1.5 1.5 0 0117.5 9h-6a1.5 1.5 0 110-3h6A1.5 1.5 0 0119 7.5zm-11 0a1.5 1.5 0 11-3.001-.001A1.5 1.5 0 018 7.5zm11 5a1.5 1.5 0 01-1.5 1.5h-6a1.5 1.5 0 110-3h6a1.5 1.5 0 011.5 1.5zm-11 0a1.5 1.5 0 11-3.001-.001A1.5 1.5 0 018 12.5zm11 5a1.5 1.5 0 01-1.5 1.5h-6a1.5 1.5 0 110-3h6a1.5 1.5 0 011.5 1.5zm-11 0a1.5 1.5 0 11-3.001-.001A1.5 1.5 0 018 17.5z"></path>
            </svg> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#0b0b12de" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-user-scan"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 9a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M4 8v-2a2 2 0 0 1 2 -2h2" /><path d="M4 16v2a2 2 0 0 0 2 2h2" /><path d="M16 4h2a2 2 0 0 1 2 2v2" /><path d="M16 20h2a2 2 0 0 0 2 -2v-2" /><path d="M8 16a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2" /></svg>
          </Navbar.Toggle>
          {profile.loading ? (
            <Row className="header-profile-loader">
              <Col>
                <Skeleton circle width={50} height={50} />
              </Col>
              <Col>
                <Skeleton circle width={50} height={50} />
              </Col>
              <Col>
                <Skeleton width={100} height={50} />
              </Col>
            </Row>
          ) : Object.keys(profile.data).length > 0 ? (
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {/* <div className="support-help-sec">
                  <a href="#" target="_blank" rel="noreferrer">
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="40"  height="40"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-headset"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 14v-3a8 8 0 1 1 16 0v3" /><path d="M18 19c0 1.657 -2.686 3 -6 3" /><path d="M4 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z" /><path d="M15 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z" /></svg>
                  </a>
                </div> */}
                <div className="user-dropdown-sec">
                  <Dropdown onToggle={handleDropdownToggle} show={dropdownOpen}>
                    <Dropdown.Toggle id="dropdown-basic">
                      <CustomLazyLoad
                        className="user-dropdown-img"
                        src={profile.data.picture}
                      />
                      <span>{profile.data.name}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {profile.data.role == 1 ||
                        profile.data.role_access?.customize ||
                        profile.data.role_access?.faq ||
                        profile.data.role_access?.source ||
                        profile.data.role_access?.custom_training_doc ||
                        profile.data.role_access?.custom_training_link ? (
                        <Link
                          to="/settings"
                          onClick={() => handleDropdownClose()}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0b0b12de" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-settings-cog"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12.003 21c-.732 .001 -1.465 -.438 -1.678 -1.317a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c.886 .215 1.325 .957 1.318 1.694" /><path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" /><path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M19.001 15.5v1.5" /><path d="M19.001 21v1.5" /><path d="M22.032 17.25l-1.299 .75" /><path d="M17.27 20l-1.3 .75" /><path d="M15.97 17.25l1.3 .75" /><path d="M20.733 20l1.3 .75" /></svg>
                          <span>{t("settings")}</span>
                        </Link>
                      ) : null}
                      <Link to="/profile" onClick={() => handleDropdownClose()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0b0b12de" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-user-circle"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /><path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" /></svg>
                        <span>{t("my_account")}</span>
                      </Link>
                      <Link
                        to="#"
                        onClick={logout}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0b0b12de" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-logout-2"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 8v-2a2 2 0 0 1 2 -2h7a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-2" /><path d="M15 12h-12l3 -3" /><path d="M6 15l-3 -3" /></svg>
                        <span>{t("logout")}</span>
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav>
            </Navbar.Collapse>
          ) : null}
        </Container>
      </Navbar>
    </div>
  );
};

export default AdminHeader;
