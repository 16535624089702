import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Dropdown,
  Row,
  Col,
  Button,
  ProgressBar,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import DashBoardRecentChatIndex from "./DashBoardRecentChatIndex";
import DashboardTicketsChart from "./ChatsGraph";
import { dashboardGraphStart, dashboardGraphUpdate, dashboardRecentChatsStart, dashboardStart } from "../../store/slices/DashboardSlice";
import ChatsGraph from "./ChatsGraph";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation, withTranslation } from "react-multi-lang";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import TableLoader from "../Helper/TableLoader";
import DashboardLoader, {
  AgentDashboardLoader,
} from "../Helper/DashboardLoader";

const DashBoardIndex = (props) => {

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E8EEF0!important",
      borderRadius: "10px",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#0b0b12de",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "500",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#4b4b4b!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#4b4b4b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        "&:active": { backgroundColor: isSelected ? "#f7f7f7" : "#fff" },
      };
    },
  };

  const dispatch = useDispatch();
  const t = useTranslation("dashboard");
  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const dashboardGraph = useSelector((state) => state.dashboard.dashboardGraph);
  const dashboardRecentChats = useSelector((state) => state.dashboard.dashboardRecentChats);
  const profile = useSelector((state) => state.admin.profile);
  const [fineTuningFilter, setFineTuningFilter] = useState(7);
  const [chatFilter, setChatFilter] = useState(7);

  const options = [
    { value: 7, label: t("last_7_days") },
    { value: 30, label: t("last_month") },
    // { value: 365, label: 'Last Year' },
  ];

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(dashboardStart());
      dispatch(dashboardGraphStart());
      dispatch(dashboardRecentChatsStart());
    }
  }, [profile]);

  const handleFineTuningFilter = (selectedOption) => {
    setFineTuningFilter(selectedOption.value);
    dispatch(
      dashboardGraphUpdate({
        fine_tuning_days_count: selectedOption.value,
        chats_days_count: chatFilter,
      })
    );
  }

  const handleChatFilter = (selectedOption) => {
    setChatFilter(selectedOption.value);
    dispatch(
      dashboardGraphUpdate({
        fine_tuning_days_count: fineTuningFilter,
        chats_days_count: selectedOption.value,
      })
    );
  }

  return (
    <>
      <div className="admin-ui-body-sec">
        <div className="admin-ui-dashboard-sec">
          <div className="admin-ui-dashboard-header-sec">
            <Row>
              {profile.data.role == 1 ? (
                dashboard.loading ?
                  <>
                    <Col md={12} lg={12} xl={8} className="resp-mrg-btm-xl">
                      <div className="admin-ui-dashboard-analytics-sec">
                        <div className="new-setting-analytics-box">
                          {[...Array(3)].map((i) => (
                            <Skeleton count={1} height={140} />
                          ))}
                        </div>
                      </div>
                      <div className="admin-ui-dashboard-analytics-new-card">
                        <div className="admin-ui-dashboard-header-left-main-box">
                          {[...Array(2)].map((i) => (
                            <Skeleton count={1} height={82} />
                          ))}
                        </div>
                      </div>
                    </Col>
                    <Col md={12} lg={12} xl={4}>
                      <div className="admin-ui-dashboard-header-left-sec">
                        <Skeleton count={1} height={238} />
                      </div>
                    </Col>
                  </>
                  :
                  Object.keys(dashboard.data).length > 0 &&
                    Object.keys(dashboard.data.current_plan).length > 0 ? (
                    <>
                      <Col md={12} lg={12} xl={8} className="resp-mrg-btm-xl">
                        <div className="admin-ui-dashboard-analytics-sec">
                          <div className="new-setting-analytics-box">
                            <div className="new-setting-analytics-card">
                              <div className="new-setting-analytics-top-sec">
                                <div className="new-setting-analytics-icon-sec">
                                  {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 19 24"
                                >
                                  <path
                                    fill="#0b0b12de"
                                    d="M17.1 23.611H1.9c-.525 0-.95-.454-.95-1.014 0-3.354 2.556-6.082 5.7-6.082h5.7c3.144 0 5.7 2.728 5.7 6.082 0 .56-.425 1.014-.95 1.014zm.475-17.14H17.1c0-2.236-1.704-4.055-3.8-4.055h-2.85V1.403c0-.56-.425-1.014-.95-1.014-.525 0-.95.454-.95 1.014v1.013H5.7c-2.096 0-3.8 1.819-3.8 4.055h-.475C.639 6.47 0 7.153 0 7.99v2.028c0 .838.64 1.52 1.425 1.52H1.9c0 2.236 1.704 4.055 3.8 4.055h7.6c2.096 0 3.8-1.819 3.8-4.055h.475c.786 0 1.425-.682 1.425-1.52V7.99c0-.838-.64-1.52-1.425-1.52zm-9.54 3.085a1.061 1.061 0 010 1.433.918.918 0 01-.673.297.918.918 0 01-.671-.297l-.516-.55-.516.55a.918.918 0 01-.671.297.918.918 0 01-.672-.297 1.061 1.061 0 010-1.433l.516-.55-.516-.551a1.061 1.061 0 010-1.434.907.907 0 011.343 0l.516.55.516-.55a.907.907 0 011.343 0 1.061 1.061 0 010 1.434l-.516.55.516.55zm6.65 0a1.061 1.061 0 010 1.433.918.918 0 01-.673.297.918.918 0 01-.671-.297l-.516-.55-.516.55a.918.918 0 01-.671.297.918.918 0 01-.672-.297 1.061 1.061 0 010-1.433l.516-.55-.516-.551a1.061 1.061 0 010-1.434.907.907 0 011.343 0l.516.55.516-.55a.908.908 0 011.343 0 1.061 1.061 0 010 1.434l-.516.55.516.55z"
                                  ></path>
                                </svg> */}
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0b0b12de" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-robot"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M6 4m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path><path d="M12 2v2"></path><path d="M9 12v9"></path><path d="M15 12v9"></path><path d="M5 16l4 -2"></path><path d="M15 14l4 2"></path><path d="M9 18h6"></path><path d="M10 8v.01"></path><path d="M14 8v.01"></path></svg>
                                </div>
                                <div className="new-setting-analytics-info-sec">
                                  <h6>{t("bots")}</h6>
                                  <h4>
                                    {dashboard.data.bots.used} /{" "}
                                    {dashboard.data.bots.total}
                                  </h4>
                                </div>
                              </div>
                              <div className="new-setting-analytics-progress-bar-sec bot-progress-bar">
                                <ProgressBar
                                  now={
                                    (dashboard.data.bots.used /
                                      dashboard.data.bots.total) *
                                    100
                                  }
                                />
                              </div>
                              <div className="new-setting-analytics-bottom-sec">
                                <p>
                                  {dashboard.data.bots.remaining} {t("remaining")}
                                </p>
                              </div>
                            </div>
                            <div className="new-setting-analytics-card">
                              <div className="new-setting-analytics-top-sec">
                                <div className="new-setting-analytics-icon-sec">
                                  {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 23 23"
                                >
                                  <path
                                    fill="#0b0b12de"
                                    fillRule="evenodd"
                                    d="M2.83 11.018h6.278c.855 0 1.55-.687 1.55-1.53V5.572c0-.974-.63-2.115-1.796-2.311a.483.483 0 01-.4-.557.49.49 0 01.565-.395 2.938 2.938 0 011.631.86V1.093a2.705 2.705 0 00-2.84-.336 2.635 2.635 0 00-1.497 2.6 2.65 2.65 0 001.938 2.298c.259.072.412.337.339.594a.49.49 0 01-.602.336C6.545 6.186 5.48 4.923 5.347 3.442a3.516 3.516 0 01.007-.692 3.378 3.378 0 00-1.79 1.565 3.344 3.344 0 00-.066 3.034.48.48 0 01-.23.644.493.493 0 01-.653-.227 4.28 4.28 0 01-.392-1.34A2.404 2.404 0 00.801 7.872c-.298.85-.09 1.78.54 2.428.395.404.923.659 1.488.718zm3.067-4.113a.493.493 0 01.683-.11c.22.154.27.456.111.673a1.257 1.257 0 00-.096 1.335 1.29 1.29 0 001.148.688h.023c.266 0 .485.211.488.476a.486.486 0 01-.482.489h-.03c-.85 0-1.62-.46-2.014-1.207a2.21 2.21 0 01.17-2.344zm-3.674 9.67c.057-.46.187-.913.393-1.34a.493.493 0 01.652-.228.48.48 0 01.23.644c-.465.96-.44 2.095.066 3.035a3.38 3.38 0 001.79 1.564 3.521 3.521 0 01-.007-.692c.133-1.48 1.198-2.743 2.649-3.142a.49.49 0 01.602.335.482.482 0 01-.34.594 2.65 2.65 0 00-1.937 2.299 2.634 2.634 0 001.497 2.599 2.706 2.706 0 002.84-.336v-2.076a2.938 2.938 0 01-1.631.86.487.487 0 01-.564-.395.483.483 0 01.399-.557c1.166-.197 1.796-1.338 1.796-2.312v-3.914c0-.844-.695-1.53-1.55-1.53H2.832a2.423 2.423 0 00-1.49.717A2.348 2.348 0 00.8 15.128a2.41 2.41 0 001.422 1.447zm3.505-2.823a2.265 2.265 0 012.044-1.207c.27.003.485.223.482.488a.486.486 0 01-.489.477h-.022a1.29 1.29 0 00-1.148.687c-.227.43-.19.942.096 1.336a.478.478 0 01-.111.673.49.49 0 01-.683-.11 2.21 2.21 0 01-.17-2.344zm8.067 8.256h-2.159v-5.132c.128.01.257.014.387.014 3.014 0 5.458-2.413 5.458-5.388 0-2.976-2.444-5.388-5.458-5.388-.13 0-.26.004-.387.013V.995h2.159l.422 2.113 2.266.928 1.813-1.2 2.506 2.474-1.215 1.79.94 2.236 2.14.416v3.499l-2.14.417-.94 2.236 1.215 1.79-2.506 2.474-1.813-1.2-2.266.927-.422 2.113z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg> */}
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0b0b12de" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-table-options"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 21h-7a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7"></path><path d="M3 10h18"></path><path d="M10 3v18"></path><path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path><path d="M19.001 15.5v1.5"></path><path d="M19.001 21v1.5"></path><path d="M22.032 17.25l-1.299 .75"></path><path d="M17.27 20l-1.3 .75"></path><path d="M15.97 17.25l1.3 .75"></path><path d="M20.733 20l1.3 .75"></path></svg>
                                </div>
                                <div className="new-setting-analytics-info-sec">
                                  <h6>{t("custom_trainings")}</h6>
                                  <h4>
                                    {dashboard.data.fine_tunings.used} /{" "}
                                    {dashboard.data.fine_tunings.total}
                                  </h4>
                                </div>
                              </div>
                              <div className="new-setting-analytics-progress-bar-sec fine-tune-progress-bar">
                                <ProgressBar
                                  now={
                                    (dashboard.data.fine_tunings.used /
                                      dashboard.data.fine_tunings.total) *
                                    100
                                  }
                                />
                              </div>
                              <div className="new-setting-analytics-bottom-sec">
                                <p>
                                  {dashboard.data.fine_tunings.remaining}{" "}
                                  {t("remaining")}
                                </p>
                              </div>
                            </div>
                            <div className="new-setting-analytics-card">
                              <div className="new-setting-analytics-top-sec">
                                <div className="new-setting-analytics-icon-sec">
                                  {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 21 21"
                                >
                                  <path
                                    fill="#0b0b12de"
                                    d="M3.26 3.43a9.999 9.999 0 0114.143 0c3.143 3.14 3.737 7.811 1.879 11.53l1.03 4.529a.822.822 0 01-.983.982l-4.531-1.03C8.21 22.73.33 17.97.33 10.498c0-2.67 1.04-5.18 2.93-7.067zm2.9 9.556h4.353a.821.821 0 100-1.642H6.159a.821.821 0 100 1.642zm0-3.336h8.344a.821.821 0 100-1.642H6.16a.821.821 0 100 1.642z"
                                  ></path>
                                </svg> */}
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0b0b12de" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-brand-hipchat"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M17.802 17.292s.077 -.055 .2 -.149c1.843 -1.425 3 -3.49 3 -5.789c0 -4.286 -4.03 -7.764 -9 -7.764c-4.97 0 -9 3.478 -9 7.764c0 4.288 4.03 7.646 9 7.646c.424 0 1.12 -.028 2.088 -.084c1.262 .82 3.104 1.493 4.716 1.493c.499 0 .734 -.41 .414 -.828c-.486 -.596 -1.156 -1.551 -1.416 -2.29z"></path><path d="M7.5 13.5c2.5 2.5 6.5 2.5 9 0"></path></svg>
                                </div>
                                <div className="new-setting-analytics-info-sec">
                                  <h6>{t("chat_messages")}</h6>
                                  <h4>
                                    {dashboard.data.messages.used} /{" "}
                                    {dashboard.data.messages.total}
                                  </h4>
                                </div>
                              </div>
                              <div className="new-setting-analytics-progress-bar-sec setting-analytics-progress-bar">
                                <ProgressBar
                                  now={
                                    (dashboard.data.messages.used /
                                      dashboard.data.messages.total) *
                                    100
                                  }
                                />
                              </div>
                              <div className="new-setting-analytics-bottom-sec">
                                <p>
                                  {dashboard.data.messages.remaining}{" "}
                                  {t("remaining")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="admin-ui-dashboard-analytics-new-card">

                          <div className="admin-ui-dashboard-header-left-main-box">
                            <div className="admin-ui-dashboard-header-left-main-card">
                              <div className="admin-ui-dashboard-header-left-main-card-info-sec">
                                <div className="admin-ui-dashboarding-header-left-new-main-card">
                                  <div className="admin-ui-dashboarding-header-left-new-main-card-icon-sec">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="#171717"
                                        d="M9.99 22.78c-.6 0-1.17-.3-1.56-.83l-1.2-1.6c0 .01-.05-.02-.07-.02h-.37c-3.42 0-5.54-.93-5.54-5.54v-4c0-4.21 2.57-5.31 4.73-5.5.24-.03.52-.04.81-.04h6.4c3.62 0 5.54 1.92 5.54 5.54v4c0 .29-.01.57-.05.84-.18 2.13-1.28 4.7-5.49 4.7h-.4l-1.24 1.62c-.39.53-.96.83-1.56.83zM6.79 6.75c-.23 0-.45.01-.66.03-2.32.2-3.38 1.47-3.38 4.01v4c0 3.43 1.06 4.04 4.04 4.04h.4c.45 0 .96.25 1.24.61l1.2 1.61c.22.3.5.3.72 0l1.2-1.6c.29-.39.75-.62 1.24-.62h.4c2.54 0 3.81-1.07 4-3.35.03-.24.04-.46.04-.69v-4c0-2.79-1.25-4.04-4.04-4.04h-6.4z"
                                      ></path>
                                      <path
                                        fill="#171717"
                                        d="M9.99 14.19c-.56 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.44 1-1 1zM13.19 14.19c-.56 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zM6.8 14.19c-.56 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
                                      ></path>
                                      <path
                                        fill="#171717"
                                        d="M17.94 16.29c-.2 0-.4-.08-.54-.23a.727.727 0 01-.2-.61c.03-.21.04-.43.04-.66v-4c0-2.79-1.25-4.04-4.04-4.04H6.8c-.23 0-.45.01-.66.03a.76.76 0 01-.61-.2.762.762 0 01-.23-.6c.18-2.16 1.29-4.73 5.5-4.73h6.4c3.62 0 5.54 1.92 5.54 5.54v4c0 4.21-2.57 5.31-4.73 5.5h-.07zM6.92 5.25h6.27c3.62 0 5.54 1.92 5.54 5.54v3.87c1.7-.42 2.5-1.67 2.5-3.87v-4c0-2.79-1.25-4.04-4.04-4.04h-6.4c-2.2 0-3.44.8-3.87 2.5z"
                                      ></path>
                                    </svg>
                                  </div>
                                  <p>{t("today_chats")}</p>
                                </div>
                                <h3>{dashboard.data.today_chats}</h3>
                              </div>
                            </div>
                            <div className="admin-ui-dashboard-header-left-main-card">
                              <div className="admin-ui-dashboard-header-left-main-card-info-sec">
                                <div className="admin-ui-dashboarding-header-left-new-main-card">
                                  <div className="admin-ui-dashboarding-header-left-new-main-card-icon-sec">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="#171717"
                                        d="M9.99 22.78c-.6 0-1.17-.3-1.56-.83l-1.2-1.6c0 .01-.05-.02-.07-.02h-.37c-3.42 0-5.54-.93-5.54-5.54v-4c0-4.21 2.57-5.31 4.73-5.5.24-.03.52-.04.81-.04h6.4c3.62 0 5.54 1.92 5.54 5.54v4c0 .29-.01.57-.05.84-.18 2.13-1.28 4.7-5.49 4.7h-.4l-1.24 1.62c-.39.53-.96.83-1.56.83zM6.79 6.75c-.23 0-.45.01-.66.03-2.32.2-3.38 1.47-3.38 4.01v4c0 3.43 1.06 4.04 4.04 4.04h.4c.45 0 .96.25 1.24.61l1.2 1.61c.22.3.5.3.72 0l1.2-1.6c.29-.39.75-.62 1.24-.62h.4c2.54 0 3.81-1.07 4-3.35.03-.24.04-.46.04-.69v-4c0-2.79-1.25-4.04-4.04-4.04h-6.4z"
                                      ></path>
                                      <path
                                        fill="#171717"
                                        d="M9.99 14.19c-.56 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.44 1-1 1zM13.19 14.19c-.56 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zM6.8 14.19c-.56 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
                                      ></path>
                                      <path
                                        fill="#171717"
                                        d="M17.94 16.29c-.2 0-.4-.08-.54-.23a.727.727 0 01-.2-.61c.03-.21.04-.43.04-.66v-4c0-2.79-1.25-4.04-4.04-4.04H6.8c-.23 0-.45.01-.66.03a.76.76 0 01-.61-.2.762.762 0 01-.23-.6c.18-2.16 1.29-4.73 5.5-4.73h6.4c3.62 0 5.54 1.92 5.54 5.54v4c0 4.21-2.57 5.31-4.73 5.5h-.07zM6.92 5.25h6.27c3.62 0 5.54 1.92 5.54 5.54v3.87c1.7-.42 2.5-1.67 2.5-3.87v-4c0-2.79-1.25-4.04-4.04-4.04h-6.4c-2.2 0-3.44.8-3.87 2.5z"
                                      ></path>
                                    </svg>
                                  </div>
                                  <p>{t("today_chats")}</p>
                                </div>
                                <h3>{dashboard.data.total_chats}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={12} lg={12} xl={4}>
                        <div className="admin-ui-dashboard-header-left-sec">
                          <div className="admin-ui-dashboard-header-main-card">
                            <div className="admin-ui-dashboard-header-main-info">
                              <h6>{t("current_plan")}</h6>
                              <h3>
                                {dashboard.data.current_plan.amount}{" "}
                                <span>
                                  ({dashboard.data.current_plan.type_formatted})
                                </span>
                              </h3>
                              <p>
                                <span>{t("expiry")}</span> :{" "}
                                {dashboard.data.current_plan.expiry_date}{" "}
                              </p>
                              <Link to="/subscription" className="white-btn">
                                {t("upgrade")}
                              </Link>
                            </div>
                            <div className="admin-ui-dashboard-header-main-img-sec">
                              <Image
                                className="admin-ui-dashboard-header-main-img"
                                src={window.location.origin + "/images/you-cuurent-plan-img.png"}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : null
              ) : dashboard.loading ? (
                <AgentDashboardLoader />
              ) : Object.keys(dashboard.data).length > 0 ? (
                <>
                  <Col md={12} lg={12} xl={12}>
                    <div className="admin-ui-dashboard-analytics-sec">
                      <div className="new-agent-analytics-box">
                        <div className="new-setting-analytics-card">
                          <div className="new-setting-analytics-top-sec">
                            <div className="new-setting-analytics-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#0b0b12de"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M21 23a1 1 0 11-2 0c0-2.206-1.794-4-4-4H9c-2.206 0-4 1.794-4 4a1 1 0 11-2 0c0-3.309 2.691-6 6-6h6c3.309 0 6 2.691 6 6zm1-15.5v2c0 .827-.673 1.5-1.5 1.5H20c0 2.206-1.794 4-4 4H8c-2.206 0-4-1.794-4-4h-.5C2.673 11 2 10.327 2 9.5v-2C2 6.673 2.673 6 3.5 6H4c0-2.206 1.794-4 4-4h3V1a1 1 0 112 0v1h3c2.206 0 4 1.794 4 4h.5c.827 0 1.5.673 1.5 1.5zM18 6c0-1.103-.897-2-2-2H8c-1.103 0-2 .897-2 2v5c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V6zM9.5 7a1.5 1.5 0 10.001 3.001A1.5 1.5 0 009.5 7zm5 0a1.5 1.5 0 10.001 3.001A1.5 1.5 0 0014.5 7z"></path>
                              </svg>
                            </div>
                            <div className="new-setting-analytics-info-sec">
                              <h6>{t("assigned_bots")}</h6>
                              <h4>{dashboard.data.agent_bots_count}</h4>
                            </div>
                          </div>
                        </div>
                        <div className="new-setting-analytics-card">
                          <div className="new-setting-analytics-top-sec">
                            <div className="new-setting-analytics-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#0b0b12de"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M19 10H7V7h1.5C10.43 7 12 5.43 12 3.5S10.43 0 8.5 0h-5C1.57 0 0 1.57 0 3.5S1.57 7 3.5 7H5v3c-2.757 0-5 2.243-5 5v4c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5v-4c0-2.757-2.243-5-5-5zM2 3.5C2 2.673 2.673 2 3.5 2h5c.827 0 1.5.673 1.5 1.5S9.327 5 8.5 5h-5C2.673 5 2 4.327 2 3.5zM5 12h14c1.654 0 3 1.346 3 3v3H2v-3c0-1.654 1.346-3 3-3zm14 10H5a3.004 3.004 0 01-2.828-2h19.656A3.004 3.004 0 0119 22zM9 15a1 1 0 112 0 1 1 0 01-2 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0zm8 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"></path>
                              </svg>
                            </div>
                            <div className="new-setting-analytics-info-sec">
                              <h6>{t("uploaded_ct")}</h6>
                              <h4>
                                {dashboard.data.agent_custom_trainings_count}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="admin-ui-dashboard-header-left-main-box">
                          <div className="admin-ui-dashboard-header-left-main-card">
                            <div className="admin-ui-dashboard-header-left-main-card-info-sec">
                              <p>{t("today_chats")}</p>
                              <h3>{dashboard.data.today_chats}</h3>
                            </div>
                          </div>
                          <div className="admin-ui-dashboard-header-left-main-card">
                            <div className="admin-ui-dashboard-header-left-main-card-info-sec">
                              <p>{t("total_chats")}</p>
                              <h3>{dashboard.data.total_chats}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              ) : null}
            </Row>
          </div>
          <div className="admin-ui-dashboard-body-sec">
            <Row>
              <Col md={6} className="resp-mrg-btm-xs">
                <div className="admin-ui-dashboard-graph-card">
                  <div className="admin-ui-dashboard-graph-header-sec">
                    <h3>{t("fine_tunings")}</h3>
                    <div className="admin-ui-dashboard-graph-filter-sec">
                      <Select
                        options={options}
                        styles={customStyles}
                        isSearchable={false}
                        defaultValue={{ value: 7, label: "Last 7 Days" }}
                        onChange={(selectedOption) => {
                          handleFineTuningFilter(selectedOption);
                        }}
                      />
                    </div>
                  </div>
                  <div className="admin-ui-dashboard-graph-body-sec">
                    <div className="admin-ui-dashboard-graph-img-sec">
                      {dashboardGraph.loading ? (
                        <div className="onboarding-form">
                          <div className="onboarding-body-sec">
                            <Row className="justify-content-md-center">
                              <Col md={12}>
                                <Skeleton count={1} height={250} />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : Object.keys(dashboardGraph.data).length > 0 &&
                        Object.keys(dashboardGraph.data.fine_tuning_graph)
                          .length > 0 ? (
                        <ChatsGraph
                          fineTuningFilter={fineTuningFilter}
                          tickets={dashboardGraph.data.fine_tuning_graph}
                          name="Custom Training"
                        />
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="admin-ui-dashboard-graph-card">
                  <div className="admin-ui-dashboard-graph-header-sec">
                    <h3>{t("chats")}</h3>
                    <div className="admin-ui-dashboard-graph-filter-sec">
                      <Select
                        options={options}
                        styles={customStyles}
                        isSearchable={false}
                        defaultValue={{ value: 7, label: "Last 7 Days" }}
                        onChange={(selectedOption) => {
                          handleChatFilter(selectedOption);
                        }}
                      />
                    </div>
                  </div>
                  <div className="admin-ui-dashboard-graph-body-sec">
                    <div className="admin-ui-dashboard-graph-img-sec">
                      {dashboardGraph.loading ? (
                        <div className="onboarding-form">
                          <div className="onboarding-body-sec">
                            <Row className="justify-content-md-center">
                              <Col md={12}>
                                <Skeleton count={1} height={250} />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : Object.keys(dashboardGraph.data).length > 0 &&
                        Object.keys(dashboardGraph.data.chats_graph).length >
                        0 ? (
                        <ChatsGraph
                          chatFilter={chatFilter}
                          tickets={dashboardGraph.data.chats_graph}
                          name="Chats"
                        />
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {profile.data.role == 1 || profile.data.role_access.inbox ? (
              <Row>
                <Col md={12}>
                  {dashboardRecentChats.loading ? (
                    <TableLoader lines={6} />
                  ) : Object.keys(dashboardRecentChats.data).length > 0 &&
                    dashboardRecentChats.data.recent_chats ? (
                    <DashBoardRecentChatIndex
                      chats={dashboardRecentChats.data.recent_chats}
                    />
                  ) : null}
                </Col>
              </Row>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation(DashBoardIndex);
