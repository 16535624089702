import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import "../OnBoarding/OnBoarding.css";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { resetPasswordStart } from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import AuthenticationRightSection from "./AuthenticationRightSection";
import NavLogo from "../Layout/Header/NavLogo";

const AIasResetPassword = (props) => {
  const t = useTranslation("reset_password");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const resetPassword = useSelector((state) => state.admin.resetPassword);
  const [skipRender, setSkipRender] = useState(true);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("new_password.required"))
      .matches(/^(?=\S*$)/, t("new_password.white_space"))
      .test("len", t("new_password.invalid"), (val) => val && val.length >= 6),
    password_confirmation: Yup.string()
      .required(t("confirm_password.required"))
      .when("password", {
        is: (val) => val && val.length > 0,
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("confirm_password.invalid")
        ),
      }),
  });

  const handleSubmit = (values) => {
    dispatch(resetPasswordStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !resetPassword.loading &&
      Object.keys(resetPassword.data).length > 0
    )
      navigate("/login");
    setSkipRender(false);
  }, [resetPassword]);

  return (
    <>
      <div className="onboarding-sec">
        <div className="onboarding-box">
          <div className="onboarding-left-sec">
            <NavLogo/>
            <div className="onboarding-steps-card">
              <div className="onboarding-header-sec">
                <Row className="justify-content-md-center">
                  <Col md={12}>
                    <div className="onboarding-onpremise-title-sec">
                      <h2>{t("heading")}</h2>
                      <p>
                      {t("message")}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <Formik
                initialValues={{
                  reset_token: params.token,
                  password: "",
                  password_confirmation: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue, values, errors, touched }) => (
                  <FORM noValidate className="onboarding-form">
                    <div className="onboarding-body-sec">
                      <Row className="justify-content-md-center">
                        <Col md={12}>
                          <div className="efi-bot-auth-form-group">
                            <Form.Label>
                            {t("new_password.label")} <span>*</span>
                            </Form.Label>
                            <div className="mb-3">
                              <InputGroup className={`register-input-group ${(errors.password && touched.password) ? "input-group-error" : ""}`}>
                                <Field
                                  type={showPassword.password ? "text" : "password"}
                                  className="form-control"
                                  placeholder={t("new_password.placeholder")}
                                  name="password"
                                />
                                <InputGroup.Text
                                  id="basic-addon2"
                                  onClick={() =>
                                    setShowPassword({
                                      ...showPassword,
                                      password: !showPassword.password,
                                    })
                                  }
                                >
                                  <Image
                                    className="register-input-group-eye-icon"
                                    src={
                                      showPassword.password
                                        ? window.location.origin +
                                          "/images/onboarding/eye-visible-off-icon.svg"
                                        : window.location.origin +
                                          "/images/onboarding/eye-visible-on-icon.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="password"
                                className="errorMsg"
                              />
                            </div>
                          </div>
                          <div className="efi-bot-auth-form-group">
                            <Form.Label>
                            {t("confirm_password.label")} <span>*</span>
                            </Form.Label>
                            <div className="mb-3">
                              <InputGroup className={`register-input-group ${(errors.password_confirmation && touched.password_confirmation) ? "input-group-error" : ""}`}>
                                <Field
                                  type={showPassword.confirm ? "text" : "password"}
                                  className="form-control"
                                  placeholder={t("confirm_password.placeholder")}
                                  name="password_confirmation"
                                />
                                <InputGroup.Text
                                  id="basic-addon2"
                                  onClick={() =>
                                    setShowPassword({
                                      ...showPassword,
                                      confirm: !showPassword.confirm,
                                    })
                                  }
                                >
                                  <Image
                                    className="register-input-group-eye-icon"
                                    src={
                                      showPassword.confirm
                                        ? window.location.origin +
                                          "/images/onboarding/eye-visible-off-icon.svg"
                                        : window.location.origin +
                                          "/images/onboarding/eye-visible-on-icon.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="password_confirmation"
                                className="errorMsg"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="onboarding-footer-sec">
                      <Row className="justify-content-md-center">
                        <Col md={12}>
                          <div className="ai-as-service-btn-sec">
                            <Button
                              className="onboarding-btn"
                              type="submit"
                              disabled={resetPassword.buttonDisable}
                            >
                              {resetPassword.buttonDisable ? t("save_btn.loading") : t("save_btn.text")}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
          </div>
          {/* <AuthenticationRightSection/> */}
        </div>
      </div>
    </>
  );
};

export default withTranslation(AIasResetPassword);
