import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useLocation } from "react-router-dom";

const SubscriptionCard = (props) => {

  const { pathname } = useLocation();
  const { plan, handleStripeIntent, type } = props;

  const t = useTranslation("onboarding_subscription");
  const generateStripeIntent = useSelector((state) => state.subscription.generateStripeIntent);

  return (
    Object.keys(plan).length > 0 &&
    (type == "yearly" && plan.amount_yearly === 0 ? null : (
      <div
        className={`onboarding-subscription-plans-card ${
          plan.is_premium && "advance-plan-card"
        }`}
      >
        <div className="onboarding-subscription-plans-header-sec" style={{textTransform:"capitalize"}}>
         <h3>{plan.name}</h3>
        </div>
        <div className="onboarding-subscription-plans-body-sec">
          <div className="onboarding-subscription-plans-amount-sec">
            <h3>
              {type == "monthly" ? plan.monthly_amount : plan.yearly_amount}{" "}
              <span>/ {type == "monthly" ? t("plans.month") : t("plans.year")}</span>
            </h3>
            {type == "yearly" && plan.yearly_save_note_percentage && (
              <p>{plan.yearly_save_note_percentage}</p>
            )}
          </div>
          <div className="onboarding-subscription-plans-info-list-sec">
            <ul className="list-unstyled onboarding-subscription-plans-info-list">
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10z"
                  ></path>
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M7.75 12l2.83 2.83 5.67-5.66"
                  ></path>
                </svg>
                <span>{type==="monthly" ? plan.messages : plan.messages * 12} {t("plans.messages")}</span>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10z"
                  ></path>
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M7.75 12l2.83 2.83 5.67-5.66"
                  ></path>
                </svg>
                <span>{plan.fine_tunings} {t("plans.fine_tune")}</span>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10z"
                  ></path>
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M7.75 12l2.83 2.83 5.67-5.66"
                  ></path>
                </svg>
                <span>{plan.bots} {t("plans.bots")}</span>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10z"
                  ></path>
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M7.75 12l2.83 2.83 5.67-5.66"
                  ></path>
                </svg>
                <span>{t("plans.support")}</span>
              </li>
            </ul>
          </div>
          <div className="onboarding-subscription-plans-choose-btn-sec">
            <Button
              className="onboarding-subscription-free-plans-btn"
              disabled={generateStripeIntent.buttonDisable || (plan.amount_monthly == 0 && pathname == '/subscription')}
              onClick={(e) => 
                {
                  if(!props.onboarding) 
                  {
                    if(!props.subscription)
                      handleStripeIntent(plan, type)
                    else
                    {
                      props.setNewPlan(plan)
                      props.setSubscriptionConfirm(type)
                    }
                  }
                  else
                  handleStripeIntent(plan, type)
                }}
              >
              {plan.is_premium ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="17"
                  fill="none"
                  viewBox="0 0 21 17"
                >
                  <path
                    fill="#000"
                    d="M1.45 12.333L.197 4.178a.68.68 0 011.08-.648l3.91 2.932a.934.934 0 001.337-.23l3.254-4.88a.869.869 0 011.446 0l3.254 4.88a.934.934 0 001.338.23l3.908-2.932a.68.68 0 011.081.648l-1.255 8.155H1.451zM18.605 16.857H2.394a.944.944 0 01-.944-.943V13.84H19.55v2.073a.944.944 0 01-.944.943z"
                  ></path>
                </svg>
              ) : null}
              {generateStripeIntent.buttonDisable &&
              generateStripeIntent.data.subscription_plan
                .subscription_plan_id === plan.subscription_plan_id
                ? t("plans.loading")
                : t("plans.choose")}
            </Button>
          </div>
        </div>
      </div>
    ))
  );
};

export default SubscriptionCard;
