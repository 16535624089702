import React, { useState, useEffect } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  singleBotViewStart,
  sourceConfigurationSaveStart,
} from "../../../store/slices/BotSlice";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation, withTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../../Helper/NoDataFound";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../../Helper/NotificationMessage";
import useBot from "../../../hooks/useBot";
import FreshChatDemoSteps from "./FreshChatDemoSteps";
import ZenDeskDemoSteps from "./ZenDeskDemoSteps";
import LiveChatDemoSteps from "./LiveChatDemoSteps";
import { sendInstallationDocumentsStart } from "../../../store/slices/AdminSlice";
import WhatsappDemoSteps from "./WhatsappDemoSteps";
import MessengerDemoSteps from "./MessengerDemoSteps";
import TelegramDemoSteps from "./TelegramDemoSteps";
import InstagramDemoSteps from "./InstagramDemoSteps";

const SourceScriptIndex = (props) => {
  const { widgets } = useBot();
  const [widgetType, setWidgetType] = useState();

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #F3F2ED!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "auto",
      fontSize: "0.9em",
      cursor: "pointer",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#50506B80",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#3E4351",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "500",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#3E4351!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#3E4351!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        "&:hover": { backgroundColor: "#f7f7f7" },
      };
    },
  };
  const t = useTranslation("settings.source");
  const dispatch = useDispatch();
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const botsStore = useSelector((state) => state.bots.botsStore);
  const sendInstallationDocuments = useSelector((state) => (state.admin.sendInstallationDocuments))
  const singleBotView = useSelector((state) => state.bots.singleBotView);
  const sourceConfigurationSave = useSelector(
    (state) => state.bots.sourceConfigurationSave
  );

  const validationSchema = Yup.object().shape({
    widget_type: Yup.string().required(t("widget_type.required")),
  });

  useEffect(() => {
    if (Object.keys(selectedBot).length > 0) {
      dispatch(singleBotViewStart({ bot_id: selectedBot.bot_id }));
      setWidgetType(selectedBot.widget_type);
    }
  }, [selectedBot]);

  const handleBotsStore = (values) => {
    let newValues = {};
    switch (values.widget_type) {
      case 3:
        newValues = {
          ...newValues,
          bot_id: values.bot_id,
          widget_type: values.widget_type,
          zendesk_key_id: values.zendesk_key_id,
          zendesk_app_id: values.zendesk_app_id,
          zendesk_secret_key: values.zendesk_secret_key,
        };
        break;
      case 4:
        newValues = {
          ...newValues,
          bot_id: values.bot_id,
          widget_type: values.widget_type,
          live_chat_email: values.live_chat_email,
          live_chat_organization_id: values.live_chat_organization_id,
        };
        break;
      case 7:
        newValues = {
          ...newValues,
          bot_id: values.bot_id,
          widget_type: values.widget_type,
          whatsapp_phone_number_id: values.whatsapp_phone_number_id,
          whatsapp_token: values.whatsapp_token,
        };
        break;
      case 8:
        newValues = {
          ...newValues,
          bot_id: values.bot_id,
          widget_type: values.widget_type,
          facebook_page_id: values.facebook_page_id,
          facebook_token: values.facebook_token,
        };
        break;
      case 9:
        newValues = {
          ...newValues,
          bot_id: values.bot_id,
          widget_type: values.widget_type,
          telegram_token: values.telegram_token,
        };
        break;
      case 10:
        newValues = {
          ...newValues,
          bot_id: values.bot_id,
          widget_type: values.widget_type,
          instagram_token: values.instagram_token,
        };
        break;
      default:
        newValues = {
          ...newValues,
          bot_id: values.bot_id,
          widget_type: values.widget_type,
        };
    }
    dispatch(sourceConfigurationSaveStart(newValues));
  };

  const onCopy = (event) => {
    getSuccessNotificationMessage("Api Key was copied to clipboard!");
  };

  const handleSendEmail = () => {
    dispatch(sendInstallationDocumentsStart({
      widget_type: widgetType
    }))
  }

  const InstallationSteps = (widgetType) => {
    switch(widgetType){
      case 1: return <FreshChatDemoSteps/>
      case 3: return <ZenDeskDemoSteps />
      case 4: return <LiveChatDemoSteps />
      case 7: return <WhatsappDemoSteps/>
      case 8: return <MessengerDemoSteps/>
      case 9: return <TelegramDemoSteps/>
      case 10: return <InstagramDemoSteps/>
      default: return null;
  }
}

  return (
    <>
      {singleBotView.loading ? (
        <div className="new-source-script-sec">
          <div className="source-loader-sec mb-3">
            <div style={{display:"flex", flexDirection:"column"}}>
              <Skeleton className="mb-2" height={25} width={200} />
              <Skeleton height={15} width={350}/>
            </div>
            <div style={{display:"flex", gap:"1em"}}>
              <Skeleton height={50} width={180}/>
              <Skeleton height={50} width={180}/>
            </div>
          </div>
          {[...Array(2)].map((i, key) => (
            <Skeleton key={key} className="mb-3" height={50} width="100%" />
          ))}
          <Skeleton className="mb-3" height={50} width={250}/>
          {[...Array(5)].map((i, key) => (
            <Skeleton key={key} className="mb-3" height={50} width="100%" />
          ))}
        </div>
      ) : Object.keys(singleBotView.data).length > 0 ? (
        <div className="new-source-script-sec">
          <div className="new-source-script-header-sec">
            <div className="new-source-script-header-left-sec">
              <h3>{t("heading")}</h3>
              <p>{t("message")}</p>
            </div>
            <div className="new-source-script-header-right-sec">
              <div className="my-subscription-header-action-btn-sec">
                <Button
                  type="button"
                  className="contact-us-btn"
                  onClick={handleSendEmail}
                  disabled={sendInstallationDocuments.buttonDisable}
                >
                  {sendInstallationDocuments.buttonDisable ? t("loading") : t("send_installation_doc")}
                </Button>
                <Link
                  to="#"
                  target="_blank"
                  className="billing-btn"
                >
                  {t("need_help")}
                </Link>
              </div>
            </div>
          </div>
          <div className="new-source-script-body-sec">
            <Formik
              initialValues={{
                bot_id: singleBotView.data.bot.bot_id,
                widget_type: singleBotView.data.bot.widget_type,
                widget_type_formatted:
                  singleBotView.data.bot.widget_type_formatted,
                zendesk_key_id:
                  singleBotView.data.bot.source?.zendesk_key_id ?? "",
                zendesk_secret_key:
                  singleBotView.data.bot.source?.zendesk_secret_key ?? "",
                zendesk_app_id:
                  singleBotView.data.bot.source?.zendesk_app_id ?? "",
                live_chat_email:
                  singleBotView.data.bot.source?.live_chat_email ?? "",
                live_chat_organization_id:
                  singleBotView.data.bot.source?.live_chat_organization_id || "",
                whatsapp_phone_number_id:
                  singleBotView.data.bot.source?.whatsapp_phone_number_id || "",
                whatsapp_token:
                  singleBotView.data.bot.source?.whatsapp_token || "",
                facebook_page_id:
                  singleBotView.data.bot.source?.facebook_page_id || "",
                facebook_token:
                  singleBotView.data.bot.source?.facebook_token || "",
                telegram_token:
                  singleBotView.data.bot.source?.telegram_token || "",
                instagram_token:
                  singleBotView.data.bot.source?.instagram_token || "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleBotsStore}
            >
              {({ setFieldValue, values }) => (
                <FORM className="onboarding-form">
                  <div className="new-source-script-form-sec">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        {t("widget_type.label")} <span>*</span>
                      </Form.Label>
                      <Select
                        options={widgets}
                        styles={customStyles}
                        value={{
                          label: values.widget_type_formatted,
                          value: values.widget_type,
                        }}
                        placeholder={t("widget_type.placeholder")}
                        onChange={(selectOption) => {
                          setFieldValue("widget_type", selectOption.value);
                          setFieldValue(
                            "widget_type_formatted",
                            selectOption.label
                          );
                          setWidgetType(selectOption.value);
                        }}
                      />
                    </Form.Group>
                    {values.widget_type == 1 &&
                      singleBotView.data.bot.api_key && (
                        <div className="new-source-script-form-box">
                          <Form.Label>
                            {t("api_key.label")} <span>*</span>
                          </Form.Label>
                          <InputGroup
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Field
                              className="form-control"
                              type="text"
                              placeholder={t("api_key.label")}
                              name="api_key"
                              value={singleBotView.data.bot.api_key || ""}
                              disabled={true}
                            />
                            <InputGroup.Text id="basic-addon2">
                              <CopyToClipboard
                                text={singleBotView.data.bot.api_key}
                                onCopy={onCopy}
                              >
                                <Button type="button" className="search-btn">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#3E4351"
                                    x="0"
                                    y="0"
                                    enableBackground="new 0 0 512 512"
                                    version="1.1"
                                    viewBox="0 0 512 512"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M256 448c-82.436-.094-149.239-66.898-149.333-149.333v-192c0-4.267.277-8.533.64-12.629-39.168 16.826-64.576 55.333-64.64 97.962v213.333C42.737 464.214 90.452 511.93 149.333 512h128c42.629-.064 81.136-25.473 97.963-64.64-4.267.363-8.363.64-12.629.64H256zM451.307 69.803l-48.725-50.325A64.01 64.01 0 00384 6.4v78.933h78.08a63.723 63.723 0 00-10.773-15.53z"></path>
                                    <path d="M362.667 85.333c0 11.782 9.551 21.333 21.333 21.333h84.608a63.6 63.6 0 00-17.301-36.864l-48.725-50.325A64.24 64.24 0 00362.667.618v84.715z"></path>
                                    <path d="M320 85.333V0h-64c-58.881.071-106.596 47.786-106.667 106.667v192c.071 58.881 47.786 106.596 106.667 106.667h106.667c58.881-.071 106.596-47.786 106.667-106.667V149.333H384c-35.346 0-64-28.653-64-64z"></path>
                                  </svg>
                                </Button>
                              </CopyToClipboard>
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                      )}
                    {values.widget_type === 3 && (
                      <>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("zendesk_key_id.label")} <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("zendesk_key_id.label")}
                            name="zendesk_key_id"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="zendesk_key_id"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("zendesk_secret_key.label")} <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("zendesk_secret_key.label")}
                            name="zendesk_secret_key"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="zendesk_secret_key"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("zendesk_app_id.label")} <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("zendesk_app_id.label")}
                            name="zendesk_app_id"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="zendesk_app_id"
                            className="errorMsg"
                          />
                        </Form.Group>
                      </>
                    )}
                    {values.widget_type === 4 && (
                      <>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("live_chat_email.label")} <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="email"
                            placeholder={t("live_chat_email.placeholder")}
                            name="live_chat_email"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="live_chat_email"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("live_chat_organisation_id.label")} <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("live_chat_organisation_id.placeholder")}
                            name="live_chat_organization_id"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="live_chat_organization_id"
                            className="errorMsg"
                          />
                        </Form.Group>
                      </>
                    )}
                    {values.widget_type === 7 && (
                      <>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("whatsapp_phone_number_id.label")} <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("whatsapp_phone_number_id.placeholder")}
                            name="whatsapp_phone_number_id"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="whatsapp_phone_number_id"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("whatsapp_token.label")} <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("whatsapp_token.placeholder")}
                            name="whatsapp_token"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="whatsapp_token"
                            className="errorMsg"
                          />
                        </Form.Group>
                      </>
                    )}
                    {values.widget_type === 8 && (
                      <>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("facebook_page_id.label")} <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("facebook_page_id.placeholder")}
                            name="facebook_page_id"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="facebook_page_id"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("facebook_token.label")} <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("facebook_token.placeholder")}
                            name="facebook_token"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="facebook_token"
                            className="errorMsg"
                          />
                        </Form.Group>
                      </>
                    )}
                    {values.widget_type === 9 && (
                      <>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("telegram_token.label")} <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("telegram_token.placeholder")}
                            name="telegram_token"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="telegram_token"
                            className="errorMsg"
                          />
                        </Form.Group>
                      </>
                    )}
                    {values.widget_type === 10 && (
                      <>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("instagram_token.label")} <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("instagram_token.placeholder")}
                            name="instagram_token"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="instagram_token"
                            className="errorMsg"
                          />
                        </Form.Group>
                      </>
                    )}
                    {(([7, 8, 10]).includes(values.widget_type)) &&
                      singleBotView.data.bot.source.webhook_url && (
                        <div className="new-source-script-form-box">
                          <Form.Label>
                            {t("webhook_url.label")} <span></span>
                          </Form.Label>
                          <InputGroup
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Field
                              className="form-control"
                              type="text"
                              placeholder={t("webhook_url.placeholder")}
                              name="webhook_url"
                              value={singleBotView.data.bot.source.webhook_url || ""}
                              disabled={true}
                            />
                            <InputGroup.Text id="basic-addon2">
                              <CopyToClipboard
                                text={singleBotView.data.bot.source.webhook_url}
                                onCopy={onCopy}
                              >
                                <Button type="button" className="search-btn">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#3E4351"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                                  </svg>
                                </Button>
                              </CopyToClipboard>
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                      )}
                    <div className="new-settings-btn-sec">
                      <Button
                        className="settings-btn"
                        type="submit"
                        disabled={sourceConfigurationSave.buttonDisable ||
                          (values.widget_type == 1 && singleBotView.data.bot.api_key && values.widget_type === singleBotView.data.bot.widget_type) ||
                          (values.widget_type == 3 && values.zendesk_key_id === singleBotView.data.bot.source?.zendesk_key_id && values.zendesk_key_id === singleBotView.data.bot.source?.zendesk_key_id && values.zendesk_secret_key === singleBotView.data.bot.source?.zendesk_secret_key && values.zendesk_app_id === singleBotView.data.bot.source?.zendesk_app_id) ||
                          (values.widget_type == 4 && values.live_chat_organization_id === singleBotView.data.bot.source?.live_chat_organization_id && values.live_chat_email === singleBotView.data.bot.source?.live_chat_email) ||
                          (values.widget_type == 9 && values.telegram_token === singleBotView.data.bot.source?.telegram_token) || (values.widget_type == 10 && values.instagram_token === singleBotView.data.bot.source?.instagram_token)
                        }>
                        {sourceConfigurationSave.buttonDisable ? "Loading" : (([1, 7, 8]).includes(values.widget_type)) &&
                          !singleBotView.data.bot.api_key ? t("generate_api_key") : t("update_changes")}
                      </Button>
                    </div>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
          {InstallationSteps(widgetType)}
        </div>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

export default withTranslation(SourceScriptIndex);
