import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useTranslation, withTranslation } from "react-multi-lang";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { botScriptUpdateStart } from "../../store/slices/BotSlice";

const Simulator = (props) => {

  const t = useTranslation("simulator");
  const dispatch = useDispatch();
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const botScriptUpdate = useSelector((state) => state.bots.botScriptUpdate);
  const [hasScript, setHasScript] = useState(false);
  const scriptUrl = '//in.fw-cdn.com/31278725/599313.js';

  const validationSchema = Yup.object().shape({
    script: Yup.string().required(t("script.required")),
  });

  const loadScript = (scriptUrl) => {
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.async = true;
    document.body.appendChild(script);
  };

  const unloadScript = () => {
    const scripts = document.getElementsByTagName('script');
    console.log(scripts);
    for (let i = scripts.length - 1; i >= 0; i--) {
      const script = scripts[i];
      if (script.src.replace("http:","") === scriptUrl) {
        script.parentNode.removeChild(script);
      }
    }
  };

  useEffect(()=> {
    if(Object.keys(selectedBot).length > 0) {
      console.log(selectedBot)
      if(selectedBot.script)
      {
        setHasScript(true);
        window.location.reload();
        loadScript(selectedBot.script);
      }
    }
  },[selectedBot])

  useEffect(()=> {
    return () => {
      unloadScript();
      window.location.reload();
    }
  },[])

  const handleBotUpdate = (values) => {
    dispatch(botScriptUpdateStart(values));
  };

  return (
    <div>
      <div className="admin-ui-body-sec">
        <div className="admin-ui-dashboard-sec">
          {hasScript ? (
            <div>
              <Row className="justify-content-md-center mb-3">
                <Col md={2}>
                  <Skeleton height={30} className="mb-3" />
                </Col>
                <Col md={9}></Col>
                <Col md={1}>
                  <Skeleton height={30} className="mb-3" />
                </Col>
              </Row>
              <Row className="justify-content-md-center admin-your-bot-list-header-sec">
                <Col md={12} className="mt-3 mb-3">
                  <Row className="justify-content-md-center">
                    <Col md={5} className="mt-3 mb-3">
                      <Skeleton height={30} />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col md={4}>
                      <Skeleton height={30} className="mb-3" />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col md={3}>
                      <Skeleton height={30} className="mb-3" />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-md-center">
                <Col md={3} className="mt-3 mb-3">
                  <Skeleton height={20} />
                </Col>
              </Row>
              <Row className="justify-content-md-center">
                <Col md={5}>
                  <Skeleton height={15} className="mb-3" />
                </Col>
              </Row>
              <Row className="mt-3">
                {[...Array(3)].map((i) => (
                  <Col md={4}>
                    <Skeleton height={200} className="mb-3" />
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <>
              <div className="my-subscription-header-info-left-sec">
                <h3>Update Bot Script</h3>
              </div>
              <Formik
                initialValues={{
                  bot_id: selectedBot.bot_id,
                  script: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleBotUpdate}
              >
                {({ setFieldValue, values }) => (
                  <FORM className="onboarding-form">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>{t("script.label")}</Form.Label>
                      <Field
                        className="form-control"
                        type="text"
                        as="textarea"
                        rows="5"
                        placeholder={t("script.placeholder")}
                        name="script"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="script"
                        className="errorMsg"
                      />
                    </Form.Group>
                    <div className="new-settings-btn-sec mb-3">
                      <Button
                        className="settings-btn"
                        type="submit"
                        disabled={botScriptUpdate.buttonDisable}
                      >
                        {botScriptUpdate.buttonDisable
                          ? t("loading")
                          : t("update")}
                      </Button>
                    </div>
                  </FORM>
                )}
              </Formik>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation(Simulator);
