import React from "react";
import { Image, Button, Container, Row, Col } from "react-bootstrap";

const LiveChatDemoSteps = (props) => {
  return (
    <>
      <div className="customize-steps-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="customize-steps-btn-sec">
                <h3>Setting Up Livechat Integration with EFI Assist</h3>
                <Button
                  className="settings-btn"
                  download={true}
                  target="_blank"
                  href={
                    window.location.origin +
                    "/installation-steps/Livechat-Installation-Steps.pdf"
                  }
                >
                  Download Doc
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="customize-steps-list-sec livechat-demo">
                <p className="note">
                  Go to the url{" "}
                  <a href="https://platform.text.com/console/" target="_blank">
                    (https://platform.text.com/console/)
                  </a>
                  . Login using your live chat credentials.
                </p>
                <ul className="customize-steps-list list-unstyled">
                  <li>
                    <span>1</span>
                    After login, click on the profile icon and choose settings.
                  </li>
                  <li>
                    <span>2</span>
                    Select Account & Copy the Organization ID.
                  </li>
                  <li>
                    <span>3</span>
                    <div>
                      Go to the EFI Assist panel. Select your bot and fill the
                      required fields.
                      <h6>
                        <em>Widget Type :</em>
                        LiveChat
                      </h6>
                      <h6>
                        <em>Live Chat Email :</em>
                        (Your registered live chat email).
                      </h6>
                      <h6>
                        <em>Live Chat Organization ID :</em>
                        (The Organization ID Copied from Step 2).
                      </h6>
                    </div>
                  </li>
                  <li>
                    <span>4</span>
                    Once you have updated the keys in EFI Assist panel. Install app
                    from livechat marketplace.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LiveChatDemoSteps;
