import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { emailVerifyStart, resendOtpStart, updateEmailCodeStart, updateEmailStart, verifyForgotPasswordStart, forgotPasswordStart } from "../../store/slices/AdminSlice";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { useTranslation, withTranslation } from "react-multi-lang";

const OnBoardingVerificationModal = (props) => {

  const t = useTranslation("verification_modal");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailVerify = useSelector(state => state.admin.emailVerify);
  const verifyForgotPassword = useSelector((state) => state.admin.verifyForgotPassword);
  const updateEmail = useSelector((state) => state.admin.updateEmail);
  const updateEmailCode = useSelector((state) => state.admin.updateEmailCode);
  const forgotPassword = useSelector((state) => state.admin.forgotPassword);
  const resendOtp = useSelector(state => state.admin.resendOtp);
  const [otp, setOtp] = useState("");
  const [skipRender, setSkipRender] = useState(true);

  const handleSubmit = e => {
    e.preventDefault();
    if (otp)
      if (otp.length === 6)
        if(props.forgotPassword && props.forgotPassword === 1)
        {
          dispatch(verifyForgotPasswordStart({
            email: props.showVerify.email,
            verification_code: otp,
          })); 
        }
        else if(props.forgotPassword && props.forgotPassword === 2)
        {
          dispatch(updateEmailStart({
            email: props.showVerify,
            verification_code: otp,
          })); 
        }
        else{
          dispatch(emailVerifyStart({
            email: props.showVerify.email,
            remember: props.showVerify.remember,
            verification_code: otp,
          })); 
        }
      else getErrorNotificationMessage(t("otp.length"));
    else getErrorNotificationMessage(t("otp.required"));
  }

  const handleresendOtp = () => {
    if (props.forgotPassword) {
      if (props.forgotPassword === 2) {
        dispatch(
          updateEmailCodeStart({
            email: props.showVerify,
          })
        );
      } else {
        dispatch(
          forgotPasswordStart({
            email: props.showVerify.email,
          })
        );
      }
    } else {
      dispatch(
        resendOtpStart({
          email: props.showVerify.email,
          code_type: "email",
        })
      );
    } 
  }

  useEffect(() => {
    if (
      !skipRender &&
      !verifyForgotPassword.loading &&
      Object.keys(verifyForgotPassword.data).length > 0
    ) {
      navigate(`/reset-password/${verifyForgotPassword.data.token}`);
    }
    setSkipRender(false);
  }, [verifyForgotPassword]);

  useEffect(() => {
    if (
      !skipRender && 
      !updateEmail.loading &&
      Object.keys(updateEmail.data).length > 0
    ) {
      props.closeVerify();
    }
    setSkipRender(false);
  }, [updateEmail]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.showVerify}
      >
        <Modal.Body>
          <h4>{props.forgotPassword ? t("otp_verification") : t("email_verification")}</h4>
          <Button className="modal-close" onClick={() => props.closeVerify()}>
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            fill="none"
            viewBox="0 0 11 11"
          >
            <path
              fill="#979BA2"
              d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"
            ></path>
          </svg>
          </Button>
            <Form onSubmit={handleSubmit}>
            <div className="verification-item">
              <h4>{t("heading")}</h4>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                separator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <div className="verification-btn-sec">
              <span>{t("message")}</span>
              <button
                type="button"
                onClick={() => handleresendOtp()}
                className="resend-link"
                disabled={resendOtp.buttonDisable || updateEmailCode.buttonDisable || verifyForgotPassword.buttonDisable || updateEmail.buttonDisable || emailVerify.buttonDisable || updateEmailCode.buttonDisable || forgotPassword.buttonDisable}
              >
                {resendOtp.buttonDisable || updateEmailCode.buttonDisable || forgotPassword.buttonDisable ? t("loading") : t("resend")}
              </button>
            </div>
            <div className="verify-btn-sec">
            {props.forgotPassword ? props.forgotPassword === 1 ? <Button
                type="submit"
                className="onboarding-btn"
                disabled={verifyForgotPassword.buttonDisable || updateEmailCode.buttonDisable || resendOtp.buttonDisable || forgotPassword.buttonDisable}
              >
                {verifyForgotPassword.buttonDisable ? t("loading") : t("verify")}
              </Button> : <Button
                type="submit"
                className="onboarding-btn"
                disabled={updateEmail.buttonDisable || updateEmailCode.buttonDisable || resendOtp.buttonDisable}
              >
                {updateEmail.buttonDisable ? t("loading") : t("update")}
              </Button> : <Button
                type="submit"
                className="onboarding-btn"
                disabled={emailVerify.buttonDisable || updateEmailCode.buttonDisable || resendOtp.buttonDisable}
              >
                {emailVerify.buttonDisable ? t("loading") : t("validate")}
              </Button>}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(OnBoardingVerificationModal);