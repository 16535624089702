import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import { faqSaveStart } from "../../../store/slices/FaqSlice";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import ImportFaq from "./ImportFaq";

const AddFaqModal = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("settings.fine_tune.add_faq");
  const faqSave = useSelector((state) => state.faq.faqSave);
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    question: Yup.string().required(t("question.required"))
      .matches(/^[^<>]*$/, t("question.invalid"))
      .trim().notOneOf(['']),
    response: Yup.string().required(t("response.required"))
      .matches(/^[^<>]*$/, t("response.invalid"))
      .trim().notOneOf(['']),
  });

  const handleFaqStore = (values) => {
    let newValues = values;
    if(props.faq && Object.keys(props.faq).length>0)
    {
      if(props.faq.chat_message_faq_id){
        newValues = {...newValues,
          chat_message_faq_id: props.faq.chat_message_faq_id
        }
      }
      else if (props.faq.preview_chat_message_id){
        newValues = {...newValues, 
          preview_chat_message_id: props.faq.preview_chat_message_id
        }
      }
    }
    dispatch(faqSaveStart(newValues));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !faqSave.loding &&
      Object.keys(faqSave.data).length > 0
    ) {
      props.closeAddFaqModal();
    }
    setSkipRender(false);
  }, [faqSave]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.addFaq}
        onHide={props.closeAddFaqModal}
      >
        <Modal.Body>
          <h4>{props.type && props.type === 2 ? t("edit") : t("heading")}</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeAddFaqModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              viewBox="0 0 11 11"
            >
              <path
                fill="#979BA2"
                d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"
              ></path>
            </svg>
          </Button>
          <Formik
            initialValues={{
              question: props.faq ? props.faq.question : "",
              response: props.faq ? props.faq.answer : "",
              status: props.faq ? props.faq.status : 3,
              bot_id: selectedBot.bot_id ?? 0,
            }}
            validationSchema={validationSchema}
            onSubmit={handleFaqStore}
          >
            {({ setFieldValue, values, errors, isValidating, isValid }) => (
              <FORM className="onboarding-form">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>{t("question.label")}</Form.Label>
                  <Field
                    className="form-control"
                    as="textarea"
                    rows={3}
                    placeholder={t("question.placeholder")}
                    name="question"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="question"
                    className="errorMsg"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>{t("response.label")}</Form.Label>
                  <Field
                    className="form-control"
                    as="textarea"
                    rows={3}
                    placeholder={t("response.placeholder")}
                    name="response"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="response"
                    className="errorMsg"
                  />
                </Form.Group>
                <div className="onboarding-import-webpage-modal-btn-sec">
                  <Button
                    className="onboarding-save-btn"
                    type="submit"
                    // onClick={() => isValid && handleFaqStore(values)}
                    disabled={faqSave.buttonDisable}
                  >
                    {faqSave.buttonDisable ? t("loading") : t("add")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
          {!props.faq && <ImportFaq
            closeAddFaqModal={props.closeAddFaqModal}
            botId={selectedBot.bot_id}
          />}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddFaqModal;
