import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  agentList: getCommonInitialState(),
  agentCreate: getCommonInitialState(),
  singleAgent: getCommonInitialState(),
  agentDelete: getCommonInitialState(),
  resetAgentPassword: getCommonInitialState(),
  updateAgentPermissions: getCommonInitialState(),
  updateAgentStatus: getCommonInitialState(),
  sendInvite: getCommonInitialState(),
  acceptInvite: getCommonInitialState(),
};

const AgentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    // Agent list
    agentListStart: (state, action) => {
      state.agentList = getCommonStart();
    },
    agentListSuccess: (state, action) => {
      state.agentList = getCommonSuccess(action.payload);
    },
    agentListFailure: (state, action) => {
      state.agentList = getCommonFailure(action.payload);
    },
    moreAgentListStart: (state) => {
      state.agentList = state.agentList;
    },

    // Create Agent
    agentCreateStart: (state, action) => {
      state.agentCreate = getCommonStart();
    },
    agentCreateSuccess: (state, action) => {
      state.agentCreate = getCommonSuccess(action.payload);
    },
    agentCreateFailure: (state, action) => {
      state.agentCreate = getCommonFailure(action.payload);
    },

    // Single Agent
    singleAgentViewStart: (state) => {
      state.singleAgent = getCommonStart();
    },
    singleAgentViewSuccess: (state, action) => {
      state.singleAgent = getCommonSuccess(action.payload);
    },
    singleAgentViewFailure: (state, action) => {
      state.singleAgent = getCommonFailure(action.payload);
    },

    // Delete Agent
    agentDeleteStart: (state, action) => {
      state.agentDelete = getCommonStart(action.payload);
    },
    agentDeleteSuccess: (state, action) => {
      state.agentDelete = getCommonSuccess(action.payload);
    },
    agentDeleteFailure: (state, action) => {
      state.agentDelete = getCommonFailure(action.payload);
    },

    // Reset Agent Password
    resetAgentPasswordStart: (state, action) => {
      state.resetAgentPassword = getCommonStart();
    },
    resetAgentPasswordSuccess: (state, action) => {
      state.resetAgentPassword = getCommonSuccess(action.payload);
    },
    resetAgentPasswordFailure: (state, action) => {
      state.resetAgentPassword = getCommonFailure(action.payload);
    },

    // Update Agent Permissions
    updateAgentPermissionsStart: (state, action) => {
      state.updateAgentPermissions = getCommonStart(action.payload);
    },
    updateAgentPermissionsSuccess: (state, action) => {
      state.updateAgentPermissions = getCommonSuccess(action.payload);
    },
    updateAgentPermissionsFailure: (state, action) => {
      state.updateAgentPermissions = getCommonFailure(action.payload);
    },

    // Update Agent Status
    updateAgentStatusStart: (state, action) => {
      state.updateAgentStatus = getCommonStart(action.payload);
    },
    updateAgentStatusSuccess: (state, action) => {
      state.updateAgentStatus = getCommonSuccess(action.payload);
    },
    updateAgentStatusFailure: (state, action) => {
      state.updateAgentStatus = getCommonFailure(action.payload);
    },

    // Send Invite
    sendInviteStart: (state, action) => {
      state.sendInvite = getCommonStart(action.payload);
    },
    sendInviteSuccess: (state, action) => {
      state.sendInvite = getCommonSuccess(action.payload);
    },
    sendInviteFailure: (state, action) => {
      state.sendInvite = getCommonFailure(action.payload);
    },

    // Accept Invite
    acceptInviteStart: (state, action) => {
      state.acceptInvite = getCommonStart();
    },
    acceptInviteSuccess: (state, action) => {
      state.acceptInvite = getCommonSuccess(action.payload);
    },
    acceptInviteFailure: (state, action) => {
      state.acceptInvite = getCommonFailure(action.payload);
    },
  },
});

export const {
  agentListFailure,
  agentListStart,
  agentListSuccess,
  moreagentListStart,
  singleAgentViewFailure,
  singleAgentViewStart,
  singleAgentViewSuccess,
  agentDeleteFailure,
  agentDeleteStart,
  agentDeleteSuccess,
  agentCreateFailure,
  agentCreateStart,
  agentCreateSuccess,
  resetAgentPasswordFailure,
  resetAgentPasswordStart,
  resetAgentPasswordSuccess,
  updateAgentPermissionsFailure,
  updateAgentPermissionsStart,
  updateAgentPermissionsSuccess,
  updateAgentStatusFailure,
  updateAgentStatusStart,
  updateAgentStatusSuccess,
  sendInviteFailure,
  sendInviteStart,
  sendInviteSuccess,
  acceptInviteFailure,
  acceptInviteStart,
  acceptInviteSuccess,
} = AgentSlice.actions;

export default AgentSlice.reducer;
