import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { delayMessageDeleteStart } from "../../store/slices/FineTuneSlice";
import { useTranslation } from "react-multi-lang";

const DeleteMessageModal = (props) => {

  const t = useTranslation("settings.delay_message.delete_modal");
  const dispatch = useDispatch();
  const [skipRender, setSkipRender] = useState(true);

  const delayMessageDelete = useSelector(
    (state) => state.fineTune.delayMessageDelete
  );

  useEffect(() => {
    if (
      !skipRender &&
      !delayMessageDelete.loading &&
      Object.keys(delayMessageDelete.data).length > 0
    ) {
      props.onHide();
    }
    setSkipRender(false);
  }, [delayMessageDelete]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal delay-text-modal"  
        size="md"
        centered
        show={props.modalShow}
        onHide={props.onHide}
      >
        <Modal.Body>
          <h4>{t("heading")}</h4>
          <Button
            className="modal-close"
            onClick={props.onHide}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 11 11"><path fill="#979BA2" d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"></path></svg>
          </Button>
          <div className="delete-bot-card">
            <p>{t("message")}</p>
            <p>{t("content")}</p>
          </div>
          <div className="onboarding-import-webpage-modal-btn-sec mt-3">
            <Button
              className="onboarding-cancel-btn"
              onClick={props.onHide}
            >
              {t("cancel")}
            </Button>
            <Button
              className="onboarding-save-btn"
              type="submit"
              onClick={() =>
                dispatch(delayMessageDeleteStart({ delay_message_id : props.modalShow.id }))
              }
              disabled={delayMessageDelete.buttonDisable}
            >
              {delayMessageDelete.buttonDisable ? t("loading") : t("confirm")}
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteMessageModal;
