import React, { useEffect, useState } from "react";
import {
  Image,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import "../OnBoarding/OnBoarding.css";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { registerStart } from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { emailVerifyStart, resendOtpStart } from "../../store/slices/AdminSlice";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import OnBoardingVerificationModal from "../OnBoarding/OnboardingVerificationModal";
import NavLogo from "../Layout/Header/NavLogo"
import AuthenticationRightSection from "./AuthenticationRightSection";

const AIasRegisterIndex = (props) => {
  const t = useTranslation("register");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const register = useSelector((state) => state.admin.register);
  const [skipRender, setSkipRender] = useState(true);
  const [showVerify, setShowVerify] = useState(false);
  // const [agreeTerms, setAgreeTerms] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });
  const emailVerify = useSelector(state => state.admin.emailVerify);
  const resendOtp = useSelector(state => state.admin.resendOtp);
  const [otp, setOTP] = useState("");
  const handleChange = (otp) => setOTP(otp);

  const registerSchema = Yup.object().shape({
    name: Yup.string().required(t("name.required"))
      .matches(/^[^<>]*$/, t('name.invalid'))
      .trim().notOneOf(['']),
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    password: Yup.string()
      .required(t("password.required"))
      .min(6, t("password.length"))
      .matches(/^(?=\S*$)/, t("password.white_space"))
      .matches(/^(?=.*[a-zA-Z0-9])/, t("password.invalid")),
    password_confirmation: Yup.string()
      .required(t("password_confirmation.required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("password_confirmation.invalid")
        ),
      }),
  });

  const handleRegister = (values) => {
    dispatch(registerStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !register.loading &&
      Object.keys(register.data).length > 0
    ) {
      if (register.data.email_status === 0) setShowVerify(register.data);
      else props.setStep(2);
    }
    setSkipRender(false);
  }, [register]);

  const handleSubmit = e => {
    e.preventDefault();
    if (otp)
      if (otp.length === 6)
        dispatch(emailVerifyStart({
          email: register.data.email,
          remember: register.data.remember,
          verification_code: otp,
        }));
      else getErrorNotificationMessage("The verification code should be 6 digits.")
    else getErrorNotificationMessage("The verification code field is required.");
  }

  const handleresendOtp = () => {
    dispatch(resendOtpStart({
      email: register.data.email,
      code_type: "email"
    }));
  }

  useEffect(() => {
    if (
      !skipRender &&
      !emailVerify.loading &&
      Object.keys(emailVerify.data).length > 0
    ) {
      navigate('/onboarding-steps')
    }
    setSkipRender(false);
  }, [emailVerify]);

  return (
    <>
      <div className="onboarding-sec">
        <div className="onboarding-box">
          <div className="onboarding-left-sec">
            <div className="onboarding-logo-sec">
              <NavLogo/>
            </div>
            <div className="onboarding-steps-card">
              <div className="onboarding-header-sec">
                <Row className="justify-content-md-center">
                  <Col md={12}>
                    <div className="onboarding-onpremise-title-sec">
                      <h2>{t("heading")}</h2>
                      <p>
                        {t("message")}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  password: "",
                  password_confirmation: "",
                  remember: false,
                }}
                validationSchema={registerSchema}
                onSubmit={handleRegister}
              >
                {({ setFieldValue, values, errors, touched }) => (
                  <FORM noValidate className="onboarding-form">
                    <div className="onboarding-body-sec">
                      <Row className="justify-content-md-center">
                        <Col md={12}>
                          <div className="efi-bot-auth-form-group mb-3">
                            <Form.Group
                              className={`${(errors.name && touched.name) ? "input-group-error" : ""}`}
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                {t("name.label")} <span>*</span>
                              </Form.Label>
                              <Field
                                className="form-control"
                                type="text"
                                placeholder={t("name.placeholder")}
                                name="name"
                              />
                              <ErrorMessage
                              component={"div"}
                              name="name"
                              className="errorMsg"
                            />
                            </Form.Group>
                          </div>
                          <div className="efi-bot-auth-form-group mb-3">
                            <Form.Group
                              className={`${(errors.email && touched.email) ? "input-group-error" : ""}`}
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                {t("email.label")} <span>*</span>
                              </Form.Label>
                              <Field
                                className="form-control"
                                type="email"
                                placeholder={t("email.placeholder")}
                                name="email"
                              />
                              <ErrorMessage
                              component={"div"}
                              name="email"
                              className="errorMsg"
                            />
                            </Form.Group>
                          </div>
                          <div className="efi-bot-auth-form-group">
                            <Form.Label>
                            {t("password.label")} <span>*</span>
                            </Form.Label>
                            <div className="mb-3">
                              <InputGroup className={`register-input-group ${(errors.password && touched.password) ? "input-group-error" : ""}`}>
                                <Field
                                  type={showPassword.password ? "text" : "password"}
                                  className="form-control"
                                  placeholder={t("password.placeholder")}
                                  name="password"
                                />
                                <InputGroup.Text
                                  id="basic-addon2"
                                  onClick={() =>
                                    setShowPassword({
                                      ...showPassword,
                                      password: !showPassword.password,
                                    })
                                  }
                                >
                                  <Image
                                    className="register-input-group-eye-icon"
                                    src={ showPassword.password ? 
                                      window.location.origin +
                                      "/images/onboarding/eye-visible-off-icon.svg"
                                      : window.location.origin +
                                      "/images/onboarding/eye-visible-on-icon.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="password"
                                className="errorMsg"
                              />
                            </div>
                          </div>
                          <div className="efi-bot-auth-form-group">
                            <Form.Label>
                            {t("password_confirmation.label")} <span>*</span>
                            </Form.Label>
                            <div className="mb-3">
                              <InputGroup className={`register-input-group ${(errors.password_confirmation && touched.password_confirmation) ? "input-group-error" : ""}`}>
                                <Field
                                  type={showPassword.confirm ? "text" : "password"}
                                  className="form-control"
                                  placeholder={t("password_confirmation.placeholder")}
                                  name="password_confirmation"
                                />
                                <InputGroup.Text
                                  id="basic-addon2"
                                  onClick={() =>
                                    setShowPassword({
                                      ...showPassword,
                                      confirm: !showPassword.confirm,
                                    })
                                  }
                                >
                                  <Image
                                    className="register-input-group-eye-icon"
                                    src={showPassword.confirm ? 
                                      window.location.origin +
                                      "/images/onboarding/eye-visible-off-icon.svg"
                                      : window.location.origin +
                                      "/images/onboarding/eye-visible-on-icon.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="password_confirmation"
                                className="errorMsg"
                              />
                            </div>
                          </div>
                          <Form.Group
                            className="mb-3 flex-data"
                            id="formGridCheckbox"
                          >
                            {/* <Form.Check
                              type="checkbox"
                              label="I agree to all the"
                              onChange={() => setAgreeTerms(!agreeTerms)}
                            /> */}
                            {/* I agree to all the <Link to="#">Terms & Conditions*</Link> */}
                            {t("agreement")} <Link target="_blank" to="/page/terms">{t("terms")}</Link> {t("and")} <Link target="_blank" to="/page/privacy">{t("privacy")}</Link>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <div className="onboarding-footer-sec">
                      <Row className="justify-content-md-center">
                        <Col md={12}>
                          <div className="ai-as-service-btn-sec">
                            <Button
                              className="onboarding-btn"
                              type="submit"
                              disabled={register.buttonDisable}
                            >
                              {register.buttonDisable
                                ? "Loading"
                                : "Create Account"}
                            </Button>
                          </div>
                          <div className="onboarding-link-sec">
                            {t("have_account")}
                            <Link to="/login">{t("sign_in")}</Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
          </div>
          {/* <AuthenticationRightSection/> */}
        </div>
      </div>
      {showVerify ?
        <OnBoardingVerificationModal showVerify={showVerify} closeVerify={() => setShowVerify(false)} />
        : null}
    </>
  );
};

export default AIasRegisterIndex;
