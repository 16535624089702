import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  InputGroup,
  Row,
  Col,
} from "react-bootstrap";
import { useTranslation, withTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { resetAgentPasswordStart } from "../../store/slices/AgentSlice";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";

const ResetPasswordModal = (props) => {
  const t = useTranslation("agents.reset_password_modal");
  const dispatch = useDispatch();
  const resetAgentPassword = useSelector(
    (state) => state.agent.resetAgentPassword
  );
  const [skipRender, setSkipRender] = useState(true);
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (
      !skipRender &&
      !resetAgentPassword.loading &&
      Object.keys(resetAgentPassword.data).length > 0
    ) {
      // props.closeResetPasswordModal();
      setStep(2);
    }
    setSkipRender(false);
  }, [resetAgentPassword]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.resetPassword}
        onHide={props.closeResetPasswordModal}
      >
        <Modal.Body>
          <h4>{t("heading")}</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeResetPasswordModal()}
          >
            <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10zM9.17 14.83l5.66-5.66M14.83 14.83L9.17 9.17"
      ></path>
    </svg>
          </Button>
          {step == 1 && (
            <div className="step-1">
              <div className="create-sales-note-sec">
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#546594"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M13.5 6.5a1.5 1.5 0 01-3 0 1.5 1.5 0 013 0zM24 19v-6.66A12.209 12.209 0 0012.836.028 12 12 0 00.029 12.854C.471 19.208 6.082 24 13.083 24H19a5.006 5.006 0 005-5zM12.7 2.024A10.2 10.2 0 0122 12.34V19a3 3 0 01-3 3h-5.917C7.049 22 2.4 18.1 2.025 12.716A10 10 0 0112.016 2c.227 0 .456.009.684.024zM14 18v-6a2 2 0 00-2-2h-1a1 1 0 000 2h1v6a1 1 0 002 0z"></path>
                  </svg>
                  {t("message")}
                </p>
              </div>
              <div className="onboarding-import-webpage-modal-btn-sec mt-4">
                <Button
                  className="onboarding-cancel-btn"
                  onClick={() => props.closeResetPasswordModal()}
                >
                  {t("cancel")}
                </Button>
                <Button
                  className="onboarding-save-btn"
                  type="submit"
                  onClick={() =>
                    dispatch(
                      resetAgentPasswordStart({ agent_id: props.resetPassword })
                    )
                  }
                  disabled={resetAgentPassword.buttonDisable}
                >
                  {resetAgentPassword.buttonDisable ? t("loading") : t("reset")}
                </Button>
              </div>
            </div>
          )}
          {step == 2 && (
            <div class="step-2">
              {!resetAgentPassword.loading &&
              Object.keys(resetAgentPassword.data).length > 0 ? (
                <div className="reset-password-success-card">
                  <div className="reset-password-success-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      fill="none"
                      viewBox="0 0 36 36"
                    >
                      <path
                        fill="#FF916F"
                        d="M20.75 21.625a2.74 2.74 0 01-2.75 2.75 2.75 2.75 0 01-2.75-2.75 2.74 2.74 0 012.75-2.75 2.75 2.75 0 012.75 2.75zM19.499 28.5c.165.99.509 1.911.99 2.75H9.75A2.75 2.75 0 017 28.5V14.75A2.74 2.74 0 019.75 12h1.375V9.25A6.878 6.878 0 0118 2.375a6.878 6.878 0 016.875 6.875V12h1.375A2.75 2.75 0 0129 14.75v4.249a9.121 9.121 0 00-1.375-.124c-.468 0-.921.055-1.375.124V14.75H9.75V28.5h9.749zM13.875 12h8.25V9.25A4.12 4.12 0 0018 5.125a4.12 4.12 0 00-4.125 4.125V12zm16.968 10.78l-4.937 4.936-2.186-2.186-1.595 1.595 3.781 4.125 6.532-6.531-1.596-1.939z"
                      ></path>
                    </svg>
                  </div>
                  <h4>{t("success.heading")}</h4>
                  <div className="reset-password-success-copy-sec">
                    <span>{resetAgentPassword.data.new_password}</span>
                    <CopyToClipboard
                      text={resetAgentPassword.data.new_password}
                      onCopy={() => getSuccessNotificationMessage(t("password_copied"))}>
                      <Button className="reset-password-success-copy-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 27 32"
                        >
                          <path
                            fill="#FF916F"
                            d="M14.8 26.333a6.529 6.529 0 004.594-1.893 6.445 6.445 0 001.906-4.565V8.564a5.103 5.103 0 00-1.524-3.654l-2.914-2.896A5.166 5.166 0 0013.184.5H7a6.53 6.53 0 00-4.594 1.894A6.445 6.445 0 00.5 6.958v12.917a6.445 6.445 0 001.906 4.564A6.529 6.529 0 007 26.333h7.8zM3.1 19.875V6.958c0-1.027.41-2.013 1.142-2.74A3.913 3.913 0 017 3.083s6.395.018 6.5.031v2.553c0 .685.274 1.342.761 1.826a2.609 2.609 0 001.839.757h2.569c.013.105.031 11.625.031 11.625 0 1.028-.41 2.013-1.142 2.74A3.913 3.913 0 0114.8 23.75H7a3.913 3.913 0 01-2.758-1.135 3.862 3.862 0 01-1.142-2.74zm23.4-9.042v14.209a6.445 6.445 0 01-1.906 4.564A6.529 6.529 0 0120 31.5H8.3c-.345 0-.675-.136-.92-.378a1.288 1.288 0 010-1.827c.245-.242.575-.378.92-.378H20a3.913 3.913 0 002.758-1.135 3.862 3.862 0 001.142-2.74V10.833c0-.342.137-.67.38-.913a1.304 1.304 0 011.84 0c.243.242.38.57.38.913z"
                          ></path>
                        </svg>
                      </Button>
                    </CopyToClipboard>
                  </div>
                  <div className="reset-password-success-btn-sec">
                    <Button
                      className="onboarding-save-btn"
                      type="submit"
                      onClick={() => props.closeResetPasswordModal()}
                    >
                      {t("done")}
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(ResetPasswordModal);
