import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";

const FeedbackSuccess = () => {

  const t = useTranslation("helper.feedback_success");
  return (
    <div className="no-bot-exists-card mt-5">
      <div className="no-bot-exists-img-sec">
        <Image
          className="no-bot-exists-img"
          src={window.location.origin + "/images/illustrations/feedback.png"}
        />
      </div>
      <div className="no-bot-exists-info">
        <h4>{t("heading")}<br /> {t("sub_heading")}</h4>
      </div>
      <Link to="/" className="settings-btn">
        {t("go_home")}
      </Link>
    </div>
  );
};

export default FeedbackSuccess;