import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutStart, profileFailure } from '../store/slices/AdminSlice';
import {
    setChats,
    setHighlightChat,
    setSelectedChats,
    setAudioNotifyCount,
    selecteInboxBot
} from '../store/slices/ChatSlice';
import { useNavigate } from 'react-router-dom';

const useLogout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        dispatch(logoutStart());
        dispatch(setSelectedChats([]));
        dispatch(selecteInboxBot({
            label: "All Chats",
            value: "",
          }));
        dispatch(setChats([]));
        dispatch(setHighlightChat(""));
        dispatch(profileFailure());
        dispatch(setAudioNotifyCount(0));
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        localStorage.removeItem("selectedBot");
        localStorage.removeItem("selectedInboxBot");
        localStorage.clear();
        navigate("/login")

    }

    return { logout };

}

export default useLogout;