import React, { useEffect, useState } from "react";
import { Nav, Row, Col, Tab } from "react-bootstrap";
import ChatCustomize from "./Customise/ChatCustomize";
import ChatPreview from "./ChatPreview";
import SettingsfineTune from "./CustomTraining/SettingsFinetune";
import SourceScriptIndex from "./Source/SourceScriptIndex";
import SettingAnalyticsIndex from "./Analytics/SettingAnalyticsIndex";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NoBotExists from "../Helper/NoBotExists";
import AddBotModal from "../Bots/AddBotModal";
import { useTranslation, withTranslation } from "react-multi-lang";
import {
  fineTuneListStart,
  customTrainingListStart,
} from "../../store/slices/FineTuneSlice";
import ReactJoyride from "react-joyride";
import { updateTourStart } from "../../store/slices/AdminSlice";
import { Step6, Step7 } from "../TourSteps/TourSteps";

const SettingsIndex = (props) => {
  const t = useTranslation("settings.options");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const botsList = useSelector((state) => state.bots.botsList);
  const profile = useSelector((state) => state.admin.profile);
  const botsStore = useSelector((state) => state.bots.botsStore);
  const fineTuneList = useSelector((state) => state.fineTune.fineTuneList);

  const [tab, setTab] = useState(
    searchParams.get("tab")
      ? searchParams.get("tab")
      : profile.data.role == 1
      ? "customize"
      : ""
  );
  const [bot, setBot] = useState(selectedBot);
  const [addBot, setAddBot] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [botDetails, setBotDetails] = useState({
    name: "",
    website: "",
  });

  const closeAddBotModal = () => {
    setAddBot(false);
  };

  useEffect(() => {
    if (selectedBot && Object.keys(selectedBot).length > 0) {
      setBot(selectedBot);
      setBotDetails({
        ...botDetails,
        name: selectedBot.name,
        website: selectedBot.website,
      });
      if (
        profile.data.role == 1 ||
        profile.data.role_access?.custom_training_doc ||
        profile.data.role_access?.custom_training_link ||
        profile.data.role_access?.conversational_data ||
        profile.data.role_access?.faq
      ) {
        if (
          profile.data.role_access?.custom_training_doc ||
          profile.data.role_access?.custom_training_link
        )
          if (
            !fineTuneList.loading &&
            !Object.keys(fineTuneList.data).length > 0
          ) {
            dispatch(fineTuneListStart({ bot_id: selectedBot.bot_id }));
          }
        dispatch(customTrainingListStart({ bot_id: selectedBot.bot_id }));
      }
    }
  }, [selectedBot]);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if (
        profile.data.role == 2 &&
        Object.keys(profile.data.role_access).length > 0
      )
        !tab &&
          setTab(
            profile.data.role_access?.customize
              ? "customize"
              : profile.data.role_access?.source
              ? "source"
              : profile.data.role_access?.custom_training_doc ||
                profile.data.role_access?.custom_training_link ||
                profile.data.role_access?.faq ||
                profile.data.role_access?.conversational_data
              ? "fine-tune"
              : tab
          );

      if (profile.data.role == 1)
        setTab(searchParams.get("tab") ? searchParams.get("tab") : "customize");

      if (
        selectedBot &&
        Object.keys(selectedBot).length > 0 &&
        (profile.data.role == 1 ||
          profile.data.role_access?.custom_training_doc ||
          profile.data.role_access?.custom_training_link ||
          profile.data.role_access?.conversational_data ||
          profile.data.role_access?.faq)
      ) {
        dispatch(customTrainingListStart({ bot_id: selectedBot.bot_id }));
      }
    }
    return () => {
      setTab("");
    };
  }, [profile]);

  useEffect(() => {
    if (
      !skipRender &&
      !botsStore.loading &&
      Object.keys(botsStore.data).length > 0 &&
      Object.keys(botsStore.data.bot).length > 0
    ) {
      setBot(botsStore.data.bot);
      setBotDetails({
        ...botDetails,
        name: botsStore.data.bot.name,
        website: botsStore.data.bot.website,
      });
    }
    setSkipRender(false);
  }, [botsStore]);

  const steps = [
    {
      target: ".my-seventh-step",
      title: "Select Bot",
      content: <Step7 />,
      disableBeacon: true,
    },
  ];

  const handleJoyrideCallback = (data) => {
    if (["next", "skip"].includes(data.action)) {
      if (
        ((["skip"].includes(data.action) && data.lifecycle == "complete") ||
          (["next"].includes(data.action) && data.lifecycle == "init")) &&
        !profile.loading &&
        Object.keys(profile.data).length > 0 &&
        !profile.data.settings_tour
      ) {
        dispatch(
          updateTourStart({
            name: profile.data.name,
            email: profile.data.email,
            settings_tour: 1,
          })
        );
      }
    }
  };

  useEffect(() => {
    selectedBot &&
      setBotDetails({
        ...botDetails,
        name: bot.name,
        website: bot.website,
      });
  }, [tab]);

  return (
    <>
      <div className="admin-ui-setting-body-sec">
        <div className="new-settings-sec">
          {!profile.loading &&
          Object.keys(profile.data).length > 0 &&
          !profile.data.settings_tour &&
          Object.keys(selectedBot).length > 0 ? (
            <ReactJoyride
              floaterProps={{ placement: "bottom" }}
              steps={steps}
              run={true}
              continuous={true}
              spotlightClicks={true}
              scrollToFirstStep={true}
              disableScrolling={true}
              showSkipButton
              showProgress={true}
              disableOverlayClose={true}
              callback={handleJoyrideCallback}
              hideCloseButton={true}
              autoStart
              styles={{
                options: {
                  borderRadius: '0',
                  arrowColor: '#fff',
                  backgroundColor: '#fff',
                  overlayColor: '#312f2f',
                  primaryColor: '#000',
                  textColor: '#212529',
                  width: 550,
                  zIndex: 99999,
                },
                buttonNext: {
                  fontSize: "0.9em",
                  padding: "1em 1em",
                  display: "inline-block",
                  color:"#212529",
                  background: "linear-gradient(89.12deg, #FFCD00 0.53%, #FF916F 96.47%)",
                  border: '0',
                  letterSpacing: ".5px",
                  borderRadius: "10px!important",
                  fontWeight: "500",
                  marginLeft: "16px",
                  outline: 'none!important',
                  position: 'relative',
                },
                overlay: {
                  backgroundColor: "none",
                  mixBlendMode: "unset",
                },
                spotlight: {
                  backgroundColor: "#FFFFFF66",
                  borderRadius: 0,
                  boxShadow: "0px 0px 0px 9999px rgba(0,0,0,0.7)",
                  marginLeft: "0px",
                  maxHeight: "66px",
                },
              }}
              {...props}
            >
              Test
            </ReactJoyride>
          ) : null}
          {/* <div className="new-settings-box"> */}
          {Object.keys(selectedBot).length > 0 ? (
            <div className="new-settings-box">
              <div className="new-settings-tab-sec">
                <Tab.Container
                  id="left-tabs-example"
                  activeKey={tab}
                  onSelect={(key, ev) => ev.type != "click" && setTab(key)}
                >
                  <Row>
                    <Col sm={12}>
                      <div className="new-settings-tab-nav-link-sec">
                        <Nav variant="pills">
                          {profile.data.role == 1 ||
                          profile.data.role_access?.customize ? (
                            <Nav.Item>
                              <Nav.Link
                                eventKey="customize"
                                onClick={() => setTab("customize")}
                              >
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12 8a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z"></path>
                                  <path d="M21.294 13.9l-.444-.256a9.1 9.1 0 000-3.29l.444-.256a3 3 0 10-3-5.2l-.445.257A8.977 8.977 0 0015 3.513V3a3 3 0 00-6 0v.513a8.977 8.977 0 00-2.848 1.646L5.705 4.9a3 3 0 00-3 5.2l.444.256a9.1 9.1 0 000 3.29l-.444.256a3 3 0 103 5.2l.445-.257A8.977 8.977 0 009 20.487V21a3 3 0 006 0v-.513a8.977 8.977 0 002.848-1.646l.447.258a3 3 0 003-5.2zm-2.548-3.776a7.048 7.048 0 010 3.75 1 1 0 00.464 1.133l1.084.626a1 1 0 01-1 1.733l-1.086-.628a1 1 0 00-1.215.165 6.984 6.984 0 01-3.243 1.875 1 1 0 00-.751.969V21a1 1 0 01-2 0v-1.252a1 1 0 00-.751-.969A6.984 6.984 0 017.006 16.9a1 1 0 00-1.215-.165l-1.084.627a1 1 0 11-1-1.732l1.084-.626a1 1 0 00.464-1.133 7.048 7.048 0 010-3.75 1 1 0 00-.465-1.129l-1.084-.626a1 1 0 011-1.733l1.086.628A1 1 0 007.006 7.1a6.984 6.984 0 013.243-1.875A1 1 0 0011 4.252V3a1 1 0 012 0v1.252a1 1 0 00.751.969A6.984 6.984 0 0116.994 7.1a1 1 0 001.215.165l1.084-.627a1 1 0 111 1.732l-1.084.626a1 1 0 00-.463 1.129z"></path>
                                </svg> */}
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-settings-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M19.875 6.27a2.225 2.225 0 0 1 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z" /><path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /></svg>
                                {t("customise")}
                              </Nav.Link>
                            </Nav.Item>
                          ) : null}
                          {profile.data.role == 1 ||
                          profile.data.role_access?.custom_training_link ||
                          profile.data.role_access?.custom_training_doc ||
                          profile.data.role_access?.faq ||
                          profile.data.role_access?.conversational_data ? (
                            <Nav.Item>
                              <Nav.Link
                                eventKey="fine-tune"
                                onClick={() => setTab("fine-tune")}
                              >
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 19 19"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M1.867 9.084H7.29c.738 0 1.339-.594 1.339-1.322v-3.38c0-.842-.544-1.827-1.552-1.997a.417.417 0 01-.345-.48.423.423 0 01.488-.342 2.537 2.537 0 011.409.743V.512a2.336 2.336 0 00-2.453-.29 2.276 2.276 0 00-1.293 2.245 2.289 2.289 0 001.673 1.985.416.416 0 01.293.513.423.423 0 01-.52.29 3.128 3.128 0 01-2.287-2.714 3.037 3.037 0 01.006-.598c-.66.25-1.207.722-1.546 1.351a2.888 2.888 0 00-.057 2.621c.1.208.012.457-.199.556a.426.426 0 01-.563-.196 3.696 3.696 0 01-.34-1.157A2.077 2.077 0 00.116 6.367a2.027 2.027 0 00.468 2.096c.34.35.796.57 1.284.62zm2.65-3.553c.137-.187.4-.23.59-.095.189.134.232.395.096.582-.247.34-.28.782-.083 1.153.193.367.572.594.99.594h.02c.231 0 .42.182.423.411a.42.42 0 01-.417.422h-.025c-.736 0-1.4-.398-1.74-1.042a1.908 1.908 0 01.146-2.025zm-3.173 8.352c.049-.398.16-.789.339-1.157a.426.426 0 01.563-.197c.21.1.3.348.2.556-.402.83-.381 1.809.056 2.621.339.63.886 1.102 1.546 1.351a3.04 3.04 0 01-.006-.598 3.128 3.128 0 012.288-2.714.423.423 0 01.52.29.416.416 0 01-.294.513 2.288 2.288 0 00-1.673 1.985 2.275 2.275 0 001.293 2.245c.804.382 1.769.263 2.453-.29v-1.793c-.36.37-.832.644-1.41.742a.42.42 0 01-.488-.341.417.417 0 01.346-.48c1.008-.17 1.552-1.156 1.552-1.997v-3.38c0-.729-.6-1.322-1.34-1.322H1.87c-.49.05-.946.27-1.287.62a2.028 2.028 0 00-.468 2.096c.202.578.66 1.034 1.229 1.25zm3.027-2.438a1.956 1.956 0 011.765-1.042c.233.002.42.192.417.421a.42.42 0 01-.423.412h-.02c-.418 0-.797.227-.99.593-.196.371-.164.814.083 1.154a.413.413 0 01-.097.581.423.423 0 01-.589-.095 1.908 1.908 0 01-.146-2.024zm6.967 7.13H9.474v-4.432c.11.008.221.012.333.012 2.604 0 4.714-2.084 4.714-4.654s-2.11-4.653-4.714-4.653c-.112 0-.223.004-.334.012V.428h1.865l.364 1.824 1.957.802 1.566-1.037 2.165 2.137-1.05 1.545.811 1.932 1.849.36v3.021l-1.849.36-.811 1.932 1.05 1.545-2.165 2.137-1.566-1.037-1.957.802-.364 1.824z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg> */}
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-table-options"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 21h-7a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7" /><path d="M3 10h18" /><path d="M10 3v18" /><path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M19.001 15.5v1.5" /><path d="M19.001 21v1.5" /><path d="M22.032 17.25l-1.299 .75" /><path d="M17.27 20l-1.3 .75" /><path d="M15.97 17.25l1.3 .75" /><path d="M20.733 20l1.3 .75" /></svg>
                                {t("fine_tune")}
                              </Nav.Link>
                            </Nav.Item>
                          ) : null}
                          {profile.data.role == 1 ||
                          profile.data.role_access?.inbox != 0 ? (
                            <Nav.Item>
                              <Nav.Link onClick={() => navigate("/inbox")}>
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 17 17"
                                >
                                  <path d="M2.488 2.49a8.5 8.5 0 0112.022 0 8.49 8.49 0 011.597 9.801l.875 3.85a.698.698 0 01-.835.835l-3.852-.875C6.695 18.895-.002 14.85-.002 8.498c0-2.27.885-4.403 2.49-6.008zm2.464 8.123h3.7a.698.698 0 100-1.396h-3.7a.698.698 0 100 1.396zm0-2.835h7.093a.698.698 0 100-1.396H4.952a.698.698 0 100 1.396z"></path>
                                </svg> */}
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-inbox"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" /><path d="M4 13h3l3 3h4l3 -3h3" /></svg>
                                {t("inbox")}
                              </Nav.Link>
                            </Nav.Item>
                          ) : null}
                          {profile.data.role == 1 ||
                          profile.data.role_access?.source ? (
                            <Nav.Item>
                              <Nav.Link
                                eventKey="source"
                                onClick={() => setTab("source")}
                              >
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 17 17"
                                >
                                  <path d="M.5 6.318v6.546c.001.964.353 1.888.977 2.57.625.681 1.473 1.065 2.356 1.066h9.334c.883-.001 1.73-.385 2.356-1.066a3.815 3.815 0 00.977-2.57V6.318H.5zm9.333 7.273H4.5a.64.64 0 01-.471-.213.762.762 0 01-.196-.514c0-.193.07-.378.196-.515a.64.64 0 01.471-.213h5.333a.64.64 0 01.472.213.762.762 0 01.195.515c0 .192-.07.377-.195.514a.64.64 0 01-.472.213zm2.667-2.91h-8a.64.64 0 01-.471-.212.762.762 0 01-.196-.514c0-.193.07-.378.196-.515a.64.64 0 01.471-.213h8a.64.64 0 01.471.213.762.762 0 01.196.515c0 .192-.07.377-.196.514a.64.64 0 01-.471.213zm4-5.817v-.728a3.815 3.815 0 00-.977-2.57C14.898.885 14.05.501 13.167.5H3.833c-.883.001-1.73.385-2.356 1.066A3.815 3.815 0 00.5 4.136v.728h16zm-9.333-2.91c.132 0 .26.043.37.123.11.08.195.194.246.327.05.132.063.279.038.42a.75.75 0 01-.183.372.652.652 0 01-.341.2.615.615 0 01-.385-.042.683.683 0 01-.3-.268.775.775 0 01.083-.918.64.64 0 01.472-.213zm-2 0c.132 0 .26.043.37.123.11.08.195.194.246.327.05.132.063.279.038.42a.75.75 0 01-.183.372.652.652 0 01-.341.2.615.615 0 01-.385-.042.683.683 0 01-.3-.268.775.775 0 01.083-.918.64.64 0 01.472-.213zm-2 0c.132 0 .26.043.37.123.11.08.195.194.246.327.05.132.063.279.038.42a.75.75 0 01-.183.372.652.652 0 01-.341.2.615.615 0 01-.385-.042.683.683 0 01-.3-.268.775.775 0 01.083-.918.64.64 0 01.472-.213z"></path>
                                </svg> */}
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-source-code"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14.5 4h2.5a3 3 0 0 1 3 3v10a3 3 0 0 1 -3 3h-10a3 3 0 0 1 -3 -3v-5" /><path d="M6 5l-2 2l2 2" /><path d="M10 9l2 -2l-2 -2" /></svg>
                                {t("source")}
                              </Nav.Link>
                            </Nav.Item>
                          ) : null}
                          {profile.data.role == 1 && (
                            <Nav.Item>
                              <Nav.Link
                                eventKey="analytics"
                                onClick={() => setTab("analytics")}
                              >
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 17 17"
                                >
                                  <path d="M4.781 9.563H1.594a.531.531 0 00-.532.53v5.313c0 .294.238.531.532.531H4.78a.531.531 0 00.532-.53v-5.313a.531.531 0 00-.532-.531zM15.406 5.313H12.22a.531.531 0 00-.531.53v9.563c0 .294.237.531.53.531h3.188a.531.531 0 00.531-.53V5.843a.531.531 0 00-.53-.532zM10.094 1.063H6.906a.531.531 0 00-.531.53v13.813c0 .294.238.531.531.531h3.188a.531.531 0 00.531-.53V1.593a.531.531 0 00-.531-.532z"></path>
                                </svg> */}
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-brand-google-analytics"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 9m0 1.105a1.105 1.105 0 0 1 1.105 -1.105h1.79a1.105 1.105 0 0 1 1.105 1.105v9.79a1.105 1.105 0 0 1 -1.105 1.105h-1.79a1.105 1.105 0 0 1 -1.105 -1.105z" /><path d="M17 3m0 1.105a1.105 1.105 0 0 1 1.105 -1.105h1.79a1.105 1.105 0 0 1 1.105 1.105v15.79a1.105 1.105 0 0 1 -1.105 1.105h-1.79a1.105 1.105 0 0 1 -1.105 -1.105z" /><path d="M5 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /></svg>
                                {t("analytics")}
                              </Nav.Link>
                            </Nav.Item>
                          )}
                        </Nav>
                      </div>
                    </Col>
                    <Col sm={12}>
                      <Tab.Content>
                        {tab == "customize" && (
                          <Tab.Pane eventKey="customize">
                            <ChatCustomize
                              setBotDetails={setBotDetails}
                              botDetails={botDetails}
                              setBot={setBot}
                              bot={bot}
                            />
                          </Tab.Pane>
                        )}
                        {tab == "fine-tune" && (
                          <Tab.Pane eventKey="fine-tune">
                            <SettingsfineTune />
                          </Tab.Pane>
                        )}
                        {tab == "analytics" && (
                          <Tab.Pane eventKey="analytics">
                            <SettingAnalyticsIndex />
                          </Tab.Pane>
                        )}
                        {tab == "source" && (
                          <Tab.Pane eventKey="source">
                            <SourceScriptIndex />
                          </Tab.Pane>
                        )}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
              <div className="new-settings-preview-sec">
                {Object.keys(selectedBot).length > 0 ? (
                  <ChatPreview selectedBot={bot} botDetails={botDetails} />
                ) : null}
              </div>
            </div>
          ) : (
            !botsList.loading &&
            Object.keys(botsList.data).length > 0 &&
            Object.keys(botsList.data.bots.filter((bot) => bot.status == 1))
              .length <= 0 && (
              <div className="mt-5">
                <NoBotExists setAddBot={setAddBot} />
              </div>
            )
          )}
        </div>
      </div>
      {addBot && (
        <AddBotModal
          addBot={addBot}
          closeAddBotModal={closeAddBotModal}
          setAddBot={setAddBot}
        />
      )}
    </>
  );
};

export default withTranslation(SettingsIndex);
