import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { subscriptionSaveStart } from "../../store/slices/SubscriptionSlice";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Checkbox, Radio, Switch } from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox';

const SubscriptionConfirmModal = (props) => {
  
  const t = useTranslation("subscriptions.subscription_confirm");

  const dispatch = useDispatch();
  const generateStripeIntent = useSelector((state) => state.subscription.generateStripeIntent);
  const subscriptionSave = useSelector((state) => state.subscription.subscriptionSave);
  const [skipRender, setSkipRender] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    // if(values.coupon_code)
    // {
    //   dispatch(subscriptionSaveStart({
    //     amount: props.subscriptionConfirm == "monthly" ? props.newPlan.amount_monthly : props.newPlan.amount_yearly,
    //     coupon_code: values.coupon_code,
    //     subscription_plan_unique_id: props.newPlan.subscription_plan_unique_id,
    //     onboarding: props.onboarding,
    //     renewal_type: values.renewal_type,
    //   }))
    // }
    // else
    values.renewal_type && props.handleStripeIntent(props.newPlan, props.subscriptionConfirm)
  };

  useEffect(() => {
    if (
      !skipRender &&
      !generateStripeIntent.loading &&
      Object.keys(generateStripeIntent.data).length > 0
    ) {
      props.closeSubscriptionConfirm();
    }
    setSkipRender(false);
  }, [generateStripeIntent]);

  useEffect(() => {
    if (
      !skipRender &&
      !subscriptionSave.loading &&
      Object.keys(subscriptionSave.data).length > 0
    ) {
      props.closeSubscriptionConfirm();
      navigate("/billings")
    }
    setSkipRender(false);
  }, [subscriptionSave]);

  return (
    <>
      {/* <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.subscriptionConfirm}
        onHide={props.closeSubscriptionConfirm}
      >
        <Modal.Body>
          <h4>{t("heading")}</h4>
          <Button
            className="modal-close"
            onClick={props.closeSubscriptionConfirm}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              viewBox="0 0 11 11"
            >
              <path
                fill="#979BA2"
                d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"
              ></path>
            </svg>
          </Button>
          <Formik
            initialValues={props.onboarding ? {
              coupon_code: "",
            } : {
              coupon_code: "",
              renewal_type: "",
            }}
            validationSchema={props.onboarding ? Yup.object().shape({
              coupon_code: Yup.string().matches(/^[^<> ]*$/, "Invalid Code"),
            }) : Yup.object().shape({
              coupon_code: Yup.string().matches(/^[^<> ]*$/, "Invalid Code"),
              renewal_type: Yup.string().required(t("renewal_type.required")),
            })}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ setFieldValue, values }) => (
              <FORM className="onboarding-form">
                <h4 className="mb-3">{t("plan_details")}</h4>
                <p>
                  {t("plan_name")} : <span style={{textTransform:"capitalize"}}>{props.newPlan.name}</span>
                </p>
                <p>
                  {t("bots")} : <span>{props.newPlan.bots}</span>
                </p>
                <p>
                  {t("fine_tunings")} : <span>{props.newPlan.fine_tunings}</span>
                </p>
                <p>
                  {t("messages")} : <span>{props.newPlan.messages}</span>
                </p>
                <hr />
                <h4 className="mb-3">{t("message")}</h4>
                <p>{t("content")}</p>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Field
                    className="form-control"
                    type="text"
                    placeholder={t("coupon_code.placeholder")}
                    name="coupon_code"
                  />
                </Form.Group>
                {!props.onboarding ? <React.Fragment>
                  <hr />
                  <h4 className="mb-3">{t("renewal_message")}</h4>
                  <div className="permission-inbox-checkbox-box" style={{ marginBottom:"1em" }}>
                  <p>{t("renewal_content")}</p>
                  <div className="invoice-checkbox">
                    <Form.Check
                      checked={values.renewal_type === 1}
                      onChange={() => {
                        setFieldValue("renewal_type", 1)
                        localStorage.setItem("renewal_type", 1)
                      }}
                      aria-label="option 1"
                    />
                    {t("options.cancel")}
                  </div>
                  <div className="invoice-checkbox mb-3">
                    <Form.Check
                      checked={values.renewal_type === 2}
                      onChange={() => {
                        setFieldValue("renewal_type", 2)
                        localStorage.setItem("renewal_type", 2)
                      }}
                    />
                    {t("options.expire")}
                  </div>
                  <ErrorMessage
                    component={"div"}
                    name="renewal_type"
                    className="errorMsg"
                  />
                  </div> 
                </React.Fragment>: null}
                <div className="onboarding-import-webpage-modal-btn-sec">
                  <Button
                    className="onboarding-cancel-btn"
                    onClick={props.closeSubscriptionConfirm}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    className="onboarding-save-btn"
                    type="submit"
                    disabled={generateStripeIntent.buttonDisable || subscriptionSave.buttonDisable}
                  >
                    {generateStripeIntent.buttonDisable || subscriptionSave.buttonDisable
                      ? t("loading")
                      : t("confirm")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal> */}
      <Modal
        className="modal-dialog-center  premium-plan-modal"
        size="lg"
        centered
        show={props.subscriptionConfirm}
        onHide={props.closeSubscriptionConfirm}
      >
        <Modal.Body>
          <div className="plan-full-card-wrapped">
            <Row className="g-0 align-items-center">
              <Col md={5} >
                <div className="plan-card-left-frame">
                  <div className="plan-card-left-top-area">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        fill="none"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="#224E55"
                          d="M256 512c141.385 0 256-114.615 256-256S397.385 0 256 0 0 114.615 0 256s114.615 256 256 256z"
                        ></path>
                        <path
                          fill="#FFB200"
                          fillRule="evenodd"
                          d="M146.788 319.376l-20.63-170.906a3.612 3.612 0 012.24-3.789 3.624 3.624 0 014.241 1.187l73.041 97.461 47.255-75.596a3.623 3.623 0 013.066-1.698c1.247 0 2.403.644 3.066 1.698l47.254 75.596 73.042-97.461a3.622 3.622 0 014.24-1.187 3.619 3.619 0 012.241 3.789l-20.631 170.906H146.788zM378.682 356.223H133.319a5.558 5.558 0 01-5.553-5.548v-18.522a5.558 5.558 0 015.553-5.548h245.363a5.552 5.552 0 015.547 5.548v18.522a5.552 5.552 0 01-5.547 5.548z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                    <h3>{props.newPlan.name}</h3>
                  </div>
                  <div className="plan-card-left-middle-area">
                    <ul className="list-unstyled">
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <rect
                            width="22.76"
                            height="22.325"
                            x="0.5"
                            y="0.5"
                            fill="#000"
                            rx="11.162"
                          ></rect>
                          <path
                            fill="#FF916F"
                            stroke="#FF916F"
                            d="M10.013 13.92l-2.021-2.021a.58.58 0 10-.822.82l2.435 2.436a.58.58 0 00.822 0l6.163-6.163a.58.58 0 10-.822-.822l-5.755 5.75z"
                          ></path>
                          <rect
                            width="22.76"
                            height="22.325"
                            x="0.5"
                            y="0.5"
                            stroke="#fff"
                            rx="11.162"
                          ></rect>
                        </svg>
                        <div className="plann-card-middle-info" >
                        <span>{t("bots")}</span>
                          <span className="plan-count">{props.newPlan.bots}</span>
                        </div>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <rect
                            width="22.76"
                            height="22.325"
                            x="0.5"
                            y="0.5"
                            fill="#000"
                            rx="11.162"
                          ></rect>
                          <path
                            fill="#FF916F"
                            stroke="#FF916F"
                            d="M10.013 13.92l-2.021-2.021a.58.58 0 10-.822.82l2.435 2.436a.58.58 0 00.822 0l6.163-6.163a.58.58 0 10-.822-.822l-5.755 5.75z"
                          ></path>
                          <rect
                            width="22.76"
                            height="22.325"
                            x="0.5"
                            y="0.5"
                            stroke="#fff"
                            rx="11.162"
                          ></rect>
                        </svg>
                        <div className="plann-card-middle-info" >
                        <span>{t("fine_tunings")}</span>
                          <span className="plan-count">{props.newPlan.fine_tunings}</span>
                        </div>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <rect
                            width="22.76"
                            height="22.325"
                            x="0.5"
                            y="0.5"
                            fill="#000"
                            rx="11.162"
                          ></rect>
                          <path
                            fill="#FF916F"
                            stroke="#FF916F"
                            d="M10.013 13.92l-2.021-2.021a.58.58 0 10-.822.82l2.435 2.436a.58.58 0 00.822 0l6.163-6.163a.58.58 0 10-.822-.822l-5.755 5.75z"
                          ></path>
                          <rect
                            width="22.76"
                            height="22.325"
                            x="0.5"
                            y="0.5"
                            stroke="#fff"
                            rx="11.162"
                          ></rect>
                        </svg>
                        <div className="plann-card-middle-info" >
                        <span>{t("messages")}</span>
                          <span className="plan-count">{props.newPlan.messages}</span>
                        </div>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <rect
                            width="22.76"
                            height="22.325"
                            x="0.5"
                            y="0.5"
                            fill="#000"
                            rx="11.162"
                          ></rect>
                          <path
                            fill="#FF916F"
                            stroke="#FF916F"
                            d="M10.013 13.92l-2.021-2.021a.58.58 0 10-.822.82l2.435 2.436a.58.58 0 00.822 0l6.163-6.163a.58.58 0 10-.822-.822l-5.755 5.75z"
                          ></path>
                          <rect
                            width="22.76"
                            height="22.325"
                            x="0.5"
                            y="0.5"
                            stroke="#fff"
                            rx="11.162"
                          ></rect>
                        </svg>
                        <div className="plann-card-middle-info" >
                          <span>
                          24/7 Support
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="plan-card-left-bottom-area">
                    <h3>Amount</h3>
                    <h4>{props.newPlan.monthly_amount}</h4>
                  </div>
                </div>
              </Col>
              <Col md={7}>
              <Formik
            initialValues={{
              renewal_type: "",
            }}
            validationSchema={Yup.object().shape({
              renewal_type: Yup.string().required(t("renewal_type.required")),
            })}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ setFieldValue, values }) => (
              <FORM className="onboarding-form">
                <div className="plan-card-right-frame">
                  <div className="plan-card-right-top-area mb-3">
                    <h3 className="mb-3">{t("renewal_message")}</h3>
                    <p className="mb-3">{t("renewal_content")}</p>
                    <form >
                      <Checkbox name="tac" value="1" checked={values.renewal_type == 1} onChange={() => {
                        setFieldValue('renewal_type', 1)
                        localStorage.setItem("renewal_type", 1)
                      }}>
                        {t("options.cancel")}
                      </Checkbox>
                      <Checkbox name="tac" value="2" checked={values.renewal_type == 2} onChange={()=> {
                        setFieldValue('renewal_type', 2)
                        localStorage.setItem("renewal_type", 2)
                      }}>
                        {t("options.expire")}
                      </Checkbox>
                    </form>
                    {/* <div className="invoice-checkbox">
                      <Form.Check
                        checked={values.renewal_type === 1}
                        onChange={() => {
                          setFieldValue("renewal_type", 1)
                          localStorage.setItem("renewal_type", 1)
                        }}
                        aria-label="option 1"
                      />
                      {t("options.cancel")}
                    </div>
                    <div className="invoice-checkbox mb-3">
                      <Form.Check
                        checked={values.renewal_type === 2}
                        onChange={() => {
                          setFieldValue("renewal_type", 2)
                          localStorage.setItem("renewal_type", 2)
                        }}
                      />
                      {t("options.expire")}
                    </div> */}
                    <ErrorMessage
                      component={"div"}
                      name="renewal_type"
                      className="errorMsg"
                    />
                  </div>
                  <div className="plan-card-top-bottom-area">
                    <Button
                      className="admin-your-bot-list-btn"
                      onClick={props.closeSubscriptionConfirm}
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      className="settings-btn"
                      type="submit"
                      disabled={generateStripeIntent.buttonDisable || subscriptionSave.buttonDisable}
                    >
                      {generateStripeIntent.buttonDisable || subscriptionSave.buttonDisable
                        ? t("loading")
                        : t("confirm")}
                    </Button>
                  </div>
                </div>
                </FORM>
            )}
          </Formik>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubscriptionConfirmModal;
