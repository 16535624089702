import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import Toggle from "react-styled-toggle";
import ResetPasswordModal from "./ResetPasswordModal";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { agentCreateStart, sendInviteStart, singleAgentViewStart, updateAgentPermissionsStart, updateAgentStatusStart } from "../../store/slices/AgentSlice";
import { AgentLoader } from "./Loader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import DeleteAgentModal from "./DeleteAgentModal";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import makeAnimated from 'react-select/animated';
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";

const AgentView = (props) => {

  const animatedComponents = makeAnimated();

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #F3F2ED!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      minHeight: "45px",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#50506B80",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#FF916F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#FF916F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#FF916F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
        '&:active':
        {
          backgroundColor: "#FFF6DD"
        }
      };
    },
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name required")
      .test(
        "len",
        "Should not exceed 255 characters",
        (val) => val && val.toString().length < 255
      ),
    // email: Yup.string().email("Invalid Email").required("Email Required"),
  });

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("agents.view");
  const [resetPassword, setResetPassword] = useState(false);
  const singleAgent = useSelector((state) => state.agent.singleAgent)
  const agentDelete = useSelector((state) => state.agent.agentDelete);
  const updateAgentPermissions = useSelector((state) => state.agent.updateAgentPermissions)
  const sendInvite = useSelector((state) => state.agent.sendInvite);
  const updateAgentStatus = useSelector((state) => state.agent.updateAgentStatus);
  const agentCreate = useSelector((state) => state.agent.agentCreate);
  const [deleteAgent, setDeleteAgent] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [editName, setEditName] = useState(false);
  const [bots, setBots] = useState([]);
  const [defaultBots, setDefaultBots] = useState(false);
  const formRef = useRef();

  const closeDeleteAgentModal = () => {
    setDeleteAgent(false);
  };

  const closeResetPasswordModal = () => {
    setResetPassword(false);
  };

  useEffect(() => {
    dispatch(singleAgentViewStart({ agent_id: params.agent_id }))
  }, [params.agent_id]);

  const handleUpdatePermissions = (values) => {
    dispatch(updateAgentPermissionsStart({ ...values, agent_id: params.agent_id, bot: false }))
  }

  const handleSubmit = (values) => {
    let newValues = {
      agent_id: singleAgent.data.agent.agent_id,
      name: singleAgent.data.agent.name,
      email: singleAgent.data.agent.email,
    }
    if (values.image) {
      newValues = { ...newValues, picture: values.image, bot_id: JSON.stringify(formRef.current?.values.bot_id) }
    }
    if (values.name) {
      newValues = { ...newValues, name: values.name, bot_id: JSON.stringify(formRef.current?.values.bot_id) }
    }
    dispatch(agentCreateStart(newValues))
  }

  const handleUpdateBots = (values) => {
    dispatch(
      updateAgentPermissionsStart({
        bot: true,
        agent_id: singleAgent.data.agent.agent_id,
        bot_id: JSON.stringify(values.bot_id),
      })
    );
  }

  useEffect(() => {
    if (
      !singleAgent.loading &&
      Object.keys(singleAgent.data).length > 0 &&
      singleAgent.data.bots.length > 0
    ) {
      let bots = singleAgent.data.bots.map((bot) => ({
        label: bot.name,
        value: bot.id,
      }));
      setBots(bots);
      let defaults = singleAgent.data.bots.filter(bot => bot.is_selected === 1).map((bot) => ({
        label: bot.name,
        value: bot.id,
      }));
      setDefaultBots(defaults);
      formRef.current.setFieldValue("bot_id", defaults.map((bot) => parseInt(bot.value)));
    }
    return () => {
      setDefaultBots(false);
    }
  }, [singleAgent]);

  useEffect(() => {
    if (
      !updateAgentPermissions.loading &&
      Object.keys(updateAgentPermissions.data).length > 0 &&
      updateAgentPermissions.data.bots.length > 0
    ) {
      let bots = updateAgentPermissions.data.bots.map((bot) => ({
        label: bot.name,
        value: bot.id,
      }));
      setBots(bots);
      let defaults = updateAgentPermissions.data.bots.filter(bot => bot.is_selected === 1).map((bot) => ({
        label: bot.name,
        value: bot.id,
      }));
      setDefaultBots(defaults);
      formRef.current.setFieldValue("bot_id", defaults.map((bot) => parseInt(bot.value)));
    }
  }, [updateAgentPermissions]);

  useEffect(() => {
    if (
      !skipRender &&
      !agentCreate.loading &&
      Object.keys(agentCreate.data).length > 0
    ) {
      setEditName(false);
    }
    setSkipRender(false);
  }, [agentCreate]);

  useEffect(() => {
    if (!skipRender && !agentDelete.loading && Object.keys(agentDelete.data).length > 0) {
      navigate('/agents');
    }
    setSkipRender(false);
  }, [agentDelete])

  return singleAgent.loading ?
    <Row>
      <Col md={12} xl={7} lg={7}>
        <Skeleton className="mt-3 mb-2" count={1} height={200} />
        <Skeleton className="mt-3" count={1} height={250} />
        <Skeleton className="mt-3" count={1} height={200} />
      </Col>
      <Col md={12} xl={5} lg={5}>
        <Skeleton className="mt-1" count={4} height={170} />
      </Col>
    </Row>
    :
    Object.keys(singleAgent.data).length > 0 ? (
      <>
        <div className="admin-ui-body-sec admin-agent-ui-body-sec">
          <div className="sales-rep-view-sec agent-body-sec">
            <div className="sales-rep-view-body-sec">
              <Row>
                <Col md={12} xl={7} lg={7}>
                  <div className="sales-rep-view-header-sec">
                    <div className="sales-rep-view-header-left-sec">
                      <div className="sales-rep-view-profile-img-change-sec">
                        <CustomLazyLoad
                          className="admin-ui-profile-img-change"
                          src={agentCreate.buttonDisable ? window.location.origin + "/images/image-pulse.svg" : singleAgent.data.agent.picture}
                        />
                        <label
                          className="admin-ui-profile-img-edit-icon"
                          aria-disabled={agentCreate.buttonDisable}
                        >
                          <input
                            type="file"
                            accept="image/png, image/jpeg,, image/jpg"
                            size="60"
                            onChange={(e) =>
                              handleSubmit({ image: e.target.files[0] })
                            }
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="none"
                            viewBox="0 0 25 24"
                          >
                            <path
                              stroke="#1c1c1c"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M11.5 2h-2c-5 0-7 2-7 7v6c0 5 2 7 7 7h6c5 0 7-2 7-7v-2"
                            ></path>
                            <path
                              stroke="#1c1c1c"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeMiterlimit="10"
                              strokeWidth="2"
                              d="M16.54 3.02L8.66 10.9c-.3.3-.6.89-.66 1.32l-.43 3.01c-.16 1.09.61 1.85 1.7 1.7l3.01-.43c.42-.06 1.01-.36 1.32-.66l7.88-7.88c1.36-1.36 2-2.94 0-4.94-2-2-3.58-1.36-4.94 0zM15.41 4.15a7.144 7.144 0 004.94 4.94"
                            ></path>
                          </svg>
                        </label>
                      </div>
                      <div className="sales-rep-view-user-info">
                        <Formik
                          initialValues={{
                            name: singleAgent.data.agent.name,
                            // email: profile.data.email,
                          }}
                          validationSchema={validationSchema}
                        >
                          {({ values, setFieldValue, resetForm }) => (
                            <FORM className="onboarding-form">
                              {!editName ? (
                                <h4>
                                  <span>{singleAgent.data.agent.name}</span>
                                  <Button
                                    className="profile-form-svg-btn edit-icon"
                                    onClick={() => setEditName(true)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="21"
                                      height="21"
                                      fill="none"
                                      viewBox="0 0 25 24"
                                    >
                                      <path
                                        stroke="#1c1c1c"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeMiterlimit="10"
                                        strokeWidth="1.5"
                                        d="M13.76 3.6l-8.21 8.69c-.31.33-.61.98-.67 1.43l-.37 3.24c-.13 1.17.71 1.97 1.87 1.77l3.22-.55c.45-.08 1.08-.41 1.39-.75l8.21-8.69c1.42-1.5 2.06-3.21-.15-5.3-2.2-2.07-3.87-1.34-5.29.16zM12.39 5.05a6.126 6.126 0 005.45 5.15M3.5 22h18"
                                      ></path>
                                    </svg>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="#FF916F" width="20" height="20" viewBox="0 0 24 24"><path d="M18 19v4.7a4.968 4.968 0 001.879-1.164l2.656-2.658A4.954 4.954 0 0023.7 18H19a1 1 0 00-1 1zM7.172 13.828A4 4 0 006 16.657V18h1.343a4 4 0 002.829-1.172L21.5 5.5a2.121 2.121 0 00-3-3z"></path><path d="M24 4.952a4.087 4.087 0 01-1.08 1.962L11.586 18.243A5.961 5.961 0 017.343 20H6a2 2 0 01-2-2v-1.343a5.957 5.957 0 011.758-4.242L17.086 1.086A4.078 4.078 0 0119.037 0H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h11v-5a3 3 0 013-3h5V5v-.048z"></path></svg> */}
                                  </Button>
                                </h4>
                              ) : (
                                <h4>
                                  <Row>
                                    <Col md={12}>
                                      <Form.Group className="mb-0">
                                        <Field
                                          className="form-control"
                                          type="text"
                                          placeholder={t("name.placeholder")}
                                          name="name"
                                          value={values.name}
                                          onChange={(e) => {
                                            setFieldValue("name", e.target.value);
                                          }}
                                        />
                                        <ErrorMessage
                                          component={"div"}
                                          name="name"
                                          className="errorMsg"
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Button
                                    className="profile-form-svg-btn"
                                    disabled={
                                      agentCreate.buttonDisable ||
                                      values.name == "" ||
                                      values.name === singleAgent.data.agent.name
                                    }
                                    onClick={() => handleSubmit(values)}
                                    type="submit"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="#58D073"
                                      data-name="Layer 1"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm-.091 15.419a2.001 2.001 0 01-2.823-.005l-2.782-2.696 1.393-1.437 2.793 2.707 5.809-5.701 1.404 1.425-5.793 5.707z"></path>
                                    </svg>
                                  </Button>
                                  <Button
                                    className="profile-form-svg-btn"
                                    onClick={() => {
                                      setEditName(false);
                                      resetForm({
                                        values: { name: singleAgent.data.agent.name },
                                      });
                                    }}
                                    type="button"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="#dc3545"
                                      x="0"
                                      y="0"
                                      enableBackground="new 0 0 512 512"
                                      version="1.1"
                                      viewBox="0 0 512 512"
                                      xmlSpace="preserve"
                                    >
                                      <path d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256C511.847 114.678 397.322.153 256 0zm85.333 311.189c8.669 7.979 9.229 21.475 1.25 30.144-7.979 8.669-21.475 9.229-30.144 1.25-.434-.399-.85-.816-1.25-1.25L256 286.165l-55.168 55.168c-8.475 8.185-21.98 7.95-30.165-.525-7.984-8.267-7.984-21.373 0-29.64L225.835 256l-55.168-55.168c-8.185-8.475-7.95-21.98.525-30.165 8.267-7.984 21.373-7.984 29.64 0L256 225.835l55.189-55.168c7.979-8.669 21.475-9.229 30.144-1.25 8.669 7.979 9.229 21.475 1.25 30.144-.399.434-.816.85-1.25 1.25L286.165 256l55.168 55.189z"></path>
                                    </svg>
                                  </Button>
                                </h4>
                              )}
                            </FORM>
                          )}
                        </Formik>
                        <h5>
                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 17 13"><path fill="#000" fill-rule="evenodd" d="M2.12 1.107L6.929 5.77c1.048 1.014 2.763 1.015 3.812 0l4.809-4.662a.104.104 0 00-.015-.162 3.084 3.084 0 00-1.73-.528h-9.94c-.643 0-1.24.197-1.73.528a.104.104 0 00-.015.162zM.835 3.353c0-.49.125-.953.346-1.36a.111.111 0 01.175-.025l4.747 4.6c1.504 1.46 3.96 1.46 5.464 0l4.747-4.6a.111.111 0 01.175.026c.22.406.346.87.346 1.359v6.128c0 1.62-1.36 2.936-3.03 2.936h-9.94c-1.67 0-3.03-1.316-3.03-2.936V3.353z" clip-rule="evenodd"></path></svg> */}
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24"><path stroke="#1c1c1c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M17 20.5H7c-3 0-5-1.5-5-5v-7c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5z"></path><path stroke="#1c1c1c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M17 9l-3.13 2.5c-1.03.82-2.72.82-3.75 0L7 9"></path></svg>
                          <span>{singleAgent.data.agent.email}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="sales-rep-view-header-right-sec">
                      <Button
                        variant="link"
                        className="update-status-btn"
                        disabled={updateAgentStatus.buttonDisable}
                      >
                        <ul
                          className="sales-rep-list-status-info-btn-list list-unstyled"
                          onClick={() =>
                            dispatch(
                              updateAgentStatusStart({
                                agent_id: singleAgent.data.agent.agent_id,
                              })
                            )
                          }
                        >
                          <li className={singleAgent.data.agent.status ? "active" : ""}>
                            {t("status.active")}
                          </li>
                          <li className={singleAgent.data.agent.status ? "" : "inactive"}>
                            {t("status.inactive")}
                          </li>
                        </ul>
                      </Button>
                      {(singleAgent.data.agent.email_status === 0 && singleAgent.data.agent.status) ? (
                        <Button
                          className="sales-rep-list-copy-btn"
                          onClick={() =>
                            dispatch(
                              sendInviteStart({
                                agent_id: singleAgent.data.agent.agent_id,
                              })
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="#ff916f"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M13.06 10.94a5.74 5.74 0 010 8.13c-2.25 2.24-5.89 2.25-8.13 0-2.24-2.25-2.25-5.89 0-8.13"
                            ></path>
                            <path
                              stroke="#ff916f"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M10.59 13.41c-2.34-2.34-2.34-6.14 0-8.49 2.34-2.35 6.14-2.34 8.49 0 2.35 2.34 2.34 6.14 0 8.49"
                            ></path>
                          </svg>
                          <span>
                            {sendInvite.buttonDisable ? t("loading") : t("send_invite")}
                          </span>
                        </Button>
                      ) : null}
                    </div>
                  </div>
                  <div className="sales-rep-view-select-box resp-mrg-btm-xs">
                    <div className="sales-rep-view-select-card">
                      <h4>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#1C1C1C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-robot"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M6 4m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path><path d="M12 2v2"></path><path d="M9 12v9"></path><path d="M15 12v9"></path><path d="M5 16l4 -2"></path><path d="M15 14l4 2"></path><path d="M9 18h6"></path><path d="M10 8v.01"></path><path d="M14 8v.01"></path></svg>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FF916F" viewBox="0 0 21 24"><path d="M18.601 23.222h-15.2c-.525 0-.95-.454-.95-1.013 0-3.355 2.556-6.082 5.7-6.082h5.7c3.143 0 5.7 2.727 5.7 6.082 0 .56-.425 1.013-.95 1.013zm.475-17.14h-.475c0-2.236-1.704-4.055-3.8-4.055h-2.85V1.014c0-.56-.425-1.014-.95-1.014-.525 0-.95.454-.95 1.014v1.013h-2.85c-2.096 0-3.8 1.819-3.8 4.055h-.475c-.786 0-1.425.682-1.425 1.52V9.63c0 .838.64 1.52 1.425 1.52h.475c0 2.236 1.704 4.055 3.8 4.055h7.6c2.096 0 3.8-1.819 3.8-4.055h.475c.786 0 1.425-.682 1.425-1.52V7.603c0-.839-.64-1.521-1.425-1.521zm-9.54 3.085a1.061 1.061 0 010 1.433.918.918 0 01-.673.297.918.918 0 01-.671-.297l-.516-.55-.516.55a.918.918 0 01-.672.297.918.918 0 01-.671-.297 1.061 1.061 0 010-1.433l.516-.55-.516-.551a1.061 1.061 0 010-1.434.907.907 0 011.343 0l.516.55.516-.55a.907.907 0 011.343 0 1.061 1.061 0 010 1.434l-.516.55.516.55zm6.65 0a1.061 1.061 0 010 1.433.918.918 0 01-.672.297.918.918 0 01-.672-.297l-.516-.55-.516.55a.918.918 0 01-.671.297.918.918 0 01-.672-.297 1.061 1.061 0 010-1.433l.516-.55-.516-.551a1.061 1.061 0 010-1.434.907.907 0 011.343 0l.516.55.516-.55a.907.907 0 011.343 0 1.061 1.061 0 010 1.434l-.516.55.516.55z"></path></svg> */}
                        {t('select_bots.heading')}
                      </h4>
                      <h5>{t('select_bots.message')}</h5>
                      <Formik
                        initialValues={{
                          bot_id: [],
                        }}
                        onSubmit={handleUpdateBots}
                        innerRef={formRef}>
                        {({ setFieldValue, values }) => (
                          <FORM>
                            {(!singleAgent.loading && Object.keys(singleAgent.data).length > 0) ?
                              bots.length && defaultBots ?
                                <div className="sales-rep-view-select-box-select-sec">
                                  <Select
                                    name="bot_id"
                                    options={bots}
                                    defaultValue={defaultBots}
                                    styles={customStyles}
                                    placeholder={"Select bots"}
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    onChange={(value) => {
                                      setFieldValue("bot_id", value.map((bot) => (
                                        parseInt(bot.value)
                                      )))
                                    }
                                    }
                                  />
                                </div> : <h5>{t('no_bots')}</h5> : <Skeleton height={50} />}
                            <div className="profile-new-security-btn-sec">
                              <Button
                                className="contact-us-btn"
                                type="submit"
                                disabled={updateAgentPermissions.buttonDisable || !bots.length}
                              >
                                {(updateAgentPermissions.buttonDisable && updateAgentPermissions.data.bot)
                                  ? t("loading")
                                  : t("update_bots")}
                              </Button>
                            </div>
                          </FORM>
                        )}
                      </Formik>
                    </div>
                  </div>
                  {(singleAgent.data.agent.email_status != 0) && <div className="profile-new-security-card resp-mrg-btm-md">
                    <h4>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        data-name="Layer 1"
                        width="24"
                        height="24"
                        fill="#FF916F"
                        viewBox="0 0 24 24"
                      >
                        <path d="M18.573 2.126L12.314.051a.995.995 0 00-.629 0L5.426 2.126a4.993 4.993 0 00-3.427 4.746v5.171c0 6.563 7.005 10.577 9.152 11.65 0 0 .467.307.85.307s.791-.24.791-.24c2.16-.869 9.207-4.281 9.207-11.717V6.872a4.993 4.993 0 00-3.427-4.746zM13 11.791V15a1 1 0 11-2 0v-3.209a2.5 2.5 0 112 0z"></path>
                      </svg>
                      {t("security.heading")}
                    </h4>
                    <h5>{t("security.message")}</h5>
                    <div className="profile-new-security-btn-sec">
                      <Button
                        className="contact-us-btn"
                        onClick={() =>
                          setResetPassword(singleAgent.data.agent.agent_id)
                        }
                      >
                        {t("reset_password")}
                      </Button>
                    </div>
                  </div>}
                  <div className="profile-new-payment-delete-account-box resp-mrg-btm-xs">
                    <h4>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0b0b12de" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-user-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path><path d="M6 21v-2a4 4 0 0 1 4 -4h3.5"></path><path d="M22 22l-5 -5"></path><path d="M17 22l5 -5"></path></svg>
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 23"><path fill="#FF916F" d="M2.77 5.98c0-1.092.324-2.159.933-3.067A5.534 5.534 0 016.188.88a5.556 5.556 0 016.036 1.197 5.503 5.503 0 011.2 6.016 5.524 5.524 0 01-2.04 2.477 5.552 5.552 0 01-6.991-.688A5.516 5.516 0 012.769 5.98zm5.538 7.36a8.33 8.33 0 00-5.872 2.428A8.275 8.275 0 000 21.62a.918.918 0 00.923.92h14.77a.925.925 0 00.922-.92 8.275 8.275 0 00-2.436-5.852 8.33 8.33 0 00-5.871-2.428zm13.316-2.203l2.117-2.11a.919.919 0 00-.656-1.559.925.925 0 00-.65.259l-2.116 2.11-2.117-2.11a.925.925 0 00-1.564.653.918.918 0 00.26.647l2.116 2.11-2.117 2.11a.92.92 0 001.006 1.507.923.923 0 00.3-.207l2.116-2.11 2.117 2.11A.925.925 0 0024 13.894a.919.919 0 00-.26-.647l-2.116-2.11z"></path></svg> */}
                      {t("delete_agent.heading")}
                    </h4>
                    <h5>{t("delete_agent.message")}</h5>
                    <p>
                      {t("delete_agent.content")}
                    </p>
                    <div className="profile-new-security-btn-sec">
                      <Button
                        className="delete-account-btn"
                        onClick={() =>
                          setDeleteAgent(singleAgent.data.agent.agent_id)
                        }
                      >
                        {t("delete")}
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col md={12} xl={5} lg={5}>
                  <Formik
                    initialValues={{
                      inbox: singleAgent.data.role_accesses.inbox,
                      custom_training_doc:
                        singleAgent.data.role_accesses.custom_training_doc,
                      custom_training_link:
                        singleAgent.data.role_accesses.custom_training_link,
                      conversational_data:
                        singleAgent.data.role_accesses.conversational_data,
                      customize: singleAgent.data.role_accesses.customize,
                      faq: singleAgent.data.role_accesses.faq,
                      source: singleAgent.data.role_accesses.source,
                    }}
                    onSubmit={handleUpdatePermissions}
                  >
                    {({ values, setFieldValue, resetForm }) => (
                      <FORM className="onboarding-form">
                        <div className="sales-rep-view-permission-box">
                          <div className="profile-new-notification-card">
                            <h4>
                              {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="#FF916F"
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                            >
                              <path d="M15.989 12.7V10a1 1 0 011-1h1a1 1 0 001-1V7a1 1 0 011-1h2.685a1.016 1.016 0 00.922-.6 4.522 4.522 0 00.376-2.377A3.491 3.491 0 0020.466 0a4.537 4.537 0 00-3.208 1.329L9.35 9.235a7.368 7.368 0 00-3.881.048A7.5 7.5 0 007.505 24a7.654 7.654 0 00.784-.041 7.529 7.529 0 006.428-5.429 7.334 7.334 0 00.047-3.88l.65-.65a1.984 1.984 0 00.575-1.3zM5.5 20A1.5 1.5 0 117 18.5 1.5 1.5 0 015.5 20z"></path>
                            </svg> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="#1c1c1c"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeMiterlimit="10"
                                  strokeWidth="2"
                                  d="M19.79 14.93a7.575 7.575 0 01-7.6 1.87l-4.71 4.7c-.34.35-1.01.56-1.49.49l-2.18-.3c-.72-.1-1.39-.78-1.5-1.5l-.3-2.18c-.07-.48.16-1.15.49-1.49l4.7-4.7c-.8-2.6-.18-5.55 1.88-7.6 2.95-2.95 7.74-2.95 10.7 0 2.96 2.95 2.96 7.76.01 10.71zM6.89 17.49l2.3 2.3"
                                ></path>
                                <path
                                  stroke="#1c1c1c"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M14.5 11a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
                                ></path>
                              </svg>
                              {t("permissions.heading")}
                            </h4>
                            <div className="permission-inbox-checkbox-sec">
                              <div className="invoice-checkbox">
                                <Form.Check
                                  checked={values.inbox === 2}
                                  onChange={() => {
                                    values.inbox === 2
                                      ? setFieldValue("inbox", 0)
                                      : setFieldValue("inbox", 2);
                                  }}
                                  aria-label="option 1"
                                />
                                {t("permissions.inbox.heading")}
                              </div>
                              <div className="permission-inbox-checkbox-box">
                                <div className="invoice-checkbox">
                                  <Form.Check
                                    checked={
                                      values.inbox === 1 || values.inbox === 2
                                    }
                                    onChange={() => {
                                      values.inbox === 1 || values.inbox === 2
                                        ? setFieldValue("inbox", 0)
                                        : setFieldValue("inbox", 1);
                                    }}
                                    aria-label="option 2"
                                  />
                                  {t("permissions.inbox.read")}
                                </div>
                                <div className="invoice-checkbox">
                                  <Form.Check
                                    checked={values.inbox === 2}
                                    onChange={() => {
                                      values.inbox === 2
                                        ? setFieldValue("inbox", 1)
                                        : setFieldValue("inbox", 2);
                                    }}
                                    aria-label="option 3"
                                  />
                                  {t("permissions.inbox.reply")}
                                </div>
                              </div>
                            </div>
                            <div className="permission-sales-list-card">
                              <div className="profile-new-notification-security-switch">
                                <Toggle
                                  name="custom-training-doc"
                                  width={40}
                                  height={20}
                                  sliderWidth={12}
                                  sliderHeight={12}
                                  translate={19}
                                  backgroundColorChecked="#FF916F"
                                  backgroundColorUnchecked="#E5E4F6"
                                  labelRight={t("permissions.custom_training_doc.heading")}
                                  checked={values.custom_training_doc}
                                  onChange={() => {
                                    values.custom_training_doc == 0
                                      ? setFieldValue("custom_training_doc", 1)
                                      : setFieldValue("custom_training_doc", 0);
                                  }}
                                />
                              </div>
                              <p>
                                {t("permissions.custom_training_doc.message")}
                              </p>
                            </div>
                            <div className="permission-sales-list-card">
                              <div className="profile-new-notification-security-switch">
                                <Toggle
                                  name="custom-training-link"
                                  width={40}
                                  height={20}
                                  sliderWidth={12}
                                  sliderHeight={12}
                                  translate={19}
                                  backgroundColorChecked="#FF916F"
                                  backgroundColorUnchecked="#E5E4F6"
                                  labelRight={t("permissions.custom_training_web.heading")}
                                  checked={values.custom_training_link}
                                  onChange={() => {
                                    values.custom_training_link == 0
                                      ? setFieldValue("custom_training_link", 1)
                                      : setFieldValue("custom_training_link", 0);
                                  }}
                                />
                              </div>
                              <p>
                                {t("permissions.custom_training_web.heading")}
                              </p>
                            </div>
                            <div className="permission-sales-list-card">
                              <div className="profile-new-notification-security-switch">
                                <Toggle
                                  width={40}
                                  height={20}
                                  sliderWidth={12}
                                  sliderHeight={12}
                                  translate={19}
                                  backgroundColorChecked="#FF916F"
                                  backgroundColorUnchecked="#E5E4F6"
                                  labelRight={t("permissions.customize.heading")}
                                  checked={values.customize}
                                  onChange={() => {
                                    values.customize === 0
                                      ? setFieldValue("customize", 1)
                                      : setFieldValue("customize", 0);
                                  }}
                                />
                              </div>
                              <p>
                                {t("permissions.customize.message")}
                              </p>
                            </div>
                            <div className="permission-sales-list-card">
                              <div className="profile-new-notification-security-switch">
                                <Toggle
                                  width={40}
                                  height={20}
                                  sliderWidth={12}
                                  sliderHeight={12}
                                  translate={19}
                                  backgroundColorChecked="#FF916F"
                                  backgroundColorUnchecked="#E5E4F6"
                                  labelRight={t("permissions.conversational_data.heading")}
                                  checked={values.conversational_data}
                                  onChange={() => {
                                    values.conversational_data == 0
                                      ? setFieldValue("conversational_data", 1)
                                      : setFieldValue("conversational_data", 0);
                                  }}
                                />
                              </div>
                              <p>
                                {t("permissions.conversational_data.message")}
                              </p>
                            </div>
                            <div className="permission-sales-list-card">
                              <div className="profile-new-notification-security-switch">
                                <Toggle
                                  width={40}
                                  height={20}
                                  sliderWidth={12}
                                  sliderHeight={12}
                                  translate={19}
                                  backgroundColorChecked="#FF916F"
                                  backgroundColorUnchecked="#E5E4F6"
                                  labelRight={t("permissions.source.heading")}
                                  checked={values.source}
                                  onChange={() => {
                                    values.source == 0
                                      ? setFieldValue("source", 1)
                                      : setFieldValue("source", 0);
                                  }}
                                />
                              </div>
                              <p>
                                {t("permissions.source.message")}
                              </p>
                            </div>
                            <div className="permission-sales-list-card">
                              <div className="profile-new-notification-security-switch">
                                <Toggle
                                  width={40}
                                  height={20}
                                  sliderWidth={12}
                                  sliderHeight={12}
                                  translate={19}
                                  backgroundColorChecked="#FF916F"
                                  backgroundColorUnchecked="#E5E4F6"
                                  labelRight={t("permissions.faq.heading")}
                                  checked={values.faq}
                                  onChange={() => {
                                    values.faq == 0
                                      ? setFieldValue("faq", 1)
                                      : setFieldValue("faq", 0);
                                  }}
                                />
                              </div>
                              <p>
                                {t("permissions.faq.message")}
                              </p>
                            </div>
                            <div className="profile-new-security-btn-sec">
                              <Button
                                className="contact-us-btn"
                                type="submit"
                                disabled={updateAgentPermissions.buttonDisable}
                              >
                                {(updateAgentPermissions.buttonDisable && !updateAgentPermissions.data.bot)
                                  ? t("loading")
                                  : t("update_permissions")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {resetPassword && (
          <ResetPasswordModal
            resetPassword={resetPassword}
            closeResetPasswordModal={closeResetPasswordModal}
            setResetPassword={setResetPassword}
          />
        )}
        {deleteAgent && (
          <DeleteAgentModal
            deleteAgent={deleteAgent}
            closeDeleteAgentModal={closeDeleteAgentModal}
            setDeleteAgent={setDeleteAgent}
          />
        )}
      </>
    ) : (
      <SomethingWentWrong
        buttonText="Retry"
        handleClick={() =>
          dispatch(singleAgentViewStart({ agent_id: params.agent_id }))
        }
      />
    );
};

export default AgentView;
