import React, { Fragment, memo, useCallback, useState } from "react";
import { Form, InputGroup, Image, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  addChat,
  setChats,
  setSelectedChats,
  updateChatMessages,
  updateChatSuggtion,
  addChatMsgCount,
  changeChatAudio,
  chatListTimeStampUpdate,
} from "../../store/slices/ChatSlice";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import useSocket from "../../hooks/useSocket";
import useDesign from "../../hooks/useDesign";
import { ButtonLoader } from "../Helper/Loader";
import { apiUrl } from "../../Environment";
import EmojiPicker from "emoji-picker-react";
import { useDropzone } from "react-dropzone";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import ChatPreviewLoader from "../Helper/ChatPreviewLoader";
import Toggle from "react-styled-toggle";
import NotChatFound from "../Helper/NoChatFound";
import { Tooltip as ReactTooltip } from "react-tooltip";
import AudioPlayer from "../CustomComponents/AudioPlayer";
import { useTranslation } from "react-multi-lang";
dayjs.extend(customParseFormat);

const InboxChat = ({ chatId }) => {

  const t = useTranslation("chats.chatbox")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  const selectedChats = useSelector((state) => state.chat.selectedChats);
  const highlightChat = useSelector((state) => state.chat.highlightChat);
  const chats = useSelector((state) => state.chat.chats);
  const chatList = useSelector((state) => state.chat.chatList);
  const socket = useSelector(state => state.chat.socket);

  const [singleMsg, setSingleMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [showChat, setShowChat] = useState(true);
  const [isMin, setIsMin] = useState(false);
  const [first, setFirst] = useState(true);
  const [notify, setNotify] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [cursorPointer, setCursorPointer] = useState(0);
  const [skipRender, setSkipRender] = useState(true);
  const [scrollDown, setScrollDown] = useState({
    notify: false,
    msg_count: 0,
    is_at_bottom: true,
  });
  const lastMsg = useRef();
  const messageField = useRef();
  const containerRef = useRef(null);

  const { getChatTypeBg } = useDesign();

  const {
    subscribe,
    reconnect,
    update,
    updateManual,
    sendMessage,
    unSubscribe,
  } = useSocket();
  const [chat, setChat] = useState({ messages: [], chatId, notify: false });
  const [sender, setSender] = useState("");
  const [isSuggestion, setIsSuggestion] = useState(false);

  useEffect(() => {
    setChat(chats.find((c) => c.chatId === chatId) ?? chat);
  }, [chats]);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if(socket && socket.readyState == 1)
        getInitialChat();
    }
  }, [profile, chatId, socket]);

  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : sessionStorage.getItem("token");

  const getInitialChat = async () => {
    setSingleMsg("");
    setLoading(true);
    setScrollDown({
      notify: false,
      msg_count: 0,
      is_at_bottom: true,
    });
    setFirst(true);
    // dispatch(changeChatAudio({ src: "" }));
    let formData = new FormData();
    formData.append("chat_id", chatId);
    const response = await axios.post(`${apiUrl}chats/messages`, formData, {
      headers: { Authorization: "Bearer " + token },
    });
    // console.log("response", response.data);
    if (response.data && response.data.data && response.data.data.chat) {
      setLoading(false);
      dispatch(
        addChat({
          chatId,
          username: response.data.data.chat.username,
          bot_name: response.data.data.chat.bot_name,
          chat_unique_id: response.data.data.chat.chat_unique_id,
          autoMsg: true,
          notify: true,
          messages: response.data.data.chat_messages,
          socketStatus: 0,
        })
      );
      subscribe(response.data.data.chat.username);
    } else {
      setLoading(false);
      setShowChat(false);
    }
  };

  useEffect(() => {
    if (sender === "manual") {
      handleScrollDown();
      setSender("");
    }
    if (chat.messages && lastMsg.current) {
      if (first)
        lastMsg?.current.scrollIntoView({
          behavior: first ? "instant" : "smooth",
        });
      const container = containerRef.current;
      if (container) {
        const scrollPosition =
          Math.trunc(container.scrollTop) + container.clientHeight;
        const isNotAtBottom = container.scrollHeight - scrollPosition > 200;
        if (!first && isNotAtBottom) {
          setScrollDown({
            ...scrollDown,
            notify: true,
            msg_count: scrollDown.msg_count + 1,
          });
        }
      }
      setFirst(false);
      const handleScroll = () => {
        const container = containerRef.current;
        if (container) {
          const isAtBottom =
            Math.trunc(container.scrollTop) + container.clientHeight >
            (container.scrollHeight ||
              Math.trunc(container.scrollTop) + container.clientHeight + 1 ===
                container.scrollHeight ||
              Math.trunc(container.scrollTop) + container.clientHeight - 1 ===
                container.scrollHeight) -
              200;
          setScrollDown((prevScrollDown) => {
            if (isAtBottom !== prevScrollDown.is_at_bottom) {
              return {
                ...prevScrollDown,
                is_at_bottom: isAtBottom,
              };
            }
            return prevScrollDown;
          });
          if (isAtBottom) {
            setScrollDown({
              ...scrollDown,
              notify: false,
              msg_count: 0,
              is_at_bottom: true,
            });
          }
          if (!isAtBottom && scrollDown.is_at_bottom) {
            setScrollDown({
              ...scrollDown,
              is_at_bottom: false,
            });
          }
        }
      };
      if (container) {
        container.addEventListener("scroll", handleScroll);
      }

      return () => {
        if (container) {
          container.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [chat.messages]);

  useEffect(() => {
    if (chat.suggetion && chat.socketStatus === 1) {
      setSingleMsg((msg) => msg ? msg + "\n\n" + chat.suggetion : chat.suggetion);
      setIsSuggestion(true);
      dispatch(updateChatSuggtion({ username: chat.username, suggetion: "" }));
    }
  }, [chat.suggetion]);

  useEffect(() => {
    if (!first && chat.socketStatus === 1) setNotify(true);
  }, [chat.notify]);

  const onSend = async (e) => {
    e.preventDefault();
    if (singleMsg && !sendLoading) {
      setShowEmoji(false);
      setSendLoading(true);
      let formData = new FormData();
      formData.append("chat_id", chatId);
      formData.append("answer", singleMsg);
      formData.append("sent_by", isSuggestion ? "ai_client" : "client");
      try {
        const response = await axios.post(
          `${apiUrl}hooks/send_message`,
          formData,
          { headers: { Authorization: "Bearer " + token } }
        );
        if (response.data.success) {
          // console.log(response.data);
          setIsSuggestion(false);
          dispatch(chatListTimeStampUpdate({ chatId }));
          const message = response.data.data.chat_message;
          setSender("manual");
          const date = dayjs().format("DD MMM YYYY");
          dispatch(
            updateChatMessages({
              username: response.data.data.chat_formatted.username,
              message: { date, message },
              disableNotify: true,
            })
          );
          dispatch(
            addChatMsgCount({
              chat_id: chatId,
              type: "text",
              message: singleMsg,
            })
          );
          setSingleMsg("");
          sendMessage(response.data.data.chat_formatted.username, {
            date,
            message,
          });
          handleScrollDown();
        } else {
          console.log(response);
          getErrorNotificationMessage(response.data.error);
        }
        setSendLoading(false);
      } catch (e) {
        setSendLoading(false);
        getErrorNotificationMessage(e);
      }
    }
  };

  const onSendFile = async (file) => {
    if (!fileLoading) {
      setShowEmoji(false);
      setFileLoading(true);
      let formData = new FormData();
      formData.append("chat_id", chatId);
      formData.append("image", file);
      formData.append("sent_by", "client");
      try {
        const response = await axios.post(
          `${apiUrl}hooks/send_message`,
          formData,
          { headers: { Authorization: "Bearer " + token } }
        );
        if (response.data.success) {
          // console.log(response.data);
          const message = response.data.data.chat_message;
          setSender("manual");
          const date = dayjs().format("DD MMM YYYY");
          dispatch(
            updateChatMessages({
              username: response.data.data.chat_formatted.username,
              message: { date, message },
              disableNotify: true,
            })
          );
          dispatch(
            addChatMsgCount({ chat_id: chatId, type: "file", message: file })
          );
          setSingleMsg("");
          sendMessage(response.data.data.chat_formatted.username, {
            date,
            message,
          });
          handleScrollDown();
        } else {
          console.log(response);
          getErrorNotificationMessage(response.data.error);
        }
        setFileLoading(false);
      } catch (e) {
        setFileLoading(false);
        getErrorNotificationMessage(false);
      }
    }
  };

  const removeChat = () => {
    dispatch(
      setSelectedChats(selectedChats.filter((chat_id) => chat_id != chatId))
    );
    setTimeout(() => {
      unSubscribe(chat.username);
    }, 500);
  };

  useEffect(() => {
    if(socket && socket.readyState === 1) {
      changeAutoMsg();
    }
    console.log("unSubscribe", chat.username);
    return () => {
      unSubscribe(chat.username);
    };
  }, [chat.username, socket]);

  const changeAutoMsg = () => update(chat.username);
  const changeAutoMsgManual = () => updateManual(chat.username);

  useEffect(() => {
    if (!skipRender && messageField.current) {
      messageField.current.selectionEnd = cursorPointer;
    }
    setSkipRender(false);
  }, [cursorPointer]);

  const onEmojiPick = (data) => {
    const ref = messageField.current;
    ref.focus();
    const start = singleMsg.substring(0, ref.selectionStart);
    const end = singleMsg.substring(ref.selectionStart);
    const text = start + data.emoji + end;
    setSingleMsg(text);
    setCursorPointer(start.length + data.emoji.length);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        onSendFile(file);
      });
    },
    [chatId]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      "image/jpeg": [".jpeg", ".png", ".gif"],
    },
    maxFiles: 1,
    noDragEventsBubbling: true,
  });

  const handleScrollDown = () => {
    lastMsg?.current?.scrollIntoView({ behavior: "smooth" });
    setScrollDown({
      ...scrollDown,
      notify: false,
      msg_count: 0,
      is_at_bottom: true,
    });
  };

  return loading || chatList.loading ? (
    <ChatPreviewLoader />
  ) : showChat && chat.chat_unique_id ? (
    <>
      <div className="new-chat-preview-card-left-header-sec">
        <div className="new-chat-preview-ip-info-sec">
          <div
            className={`new-chat-preview-user-img-sec ${getChatTypeBg(
              chat.widget_type
            )}`}
          >
            <Image
              className="new-chat-preview-user-img"
              src={window.location.origin + "/images/your-bot/your-bot-img.png"}
            />
          </div>
          <div className="new-chat-preview-user-info">
            <h4>{chat.chat_unique_id}</h4>
            <p>{chat.bot_name}</p>
          </div>
        </div>
        <div
          className="new-chat-preview-ip-icon-right"
          data-tooltip-id="my-tooltip-2"
        >
          <Toggle
            backgroundColorChecked="#FF916F"
            backgroundColorUnchecked="#9c9ca5"
            width={50}
            height={25}
            sliderWidth={19}
            sliderHeight={19}
            translate={23}
            checked={chat.autoMsg}
            onChange={() => changeAutoMsgManual()}
          />
        </div>
      </div>
      <div className="new-chat-preview-card-left-body-sec" ref={containerRef}>
        {chat.messages.length > 0 ? (
          <>
            {chat.messages.map((dailyChat, i) => (
              <Fragment key={i}>
                <div className="new-chat-preview-sticky-date-time">
                  {dailyChat.date}
                </div>
                {dailyChat.messages.map((message, i) =>
                  message.sent_by === "user" ? (
                    <div
                      className="new-chat-preview-user-receiver-card"
                      key={i}
                    >
                      <div className="new-chat-preview-user-receiver-icon-sec">
                        <Image
                          className="new-chat-preview-user-receiver-icon"
                          src={window.location.origin + "/images/placeholder-img.png"}
                        />
                      </div>
                      <div className="new-chat-preview-user-receiver-message-card">
                        <div className="new-chat-preview-user-receiver-message">
                          {message.file ? (
                            [
                              "png",
                              "jpg",
                              "jpeg",
                              "svg",
                              "gif",
                              "webp",
                            ].includes(message.file_type) ? (
                              <div className="my-2">
                                <CustomLazyLoad
                                  className="chat-img"
                                  src={message.file}
                                />
                              </div>
                            ) : ["mp3", "ogg", "oga", "wav"].includes(
                                message.file_type
                              ) ? (
                              <div className="my-2">
                                <AudioPlayer src={message.file} />
                              </div>
                            ) : null
                          ) : null}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: urlify(message.message),
                            }}
                          />
                        </div>
                        <div className="client-chat-user-receiver-time">
                          <span>{message.send_at}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="new-chat-preview-user-sender-card" key={i}>
                      <div className="new-chat-preview-user-sender-icon-sec">
                        <Image
                          className="new-chat-preview-user-sender-icon"
                          src={
                            window.location.origin +
                            "/images/chat/new-chat-bot-img.svg"
                          }
                        />
                      </div>
                      <div className="new-chat-preview-user-sender-message-card">
                        <div className="new-chat-preview-user-sender-message">
                          {message.file ? (
                            [
                              "png",
                              "jpg",
                              "jpeg",
                              "svg",
                              "gif",
                              "webp",
                            ].includes(message.file_type) ? (
                              <div className="my-2">
                                <CustomLazyLoad
                                  className="chat-img"
                                  src={message.file}
                                />
                              </div>
                            ) : ["mp3", "ogg"].includes(message.file_type) ? (
                              <div className="my-2">
                                <AudioPlayer src={message.file} />
                              </div>
                            ) : null
                          ) : null}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: urlify(message.message),
                            }}
                          />
                        </div>
                        <div className="client-chat-user-sender-time">
                          {message.send_at}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Fragment>
            ))}
            <div ref={lastMsg} />
          </>
        ) : null}
        {chat.socketStatus ? chat.socketStatus === 1 ? (
          <div className="new-chat-preview-card-left-footer-form">
            <Form onSubmit={onSend}>
              {showEmoji ? <EmojiPicker onEmojiClick={onEmojiPick} /> : null}
              <div className="new-chat-preview-input-typing-card">
                <div className="new-chat-preview-input-typing-card-top-sec">
                  <ChatMessageBox
                    value={singleMsg}
                    onChange={(e) => setSingleMsg(e.target.value)}
                    textareaRef={messageField}
                    onSend={onSend}
                  />
                </div>
                <div className="new-chat-preview-input-typing-card-bottom-sec">
                  <div className="new-chat-preview-input-typing-card-left-sec">
                    <Button
                      className="emoji-btn"
                      onClick={() => setShowEmoji(!showEmoji)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8 8a2 2 0 012 2c0 1-.895 1-2 1s-2 0-2-1a2 2 0 012-2zm9.666 7.746A9.454 9.454 0 0112 18a9.454 9.454 0 01-5.666-2.254 1 1 0 011.332-1.492A7.509 7.509 0 0012 16a7.508 7.508 0 004.336-1.748 1 1 0 011.33 1.494zM16 11c-1.105 0-2 0-2-1a2 2 0 014 0c0 1-.895 1-2 1z"></path>
                      </svg>
                    </Button>
                    <div className="upload-btn-wrapper">
                      <div className="new-chat-attached-file">
                        <button
                          className="search-btn"
                          type="button"
                          onClick={() => (!fileLoading ? open() : null)}
                        >
                          {fileLoading ? (
                            <Image
                              className="btn-loader"
                              src={
                                window.location.origin +
                                "/images/loader/btn-loader.gif"
                              }
                            />
                          ) : (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path d="M22.95 9.6a1 1 0 00-1.414 0L10.644 20.539a5 5 0 11-7.072-7.071L14.121 2.876a3 3 0 014.243 4.242L7.815 17.71a1.022 1.022 0 01-1.414 0 1 1 0 010-1.414l9.392-9.435a1 1 0 00-1.414-1.414l-9.392 9.435a3 3 0 000 4.243 3.073 3.073 0 004.243 0L19.778 8.532a5 5 0 00-7.071-7.07L2.158 12.054a7 7 0 009.9 9.9L22.95 11.018a1 1 0 000-1.418z"></path>
                              </svg>
                            </>
                          )}
                        </button>
                        <input {...getInputProps()} disabled={fileLoading} />
                      </div>
                    </div>
                  </div>
                  <div className="new-chat-preview-input-typing-card-end-sec">
                    <Button
                      type="submit"
                      className="send-btn"
                      disabled={sendLoading}
                    >
                      {sendLoading ? t("loading") : t("send")}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        ) : chat.socketStatus === 2 ? (
          <div className="new-chat-preview-card-left-footer-form">
            <h4>{t("monitoring.heading")}</h4>
            <p>{t("monitoring.content")}</p>
            <Button
              className="onboarding-add-fine-tuning-btn btn btn-primary"
              type="button"
              onClick={() => reconnect(chat.username)}
            >
              {t("monitoring.make_active")}
            </Button>
          </div>
        ) : (
          <div className="new-chat-preview-card-left-footer-form">
            <h4>{t("socket.heading")}</h4>
            <p>
            {t("socket.content")}
            </p>
            <Button
              className="onboarding-add-fine-tuning-btn btn btn-primary"
              type="button"
              onClick={() => window.location.reload()}
            >
              {t("socket.reload")}
            </Button>
          </div>
        ): null}
        {scrollDown.is_at_bottom === false && (
          <div className="scroll-down-button" onClick={handleScrollDown}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#FF916F"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M12.836.029C9.367-.206 5.961 1.065 3.509 3.521S-.211 9.378.03 12.854C.47 19.208 6.082 24 13.083 24H19c2.757 0 5-2.243 5-5v-6.66C24 5.861 19.097.454 12.836.029zm3.871 14.56l-2.535 2.535a2.992 2.992 0 01-2.122.877H12a2.982 2.982 0 01-2.071-.877l-2.636-2.636a.999.999 0 111.414-1.414L11 15.367V7a1 1 0 012 0v8.467l2.293-2.293a.999.999 0 111.414 1.414z"></path>
            </svg>
          </div>
        )}
        {scrollDown.notify === true && (
          <div className="new-msg-notify" onClick={handleScrollDown}>
            <div className="msg-content">
              <span>{scrollDown.msg_count} {t("new_message")}</span>
            </div>
          </div>
        )}
      </div>
      <ReactTooltip
        id="my-tooltip-2"
        place="bottom"
        // className="opaque"
        variant={!chat.autoMsg ? "success" : "warning"}
        content={
          !chat.autoMsg ? t("automated_reply_enable") : t("automated_reply_disable")
        }
      />
    </>
  ) : (
    <NotChatFound />
  );
};

export const urlify = (text) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  text = text.replaceAll("\n", " \n");
  text = text.replaceAll("\n", "<br />");
  return text.replaceAll(urlRegex, function (url) {
    return (
      '<a className="text-info underline" href="' +
      url +
      '" target="_blank">' +
      url +
      "</a>"
    );
  });
};

const ChatMessageBox = ({ value, onChange, textareaRef, onSend }) => {
  // const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;
    // console.log(scrollHeight);
    textareaRef.current.style.height =
      scrollHeight < 76
        ? "76px"
        : scrollHeight > 192
        ? "192px"
        : scrollHeight + "px";
  }, [value]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onSend(event);
    }
  };

  return (
    <textarea
      ref={textareaRef}
      className="form-control w-full border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:z-10 hover:border-slate-400 chat-msg"
      placeholder="Write your message"
      type="text"
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export default InboxChat;
