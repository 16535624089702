import React, { useState, useEffect } from "react";
import {
  Tab,
  Row,
  Col,
  Nav,
} from "react-bootstrap";
import AddFaqModal from "../Faqs/AddFaqModal";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import ImportWebpageModal from "../../OnBoarding/ImportWebpageModal";
import ImportDocumentModal from "../../OnBoarding/ImportDocumentModal";
import FaqIndex from "../Faqs/FaqIndex";
import FinetuneIndex from "./FinetuneIndex";
import ConversationsIndex from "../ConversationData/ConversationsIndex";
import ImportConversationsModal from "../ConversationData/ImportConversationsModal";
import { customTrainingListStart } from "../../../store/slices/FineTuneSlice";

const SettingsfineTune = (props) => {
  const dispatch = useDispatch();

  const t = useTranslation("settings.fine_tune");
  const [addFaq, setAddFaq] = useState(false);
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const fineTuneList = useSelector((state) => state.fineTune.fineTuneList);
  const customTrainingList = useSelector(
    (state) => state.fineTune.customTrainingList
  );
  const profile = useSelector((state) => state.admin.profile);
  const [importWebpage, setImportWebpage] = useState(false);
  const [importDocument, setImportDocument] = useState(false);
  const [importConversations, setImportConversations] = useState(false);
  const [tab, setTab] = useState("docs");

  const closeAddFaqModal = () => {
    setAddFaq(false);
  };

  const closeImportWebpageModal = () => {
    setImportWebpage(false);
  };

  const closeImportDocumentModal = () => {
    setImportDocument(false);
  };

  const closeImportConversationsModal = () => {
    setImportConversations(false);
  };

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if (profile.data.role == 2 && Object.keys(profile.data.role_access).length > 0)
        setTab(
          profile.data.role_access?.custom_training_doc
            ? "docs"
            : profile.data.role_access?.custom_training_link
            ? "web-page"
            : profile.data.role_access?.faq
            ? "faqs"
            : profile.data.role_access?.conversational_data
            ? "conversations"
            : ""
        );
      if (profile.data.role == 1) setTab("docs");
    }
    return () => {
      setTab("");
    };
  }, [profile]);

  useEffect(() => {
    dispatch(
      customTrainingListStart({ bot_id: selectedBot.bot_id, skip: 0, take: 12 })
    );
  }, [selectedBot]);

  return (
    <>
      <div className="new-settings-finetune-sec">
        <div className="new-settings-finetune-header-sec">
          <div className="new-settings-finetune-info-sec">
            <h3>{t("heading")}</h3>
          </div>
          <div className="new-settings-finetune-header-box">
            {profile.data.role == 1 ||
            profile.data.role_access?.custom_training_doc ? (
              <div
                className="new-settings-finetune-header-card"
                onClick={() => setImportDocument(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M22 13V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7h6"
                  ></path>
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M19 22v-6l-2 2M19 16l2 2M9.95 6.26L8.9 15.73M13.11 6.26l-1.05 9.47M6.53 9.42H16M6 12.58h9.47"
                  ></path>
                </svg>
                <h4>{t("import.doc")}</h4>
              </div>
            ) : null}
            {profile.data.role == 1 ||
            profile.data.role_access?.custom_training_link ? (
              <div
                className="new-settings-finetune-header-card"
                onClick={() => setImportWebpage(true)}
              >
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="#0b0b12de"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm8.647 7h-3.221a19.676 19.676 0 00-2.821-4.644A10.031 10.031 0 0120.647 7zM16.5 12a10.211 10.211 0 01-.476 3H7.976a10.211 10.211 0 01-.476-3 10.211 10.211 0 01.476-3h8.048a10.211 10.211 0 01.476 3zm-7.722 5h6.444A19.614 19.614 0 0112 21.588 19.57 19.57 0 018.778 17zm0-10A19.614 19.614 0 0112 2.412 19.57 19.57 0 0115.222 7zM9.4 2.356A19.676 19.676 0 006.574 7H3.353A10.031 10.031 0 019.4 2.356zM2.461 9H5.9a12.016 12.016 0 00-.4 3 12.016 12.016 0 00.4 3H2.461a9.992 9.992 0 010-6zm.892 8h3.221A19.676 19.676 0 009.4 21.644 10.031 10.031 0 013.353 17zm11.252 4.644A19.676 19.676 0 0017.426 17h3.221a10.031 10.031 0 01-6.042 4.644zM21.539 15H18.1a12.016 12.016 0 00.4-3 12.016 12.016 0 00-.4-3h3.437a9.992 9.992 0 010 6z"></path>
                </svg> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
                  ></path>
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M8 3h1a28.424 28.424 0 000 18H8M15 3a28.424 28.424 0 010 18"
                  ></path>
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M3 16v-1a28.424 28.424 0 0018 0v1M3 9a28.424 28.424 0 0118 0"
                  ></path>
                </svg>
                <h4>{t("import.web")}</h4>
              </div>
            ) : null}
            {profile.data.role == 1 || profile.data.role_access?.faq ? (
              <div
                className="new-settings-finetune-header-card"
                onClick={() => setAddFaq(true)}
              >
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="none"
                  viewBox="0 0 28 27"
                >
                  <g clipPath="url(#clip0_1942_10174)">
                    <path
                      fill="#0b0b12de"
                      d="M9.384 18.118a1.687 1.687 0 112.386-2.387c1.491 1.492 4.095 1.493 5.586 0l5.613-5.612a3.955 3.955 0 000-5.587 3.955 3.955 0 00-5.587 0 1.687 1.687 0 11-2.386-2.386c2.855-2.856 7.504-2.856 10.36 0s2.856 7.503 0 10.359l-5.613 5.613a7.301 7.301 0 01-5.18 2.142 7.304 7.304 0 01-5.18-2.142zm-1.557 8.88c1.875 0 3.75-.715 5.18-2.143a1.687 1.687 0 10-2.387-2.386 3.954 3.954 0 01-5.587 0 3.955 3.955 0 010-5.587l5.581-5.58a3.953 3.953 0 015.587 0 1.687 1.687 0 102.386-2.387c-2.856-2.856-7.502-2.856-10.359 0l-5.582 5.581C-.21 17.353-.21 22 2.646 24.856a7.304 7.304 0 005.18 2.141z"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1942_10174">
                      <path
                        fill="#000"
                        d="M0 0H27V27H0z"
                        transform="translate(.5)"
                      ></path>
                    </clipPath>
                  </defs>
                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 24 24"><path stroke="#1c1c1c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13.06 10.94a5.74 5.74 0 010 8.13c-2.25 2.24-5.89 2.25-8.13 0-2.24-2.25-2.25-5.89 0-8.13"></path><path stroke="#1c1c1c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10.59 13.41c-2.34-2.34-2.34-6.14 0-8.49 2.34-2.35 6.14-2.34 8.49 0 2.35 2.34 2.34 6.14 0 8.49"></path></svg>
                <h4>{t("import.faq")}</h4>
              </div>
            ) : null}
            {profile.data.role == 1 ||
            profile.data.role_access?.conversational_data ? (
              <div
                className="new-settings-finetune-header-card"
                onClick={() => setImportConversations(true)}
              >
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="none"
                  viewBox="0 0 24 25"
                >
                  <path
                    fill="#0b0b12de"
                    d="M3.868 4.03c4.494-4.679 11.768-4.68 16.263 0 3.614 3.763 4.297 9.358 2.16 13.813l1.185 5.425c.154.705-.453 1.337-1.13 1.177l-5.21-1.233C9.558 27.15.5 21.448.5 12.496c0-3.198 1.196-6.204 3.368-8.466zm3.334 11.448h5.006c.521 0 .944-.44.944-.983 0-.544-.423-.984-.944-.984H7.202c-.522 0-.945.44-.945.984 0 .543.423.983.945.983zm0-3.996h9.595c.522 0 .945-.44.945-.984 0-.543-.423-.983-.945-.983H7.202c-.522 0-.945.44-.945.983 0 .543.423.984.945.984z"
                  ></path>
                </svg> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                    d="M22 6.25v5.1c0 1.27-.42 2.34-1.17 3.08-.74.75-1.81 1.17-3.08 1.17v1.81c0 .68-.76 1.09-1.32.71l-.97-.64c.09-.31.13-.65.13-1.01V12.4c0-2.04-1.36-3.4-3.4-3.4H5.4c-.14 0-.27.01-.4.02V6.25C5 3.7 6.7 2 9.25 2h8.5C20.3 2 22 3.7 22 6.25z"
                  ></path>
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                    d="M15.59 12.4v4.07c0 .36-.04.7-.13 1.01-.37 1.47-1.59 2.39-3.27 2.39H9.47l-3.02 2.01a.671.671 0 01-1.05-.56v-1.45c-1.02 0-1.87-.34-2.46-.93-.6-.6-.94-1.45-.94-2.47V12.4c0-1.9 1.18-3.21 3-3.38.13-.01.26-.02.4-.02h6.79c2.04 0 3.4 1.36 3.4 3.4z"
                  ></path>
                </svg>
                <h4>{t("import.conversations")}</h4>
              </div>
            ) : null}
          </div>
        </div>
        <div className="new-settings-finetune-body-sec">
          <Tab.Container
            id="left-tabs-example"
            activeKey={tab}
            // defaultActiveKey="docs"
            onSelect={(key, ev) => ev.type != "click" && setTab(key)}
          >
            <Row>
              <Col sm={12}>
                <Nav variant="pills">
                  {/* <Nav.Item>
                    <Nav.Link eventKey="all" onClick={() => setTab("all")}>
                      {t("options.all")}
                      <span className="count">{!customTrainingList.loading && Object.keys(customTrainingList.data).length > 0 ? customTrainingList.data.total_custom_training_count : 0}</span>
                    </Nav.Link>
                  </Nav.Item> */}
                  {profile.data.role == 1 ||
                  profile.data.role_access?.custom_training_doc ? (
                    <Nav.Item>
                      <Nav.Link eventKey="docs" onClick={() => setTab("docs")}>
                        {t("options.docs")}
                        <span className="count">
                          {!customTrainingList.loading &&
                          Object.keys(customTrainingList.data).length > 0
                            ? customTrainingList.data.docs_fine_tune_count
                            : 0}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  ) : null}
                  {profile.data.role == 1 ||
                  profile.data.role_access?.custom_training_link ? (
                    <Nav.Item>
                      <Nav.Link
                        eventKey="web-page"
                        onClick={() => setTab("web-page")}
                      >
                        {t("options.web")}
                        <span className="count">
                          {!customTrainingList.loading &&
                          Object.keys(customTrainingList.data).length > 0
                            ? customTrainingList.data.links_fine_tune_count
                            : 0}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  ) : null}
                  {profile.data.role == 1 || profile.data.role_access?.faq ? (
                    <Nav.Item>
                      <Nav.Link eventKey="faqs" onClick={() => setTab("faqs")}>
                        {t("options.faq")}
                        <span className="count">
                          {!customTrainingList.loading &&
                          Object.keys(customTrainingList.data).length > 0
                            ? customTrainingList.data.total_faq_count
                            : 0}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  ) : null}
                  {profile.data.role == 1 ||
                  profile.data.role_access?.conversational_data ? (
                    <Nav.Item>
                      <Nav.Link
                        eventKey="conversations"
                        onClick={() => setTab("conversations")}
                      >
                        {t("options.conversations")}
                        <span className="count">
                          {!customTrainingList.loading &&
                          Object.keys(customTrainingList.data).length > 0
                            ? customTrainingList.data
                                .total_conversational_data_count
                            : 0}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  ) : null}
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  {/* {tab == "all" && (
                    <Tab.Pane eventKey="all">
                      <CustomTrainingIndex />
                    </Tab.Pane>
                  )} */}
                  {tab == "docs" && (
                    <Tab.Pane eventKey="docs">
                      <FinetuneIndex file_type="docs" />
                    </Tab.Pane>
                  )}
                  {tab == "web-page" && (
                    <Tab.Pane eventKey="web-page">
                      <FinetuneIndex file_type="link" />
                    </Tab.Pane>
                  )}
                  {tab == "faqs" && (
                    <Tab.Pane eventKey="faqs">
                      <FaqIndex />
                    </Tab.Pane>
                  )}
                  {tab == "conversations" && (
                    <Tab.Pane eventKey="conversations">
                      <ConversationsIndex />
                    </Tab.Pane>
                  )}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
      {addFaq && (
        <AddFaqModal
          addFaq={addFaq}
          closeAddFaqModal={closeAddFaqModal}
          setAddFaq={setAddFaq}
        />
      )}
      {importWebpage && (
        <ImportWebpageModal
          importWebpage={importWebpage}
          closeImportWebpageModal={closeImportWebpageModal}
          setImportWebpage={setImportWebpage}
          onboardingBot={selectedBot}
          onboarding={0}
        />
      )}
      {importDocument && (
        <ImportDocumentModal
          importDocument={importDocument}
          closeImportDocumentModal={closeImportDocumentModal}
          setImportDocument={setImportDocument}
          onboardingBot={selectedBot}
          onboarding={0}
        />
      )}
      {importConversations && (
        <ImportConversationsModal
          importConversations={importConversations}
          closeImportConversationsModal={closeImportConversationsModal}
          setImportConversations={setImportConversations}
          onboardingBot={selectedBot}
        />
      )}
    </>
  );
};

export default withTranslation(SettingsfineTune);
