import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
} from "react-bootstrap";
import { useTranslation, withTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { faqDeleteStart } from "../../../store/slices/FaqSlice";

const DeleteFaqModal = (props) => {

  const t = useTranslation("settings.faq.delete_faq");
  const dispatch = useDispatch();
  const faqDelete = useSelector((state) => state.faq.faqDelete);
  const [skipRender, setSkipRender] = useState(true);
  const selectedBot = useSelector((state) => state.admin.selectedBot);

  useEffect(() => {
    if (
      !skipRender &&
      !faqDelete.loading &&
      Object.keys(faqDelete.data).length > 0
    ) {
      props.closeDeleteFaqModal();
    }
    setSkipRender(false);
  }, [faqDelete]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.deleteFaq}
        onHide={props.closeDeleteFaqModal}
      >
        <Modal.Body>
          <h4>{t("heading")}</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeDeleteFaqModal()}
          >
            <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10zM9.17 14.83l5.66-5.66M14.83 14.83L9.17 9.17"
      ></path>
    </svg>
          </Button>
          <div className="delete-bot-card">
            <p>{t("message")}</p>
            <p>{t("heading")}</p>
          </div>
          <div className="onboarding-import-webpage-modal-btn-sec mt-3">
            <Button
              className="onboarding-cancel-btn"
              onClick={() => props.closeDeleteFaqModal()}
            >
              {t("cancel")}
            </Button>
            <Button
              className="onboarding-save-btn"
              type="submit"
              onClick={() =>
                dispatch(
                  faqDeleteStart({ chat_message_faq_id: props.deleteFaq, bot_id: selectedBot?.bot_id, })
                )
              }
              disabled={faqDelete.buttonDisable}
            >
              {faqDelete.buttonDisable ? t("loading") : t("confirm")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(DeleteFaqModal);
