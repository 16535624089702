import React, { useEffect, useState } from "react";
import { Button, Image, Modal, InputGroup, Form } from "react-bootstrap";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProfileStart,
} from "../../store/slices/AdminSlice";
import { useTranslation, withTranslation } from "react-multi-lang";
import useLogout from "../../hooks/useLogout";

const DeleteProfileModal = (props) => {

  const t = useTranslation("profile.delete_profile");
  const dispatch = useDispatch();
  const deleteProfile = useSelector((state) => state.admin.deleteProfile);
  const [skipRender, setSkipRender] = useState(true);
  const [showPassword, setShowPassword] = useState({
    password: false,
  });
  const { logout } = useLogout();

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(t("password.required")),
  });

  const handleSubmit = (values) => {
    dispatch(deleteProfileStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !deleteProfile.loading &&
      Object.keys(deleteProfile.data).length > 0
    ) {
      logout();
    }
    setSkipRender(false);
  }, [deleteProfile]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.newEditProfile}
        onHide={props.closeNewEditProfileModal}
      >
        <Modal.Body>
          <h4>{t("heading")}</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeNewEditProfileModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              viewBox="0 0 11 11"
            >
              <path
                fill="#979BA2"
                d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"
              ></path>
            </svg>
          </Button>
          <Formik
            initialValues={{
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <FORM className="onboarding-form">
                <Form.Label>
                  {t("password.label")} <span>*</span>
                </Form.Label>
                <div className="mb-3">
                  <InputGroup className="register-input-group">
                    <Field
                      type={showPassword.password ? "text" : "password"}
                      className="form-control"
                      placeholder={t("password.placeholder")}
                      name="password"
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          password: !showPassword.password,
                        })
                      }
                    >
                      <Image
                        className="register-input-group-eye-icon"
                        src={
                          showPassword.password
                            ? window.location.origin +
                              "/images/onboarding/eye-visible-off-icon.svg"
                            : window.location.origin +
                              "/images/onboarding/eye-visible-on-icon.svg"
                        }
                      />
                    </InputGroup.Text>
                  </InputGroup>
                  <ErrorMessage
                    component={"div"}
                    name="password"
                    className="errorMsg"
                  />
                </div>
                <div className="admin-ui-profile-btn-sec">
                  <Button
                    className="onboarding-cancel-btn"
                    onClick={() => props.closeNewEditProfileModal()}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    className="onboarding-save-btn"
                    disabled={deleteProfile.buttonDisable}
                    type="submit"
                  >
                    {deleteProfile.buttonDisable ? t("loading") : t("confirm")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(DeleteProfileModal);
