import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";

const SomethingWentWrong = ({ buttonText = "", handleClick = () => {} }) => {

  const t = useTranslation("helper.something_went_wrong");
  return (
    <div className="no-bot-exists-card">
      <div className="no-bot-exists-img-sec">
        <Image
          className="something-wrong-img"
          src={
            window.location.origin +
            "/images/illustrations/something-went-wrong.gif"
          }
        />
      </div>
      <div className="no-bot-exists-info">
        <h4>{t("heading")}</h4>
      </div>
      {buttonText ? (
        <button
          className="settings-btn"
          onClick={() => handleClick()}
        >
          {buttonText}
        </button>
      ) : null}
    </div>
  );
};

export default SomethingWentWrong;
