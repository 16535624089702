import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  InputGroup,
  Image,
  Nav,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Select from "react-select";
import Toggle from "react-styled-toggle";
import InboxChat from "./InboxChat";
import {
  chatListStart,
  disableChatNotify,
  setHighlightChat,
  setSelectedChats,
} from "../../store/slices/ChatSlice";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import Skeleton from "react-loading-skeleton";
import {
  profileStart,
  selectedBotSuccess,
} from "../../store/slices/AdminSlice";
import Pagination from "../Helper/Pagination";
import { Field, Form as FORM, Formik } from "formik";
import NoDataFound from "../Helper/NoDataFound";
import useDesign from "../../hooks/useDesign";
import NotChatFound from "../Helper/NoChatFound";
import { botsListStart } from "../../store/slices/BotSlice";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import { useTranslation } from "react-multi-lang";
import ChatPreviewLoader from "../Helper/ChatPreviewLoader";
import NoBotExists from "../Helper/NoBotExists";
import AddBotModal from "../Bots/AddBotModal";
import ChatLoader from "./ChatLoader";
import TimeElapsed from "./TimeElapsed";

const NewChatInbox = (props) => {
  const { getChatTypeName, getChatTypeBg } = useDesign();
  const t = useTranslation("chats");

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #F3F2ED!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "auto",
      fontSize: "0.9em",
      cursor: "pointer",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#3E4351",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#FF916F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#FF916F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#FF916F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#3E4351",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        "&:hover": { backgroundColor: "#f7f7f7" },
      };
    },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const profile = useSelector((state) => state.admin.profile);
  const chatList = useSelector((state) => state.chat.chatList);
  const selectedChats = useSelector((state) => state.chat.selectedChats);
  const botsList = useSelector((state) => state.bots.botsList);
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const selectedInboxBot = useSelector((state) => state.chat.selectedInboxBot);
  const botsStore = useSelector((state) => state.bots.botsStore);
  const [skipRender, setSkipRender] = useState(true);
  const [unreadChat, setUnreadChat] = useState(0);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [initialChat, setInitialChat] = useState(true);
  const [chatId, setChatId] = useState("");
  const [bots, setBots] = useState([]);
  const [selectedBotDetail, setSelectedBotDetail] = useState({
    label: "All Chats",
    value: "",
  });

  const [filterStatus, setFilterStatus] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState();
  const [addBot, setAddBot] = useState(false);

  const closeAddBotModal = () => {
    setAddBot(false);
  };

  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    order_by: searchParams.get("order_by") ? searchParams.get("order_by") : "",
  });

  const options = [
    { value: "newest", label: t("filter.newest") },
    { value: "oldest", label: t("filter.oldest") },
  ];

  useEffect(() => {
    if (!botsList.loading && !Object.keys(botsList.data).length)
      dispatch(botsListStart());
  }, []);

  useEffect(() => {
    let newValues = { ...filter };
    if (
      !profile.loading &&
      Object.keys(profile.data).length > 0 &&
      !skipRender
    ) {
      if (Object.keys(selectedInboxBot).length > 0)
        newValues = { ...newValues, bot_id: selectedInboxBot.value };
      dispatch(
        chatListStart({
          skip: 5 * (page - 1),
          take: 5,
          ...newValues,
        })
      );
      // (searchParams.get("order_by") && searchParams.get("order_by") != 'null') && setSelectedFilter({
      //   label: t(`${searchParams.get("order_by")}`),
      //   value: searchParams.get("order_by"),
      // });
    }
  }, [page, filter]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
  }, [searchParams.get("page")]);

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    params += searchParams.get("status")
      ? `status=${searchParams.get("status")}&`
      : "";
    navigate(`/inbox?${params}page=${selected + 1}`);
  };

  useEffect(() => {
    if (
      !skipRender &&
      !chatList.loading &&
      Object.keys(chatList.data).length > 0
    ) {
      setUnreadChat(chatList.data.un_read_chats_count ?? 0);
      if (chatList.data.chats.length <= 0) setChatId("");
      else if (!chatId) {
        setChatId(chatList.data.chats[0].chat_id ?? 0);
      }
    }
  }, [chatList]);

  useEffect(() => {
    if (initialChat && chatId) {
      dispatch(disableChatNotify(chatId));
      setInitialChat(false);
    }
  }, [chatId]);

  useEffect(() => {
    let newValues = { ...filter };
    if (
      !skipRender &&
      !botsList.loading &&
      Object.keys(botsList.data).length > 0 &&
      botsList.data.bots.length > 0
    ) {
      setInitialChat(true);
      if (Object.keys(selectedInboxBot).length > 0)
        newValues = { ...newValues, bot_id: selectedInboxBot.value };
      dispatch(
        chatListStart({
          skip: 5 * (page - 1),
          take: 5,
          ...newValues,
        })
      );
    }
    setSkipRender(false);
  }, [botsList, selectedInboxBot]);

  useEffect(() => {
    let option = options.find(
      (option) => option.value == searchParams.get("order_by")
    );
    if (option) {
      setFilterStatus(option);
    }
  }, [searchParams.get("order_by")]);

  useEffect(() => {
    if (
      !skipRender &&
      !botsStore.loading &&
      Object.keys(botsStore.data).length > 0 &&
      Object.keys(botsStore.data.bot).length > 0
    ) {
      dispatch(selectedBotSuccess(botsStore.data.bot));
    }
    setSkipRender(false);
  }, [botsStore]);

  return profile.loading || chatList.buttonDisable || botsList.loading ? (
    <ChatLoader />
  ) : Object.keys(profile.data).length > 0 ? (
    <>
      {selectedBot && Object.keys(selectedBot).length > 0 ? (
        <div className="admin-ui-setting-body-sec">
          <div className="new-settings-sec">
            <div className="new-settings-tab-sec">
              <Tab.Container id="left-tabs-example" defaultActiveKey="inbox">
                <Row>
                  <Col sm={12}>
                    <div className="new-settings-tab-nav-link-sec">
                      <Nav variant="pills">
                        {profile.data.role == 1 ||
                        profile.data.role_access?.customize ? (
                          <Nav.Item>
                            <Nav.Link
                              onClick={() =>
                                navigate("/settings?tab=customize")
                              }
                            >
                               <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-settings-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M19.875 6.27a2.225 2.225 0 0 1 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z" /><path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /></svg>
                              {t("tabs.customize")}
                            </Nav.Link>
                          </Nav.Item>
                        ) : null}
                        {profile.data.role == 1 ||
                        profile.data.role_access?.custom_training_doc ||
                        profile.data.role_access?.custom_training_link ||
                        profile.data.role_access?.faq ||
                        profile.data.role_access?.conversational_data ? (
                          <Nav.Item>
                            <Nav.Link
                              onClick={() =>
                                navigate("/settings?tab=fine-tune")
                              }
                            >
                             <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-table-options"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 21h-7a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7" /><path d="M3 10h18" /><path d="M10 3v18" /><path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M19.001 15.5v1.5" /><path d="M19.001 21v1.5" /><path d="M22.032 17.25l-1.299 .75" /><path d="M17.27 20l-1.3 .75" /><path d="M15.97 17.25l1.3 .75" /><path d="M20.733 20l1.3 .75" /></svg>
                              {t("tabs.fine_tune")}
                            </Nav.Link>
                          </Nav.Item>
                        ) : null}
                        {profile.data.role == 1 ||
                        profile.data.role_access?.inbox != 0 ? (
                          <Nav.Item>
                            <Nav.Link
                              eventKey="inbox"
                              onClick={() => navigate("/inbox")}
                            >
                              <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-inbox"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" /><path d="M4 13h3l3 3h4l3 -3h3" /></svg>
                              {t("tabs.inbox")}
                            </Nav.Link>
                          </Nav.Item>
                        ) : null}
                        {profile.data.role == 1 ||
                        profile.data.role_access?.source ? (
                          <Nav.Item>
                            <Nav.Link
                              onClick={() => navigate("/settings?tab=source")}
                            >
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 17 17"
                              >
                                <path d="M.5 6.318v6.546c.001.964.353 1.888.977 2.57.625.681 1.473 1.065 2.356 1.066h9.334c.883-.001 1.73-.385 2.356-1.066a3.815 3.815 0 00.977-2.57V6.318H.5zm9.333 7.273H4.5a.64.64 0 01-.471-.213.762.762 0 01-.196-.514c0-.193.07-.378.196-.515a.64.64 0 01.471-.213h5.333a.64.64 0 01.472.213.762.762 0 01.195.515c0 .192-.07.377-.195.514a.64.64 0 01-.472.213zm2.667-2.91h-8a.64.64 0 01-.471-.212.762.762 0 01-.196-.514c0-.193.07-.378.196-.515a.64.64 0 01.471-.213h8a.64.64 0 01.471.213.762.762 0 01.196.515c0 .192-.07.377-.196.514a.64.64 0 01-.471.213zm4-5.817v-.728a3.815 3.815 0 00-.977-2.57C14.898.885 14.05.501 13.167.5H3.833c-.883.001-1.73.385-2.356 1.066A3.815 3.815 0 00.5 4.136v.728h16zm-9.333-2.91c.132 0 .26.043.37.123.11.08.195.194.246.327.05.132.063.279.038.42a.75.75 0 01-.183.372.652.652 0 01-.341.2.615.615 0 01-.385-.042.683.683 0 01-.3-.268.775.775 0 01.083-.918.64.64 0 01.472-.213zm-2 0c.132 0 .26.043.37.123.11.08.195.194.246.327.05.132.063.279.038.42a.75.75 0 01-.183.372.652.652 0 01-.341.2.615.615 0 01-.385-.042.683.683 0 01-.3-.268.775.775 0 01.083-.918.64.64 0 01.472-.213zm-2 0c.132 0 .26.043.37.123.11.08.195.194.246.327.05.132.063.279.038.42a.75.75 0 01-.183.372.652.652 0 01-.341.2.615.615 0 01-.385-.042.683.683 0 01-.3-.268.775.775 0 01.083-.918.64.64 0 01.472-.213z"></path>
                              </svg> */}
                               <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-source-code"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14.5 4h2.5a3 3 0 0 1 3 3v10a3 3 0 0 1 -3 3h-10a3 3 0 0 1 -3 -3v-5" /><path d="M6 5l-2 2l2 2" /><path d="M10 9l2 -2l-2 -2" /></svg>
                              {t("tabs.source")}
                            </Nav.Link>
                          </Nav.Item>
                        ) : null}
                        {profile.data.role == 1 && (
                          <Nav.Item>
                            <Nav.Link
                              onClick={() =>
                                navigate("/settings?tab=analytics")
                              }
                            >
                              <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-brand-google-analytics"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 9m0 1.105a1.105 1.105 0 0 1 1.105 -1.105h1.79a1.105 1.105 0 0 1 1.105 1.105v9.79a1.105 1.105 0 0 1 -1.105 1.105h-1.79a1.105 1.105 0 0 1 -1.105 -1.105z" /><path d="M17 3m0 1.105a1.105 1.105 0 0 1 1.105 -1.105h1.79a1.105 1.105 0 0 1 1.105 1.105v15.79a1.105 1.105 0 0 1 -1.105 1.105h-1.79a1.105 1.105 0 0 1 -1.105 -1.105z" /><path d="M5 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /></svg>
                              {t("tabs.analytics")}
                            </Nav.Link>
                          </Nav.Item>
                        )}
                      </Nav>
                    </div>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
            <div className="new-settings-box">
              <div className="new-inbox-tab-sec">
                <div className="new-chat-inbox-sec">
                  <div className="new-chat-inbox-header-sec">
                    <div className="new-chat-inbox-header-left-sec">
                      <h3>
                        {t("tabs.inbox")}{" "}
                        {chatList.loading ? (
                          <Skeleton count={1} height={20} width={20} />
                        ) : (
                          <span className="count">{unreadChat}</span>
                        )}
                      </h3>
                    </div>
                    <div className="new-chat-inbox-header-right-sec">
                      <div className="new-billing-search-sec">
                        <Formik
                          initialValues={{
                            search_key: searchParams.get("search_key")
                              ? searchParams.get("search_key")
                              : "",
                            order_by: searchParams.get("order_by")
                              ? searchParams.get("order_by")
                              : filter.order_by,
                          }}
                          onSubmit={(val) => {
                            setFilter({
                              ...filter,
                              search_key: val.search_key,
                            });
                            navigate(
                              searchParams.get("order_by")
                                ? `/inbox?search_key=${
                                    val.search_key
                                  }&order_by=${searchParams.get("order_by")}`
                                : `/inbox?search_key=${val.search_key}`
                            );
                            // navigate(
                            //   `/inbox?search_key=${
                            //     val.search_key
                            //   }&order_by=${searchParams.get("order_by")}`
                            // );
                          }}
                        >
                          {({ values, setFieldValue, resetForm }) => (
                            <FORM className="form">
                              <InputGroup className="mb-0">
                                <Field
                                  placeholder={t("search_chats")}
                                  type="text"
                                  className="form-control"
                                  name="search_key"
                                />
                                {searchParams.get("search_key") && (
                                  <InputGroup.Text id="basic-addon2" className={values.search_key && "after-search-input-text"}>
                                    <button
                                      className="search-btn"
                                      type="button"
                                      onClick={() => {
                                        setFieldValue("search_key", "");
                                        setFilter({
                                          ...filter,
                                          search_key: "",
                                        });
                                        // navigate(
                                        //   `/inbox?order_by=${searchParams.get(
                                        //     "order_by"
                                        //   )}`
                                        // );
                                        navigate(
                                          searchParams.get("order_by")
                                            ? `/inbox?order_by=${searchParams.get(
                                                "order_by"
                                              )}`
                                            : `/inbox`
                                        );
                                      }}
                                    >
                                      <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      stroke="#3E4351"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.5"
                                      d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10zM9.17 14.83l5.66-5.66M14.83 14.83L9.17 9.17"
                                    ></path>
                                  </svg>
                                    </button>
                                  </InputGroup.Text>
                                )}
                                <InputGroup.Text id="basic-addon2">
                                  <Button className="search-btn" type="submit">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      stroke="#3E4351"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.5"
                                      d="M11.5 21a9.5 9.5 0 100-19 9.5 9.5 0 000 19zM22 22l-2-2"
                                    ></path>
                                  </svg>
                                  </Button>
                                </InputGroup.Text>
                              </InputGroup>
                            </FORM>
                          )}
                        </Formik>
                      </div>
                      <div className="new-chat-inbox-filter-sec">
                        <Select
                          options={options}
                          styles={customStyles}
                          isClearable={true}
                          defaultValue={filterStatus ? filterStatus : null}
                          // menuIsOpen={true}
                          // value={selectedFilter}
                          isSearchable={false}
                          onChange={(selectedOption) => {
                            if (selectedOption) {
                              setFilter({
                                ...filter,
                                order_by: selectedOption.value,
                              });
                              navigate(
                                filter.search_key
                                  ? `/inbox?search_key=${filter.search_key}&order_by=${selectedOption.value}`
                                  : `/inbox?order_by=${selectedOption.value}`
                              );
                            } else {
                              setFilterStatus(false);
                              setFilter({
                                ...filter,
                                order_by: "",
                              });
                              navigate(
                                filter.search_key
                                  ? `/inbox?search_key=${filter.search_key}`
                                  : "/inbox"
                              );
                            }
                            // setFilter({
                            //   ...filter,
                            //   order_by: selectedOption.value,
                            // });
                            // navigate(
                            //   `/inbox?search_key=${filter.search_key}&order_by=${selectedOption.value}`
                            // );
                          }}
                          placeholder={
                            <div className="placeholder-flex">
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#3E4351"
                                viewBox="0 0 24 24"
                              >
                                <path d="M10 6h13a1 1 0 000-2H10a1 1 0 000 2zM23 11H10a1 1 0 000 2h13a1 1 0 000-2zM23 18H10a1 1 0 000 2h13a1 1 0 000-2zM6.087 6a.5.5 0 00.353-.854L4 2.707a1 1 0 00-1.414 0L.147 5.146A.5.5 0 00.5 6h1.794v12H.5a.5.5 0 00-.354.854l2.44 2.439a1 1 0 001.414 0l2.44-2.439A.5.5 0 006.087 18H4.294V6z"></path>
                              </svg> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="#3E4351"
                                  strokeLinecap="round"
                                  strokeWidth="1.5"
                                  d="M3 7h18M6 12h12M10 17h4"
                                ></path>
                              </svg>
                              <span>{t("filter.label")}</span>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="new-chat-inbox-body-sec">
                    <div className="new-chat-inbox-list-sec">
                      {chatList.loading ? (
                        [...Array(5)].map((i, key) => (
                          <div key={key}>
                            <Skeleton count={1} height={100} key={i} />
                          </div>
                        ))
                      ) : Object.keys(chatList.data).length > 0 &&
                        chatList.data.chats.length > 0 ? (
                        <>
                          <div className="new-chat-inbox-list-box">
                            {chatList.data.chats
                              .filter(
                                (chat) =>
                                  selectedBotDetail.value == "" ||
                                  chat.bot_id == selectedBotDetail.value
                              )
                              .map((chat, i) => (
                                <div key={i}
                                  className={`new-chat-inbox-list-card ${
                                    chatId == chat.chat_id && "active"
                                  }`}
                                  onClick={() => {
                                    setChatId(chat.chat_id);
                                    // setUnreadChat(
                                    //   chat.notify && unreadChat > 0
                                    //     ? unreadChat - 1
                                    //     : unreadChat
                                    // );
                                    dispatch(disableChatNotify(chat.chat_id));
                                  }}
                                >
                                  {chat.notify ? (
                                    <div className="pulsating-circle"></div>
                                  ) : null}
                                  <div className="new-chat-inbox-list-user-details-sec">
                                    <div
                                      className={`new-chat-preview-user-img-sec ${getChatTypeBg(
                                        chat.widget_type
                                      )}`}
                                    >
                                      <Image
                                        className="new-chat-preview-user-img"
                                        src={
                                          window.location.origin +
                                          "/images/your-bot/your-bot-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="new-chat-inbox-list-user-details-info">
                                      <h4>
                                        {chat.chat_unique_id}{" "}
                                        <span className="count">
                                          {chat.chat_messages_count}
                                        </span>
                                      </h4>
                                      {chat.email ? (
                                        <h4>
                                          {t("email")} :
                                          <p>
                                            {chat.email}
                                            <CopyToClipboard
                                              text={chat.email}
                                              onCopy={() =>
                                                getSuccessNotificationMessage(
                                                  "Email copied to clipboard!"
                                                )
                                              }
                                            >
                                              <Button
                                                type="button"
                                                className="search-btn"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="22"
                                                  height="22"
                                                  fill="#3E4351"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                                                </svg>
                                              </Button>
                                            </CopyToClipboard>
                                          </p>
                                        </h4>
                                      ) : null}
                                      {chat.contact ? (
                                        <h4>
                                          {t("contact")} :
                                          <p>
                                            {chat.contact}
                                            <CopyToClipboard
                                              text={chat.contact}
                                              onCopy={() =>
                                                getSuccessNotificationMessage(
                                                  "Contact copied to clipboard!"
                                                )
                                              }
                                            >
                                              <Button
                                                type="button"
                                                className="search-btn"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="22"
                                                  height="22"
                                                  fill="#3E4351"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                                                </svg>
                                              </Button>
                                            </CopyToClipboard>
                                          </p>
                                        </h4>
                                      ) : null}
                                      {Object.keys(chat.last_chat_message)
                                        .length > 0 &&
                                        (chat.last_chat_message.message ? (
                                          <p>
                                            {chat.last_chat_message.message}
                                          </p>
                                        ) : chat.last_chat_message.file_type ==
                                            "mp3" ||
                                          chat.last_chat_message.file_type ==
                                            "wav" ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              stroke="#FF916F"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="1.5"
                                              d="M3 8.25v7.5M7.5 5.75v12.5M12 3.25v17.5M16.5 5.75v12.5M21 8.25v7.5"
                                            ></path>
                                          </svg>
                                        ) : (
                                          <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          fill="none"
                                          viewBox="0 0 25 24"
                                        >
                                          <path
                                            stroke="#FF916F"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                            d="M9.5 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7h-6c-5 0-7 2-7 7v6c0 5 2 7 7 7z"
                                          ></path>
                                          <path
                                            stroke="#FF916F"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                            d="M9.5 10a2 2 0 100-4 2 2 0 000 4zM3.17 18.95l4.93-3.31c.79-.53 1.93-.47 2.64.14l.33.29c.78.67 2.04.67 2.82 0l4.16-3.57c.78-.67 2.04-.67 2.82 0l1.63 1.4"
                                          ></path>
                                        </svg>
                                        ))}
                                      <div className="new-chat-inbox-list-info-last-seen">
                                        {chat.age_timestamp ? <TimeElapsed timestamp={chat.age_timestamp} /> : null}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="new-chat-inbox-list-info">
                                    <div className="new-chat-inbox-list-info-top-sec">
                                      {chat.bot_name ? (
                                        <div className="new-chat-inbox-list-bot-name">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="none"
                                            viewBox="0 0 20 15"
                                          >
                                            <path
                                              fill="#000"
                                              d="M18.5 6H18c0-2.206-1.794-4-4-4h-3V1a1 1 0 10-2 0v1H6C3.794 2 2 3.794 2 6h-.5C.673 6 0 6.673 0 7.5v2c0 .827.673 1.5 1.5 1.5H2c0 2.206 1.794 4 4 4h8c2.206 0 4-1.794 4-4h.5c.827 0 1.5-.673 1.5-1.5v-2c0-.827-.673-1.5-1.5-1.5zM8.457 9.043a.999.999 0 11-1.414 1.414L6.5 9.914l-.543.543a.997.997 0 01-1.414 0 .999.999 0 010-1.414l.543-.543-.543-.543a.999.999 0 111.414-1.414l.543.543.543-.543a.999.999 0 111.414 1.414l-.543.543.543.543zm7 0a.999.999 0 11-1.414 1.414l-.543-.543-.543.543a.997.997 0 01-1.414 0 .999.999 0 010-1.414l.543-.543-.543-.543a.999.999 0 111.414-1.414l.543.543.543-.543a.999.999 0 111.414 1.414l-.543.543.543.543z"
                                            ></path>
                                          </svg>
                                          <span>{chat.bot_name}</span>
                                        </div>
                                      ) : null}
                                      <div
                                        className={getChatTypeName(
                                          chat.widget_type
                                        )}
                                      >
                                        {chat.widget_type_formatted}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="new-billings-footer-sec">
                            <div className="new-billings-pagination-sec">
                              <Pagination
                                page={page}
                                pageCount={Math.ceil(
                                  chatList.data.total_chats / 5
                                )}
                                handlePageClick={handlePageClick}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="new-settings-preview-sec">
                {chatList.loading ? (
                  <ChatPreviewLoader />
                ) : chatId ? (
                  <InboxChat chatId={chatId} />
                ) : (
                  <NotChatFound />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        !botsList.loading &&
        Object.keys(botsList.data).length > 0 &&
        Object.keys(botsList.data.bots.filter((bot)=> bot.status == 1)).length <= 0 && (
          <div className="mt-5">
            <NoBotExists setAddBot={setAddBot} />
          </div>
        )
      )}
      {addBot && (
        <AddBotModal
          addBot={addBot}
          closeAddBotModal={closeAddBotModal}
          setAddBot={setAddBot}
        />
      )}
    </>
  ) : (
    <SomethingWentWrong
      buttonText="Retry"
      handleClick={() => dispatch(profileStart())}
    />
  );
};

export default NewChatInbox;
