import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, InputGroup, Row, Col } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { agentDeleteStart } from "../../store/slices/AgentSlice";


const DeleteAgentModal = (props) => {

  const t = useTranslation("agents.delete_modal");
  const dispatch = useDispatch();
  const agentDelete = useSelector((state) => state.agent.agentDelete);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(()=> {
    if(!skipRender && !agentDelete.loading && Object.keys(agentDelete.data).length > 0)
    {
      props.closeDeleteAgentModal();
    }
    setSkipRender(false);
  }, [agentDelete])

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.deleteAgent}
        onHide={props.closeDeleteAgentModal}
      >
        <Modal.Body>
          <h4>{t("heading")}</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeDeleteAgentModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#171717"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10zM9.17 14.83l5.66-5.66M14.83 14.83L9.17 9.17"
              ></path>
            </svg>
          </Button>
          <div className="delete-bot-card">
            <h4>{t("message")}</h4>
            <p>{t("content")}</p>
          </div>
          <div className="onboarding-import-webpage-modal-btn-sec mt-3">
            <Button
              className="onboarding-cancel-btn"
              onClick={() => props.closeDeleteAgentModal()}
            >
              {t("cancel")}
            </Button>
            <Button
              className="onboarding-save-btn"
              type="submit"
              onClick={() =>
                dispatch(agentDeleteStart({ agent_id: props.deleteAgent }))
              }
              disabled={agentDelete.buttonDisable}
            >
              {agentDelete.buttonDisable ? t("loading") : t("delete")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(DeleteAgentModal);
