import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-multi-lang";

const NoAgentExists = (props) => {
  const t = useTranslation("agents.list");

  return (
    <>
      <div className="no-bot-exists-card">
        <div className="no-bot-exists-img-sec">
          <Image
            className="no-bot-exists-img"
            src={window.location.origin + "/images/no-data/no-agents.gif"}
          />
        </div>
        <div className="no-bot-exists-info">
          <h4>{t("no_agents.heading")}</h4>
        </div>
      </div>
    </>
  );
};

export default withTranslation(NoAgentExists);
