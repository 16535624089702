import React, { useEffect } from 'react';
import useAudioPlayer from '../../hooks/useAudioPlayer';
import Pause from './Pause';
import Play from './Play';
import './AudioPlayer.css';
import WaveProgressBar from './WaveProgressBar';
import { changeChatAudio } from '../../store/slices/ChatSlice';
import { useDispatch } from 'react-redux';

const AudioPlayer = (props) => {

  const dispatch = useDispatch()

  const { curTime, duration, playing, setPlaying, setClickedTime, changeSrc } = useAudioPlayer(props.src, false);

  useEffect(() => {
    changeSrc(props.src);
  }, [props.src]);

  function formatDuration(duration) {
    if (duration) {
      var date = new Date(null);
      date.setSeconds(duration);
      return date.toISOString().substr(14, 5);
    } else {
      return "00:00";
    }
  }

  return (
    <>
      <div className="altra_chat_audio_container">
        <WaveProgressBar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)} />
        <div className='duration'>{formatDuration(duration - curTime)}</div>
        {playing ?
          <Pause handleClick={() => setPlaying(false)} className="altra_chat_audio_btn" /> :
          <Play handleClick={() => {
            dispatch(changeChatAudio({
              src: props.src
            }))
            setPlaying(true)
          }} className="altra_chat_audio_btn" />
        }
      </div>
      <div style={{}}></div>
    </>
  );
}

export default AudioPlayer;