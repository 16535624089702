import React, { useEffect, useState } from "react";
import { Nav, Row, Col, Tab, Form, InputGroup, Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import CreateAgentModal from "./AgentModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation, withTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { agentListStart, sendInviteStart, updateAgentStatusStart } from "../../store/slices/AgentSlice";
import AgentListLoader from "./Loader"
import { botsListStart } from "../../store/slices/BotSlice";
import NoDataFound from "../Helper/NoDataFound";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import DeleteAgentModal from "./DeleteAgentModal";
import { Field, Form as FORM, Formik } from "formik";
import Select from "react-select";
import NoAgentExists from "./NoAgentExists";
import Skeleton from "react-loading-skeleton";

const AgentListIndex = (props) => {

  const t = useTranslation("agents.list")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [createAgent, setCreateAgent] = useState(false);
  const [editAgent, setEditAgent] = useState(false);
  const agentList = useSelector((state) => state.agent.agentList);
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const botsList = useSelector((state) => state.bots.botsList);
  const sendInvite = useSelector((state) => state.agent.sendInvite);
  const updateAgentStatus = useSelector((state) => state.agent.updateAgentStatus);
  const [skipRender, setSkipRender] = useState(true);
  const [bot, setBot] = useState(selectedBot);
  const [bots, setBots] = useState([]);
  const [deleteAgent, setDeleteAgent] = useState(false);
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    order_by: searchParams.get("order_by") ? searchParams.get("order_by") : "",
  });

  const closeDeleteAgentModal = () => {
    setDeleteAgent(false);
  };

  const closeCreateAgentModal = () => {
    setCreateAgent(false);
  };

  const closeEditAgentModal = () => {
    setEditAgent(false);
  };

  const handleSendInvite = (agent_id) => {
    if (agent_id)
      dispatch(sendInviteStart({ agent_id: agent_id }))
  }

  const handleUpdateStatus = (agent_id) => {
    if (agent_id)
      dispatch(updateAgentStatusStart({ agent_id: agent_id }))
  }

  // useEffect(()=> {
  //   dispatch(botsListStart());
  // }, [])

  useEffect(() => {
    if (
      !botsList.loading &&
      Object.keys(botsList.data).length > 0 &&
      botsList.data.bots.length > 0
    ) {
      let bots = botsList.data.bots.map((bot) => ({
        label: bot.name,
        value: bot.bot_id,
      }));
      setBots(bots);
    }
  }, [botsList]);

  useEffect(() => {
    if (selectedBot && Object.keys(selectedBot).length > 0) {
      setBot(selectedBot);
    }
  }, [selectedBot]);

  useEffect(() => {
    dispatch(agentListStart({
      ...filter
    }));
  }, [filter])

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #171717!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#171717",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#171717",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#171717!important",
      fill: "#0b0b12de!important",

    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#171717",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
        '&:active': { backgroundColor: isSelected ? "#f7f7f7" : "#fff" }
      };
    },
  };

  const options = [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
  ];


  return (
    <>
      <div className="admin-ui-body-sec">
        <div className="sales-rep-list-sec">
          <Row className="justify-content-md-center">
            <Col md={12} xl={12} lg={12}>
              <div className="sales-rep-list-box">
                {agentList.loading ? (
                  <div className="sales-rep-list-header-sec" style={{ display: "flex" }}>
                    <Skeleton width={400} height={50} />
                    <Skeleton width={300} height={50} />
                  </div>
                ) : Object.keys(agentList.data).length > 0 ?
                  <div className="sales-rep-list-header-sec">
                    <div className="new-billing-search-sec">
                      <Formik
                        initialValues={{
                          search_key: searchParams.get("search_key")
                            ? searchParams.get("search_key")
                            : "",
                          order_by: searchParams.get("order_by")
                            ? searchParams.get("order_by")
                            : filter.order_by,
                        }}
                        onSubmit={(val) => {
                          setFilter({
                            ...filter,
                            search_key: val.search_key,
                          });
                          navigate(filter.order_by ? `/agents?search_key=${val.search_key}&order_by=${filter.order_by}` : `/agents?search_key=${val.search_key}`);
                        }}
                      >
                        {({ values, setFieldValue, resetForm }) => (
                          <FORM className="form">
                            <InputGroup className="mb-0">
                              <Field
                                placeholder={t("search")}
                                type="text"
                                className="form-control"
                                name="search_key"
                              />
                              <InputGroup.Text id="basic-addon2">
                                {searchParams.get("search_key") && (
                                  <button
                                    type="button"
                                    className="search-btn"
                                    onClick={() => {
                                      setFieldValue("search_key", "");
                                      setFilter({
                                        ...filter,
                                        search_key: "",
                                      });
                                      navigate(`/agents`);
                                    }}
                                  >
                                    {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#3E4351"
                                    data-name="Layer 1"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M16.707 8.707L13.414 12l3.293 3.293-1.414 1.414L12 13.414l-3.293 3.293-1.414-1.414L10.586 12 7.293 8.707l1.414-1.414L12 10.586l3.293-3.293 1.414 1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-2 0c0-5.514-4.486-10-10-10S2 6.486 2 12s4.486 10 10 10 10-4.486 10-10z"></path>
                                  </svg> */}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        stroke="#3E4351"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10zM9.17 14.83l5.66-5.66M14.83 14.83L9.17 9.17"
                                      ></path>
                                    </svg>
                                  </button>
                                )}
                              </InputGroup.Text>
                              <InputGroup.Text id="basic-addon2">
                                <button className="search-btn" type="submit">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      stroke="#3E4351"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.5"
                                      d="M11.5 21a9.5 9.5 0 100-19 9.5 9.5 0 000 19zM22 22l-2-2"
                                    ></path>
                                  </svg>
                                </button>
                              </InputGroup.Text>
                            </InputGroup>
                          </FORM>
                        )}
                      </Formik>
                    </div>
                    <div className="new-billing-add-sales-rep-btn-sec">
                      <div className="admin-ui-dashboard-graph-filter-sec">
                        <Select
                          options={options}
                          styles={customStyles}
                          isSearchable={false}
                          isClearable={true}
                          value={filter.order_by === "newest" ? options[0] : filter.order_by === "oldest" ? options[1] : null}
                          placeholder={
                            <div className="placeholder-flex">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 25 24"
                              >
                                <path
                                  stroke="#171717"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeMiterlimit="10"
                                  strokeWidth="1.5"
                                  d="M3.85 2h8.9c.74 0 1.35.61 1.35 1.35v1.48c0 .54-.34 1.21-.67 1.55l-2.9 2.56c-.4.34-.67 1.01-.67 1.55v2.9c0 .4-.27.94-.61 1.15l-.94.61c-.88.54-2.09-.07-2.09-1.15v-3.57c0-.47-.27-1.08-.54-1.42l-2.56-2.7c-.34-.34-.61-.94-.61-1.35V3.41C2.5 2.61 3.11 2 3.85 2z"
                                ></path>
                                <path
                                  stroke="#171717"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                  d="M2.5 12v3c0 5 2 7 7 7h6c5 0 7-2 7-7V9c0-3.12-.78-5.08-2.59-6.1-.51-.29-1.53-.51-2.46-.66M13.5 13h5M11.5 17h7"
                                ></path>
                              </svg>
                              {t("filter")}
                            </div>
                          }
                          onChange={(selectedOption) => {
                            if (selectedOption?.value) {
                              setFilter({
                                ...filter,
                                order_by: selectedOption.value,
                              });
                              navigate(
                                filter.search_key ? `/agents?search_key=${filter.search_key}&order_by=${selectedOption.value}` : `/agents?order_by=${selectedOption.value}`
                              );
                            } else {
                              setFilter({
                                ...filter,
                                order_by: "",
                              });
                              navigate(
                                filter.search_key ? `/agents?search_key=${filter.search_key}` : `/agents`
                              );
                            }
                          }}
                        />
                      </div>
                      <Button
                        className="onboarding-add-fine-tuning-btn"
                        onClick={() => setCreateAgent(true)}
                      >
                        {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        fill="#fff"
                        x="0"
                        y="0"
                        enableBackground="new 0 0 512 512"
                        version="1.1"
                        viewBox="0 0 512 512"
                        xmlSpace="preserve"
                      >
                        <path d="M480 224H288V32c0-17.673-14.327-32-32-32s-32 14.327-32 32v192H32c-17.673 0-32 14.327-32 32s14.327 32 32 32h192v192c0 17.673 14.327 32 32 32s32-14.327 32-32V288h192c17.673 0 32-14.327 32-32s-14.327-32-32-32z"></path>
                      </svg> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6 12h12M12 18V6"></path></svg>
                        <span>{t("add_agent")}</span>
                      </Button>
                    </div>
                  </div> : null}
                <div className="sales-rep-list-body-sec">
                  {agentList.loading ?
                    <>
                      <div className="sales-rep-list-header-card">
                        <Skeleton count={1} width={120} height={22} />
                      </div>
                      <div className="sales-resp-list-box">
                        {[...Array(4)].map((i) => (
                          <Skeleton count={1} height={180} />
                        ))}
                      </div>
                    </>
                    :
                    Object.keys(agentList.data).length > 0 &&
                      agentList.data.agents &&
                      agentList.data.agents.length > 0 ? (
                      <>
                        <div className="sales-rep-list-header-card">
                          <h4>{t("total_agents")} <span>{agentList.data.agents.length}</span></h4>
                          <div className="slaes-rep-list-header-right-sec">
                            <h4>{t("status.heading")}</h4>
                          </div>
                        </div>
                        <div className="sales-resp-list-box">
                          {agentList.data.agents.map((agent, i) => (
                            <div className="sales-rep-list-card" key={i}>
                              <div
                                className="sales-rep-list-left-sec-item"
                                onClick={() =>
                                  navigate(`/agent/${agent.agent_id}`)
                                }
                              >
                                <div className="sales-rep-list-left-sec">
                                  <div className="sales-rep-list-user-img-sec">
                                    <CustomLazyLoad
                                      className="sales-rep-list-user-img"
                                      src={agent.picture}
                                    />
                                  </div>
                                  <div className="sales-rep-list-user-details">
                                    <h4>{agent.name}</h4>
                                    <h5>
                                      {/* <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      fill="none"
                                      viewBox="0 0 15 12"
                                    >
                                      <path
                                        fill="#A3ADB8"
                                        d="M11.875 0h-8.75C2.296 0 1.502.288.916.8.331 1.31.001 2.004 0 2.727v6.546c.001.723.33 1.416.916 1.927.586.511 1.38.8 2.209.8h8.75c.829 0 1.623-.289 2.209-.8.585-.511.915-1.204.916-1.927V2.727c-.001-.723-.33-1.416-.916-1.927-.586-.512-1.38-.8-2.209-.8zm-8.75 1.09h8.75c.374.002.74.1 1.05.283.309.184.549.444.688.747L8.826 6.298c-.352.306-.829.478-1.326.478-.497 0-.974-.172-1.326-.478L1.387 2.12a1.7 1.7 0 01.689-.747c.31-.183.675-.281 1.049-.282zm8.75 9.82h-8.75c-.497 0-.974-.173-1.326-.48a1.538 1.538 0 01-.549-1.157V3.545L5.29 7.07c.587.51 1.381.798 2.21.798s1.623-.287 2.21-.798l4.04-3.524v5.728c0 .434-.197.85-.55 1.157-.35.307-.828.48-1.325.48z"
                                      ></path>
                                    </svg> */}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          stroke="#A3ADB8"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeMiterlimit="10"
                                          strokeWidth="1.5"
                                          d="M17 20.5H7c-3 0-5-1.5-5-5v-7c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5z"
                                        ></path>
                                        <path
                                          stroke="#A3ADB8"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeMiterlimit="10"
                                          strokeWidth="1.5"
                                          d="M17 9l-3.13 2.5c-1.03.82-2.72.82-3.75 0L7 9"
                                        ></path>
                                      </svg>
                                      <span>{agent.email}</span>
                                    </h5>
                                    <p>{t("created")} : {agent.created_at.substring(0, 11)}</p>
                                  </div>
                                </div>
                                <div className="sales-rep-list-status-info-btn-sec">
                                  <Button
                                    variant="link"
                                    className="update-status-btn"
                                    disabled={
                                      updateAgentStatus.buttonDisable &&
                                      updateAgentStatus.data.agent_id ===
                                      agent.agent_id
                                    }
                                  >
                                    <ul
                                      className="sales-rep-list-status-info-btn-list list-unstyled"
                                      onClick={() =>
                                        dispatch(
                                          updateAgentStatusStart({
                                            agent_id: agent.agent_id,
                                          })
                                        )
                                      }
                                    >
                                      <li
                                        className={agent.status ? "active" : ""}
                                      >
                                        {t("status.active")}
                                      </li>
                                      <li
                                        className={agent.status ? "" : "inactive"}
                                      >
                                        {t("status.inactive")}
                                      </li>
                                    </ul>
                                  </Button>
                                </div>
                              </div>
                              <div className="sales-rep-list-right-sec">

                                <div className="sales-rep-list-status-hover-show-info">
                                  <div className="sales-rep-list-status-action-btn-sec">
                                    {(agent.email_status === 0 && agent.status) ? (
                                      <Button
                                        className="sales-rep-list-copy-btn"
                                        disabled={sendInvite.buttonDisable}
                                        onClick={() =>
                                          handleSendInvite(agent.agent_id)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="22"
                                          height="22"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            stroke="#ff916f"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                            d="M13.06 10.94a5.74 5.74 0 010 8.13c-2.25 2.24-5.89 2.25-8.13 0-2.24-2.25-2.25-5.89 0-8.13"
                                          ></path>
                                          <path
                                            stroke="#ff916f"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                            d="M10.59 13.41c-2.34-2.34-2.34-6.14 0-8.49 2.34-2.35 6.14-2.34 8.49 0 2.35 2.34 2.34 6.14 0 8.49"
                                          ></path>
                                        </svg>
                                        <span>{sendInvite.buttonDisable &&
                                          sendInvite.data.agent_id ===
                                          agent.agent_id ? t('loading') : t("send_invite")}</span>
                                      </Button>
                                    ) : null}
                                  </div>
                                  <div className="sales-rep-list-status-action-btn-right-sec">
                                    <OverlayTrigger
                                      placement="top"
                                      trigger={["hover"]}
                                      overlay={
                                        <Tooltip id="tooltip">
                                          <strong>{t("view_agent")}</strong>
                                        </Tooltip>
                                      }>
                                      <Button
                                        className="sales-rep-list-view-btn"
                                        onClick={() =>
                                          navigate(`/agent/${agent.agent_id}`)
                                        }
                                      >
                                        {/* <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="#A3ADB8"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 000 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 000-5.162zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 010-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 010 3.068z"></path>
                                        <path d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"></path>
                                      </svg> */}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="23"
                                          height="23"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            stroke="#A3ADB8"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                            d="M15.58 12c0 1.98-1.6 3.58-3.58 3.58S8.42 13.98 8.42 12s1.6-3.58 3.58-3.58 3.58 1.6 3.58 3.58z"
                                          ></path>
                                          <path
                                            stroke="#A3ADB8"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                            d="M12 20.27c3.53 0 6.82-2.08 9.11-5.68.9-1.41.9-3.78 0-5.19-2.29-3.6-5.58-5.68-9.11-5.68-3.53 0-6.82 2.08-9.11 5.68-.9 1.41-.9 3.78 0 5.19 2.29 3.6 5.58 5.68 9.11 5.68z"
                                          ></path>
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="top"
                                      trigger={["hover"]}
                                      overlay={
                                        <Tooltip id="tooltip">
                                          <strong>{t("edit_agent")}</strong>
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        className="sales-rep-list-edit-btn"
                                        onClick={() => setEditAgent(agent)}
                                      >
                                        {/* <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19"
                                        height="16"
                                        fill="none"
                                        viewBox="0 0 19 16"
                                      >
                                        <path
                                          fill="#A3ADB8"
                                          d="M15.779 0c-.4 0-.797.072-1.166.21-.37.138-.704.339-.986.592L1.92 11.208l-.046.206-.81 3.62-.23.966 1.087-.205 4.072-.72.231-.042L17.931 4.627a2.69 2.69 0 00.668-.876c.155-.328.235-.68.235-1.037 0-.356-.08-.708-.235-1.037a2.69 2.69 0 00-.668-.875 3.08 3.08 0 00-.986-.593A3.363 3.363 0 0015.78 0zm0 1.254c.373 0 .75.152 1.11.474.72.64.72 1.334 0 1.974l-.531.452L14.159 2.2l.51-.472c.361-.322.737-.474 1.11-.474zm-2.66 1.872l2.197 1.954-8.953 7.958c-.483-.84-1.253-1.524-2.199-1.953l8.955-7.959zm-9.902 9.029c.438.156.836.39 1.17.687.334.297.597.65.773 1.04l-2.43.432.487-2.16z"
                                        ></path>
                                      </svg> */}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="22"
                                          height="22"
                                          fill="none"
                                          viewBox="0 0 25 24"
                                        >
                                          <path
                                            stroke="#A3ADB8"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeMiterlimit="10"
                                            strokeWidth="1.5"
                                            d="M13.76 3.6l-8.21 8.69c-.31.33-.61.98-.67 1.43l-.37 3.24c-.13 1.17.71 1.97 1.87 1.77l3.22-.55c.45-.08 1.08-.41 1.39-.75l8.21-8.69c1.42-1.5 2.06-3.21-.15-5.3-2.2-2.07-3.87-1.34-5.29.16zM12.39 5.05a6.126 6.126 0 005.45 5.15M3.5 22h18"
                                          ></path>
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="top"
                                      trigger={["hover"]}
                                      overlay={
                                        <Tooltip id="tooltip">
                                          <strong>{t("delete_agent")}</strong>
                                        </Tooltip>
                                      }>
                                      <Button
                                        className="sales-rep-list-delete-btn"
                                        onClick={() =>
                                          setDeleteAgent(agent.agent_id)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="22"
                                          height="22"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            stroke="#A3ADB8"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                            d="M21 5.98c-3.33-.33-6.68-.5-10.02-.5-1.98 0-3.96.1-5.94.3L3 5.98M8.5 4.97l.22-1.31C8.88 2.71 9 2 10.69 2h2.62c1.69 0 1.82.75 1.97 1.67l.22 1.3M18.85 9.14l-.65 10.07C18.09 20.78 18 22 15.21 22H8.79C6 22 5.91 20.78 5.8 19.21L5.15 9.14M10.33 16.5h3.33M9.5 12.5h5"
                                          ></path>
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                  </div>
                                </div>

                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <NoAgentExists setAddAgent={setCreateAgent} />
                    )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {createAgent && (
        <CreateAgentModal
          create={1}
          bots={bots}
          selectedBot={bot}
          createAgent={createAgent}
          closeCreateAgentModal={closeCreateAgentModal}
          setCreateAgent={setCreateAgent}
        />
      )}
      {editAgent && (
        <CreateAgentModal
          create={0}
          bots={bots}
          selectedBot={bot}
          createAgent={editAgent}
          closeCreateAgentModal={closeEditAgentModal}
          setCreateAgent={setEditAgent}
        />
      )}
      {deleteAgent && (
        <DeleteAgentModal
          deleteAgent={deleteAgent}
          closeDeleteAgentModal={closeDeleteAgentModal}
          setDeleteAgent={setDeleteAgent}
        />
      )}
    </>
  );
};

export default withTranslation(AgentListIndex);
