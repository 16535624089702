import React from "react";
import { Image, Button, Container, Row, Col } from "react-bootstrap";

const InstagramDemoSteps = (props) => {
  return (
    <>
<div className="customize-steps-sec">
      <Container>
        <Row>
          <Col md={12}>
            <div className="customize-steps-btn-sec">
              <h3>Setting Up Instagram Integration with EFI Assits</h3>
              <a
                className="settings-btn"
                download={true}
                target="_blank"
                rel="noreferrer"
                href={
                  window.location.origin +
                  "/installation-steps/Instagram-Installation-Steps.pdf"
                }
              >
                Download Doc
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="customize-steps-list-sec">
              <ul className="customize-steps-list list-unstyled">
                <li>
                  <span>1</span> 
                  <div>
                  Begin by logging in to your Facebook account at <a href="https://facebook.com/" target="_blank" rel="noreferrer">https://facebook.com/</a>.
                  </div>
                </li>
                <li>
                  <span>2</span> 
                  <div>
                    Navigate to <a href="https://developers.facebook.com/" target="_blank" rel="noreferrer">https://developers.facebook.com/</a> and locate the "My Apps" section.
                  </div>
                </li>
                <li>
                  <span>3</span> 
                  <div>
                  Create a new app:
                    <h6>Click on the "Create App" button.</h6>
                    <h6>Opt for the "Other" category and proceed by clicking "Next."</h6>
                    <h6>Select "Business" and click "Next."</h6>
                    <h6>Assign a name to your app.</h6>
                    <h6>Choose a Business account (which is required for subsequent steps).</h6>
                    <h6>Finalize the creation by clicking "Create app."</h6>
                  </div>
                </li>
                <li>
                  <span>4</span> 
                  <div>
                    Integrate Messenger into your app:
                    <h6>On the "Add products to your app" page, choose "Messenger" and click "Set up."</h6>
                  </div>
                </li>
                <li>
                  <span>5</span> 
                  <div>
                  Link your Facebook page with an Instagram business account:
                    <h6>Access your Facebook account.</h6>
                    <h6>Navigate to "Pages."</h6>
                    <h6>Select the specific page you wish to link.</h6>
                    <h6>Click on "Settings" > "Linked account" > Choose "Instagram" > "Connect Account."</h6>
                    <h6>A popup will appear; follow the provided steps to connect your Instagram account.</h6>
                    <h6>Upon successful linking, you will receive a confirmation message stating, "Instagram connected - The (Facebook page name) Facebook Page is now connected to the @(Instagram name) Instagram account."</h6>
                  </div>
                </li>
                <li>
                  <span>6</span>
                  <div>
                  Add a Page & Generate Token:
                    <h6>Head back to your Facebook app.</h6>
                    <h6>Select "Instagram Settings" > "Add or remove Pages."</h6>
                    <h6>A popup will appear; add your Facebook page by following the instructions.</h6>
                    <h6>After adding the page to your app, click on "Generate Token."</h6>
                    <h6>Confirm your understanding in the popup and securely store the Access Token.</h6>
                  </div>
                </li>
                <li>
                  <span>7</span> 
                  <div>
                  Configure the EFI Assist panel:
                    <h6>Access the EFI Assist panel, select a bot, and click on "Source."</h6>
                  </div>
                </li>
                <li>
                  <span>8</span> 
                  <div>
                  Connect Instagram to EFI Assist:
                    <h6>Choose "Instagram" and paste the previously copied Access Token.</h6>
                    <h6>Click "Update Changes" and then copy the Webhook URL.</h6>
                  </div>
                </li>
                <li>
                  <span>9</span> 
                  <div>
                   Configure the Instagram App:
                    <h6>Return to the Instagram App page.</h6>
                    <h6>In the webhook section, click "Add Callback URL."</h6>
                    <h6>Paste the Webhook URL from the previous step into the "Callback URL" field.</h6>
                    <h6>Enter "EFI Assist" in the "Verify token" field.</h6>
                    <h6>Click "Verify & Save."</h6>
                    <h6>Click "Edit subscriptions," select "messages" in the popup, and click "Save."</h6>
                  </div>
                </li>
                <li>
                  <span>10</span> 
                  <div>
                    Finalize the integration:
                    <h6>Your Instagram account is now successfully connected to EFI Assist.</h6>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
};

export default InstagramDemoSteps;
