import React, { useEffect } from "react";
import {
  Table,
  Dropdown,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  customTrainingListStart,
  fineTuneDeleteStart,
  fineTuneListStart,
  moreFineTuneListStart,
} from "../../../store/slices/FineTuneSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../../Helper/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-multi-lang";

const FinetuneIndex = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("settings.fine_tune.fine_tune_list")
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const fineTuneList = useSelector((state) => state.fineTune.fineTuneList);
  const fineTuneDelete = useSelector((state) => state.fineTune.fineTuneDelete);
  const profile = useSelector((state) => state.admin.profile);

  useEffect(() => {
    if(!profile.loading && Object.keys(profile.data).length > 0 && selectedBot.bot_id)
    { 
      dispatch(customTrainingListStart({ bot_id: selectedBot.bot_id}))
      if(profile.data.role == 1 || (profile.data.role_access.custom_training_doc && props.file_type == 'docs') || (profile.data.role_access.custom_training_link && props.file_type == 'link'))
      dispatch(fineTuneListStart({ bot_id: selectedBot.bot_id, skip: 0, take: 12, ...props }));
    }
  }, [selectedBot]);

  const getStatusName = (status) => {
    switch (status) {
      case 1:
        return "progress-badge";
      case 2:
        return "trained-badge";
      case 3:
        return "failed-badge";
      default:
        return "uploaded-badge";
    }
  };

  const handleDeleteFineTuning = (modelId) => {
    dispatch(fineTuneDeleteStart({
      fine_tune_model_id: modelId,
      bot_id: selectedBot?.bot_id, 
    }));
  };

  const fetchMoreData = () => {
    dispatch(
      moreFineTuneListStart({
        bot_id: selectedBot.bot_id,
        skip: fineTuneList.data.fine_tune_models.length,
        take: 12,
        ...props,
      })
    );
  };

  return (
    <div className="new-settings-finetune-table-sec">
      {fineTuneList.loading ? (
        <div className="onboarding-fine-tunings-files-box">
          {[...Array(4)].map((i, key) => (
            <Skeleton height={50} key={key} />
          ))}
        </div>
      ) : Object.keys(fineTuneList.data).length > 0 &&
        fineTuneList.data.fine_tune_models &&
        fineTuneList.data.fine_tune_models.filter(model => model.file_type == props.file_type).length > 0 ? (
          <InfiniteScroll
            dataLength={fineTuneList.data.fine_tune_models.length}
            next={fetchMoreData}
            hasMore={
              fineTuneList.data.fine_tune_models.length <
              (props.file_type== 'docs' ? fineTuneList.data.docs_fine_tune_count : fineTuneList.data.links_fine_tune_count)
            }
            loader={[...Array(3)].map((i) => (
              <Skeleton height={80} className="mb-3" />
            ))}
          >
            <div className="new-dashboard-recent-chat-list-table-sec">
            <Table responsive="md" borderless>
              {/* <thead>
                <tr>
                  <th>{t("file_name")}</th>
                  <th>{t("created")}</th>
                  <th>{t("status")}</th>
                  <th>{t("actions")}</th>
                </tr>
              </thead> */}
              <tbody>
                {fineTuneList.data.fine_tune_models.filter(model => model.file_type == props.file_type).map((model, key) => (
                  <tr key={key}>
                    <td>{model.name.length <= 20 ? model.name : model.name.slice(0,10)+"..."+model.name.slice(-10)}</td>
                    <td>
                      <span>{model.created_at}</span> 
                    </td>
                    <td>
                      {model.import_url && model.web_scrap_status != 2 ? (
                        <div className={getStatusName(model.web_scrap_status)}>
                          {t("web_scrape")} {model.web_scrap_status_formatted}
                        </div>
                      ) : (
                        <div className={getStatusName(model.training_status)}>
                          {t("training_model")} {model.training_status_formatted}
                        </div>
                      )}
                    </td>
                    <td className="recent-chat-menu-btn">
                      {fineTuneDelete.buttonDisable &&
                      fineTuneDelete.data.fine_tune_model_id == model.id ? (
                        <Image 
                          style={{marginLeft: '1em'}}
                          className="btn-loader"
                          src={
                            window.location.origin +
                            "/images/loader/btn-loader.gif"
                          }
                        />
                      ) : (
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <div className="recent-chat-menu-icon-sec">
                              <svg  xmlns="http://www.w3.org/2000/svg"  width="20"  height="20"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-dots"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /></svg>
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {model.file_type === "link" ? (
                              <Dropdown.Item
                                href={model.import_url}
                                target="_blank"
                              >
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-eye"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
                                {t("view")}
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                download={true}
                                target="_blank"
                                href={model.file}
                              >
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-download"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" /><path d="M7 11l5 5l5 -5" /><path d="M12 4l0 12" /></svg>
                                {t("download")}
                              </Dropdown.Item>
                            )}
                            {(model.training_status != 1 && model.web_scrap_status != 1) && <Dropdown.Item
                              disabled={fineTuneDelete.buttonDisable}
                              onClick={() => handleDeleteFineTuning(model.id)}
                            >
                              <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-trash"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                              {t("delete")}
                            </Dropdown.Item>}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            </div>
          </InfiniteScroll>
      ) : (
        <NoDataFound />
      )}
    </div>
  );
};

export default FinetuneIndex;
