import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  botsList: getCommonInitialState(),
  botsStore: getCommonInitialState(),
  singleBotView: getCommonInitialState(),
  sourceConfigurationSave: getCommonInitialState(),
  botDelete: getCommonInitialState(),
  botScriptUpdate: getCommonInitialState(),
};

const BotSlice = createSlice({
  name: "bots",
  initialState,
  reducers: {

    //Bots List
    botsListStart: (state) => { state.botsList = getCommonStart() },
    botsListSuccess: (state, action) => { state.botsList = getCommonSuccess(action.payload) },
    botsListFailure: (state, action) => { state.botsList = getCommonFailure(action.payload) },

    //Bots Store
    botsStoreStart: (state, action) => { state.botsStore = getCommonStart() },
    botsStoreSuccess: (state, action) => { state.botsStore = getCommonSuccess(action.payload) },
    botsStoreFailure: (state, action) => { state.botsStore = getCommonFailure(action.payload) },

    //Bots Store
    singleBotViewStart: (state) => { state.singleBotView = getCommonStart() },
    singleBotViewSuccess: (state, action) => { state.singleBotView = getCommonSuccess(action.payload) },
    singleBotViewFailure: (state, action) => { state.singleBotView = getCommonFailure(action.payload) },

    // Delete bot
    botDeleteStart: (state, action) => { state.botDelete = getCommonStart(action.payload) },
    botDeleteSuccess: (state, action) => { state.botDelete = getCommonSuccess(action.payload) },
    botDeleteFailure: (state, action) => { state.botDelete = getCommonFailure(action.payload) },

    //Bots config update
    sourceConfigurationSaveStart: (state) => { state.sourceConfigurationSave = getCommonStart() },
    sourceConfigurationSaveSuccess: (state, action) => { state.sourceConfigurationSave = getCommonSuccess(action.payload) },
    sourceConfigurationSaveFailure: (state, action) => { state.sourceConfigurationSave = getCommonFailure(action.payload) },

    // Delete bot
    botScriptUpdateStart: (state) => { state.botScriptUpdate = getCommonStart() },
    botScriptUpdateSuccess: (state, action) => { state.botScriptUpdate = getCommonSuccess(action.payload) },
    botScriptUpdateFailure: (state, action) => { state.botScriptUpdate = getCommonFailure(action.payload) },

  }
});


export const {
  botsListStart,
  botsListSuccess,
  botsListFailure,
  botsStoreStart,
  botsStoreSuccess,
  botsStoreFailure,
  singleBotViewFailure,
  singleBotViewStart,
  singleBotViewSuccess,
  sourceConfigurationSaveFailure,
  sourceConfigurationSaveStart,
  sourceConfigurationSaveSuccess,
  botDeleteStart,
  botDeleteSuccess,
  botDeleteFailure,
  botScriptUpdateFailure,
  botScriptUpdateStart,
  botScriptUpdateSuccess

} = BotSlice.actions;

export default BotSlice.reducer;