import React, { useEffect, useState } from "react";
import { Nav, Row, Col, Tab, Table, Button, Image, OverlayTrigger } from "react-bootstrap";
import "./DelayMessage.css";
import DelayMessageEditModal from "./DelayMessageEditModal";
import NoDataFound from "../Helper/NoDataFound";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  delayMessageListStart,
  moreDelayMessageListStart,
} from "../../store/slices/FineTuneSlice";
import Skeleton from "react-loading-skeleton";
import DeleteMessageModal from "./DeleteMessageModal";
import { useTranslation } from "react-multi-lang";

const DelayMessage = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("settings.delay_message");
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const delayMessageList = useSelector((state) => state.fineTune.delayMessageList);
  const delayMessageDelete = useSelector((state) => state.fineTune.delayMessageDelete);
  const [deleteMessageModal, setDeleteMessageModal] = useState(false);

  useEffect(() => {
    dispatch(
      delayMessageListStart({ bot_id: selectedBot.bot_id, skip: 0, take: 12 })
    );
  }, [selectedBot]);

  const fetchMoreData = () => {
    dispatch(
      moreDelayMessageListStart({
        bot_id: selectedBot.bot_id,
        skip: delayMessageList.data.delay_messages.length,
        take: 12,
      })
    );
  };

  return (
    <>
      <div className="admin-ui-delay-body-sec">
        <div className="new-delay-table">
          {delayMessageList.loading ? (
            <div className="onboarding-fine-tunings-files-box">
              {[...Array(4)].map((i, key) => (
                <Skeleton key={key} height={60} />
              ))}
            </div>
          ) : Object.keys(delayMessageList.data).length > 0 &&
            delayMessageList.data.delay_messages &&
            delayMessageList.data.delay_messages.length > 0 ? (
            <>
              <InfiniteScroll
                dataLength={delayMessageList.data.delay_messages.length}
                next={fetchMoreData}
                hasMore={
                  delayMessageList.data.delay_messages.length <
                  delayMessageList.data.total_delay_messages
                }
                loader={[...Array(3)].map((i) => (
                  <Skeleton height={60} className="mb-3" />
                ))}
              >
                <Table bordered>
                  <thead>
                    <tr>
                      <th>{t("message")}</th>
                      <th className="text-center">{t("actions")}</th>
                    </tr>
                  </thead>
                  {delayMessageList.data.delay_messages.map(
                    (message, index) => (
                      <tbody key={index}>
                        <tr>
                          <td>
                            <p>{message.delay_message}</p>
                          </td>
                          <td>
                            <div className="delay-action">
                              <div className="edit-action">
                                <Button onClick={() => props.setModalShow(message)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="none"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      stroke="#FF916F"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.5"
                                      d="M10.059 3.409l2.47 2.47m-4.117 9.06H15M1.824 11.643L1 14.938l3.294-.823 9.542-9.542a1.647 1.647 0 000-2.329l-.142-.141a1.647 1.647 0 00-2.33 0l-9.54 9.541z"
                                    ></path>
                                  </svg>
                                </Button>
                              </div>
                              <div className="delete-action">
                                <Button
                                  disabled={delayMessageDelete.buttonDisable}
                                  onClick={() => setDeleteMessageModal(message)}>
                                  {delayMessageDelete.buttonDisable && delayMessageDelete.data.delay_message_id === message.id ? (
                                    <Image
                                      className="btn-loader"
                                      src={
                                        window.location.origin +
                                        "/images/loader/btn-loader.gif"
                                      }
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="19"
                                      fill="none"
                                      viewBox="0 0 15 19"
                                    >
                                      <g fill="#FF717B">
                                        <path d="M13.95 3.405h-2.276A3.678 3.678 0 008.077.47H6.608A3.678 3.678 0 003.01 3.405H.734a.734.734 0 000 1.469h.734v9.545A3.676 3.676 0 005.14 18.09h4.405a3.675 3.675 0 003.671-3.671V4.874h.735a.734.734 0 100-1.469zM6.609 1.937h1.469a2.207 2.207 0 012.077 1.468H4.53a2.207 2.207 0 012.077-1.468zm5.14 12.482a2.203 2.203 0 01-2.203 2.203H5.14a2.203 2.203 0 01-2.203-2.203V4.874h8.81v9.545z"></path>
                                        <path d="M5.873 13.685a.734.734 0 00.734-.734V8.545a.734.734 0 10-1.468 0v4.406a.734.734 0 00.734.734z"></path>
                                        <path d="M8.811 13.685a.734.734 0 00.734-.734V8.545a.734.734 0 00-1.468 0v4.406a.734.734 0 00.734.734z"></path>
                                      </g>
                                    </svg>
                                  )}
                                </Button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    )
                  )}
                </Table>
              </InfiniteScroll>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
      {props.modalShow && <DelayMessageEditModal
        modalShow={props.modalShow}
        onHide={() => props.setModalShow(false)}
      />}
      {deleteMessageModal && (
        <DeleteMessageModal
          modalShow={deleteMessageModal}
          onHide={() => setDeleteMessageModal(false)}
        />
      )}
    </>
  );
};

export default DelayMessage;
