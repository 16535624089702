import React from "react";
import { Image, Button, Container, Row, Col } from "react-bootstrap";

const FreshChatDemoSteps = (props) => {
  return (
    <>
      <div className="customize-steps-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="customize-steps-btn-sec">
                <h3>Setting Up Freshchat Integration with EFI Assist</h3>
                <a
                  className="settings-btn"
                  download={true}
                  target="_blank"
                  rel="noreferrer"
                  href={
                    window.location.origin +
                    "/installation-steps/Freshchat-Installation-Steps.pdf"
                  }
                >
                  Download Doc
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="customize-steps-list-sec">
                <ul className="customize-steps-list list-unstyled">
                  <li>
                    <span>1</span>
                    Create AI Agent from user panel. Setup Source. Copy the API
                    KEY.
                  </li>
                  <li>
                    <span>2</span>
                    Login to your freshchat account. Click on crm/sales
                  </li>
                  <li>
                    <span>3</span>
                    Setings {">"} Click On Admin Settings
                  </li>
                  <li>
                    <span>4</span>
                    Search for “api” and choose “API Settings”
                  </li>
                  <li>
                    <span>5</span>
                    <div>
                      In the API DETAILS FOR CHAT section, Copy the “Your API
                      Key” & “Your chat URL”
                      <h6>
                        <em>Note:</em>
                        If the API key (access token) is unavailable or visiting
                        the page for first time, click Generate token displayed
                        adjacent to the Your API Key field.
                      </h6>
                    </div>
                  </li>
                  <li>
                    <span>6</span>
                    While installing the app. Configure the Copied Keys.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="customize-steps-key-list-note-sec">
                <div className="customize-steps-key-list-note-box">
                  <div className="customize-steps-key-list-note-card">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#171717"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M9.51 4.23l8.56 4.28c3.84 1.92 3.84 5.06 0 6.98l-8.56 4.28c-5.76 2.88-8.11.52-5.23-5.23l.87-1.73c.22-.44.22-1.17 0-1.61l-.87-1.74C1.4 3.71 3.76 1.35 9.51 4.23zM5.44 12h5.4"
                      ></path>
                    </svg>
                    <p>
                      <span>Domain : </span>
                      (Domain Value copied from the Step 5).
                    </p>
                  </div>
                  <div className="customize-steps-key-list-note-card">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#171717"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M9.51 4.23l8.56 4.28c3.84 1.92 3.84 5.06 0 6.98l-8.56 4.28c-5.76 2.88-8.11.52-5.23-5.23l.87-1.73c.22-.44.22-1.17 0-1.61l-.87-1.74C1.4 3.71 3.76 1.35 9.51 4.23zM5.44 12h5.4"
                      ></path>
                    </svg>
                    <p>
                      <span>API Key : </span>
                      (API Key Value copied from Step 5).
                    </p>
                  </div>
                  <div className="customize-steps-key-list-note-card">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#171717"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M9.51 4.23l8.56 4.28c3.84 1.92 3.84 5.06 0 6.98l-8.56 4.28c-5.76 2.88-8.11.52-5.23-5.23l.87-1.73c.22-.44.22-1.17 0-1.61l-.87-1.74C1.4 3.71 3.76 1.35 9.51 4.23zM5.44 12h5.4"
                      ></path>
                    </svg>
                    <p>
                      <span>EFI Assist API Key : </span>
                      (API Key copied from Step 1).
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FreshChatDemoSteps;
