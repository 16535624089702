import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, InputGroup, Row, Col } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { faqDeleteStart } from "../../../store/slices/FaqSlice";
import { conversationsDeleteStart } from "../../../store/slices/FineTuneSlice";


const DeleteConversationsModal = (props) => {

  const t = useTranslation("settings.conversations.delete_conversations")
  const dispatch = useDispatch();
  const conversationsDelete = useSelector((state) => state.fineTune.conversationsDelete);
  const [skipRender, setSkipRender] = useState(true);
  const selectedBot = useSelector((state) => state.admin.selectedBot);

  useEffect(()=> {
    if(!skipRender && !conversationsDelete.loading && Object.keys(conversationsDelete.data).length > 0)
    {
      props.closeDeleteConversationsModal();
    }
    setSkipRender(false);
  }, [conversationsDelete])

 
  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.deleteConversations}
        onHide={props.closeDeleteConversationsModal}
      >
        <Modal.Body>
          <h4>{t("heading")}</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeDeleteConversationsModal()}
          >
            <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#171717"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10zM9.17 14.83l5.66-5.66M14.83 14.83L9.17 9.17"
      ></path>
    </svg>
          </Button>
          <div className="delete-bot-card">
            <h4>{t("message")}</h4>
            <p>{t("content")}</p>
          </div>
          <div className="onboarding-import-webpage-modal-btn-sec mt-3">
            <Button
              className="onboarding-cancel-btn"
              onClick={() => props.closeDeleteConversationsModal()}
            >
              {t("cancel")}
            </Button>
            <Button
              className="onboarding-save-btn"
              type="submit"
              onClick={() =>
                dispatch(conversationsDeleteStart({ conversational_data_id: props.deleteConversations, bot_id: selectedBot?.bot_id }))
            }
              disabled={conversationsDelete.buttonDisable}
            >
              {conversationsDelete.buttonDisable ? t("loading") : t("confirm")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(DeleteConversationsModal);
