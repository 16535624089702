import React from "react";
import { Image, Button, Container, Row, Col } from "react-bootstrap";

const WhatsappDemoSteps = (props) => {
  return (
    <>
      <div className="customize-steps-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="customize-steps-btn-sec">
                <h3>Setting Up Whatsapp Integration with EFI Assist</h3>
                <a
                  className="settings-btn"
                  download={true}
                  target="_blank"
                  rel="noreferrer"
                  href={
                    window.location.origin +
                    "/installation-steps/Whatsapp-Installation-Steps.pdf"
                  }
                >
                  Download Now
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="customize-steps-list-sec">
                <ul className="customize-steps-list list-unstyled">
                  <li>
                    <span>1</span>
                    <div>
                    Log in to your Facebook account at{" "}
                    <a
                      href="https://facebook.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      (https://facebook.com/)
                      </a>
                    </div>
                  </li>
                  <li>
                    <span>2</span>
                    <div>
                    Visit <a
                      href="https://developers.facebook.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://developers.facebook.com
                      </a> and click on "My
                    Apps."
                    </div>
                  </li>
                  <li>
                    <span>3</span>
                    <div>
                      Create a new app
                      <h6>Click on "Create App."</h6>
                      <h6>Choose "Other" and click "Next."</h6>
                      <h6>Select "Business" and click "Next."</h6>
                      <h6>Provide a name for your app.</h6>
                      <h6>
                        Choose a Business account (required in the later steps).
                      </h6>
                      <h6>Click "Create app."</h6>
                    </div>
                  </li>
                  <li>
                    <span>4</span>
                    Add WhatsApp to your app: On the "Add products to your app"
                    page, select "WhatsApp" and click "Set up."
                  </li>
                  <li>
                    <span>5</span>
                    <div>
                      Add phone number: In the next page, navigate to API Setup.
                      <h6>Click on "Add Phone Number"</h6>
                      <h6>
                        In the popup, Fill the required fields and Add your
                        business phone number.
                      </h6>
                      <h6>Then, copy your "Phone number ID".</h6>
                    </div>
                  </li>
                  <li>
                    <span>6</span>
                    Generate Permanent WhatsApp Token: Refer the documentation
                    to generate the permanent WhatsApp token -
                    https://developers.facebook.com/docs/whatsapp/business-management-api/get-started#1--acquire-an-access-token-using-a-system-user-or-facebook-login
                    <br />
                    Please make sure you are giving all the required permissions
                    to the token.
                  </li>
                  <li>
                    <span>7</span>
                    <div>
                      Connect WhatsApp to EFI Assist:                    
                      <h6>Access EFI Assist panel, choose a bot, and click "Source."</h6>
                      <h6>
                      Select "WhatsApp" and paste the WhatsApp Phone Number ID and Token.
                      </h6>
                      <h6>Click "Generate API Key" and then copy the Webhook URL.</h6>
                    </div>
                  </li>
                  <li>
                    <span>8</span>
                    <div>
                    Configure WhatsApp App:                    
                      <h6>Return to the WhatsApp App page.</h6>
                      <h6>
                      Go to WhatsApp > Quickstart > Configuration                      
                      </h6>
                      <h6>In the Webhook section, click "Edit"</h6>
                      <h6>
                      In the popup, paste the Webhook URL from the previous step into the "Callback URL" textbox.
                      </h6>
                      <h6>
                      Type "EFI Assist" in the "Verify token" textbox.
                      </h6>
                      <h6>
                      Click "Verify & Save."
                      </h6>
                      <h6>
                      In the Webhook fields section, click "Manage", select the latest version & subscribe to "messages" in the popup, and then click "Done."
                      </h6>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default WhatsappDemoSteps;
