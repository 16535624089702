import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { Link } from "react-router-dom";

const NoBillingExists = () => {
  const t = useTranslation("helper.no_billing_exists")
  return (
    <>
      <div className="no-bot-exists-card">
        <div className="no-bot-exists-img-sec">
          <Image
            className="no-bot-exists-img"
            src={
              window.location.origin +
              "/images/no-data/no-billing-exists-img.gif"
            }
          />
        </div>
        <div className="no-bot-exists-info">
          <h4>{t("heading")}</h4>
          <p>{t("content")}</p>
          <div className="no-bot-exists-btn-sec">
            <Link to="/subscription" className="onboarding-add-fine-tuning-btn">
              <span>{t("subscribe")}</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoBillingExists;
