import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { requestEnterprisePlanStart } from "../../store/slices/SubscriptionSlice";
import { useNavigate } from "react-router-dom";

const EnterpriseSubscriptionCard = (props) => {

  const dispatch = useDispatch();
  const { plan, type } = props;
  const t = useTranslation("onboarding_subscription");
  const [skipRender, setSkipRender] = React.useState(true);
  const navigate = useNavigate();

  const requestEnterprisePlan = useSelector((state) => state.subscription.requestEnterprisePlan);

    useEffect(() => {
        if(!skipRender && Object.keys(requestEnterprisePlan.data).length > 0) {
            props.onboarding && navigate("/dashboard")
        }
    }
    , [requestEnterprisePlan.data]);
  return (
    <div className={`onboarding-subscription-plans-card advance-plan-card`}>
      <div className="onboarding-subscription-plans-header-sec">
        {plan.name}
      </div>
      <div className="onboarding-subscription-plans-body-sec">
        <div className="onboarding-subscription-plans-amount-sec">
          <h3>
            Custom Plan
            {/* <span>/ {type == "monthly" ? "Month" : "Year"}</span> */}
          </h3>
          {type == "yearly" && plan.yearly_save_note_percentage && (
            <p>{plan.yearly_save_note_percentage}</p>
          )}
        </div>
        <div className="onboarding-subscription-plans-info-list-sec">
          <ul className="list-unstyled onboarding-subscription-plans-info-list">
            <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10z"
                  ></path>
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M7.75 12l2.83 2.83 5.67-5.66"
                  ></path>
                </svg>
              <span>
                1000+ Messages
              </span>
            </li>
            <li>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10z"
                  ></path>
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M7.75 12l2.83 2.83 5.67-5.66"
                  ></path>
                </svg>
              <span>100+ Custom Training</span>
            </li>
            <li>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10z"
                  ></path>
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M7.75 12l2.83 2.83 5.67-5.66"
                  ></path>
              </svg>
              <span>10+ Bots</span>
            </li>
            <li>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10z"
                  ></path>
                  <path
                    stroke="#1c1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.8"
                    d="M7.75 12l2.83 2.83 5.67-5.66"
                  ></path>
              </svg>
              <span>24/7 Support</span>
            </li>
          </ul>
        </div>
        <div className="onboarding-subscription-plans-choose-btn-sec">
          <Button
            className="onboarding-subscription-free-plans-btn"
            disabled={props.buttonDisable || requestEnterprisePlan.buttonDisable || Object.keys(requestEnterprisePlan.data).length > 0}
            onClick={(e) =>
              dispatch(
                requestEnterprisePlanStart({
                  onboarding: props.onboarding,
                })
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="17"
              fill="none"
              viewBox="0 0 21 17"
            >
              <path
                fill="#252525"
                d="M1.45 12.333L.197 4.178a.68.68 0 011.08-.648l3.91 2.932a.934.934 0 001.337-.23l3.254-4.88a.869.869 0 011.446 0l3.254 4.88a.934.934 0 001.338.23l3.908-2.932a.68.68 0 011.081.648l-1.255 8.155H1.451zM18.605 16.857H2.394a.944.944 0 01-.944-.943V13.84H19.55v2.073a.944.944 0 01-.944.943z"
              ></path>
            </svg>
            {(Object.keys(requestEnterprisePlan.data).length > 0 || props.buttonDisable) ? "Requested" : requestEnterprisePlan.buttonDisable
              ? t("plans.loading")
              : "Request Plan"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseSubscriptionCard;
