import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";
const storedSelectedInboxBot = localStorage.getItem('selectedInboxBot');


const initialState = {
  chatList: getCommonInitialState(),
  sendMessage: getCommonInitialState(),
  sendPreviewMessage: getCommonInitialState(),
  selectedChats: [],
  highlightChat: "",
  socket: null,
  chats: [],
  previewChats: getCommonStart(),
  previewChatMessages: getCommonInitialState(),
  selectedInboxBot: storedSelectedInboxBot ? JSON.parse(storedSelectedInboxBot) : {
    label: "All Chats",
    value: "",
  },
  audioNotifyCount: 0,
  currentAudio: {
    src: "",
  },
};

const ChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {

    //Chat Socket
    setChatSocket: (state, action) => { state.socket = action.payload },
    setChats: (state, action) => { state.chats = action.payload },
    addChat: (state, action) => { state.chats = [...state.chats.filter(chat => chat.username != action.payload.username), action.payload] },
    updateChatStatus: (state, { payload }) => {
      state.chats = state.chats.map(chat => chat.username === payload.username ?
        { ...chat, autoMsg: payload.auto }
        : chat
      )
    },
    updateChatSocketStatus: (state, { payload }) => {
      state.chats = state.chats.map(chat => chat.username === payload.username ?
        { ...chat, socketStatus: payload.socketStatus }
        : chat
      )
    },
    updateChatMessages: (state, { payload }) => {
      state.chats = state.chats.map(chat => {
        if (chat.username === payload.username) {
          let dateObj = chat.messages.find(msg => msg.date === payload.message.date);
          if (dateObj) {
            dateObj = {
              ...dateObj, messages: [...dateObj.messages, payload.message.message]
            }
          } else {
            dateObj = { date: payload.message.date, messages: [payload.message.message] };
          }
          return {
            ...chat,
            notify: chat.auto || payload.disableNotify ? chat.notify : !chat.notify,
            messages: [...chat.messages.filter(msg => msg.date !== payload.message.date), dateObj],
          }
        } else
          return chat;
      })
    },
    updateChatSuggtion: (state, { payload }) => {
      state.chats = state.chats.map(chat => chat.username === payload.username ? { ...chat, suggetion: payload.message } : chat)
    },
    unSubscribeChat: (state, { payload }) => {
      state.chats = state.chats.filter(chat => chat.username !== payload.username)
    },

    //Chats
    setSelectedChats: (state, action) => { state.selectedChats = action.payload },
    setHighlightChat: (state, action) => { state.highlightChat = action.payload },

    //Subscription List
    chatListStart: (state) => { state.chatList = getCommonStart() },
    chatListSuccess: (state, action) => { state.chatList = getCommonSuccess(action.payload) },
    chatListFailure: (state, action) => { state.chatList = getCommonFailure(action.payload) },
    chatListUpdate: (state, { payload }) => {
      let inboxBot = state.selectedInboxBot;
      state.audioNotifyCount = payload.audio_notify_count;
      if(inboxBot.value == "" || inboxBot.value == payload.bot_id ) {
        if (state.chatList.data.chats)
          state.chatList = getCommonSuccess({
            chats: [{
              ...payload,
              age_timestamp: new Date().toISOString(),
            }, ...state.chatList.data.chats.filter(chat => chat.chat_id !== payload.chat_id).slice(0, 11)],
            total_chats: state.chatList.data.total_chats,
            un_read_chats_count: payload.notify ? (inboxBot.value == "" ? payload.un_read_chats_count : payload.un_read_chats_count_by_bot) : state.chatList.data.un_read_chats_count,
            audio_notify_count: payload.notify ? (inboxBot.value == "" ? payload.audio_notify_count : payload.audio_notify_count_by_bot) : state.chatList.data.audio_notify_count,
          })
        else
          state.chatList = state.chatList;
      }
    },
    chatListUpdateContent: (state, { payload }) => {
      if (state.chatList.data.chats)
        state.chatList = getCommonSuccess({
          ...state.chatList.data,
          chats: state.chatList.data.chats.map(chat => chat.username === payload.username ? 
            { ...chat,
              age_timestamp: new Date().toISOString(),
              chat_messages_count: chat.chat_messages_count + 1,
              last_chat_message: {
                ...chat.last_chat_message,
                message: payload.message && payload.message.message && payload.message.message.message ? payload.message.message.message : "",
                file: payload.message && payload.message.message && payload.message.message.file ? payload.message.message.file : ""
              }
            } 
            : chat),
        })
    },
    disableChatNotify: (state, { payload }) => {
      let chatObj = state?.chatList?.data?.chats.find(chat => chat.chat_id === payload);
      state.audioNotifyCount = chatObj && chatObj.audio_notify ? state.audioNotifyCount - 1 : state.audioNotifyCount;
      state.chatList = getCommonSuccess({
        ...state.chatList.data,
        chats: state.chatList.data.chats.map(chat => chat.chat_id === payload ? { ...chat, notify: false, audio_notify: 0 } : chat),
        un_read_chats_count: chatObj && chatObj.notify ? state.chatList.data.un_read_chats_count - 1 : state.chatList.data.un_read_chats_count,
        audio_notify_count: chatObj && chatObj.audio_notify ? state.chatList.data.audio_notify_count - 1 : state.chatList.data.audio_notify_count,
      })
    },
    messageListNotify: (state, { payload }) => {
      if (state.chatList.data.chats)
        state.chatList = getCommonSuccess({
          ...state.chatList.data,
          chats: state.chatList.data.chats.map(chat => chat.username === payload.username ? 
            { ...chat,
              age: "1 second ago",
              age_timestamp: new Date().toISOString(),
              notify: true,
              chat_messages_count: chat.chat_messages_count + 1,
              last_chat_message: {
                ...chat.last_chat_message,
                message: payload.message && payload.message.message && payload.message.message.message ? payload.message.message.message : "",
                file: payload.message && payload.message.message && payload.message.message.file ? payload.message.message.file : "",
                file_type: payload.message && payload.message.message && payload.message.message.file ? payload.message.message.file_type : ""
              }
            } 
            : chat),
        })
    },
    addChatMsgCount: (state, { payload }) => {
      state.chatList = getCommonSuccess({
        ...state.chatList.data,
        chats: state.chatList.data.chats.map(chat => chat.chat_id === payload.chat_id ? 
          { ...chat, 
            chat_messages_count: chat.chat_messages_count + 1,
            last_chat_message: {
              ...chat.last_chat_message,
              message: payload.type == "text" ? payload.message : "",
              file: payload.type == "file" ? payload.file : ""
            }
          } 
          : chat),
      })
    },
    chatListTimeStampUpdate: (state, { payload }) => {
      if (state.chatList.data.chats)
        state.chatList = getCommonSuccess({
          ...state.chatList.data,
          chats: state.chatList.data.chats.map(chat => chat.chat_id === payload.chatId ? { ...chat, age_timestamp: new Date().toISOString() } : chat),
        })
    },


    //Send preview
    sendPreviewMessageStart: (state, action) => { state.sendPreviewMessage = getCommonStart() },
    sendPreviewMessageSuccess: (state, action) => { state.sendPreviewMessage = getCommonSuccess(action.payload) },
    sendPreviewMessageFailure: (state, action) => { state.sendPreviewMessage = getCommonFailure(action.payload) },

    //Previeew chat List
    previewChatListStart: (state) => { state.previewChats = getCommonStart() },
    previewChatListSuccess: (state, action) => { state.previewChats = getCommonSuccess(action.payload) },

    //Preview chat messages
    previewChatMessagesStart: (state) => { state.previewChatMessages = getCommonStart() },
    previewChatMessagesSuccess: (state, action) => { state.previewChatMessages = getCommonSuccess(action.payload) },
    previewChatMessagesFailure: (state, action) => { state.previewChatMessages = getCommonFailure(action.payload) },

    //Selected Inbox Bot 
    selecteInboxBot: (state, action) => { state.selectedInboxBot = action.payload },

    setAudioNotifyCount: (state, action) => { state.audioNotifyCount = action.payload },

    // Change Chat Audio
    changeChatAudio:(state, {payload}) => { state.currentAudio = payload }
  }
});


export const {
  setChatSocket,
  chatListStart,
  chatListSuccess,
  chatListFailure,
  chatListUpdate,
  chatListUpdateContent,
  messageListNotify,
  disableChatNotify,
  setSelectedChats,
  setHighlightChat,
  setChats,
  addChat,
  updateChatStatus,
  updateChatSocketStatus,
  updateChatMessages,
  updateChatSuggtion,
  unSubscribeChat,
  sendPreviewMessageStart,
  sendPreviewMessageSuccess,
  sendPreviewMessageFailure,
  previewChatListStart,
  previewChatListSuccess,
  previewChatMessagesFailure,
  previewChatMessagesStart,
  previewChatMessagesSuccess,
  selecteInboxBot,
  setAudioNotifyCount,
  addChatMsgCount,
  changeChatAudio,
  chatListTimeStampUpdate,
} = ChatSlice.actions;

export default ChatSlice.reducer;