import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Dropdown,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  faqListStart,
  moreFaqListStart,
  faqTrainStart,
} from "../../../store/slices/FaqSlice";
import {
  customTrainingListStart,
  fineTuneListStart,
} from "../../../store/slices/FineTuneSlice";
import NoDataFound from "../../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import AddFaqModal from "./AddFaqModal";
import InfiniteScroll from "react-infinite-scroll-component";
import DeleteFaqModal from "./DeleteFaqModal";
import { useTranslation } from "react-multi-lang";

const FaqIndex = () => {

  const dispatch = useDispatch();
  const t = useTranslation("settings.faq.faq_list")
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const faqList = useSelector((state) => state.faq.faqList);
  const faqDelete = useSelector((state) => state.faq.faqDelete);
  const faqTrain = useSelector((state) => state.faq.faqTrain);
  const [addFaq, setAddFaq] = useState(false);
  const [faq, setFaq] = useState({});
  const [deleteFaq, setDeleteFaq] = useState(false);

  const handleAddFaq = (message) => {
    setFaq({ ...faq, question: message.question, answer: message.response, status: message.status, chat_message_faq_id: message.chat_message_faq_id });
    setAddFaq(true);
  };

  const closeAddFaqModal = () => {
    setAddFaq(false);
  };

  const closeDeleteFaqModal = () => {
    setDeleteFaq(false);
  };

  useEffect(() => {
    dispatch(faqListStart({ bot_id: selectedBot.bot_id, skip: 0, take: 12 }));
    dispatch(customTrainingListStart({ bot_id: selectedBot.bot_id}))
  }, [selectedBot]);

  const fetchMoreData = () => {
    dispatch(
      moreFaqListStart({
        bot_id: selectedBot.bot_id,
        skip: faqList.data.chat_message_faqs.length,
        take: 12,
      })
    );
  };

  const handleTrain = () => {
    dispatch(faqTrainStart({ bot_id: selectedBot.bot_id }));
  };

  const getStatusName = (status) => {
    switch (status) {
      case 1:
        return "progress-badge";
      case 2:
        return "trained-badge";
      case 3:
        return "failed-badge";
      default:
        return "uploaded-badge";
    }
  };

  return (
    <>
      <div className="new-finetune-faq-table-sec">
        <div className="new-finetune-faq-table-box">
          {faqList.loading ? (
            <div className="onboarding-fine-tunings-files-box">
              {[...Array(4)].map((i, key) => (
                <Skeleton height={80} key={key} />
              ))}
            </div>
          ) : Object.keys(faqList.data).length > 0 &&
            faqList.data.chat_message_faqs &&
            faqList.data.chat_message_faqs.length > 0 ? (
            <>
              {faqList.data.chat_message_faqs.find(
                (faq) => faq.training_status == 0
              ) && (
                <div className="admin-ui-dashboard-graph-header-sec">
                  <p className="notes">{t("total_faqs")} - {faqList.data.total_faqs}</p>
                  <Button
                    type="button"
                    className="onboarding-add-fine-tuning-btn btn btn-primary"
                    onClick={() => handleTrain()}
                    disabled={faqTrain.buttonDisable}
                  >
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 12 17"
                    >
                      <path
                        fill="#000"
                        d="M3.333.5A3.337 3.337 0 000 3.833v12a.667.667 0 001.043.55l1.404-.96 1.404.96a.667.667 0 00.753 0l1.4-.96 1.4.96a.667.667 0 00.754 0l1.4-.958 1.4.957A.666.666 0 0012 15.834v-12A3.337 3.337 0 008.667.5H3.333zm4 9.333h-4a.667.667 0 110-1.333h4a.667.667 0 110 1.333zm2-3.333a.667.667 0 01-.666.667H3.333a.667.667 0 010-1.334h5.334a.667.667 0 01.666.667z"
                      ></path>
                    </svg> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-receipt"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2"></path></svg>
                    {faqTrain.buttonDisable ? t("loading") : t("train")}
                  </Button>
                </div>
              )}
              <InfiniteScroll
                dataLength={faqList.data.chat_message_faqs.length}
                next={fetchMoreData}
                hasMore={
                  faqList.data.chat_message_faqs.length <
                  faqList.data.total_chat_message_faqs
                }
                loader={[...Array(3)].map((i) => (
                  <Skeleton height={80} className="mb-3" />
                ))}
              >
                <div className="new-settings-finetune-table-sec pt-0">
                  <div className="new-dashboard-recent-chat-list-table-sec mt-0">
                    <Table responsive="md" borderless>
                      {faqList.data.chat_message_faqs.map((faq, index) => (
                        <tbody key={index}>
                          {faq.file.length > 0 ? (
                            <tr>
                              <div className="new-finetune-faq-mesage">
                                <h4 className="question-text">{faq.batch_id.slice(0,5)}...{faq.batch_id.slice(-5)}</h4>
                              </div>
                              <div className="new-finetune-faq-btn-sec">
                                <div className={getStatusName(faq.training_status)}>
                                  {faq.training_status_formatted}
                                </div>
                                <div className="new-finetune-faq-action-btn-sec">
                                  <div className="recent-chat-menu-btn">
                                    {faqDelete.buttonDisable &&
                                    faqDelete.data.chat_message_faq_id == faq.chat_message_faq_id ? (
                                      <Image
                                        className="btn-loader"
                                        src={
                                          window.location.origin +
                                          "/images/loader/btn-loader.gif"
                                        }
                                      />
                                    ) : (
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="success"
                                          id="dropdown-basic"
                                        >
                                          <div className="recent-chat-menu-icon-sec">
                                            <svg  xmlns="http://www.w3.org/2000/svg"  width="20"  height="20"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-dots"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /></svg>
                                          </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            href={faq.file}
                                            target="_blank"
                                          >
                                            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-eye"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
                                            {t("view")}
                                          </Dropdown.Item>
                                          {faq.training_status != 1 && (
                                            <Dropdown.Item
                                              disabled={faqDelete.buttonDisable}
                                              onClick={() => setDeleteFaq(faq.chat_message_faq_id)}
                                            >
                                              <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-trash"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                                              {t("delete")}
                                            </Dropdown.Item>
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </tr>
                          ) : (
                            <tr>
                              <div className="new-finetune-faq-mesage">
                                <h4 className="question-text">{faq.question}</h4>
                                <p className="answer-text">{faq.response}</p>
                              </div>
                              <div className="new-finetune-faq-btn-sec">
                                <div className={getStatusName(faq.training_status)}>
                                  {faq.training_status_formatted}
                                </div>
                                <div className="new-finetune-faq-action-btn-sec">
                                  {faq.training_status != 1 ? (
                                    <div className="recent-chat-menu-btn">
                                      {faqDelete.buttonDisable &&
                                      faqDelete.data.chat_message_faq_id ==
                                        faq.chat_message_faq_id ? (
                                        <Image
                                          className="btn-loader"
                                          src={
                                            window.location.origin +
                                            "/images/loader/btn-loader.gif"
                                          }
                                        />
                                      ) : (
                                        <Dropdown>
                                          <Dropdown.Toggle
                                            variant="success"
                                            id="dropdown-basic"
                                          >
                                            <div className="recent-chat-menu-icon-sec">
                                              <svg  xmlns="http://www.w3.org/2000/svg"  width="20"  height="20"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-dots"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /></svg>
                                            </div>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            {faq.training_status != 2 && (
                                              <Dropdown.Item
                                                disabled={faqDelete.buttonDisable}
                                                onClick={() => handleAddFaq(faq)}
                                              >
                                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-edit"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
                                                {t("edit")}
                                              </Dropdown.Item>
                                            )}
                                            <Dropdown.Item
                                              disabled={faqDelete.buttonDisable}
                                              onClick={() => setDeleteFaq(faq.chat_message_faq_id)}
                                            >
                                              <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-trash"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                                              {faqDelete.buttonDisable
                                                ? t("loading")
                                                : t("delete")}
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </tr>
                          )}
                        </tbody>
                      ))}
                    </Table>
                  </div>
                </div>
              </InfiniteScroll>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
      {addFaq && (
        <AddFaqModal
          addFaq={addFaq}
          closeAddFaqModal={closeAddFaqModal}
          setAddFaq={setAddFaq}
          faq={faq}
          type={2}
        />
      )}
      {deleteFaq && (
        <DeleteFaqModal
          deleteFaq={deleteFaq}
          closeDeleteFaqModal={closeDeleteFaqModal}
          setDeleteBot={setDeleteFaq}
        />
      )}
    </>
  );
};

export default FaqIndex;
