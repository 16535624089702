import React from "react";
import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const TableLoader = (props) => {
  return (
    <div className="table-loader mt-3">
      <div className="mb-3">
        <Skeleton width={150} height={30} />
      </div>
      <Table bordered>
        <tbody>
          {[...Array(props.lines)].map((index, key) => (
            <tr key={key}>
              <td>
                <div className="skeleton-cell">
                  <Skeleton width={30} height={15} />
                </div>
              </td>
              <td>
                <Skeleton width={200} height={10} />
                <Skeleton width={300} height={10} />
              </td>
              <td>
                <div className="skeleton-cell">
                  <Skeleton width={200} height={15} />
                </div>
              </td>
              <td>
                <div className="skeleton-cell">
                  <Skeleton width={100} height={15} />
                </div>
              </td>
              <td>
                <div className="skeleton-cell">
                  <Skeleton width={50} height={15} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TableLoader;
