import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";

const UpcomingBills = (props) => {

  const t = useTranslation("subscriptions.upcoming")
  return (
    <div className="upcoming-bills-box">
      <div className="upcoming-bills-title-sec">
        <h3>{t("heading")}</h3>
      </div>
      <div className="upcoming-bill-amount-sec">
        <h6>{props.data.current_plan.type_formatted}</h6>
        <div className="upcoming-bill-amount-item">
          <h5 className="bill-period">{props.data.current_plan.plan_name}</h5>
          <h3>{props.data.current_plan.type === 1 ? props.data.subscription_plan.monthly_amount : props.data.subscription_plan.yearly_amount}</h3>
        </div>
      </div>
      <div className="upcoming-bill-amount-item mt-3">
        <h6>{t("message")}</h6>
        <h5>{props.data.current_plan.expiry_date}</h5>
      </div>
      {props.data.user_billing_address &&
      Object.keys(props.data.user_billing_address).length > 0 ? (
        <>
          <div className="billing-address-sec">
            <h6>Billing Address</h6>
            <p>
              {props.data.user_billing_address.name +
                ", " +
                props.data.user_billing_address.address +
                ", " +
                props.data.user_billing_address.landmark +
                ", " +
                props.data.user_billing_address.state +
                ", " +
                props.data.user_billing_address.country +
                ", " +
                props.data.user_billing_address.pincode +
                ". Phone Number: " +
                props.data.user_billing_address.contact_number}
            </p>
          </div>
          <div className="upcoming-edit-profile-btn-sec">
            <Button
              className="contact-us-btn"
              onClick={() => props.setEditAddress(true)}
            >
              {t("add_address")}
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default UpcomingBills;
