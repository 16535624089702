/* eslint-disable require-yield */
import { takeEvery } from 'redux-saga/effects';

function* setSelectedBotSaga(action) {
    if (action.type === 'admin/selectedBotSuccess') {
        localStorage.setItem('selectedBot', JSON.stringify(action.payload));
    }
}

function* selecteInboxBotSaga(action) {
    if (action.type === 'chat/selecteInboxBot') {
        localStorage.setItem('selectedInboxBot', JSON.stringify(action.payload));
    }
}

export function* selectedBotMiddlewareSaga() {
    yield takeEvery('admin/selectedBotSuccess', setSelectedBotSaga);
    yield takeEvery('chat/selecteInboxBot', selecteInboxBotSaga);
}
