import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  subscriptionList: getCommonInitialState(),
  subscriptionView: getCommonInitialState(),
  subscriptionSave: getCommonInitialState(),
  generateStripeIntent: getCommonInitialState(),
  subscriptionPlans: getCommonInitialState(),
  currentSubscription: getCommonInitialState(),
  billingAddressSave: getCommonInitialState(),
  billingInvoiceDownload: getCommonInitialState(),
  requestEnterprisePlan: getCommonInitialState(),
  checkCoupon: getCommonInitialState(),
};

const SubscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {

    //Subscription List
    subscriptionListStart: (state) => { state.subscriptionList = getCommonStart() },
    subscriptionListSuccess: (state, action) => { state.subscriptionList = getCommonSuccess(action.payload) },
    subscriptionListFailure: (state, action) => { state.subscriptionList = getCommonFailure(action.payload) },

    //Subscription View
    subscriptionViewStart: (state, action) => { state.subscriptionView = getCommonStart() },
    subscriptionViewSuccess: (state, action) => { state.subscriptionView = getCommonSuccess(action.payload) },
    subscriptionViewFailure: (state, action) => { state.subscriptionView = getCommonFailure(action.payload) },

    //Subscription save
    subscriptionSaveStart: (state, action) => { state.subscriptionSave = getCommonStart() },
    subscriptionSaveSuccess: (state, action) => { state.subscriptionSave = getCommonSuccess(action.payload) },
    subscriptionSaveFailure: (state, action) => { state.subscriptionSave = getCommonFailure(action.payload) },

    //Generate stripe intent
    generateStripeIntentStart: (state, action) => { state.generateStripeIntent = getCommonStart(action.payload) },
    generateStripeIntentSuccess: (state, action) => { state.generateStripeIntent = getCommonSuccess(action.payload) },
    generateStripeIntentFailure: (state, action) => { state.generateStripeIntent = getCommonFailure(action.payload) },

     //Subscription Plans
    subscriptionPlansStart: (state) => { state.subscriptionPlans = getCommonStart() },
    subscriptionPlansSuccess: (state, action) => { state.subscriptionPlans = getCommonSuccess(action.payload) },
    subscriptionPlansFailure: (state, action) => { state.subscriptionPlans = getCommonFailure(action.payload) },

     //Subscription Plans
    currrentSubscriptionPlanStart: (state) => { state.currentSubscription = getCommonStart() },
    currrentSubscriptionPlanSuccess: (state, action) => { state.currentSubscription = getCommonSuccess(action.payload) },
    currrentSubscriptionPlanFailure: (state, action) => { state.currentSubscription = getCommonFailure(action.payload) },

    //Subscription save
    billingAddressSaveStart: (state) => { state.billingAddressSave = getCommonStart() },
    billingAddressSaveSuccess: (state, action) => { state.billingAddressSave = getCommonSuccess(action.payload) },
    billingAddressSaveFailure: (state, action) => { state.billingAddressSave = getCommonFailure(action.payload) },

    //Billing Invoice Download
    billingInvoiceDownloadStart: (state, action) => { state.billingInvoiceDownload = getCommonStart(action.payload) },
    billingInvoiceDownloadSuccess: (state, action) => { state.billingInvoiceDownload = getCommonSuccess(action.payload) },
    billingInvoiceDownloadFailure: (state, action) => { state.billingInvoiceDownload = getCommonFailure(action.payload) },

    //Request Enterprise Plan
    requestEnterprisePlanStart: (state, action) => { state.requestEnterprisePlan = getCommonStart(action.payload) },
    requestEnterprisePlanSuccess: (state, action) => { state.requestEnterprisePlan = getCommonSuccess(action.payload) },
    requestEnterprisePlanFailure: (state, action) => { state.requestEnterprisePlan = getCommonFailure(action.payload) },

    // Check Coupon
    checkCouponStart: (state, action) => { state.checkCoupon = getCommonStart(action.payload) },
    checkCouponSuccess: (state, action) => { state.checkCoupon = getCommonSuccess(action.payload) },
    checkCouponFailure: (state, action) => { state.checkCoupon = getCommonFailure(action.payload) },

  }
});


export const {
  subscriptionListStart,
  subscriptionListSuccess,
  subscriptionListFailure,
  subscriptionViewStart,
  subscriptionViewSuccess,
  subscriptionViewFailure,
  subscriptionSaveStart,
  subscriptionSaveSuccess,
  subscriptionSaveFailure,
  generateStripeIntentStart,
  generateStripeIntentSuccess,
  generateStripeIntentFailure,
  subscriptionPlansStart,
  subscriptionPlansSuccess,
  subscriptionPlansFailure,
  subscriptionIntentSuccess,
  currrentSubscriptionPlanFailure,
  currrentSubscriptionPlanStart,
  currrentSubscriptionPlanSuccess,
  billingAddressSaveFailure,
  billingAddressSaveStart,
  billingAddressSaveSuccess,
  billingInvoiceDownloadStart,
  billingInvoiceDownloadSuccess,
  billingInvoiceDownloadFailure,
  requestEnterprisePlanStart,
  requestEnterprisePlanSuccess,
  requestEnterprisePlanFailure,
  checkCouponStart,
  checkCouponSuccess,
  checkCouponFailure,

} = SubscriptionSlice.actions;

export default SubscriptionSlice.reducer;