import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-multi-lang";
import Select from "react-select";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { agentCreateStart, singleAgentViewStart } from "../../store/slices/AgentSlice";
import makeAnimated from 'react-select/animated';
import Skeleton from "react-loading-skeleton"

const CreateAgentModal = (props) => {

  const animatedComponents = makeAnimated();

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #F3F2ED!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      minHeight: "40px",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#50506B80",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#FF916F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#FF916F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#FF916F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
        '&:active': { backgroundColor: isSelected ? "#f7f7f7" : "#fff" }
      };
    },
  };

  const t = useTranslation("agents.create");
  const dispatch = useDispatch();
  const agentCreate = useSelector((state) => state.agent.agentCreate);
  const singleAgent = useSelector((state) => state.agent.singleAgent);
  const { selectedBot, bots } = props;
  const [skipRender, setSkipRender] = useState(true);
  const [defaultBots, setDefaultBots] = useState(false);
  const formRef = useRef();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name.required")).trim().notOneOf([''])
      .matches(/^[^<>]*$/, t('name.invalid')),
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    // bot_id: Yup.array().required(t("bots.required")),
  });

  const handleAgentStore = (values) => {
    let newValues = props.create === 1 ? values : {...values, agent_id: props.createAgent.agent_id }
    dispatch(agentCreateStart({...newValues, bot_id: newValues.bot_id ? JSON.stringify(newValues.bot_id) : [] }))
  }

  useEffect(() => {
    if (
      !singleAgent.loading &&
      Object.keys(singleAgent.data).length > 0 &&
      singleAgent.data.bots.length > 0
    ) {
      let defaults = singleAgent.data.bots.filter(bot => bot.is_selected === 1).map((bot) => ({
        label: bot.name,
        value: bot.id,
      }));
      setDefaultBots(defaults); 
      formRef.current.setFieldValue("bot_id", defaults.map((bot) => parseInt(bot.value)));
    }
    return () => {
      setDefaultBots(false);
    }
  }, [singleAgent]);

  useEffect(()=> {
    if(!skipRender && !agentCreate.loading && Object.keys(agentCreate.data).length > 0)
    {
      props.closeCreateAgentModal();
    }
    setSkipRender(false)
  }, [agentCreate])

  useEffect(()=> {
    if(props.create==0)
     dispatch(singleAgentViewStart({agent_id: props.createAgent.agent_id}))
    else
    {
      setDefaultBots(false);
      formRef.current.setFieldValue("bot_id", []);
    }
  }, [props.createAgent])

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal create-sales-rep-modal"
        size="md"
        centered
        show={props.createAgent}
        onHide={props.closeCreateAgentModal}
      >
        <Modal.Body>
          <h4>{props.create === 1 ? t("add_heading") : t("edit_heading")}</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeCreateAgentModal()}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 11 11"><path fill="#979BA2" d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"></path></svg>
          </Button>
          <Formik
            initialValues={
              props.create === 1
                ? {
                    name: "",
                    email: "",
                    bot_id: "",
                  }
                : {
                    name: props.createAgent.name,
                    email: props.createAgent.email,
                    bot_id: [],
                  }
            }
            validationSchema={validationSchema}
            onSubmit={handleAgentStore}
            innerRef={formRef}
          >
            {({ setFieldValue, values, isValid }) => (
              <FORM className="onboarding-form">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("name.label")} <span>*</span>
                  </Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder={t("name.placeholder")}
                    name="name"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="name"
                    className="errorMsg"
                  />
                </Form.Group>
                {props.create === 1 && <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("email.label")} <span>*</span>
                  </Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder={t("email.placeholder")}
                    name="email"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="email"
                    className="errorMsg"
                  />
                </Form.Group>}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("bots.label")}
                    {/* {props.create === 1 && <span>*</span>} */}
                  </Form.Label>
                  <div className="sales-rep-view-select-box-select-sec">
                    {props.create === 1 ? <Select
                      name="bot_id"
                      defaultInputValue=""
                      options={bots}
                      styles={customStyles}
                      placeholder={t("bots.placeholder")}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      onChange={(value) => {
                        setFieldValue("bot_id", value.map((bot)=> (
                          parseInt(bot.value)
                        )))}
                      }
                      /> : (!singleAgent.loading && Object.keys(singleAgent.data).length > 0) && defaultBots ? <Select
                      name="bot_id"
                      options={bots}
                      defaultValue={defaultBots}
                      styles={customStyles}
                      placeholder={t("bots.placeholder")}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      onChange={(value) => {
                        setFieldValue("bot_id", value.map((bot)=> (
                          parseInt(bot.value)
                        )))}
                      }
                    /> : <Skeleton height={50} /> }
                  </div>
                  {/* <ErrorMessage
                    component={"div"}
                    name="bot_id"
                    className="errorMsg"
                  /> */}
                  <div className="onboarding-import-webpage-modal-btn-sec mt-4">
                    <Button
                      className="onboarding-cancel-btn"
                      disabled={agentCreate.buttonDisable}
                      onClick={() => props.closeCreateAgentModal()}
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      className="onboarding-save-btn"
                      type="submit"
                      // onClick={() => isValid && handleAgentStore(values)}
                      disabled={agentCreate.buttonDisable}
                    >
                      {agentCreate.buttonDisable
                        ? t("loading")
                        : props.create === 1
                        ? t("add_heading")
                        : t("update")}
                    </Button>
                  </div>
                </Form.Group>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(CreateAgentModal);
