import axios from "axios";
import { getErrorNotificationMessage } from "../components/Helper/NotificationMessage";
const useBot = () => {

  const widgets = [
    {
      label: "FreshChat",
      value: 1,
      picture: window.location.origin + "/images/onboarding/choose-solution/new/freshchat.png"
    },
    {
      label: "ZenDesk",
      value: 3,
      picture: window.location.origin + "/images/onboarding/choose-solution/new/zendesk.png"
    },
    {
      label: "LiveChat",
      value: 4,
      picture: window.location.origin + "/images/onboarding/choose-solution/new/livechat.png"
    },
    {
      label: "Whatsapp",
      value: 7,
      picture: window.location.origin + "/images/onboarding/choose-solution/new/whatsapp.png"
    },
    {
      label: "Messenger",
      value: 8,
      picture: window.location.origin + "/images/onboarding/choose-solution/new/messenger.png"
    },
    {
      label: "Telegram",
      value: 9,
      picture: window.location.origin + "/images/onboarding/choose-solution/new/telegram.png"
    },
    {
      label: "Instagram",
      value: 10,
      picture: window.location.origin + "/images/onboarding/choose-solution/new/instagram.png"
    }
  ];

  const promptOptions = [
    { label: "Customer Support Representative", value: "Customer Support Representative" },
    { label: "Technical Support Specialist", value: "Technical Support Specialist" },
    { label: "Sales Consultant", value: "Sales Consultant" },
    { label: "Human Resources Assistant", value: "Human Resources Assistant" },
    { label: "Financial Advisor", value: "Financial Advisor" },
    { label: "Travel Agent", value: "Travel Agent" },
    { label: "Legal Consultant", value: "Legal Consultant" },
    { label: "Educational Advisor", value: "Educational Advisor" },
    { label: "Real Estate Agent", value: "Real Estate Agent" },
    { label: "Insurance Advisor", value: "Insurance Advisor" },
    { label: "Healthcare Advisor", value: "Healthcare Advisor" },
    { label: "Others", value: "Others" }
  ];
  
 
  const handlePrompt = async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_PROMPT_URL}/api/v1/generate_prompt`,data);
      return response.data;
    } catch (err) {
      getErrorNotificationMessage(err.message); 
    }
  };

  return {
    widgets,
    promptOptions,
    handlePrompt
  };
};

export default useBot;
