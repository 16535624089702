import React from "react";
import { Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const DashboardLoader = () => {
  return (
    <>
      <Col md={12} lg={12} xl={8} className="resp-mrg-btm-xl">
        <div className="admin-ui-dashboard-analytics-sec">
          <div className="new-setting-analytics-box">
            {[...Array(3)].map((i, key) => (
              <Skeleton count={1} height={250} key={key} />
            ))}
          </div>
        </div>
      </Col>
      <Col md={12} lg={12} xl={4}>
        <div className="admin-ui-dashboard-header-left-sec">
          {/* <div className="admin-ui-dashboard-header-left-main-box">
            <Skeleton count={2} height={122.5} />
          </div> */}
          <Skeleton count={1} height={250} />
        </div>
      </Col>
    </>
  );
};

export const AgentDashboardLoader = () => {
  return (
    <>
      <Col md={12} lg={12} xl={12}>
        <div className="admin-ui-dashboard-analytics-sec">
          <div className="new-setting-analytics-box">
            {[...Array(3)].map((i, key) => (
              <Skeleton count={1} height={150} key={key} />
            ))}
          </div>
        </div>
      </Col>
    </>
  );
}

export default DashboardLoader;
