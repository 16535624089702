import React, { useEffect, useState } from "react";
import { Image, Button, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddBotModal from "./AddBotModal";
import { botsListStart } from "../../store/slices/BotSlice";
import { selectedBotSuccess } from "../../store/slices/AdminSlice";
import Skeleton from "react-loading-skeleton";
import useDesign from "../../hooks/useDesign";
import NoBotExists from "../Helper/NoBotExists";
import { useTranslation, withTranslation } from "react-multi-lang";
import DeleteBotModal from "./DeleteBotModal";
import { selecteInboxBot } from "../../store/slices/ChatSlice";

const YourBotIndex = (props) => {

  const t = useTranslation("bot_creation");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const botsList = useSelector((state) => state.bots.botsList);
  const botDelete = useSelector((state) => state.bots.botDelete);
  const profile = useSelector((state) => state.admin.profile);
  const [addBot, setAddBot] = useState(false);
  const [deleteBot, setDeleteBot] = useState(false);

  const { getChatTypeName, getChatTypeBg } = useDesign();

  // useEffect(() => {
  //   dispatch(botsListStart());
  // }, []);

  const closeAddBotModal = () => {
    setAddBot(false);
  };

  const closeDeleteBotModal = () => {
    setDeleteBot(false);
  };

  const handleRedirect = (bot, url) => {
    dispatch(selectedBotSuccess(bot));
    dispatch(
      selecteInboxBot({
        label: bot.name,
        value: bot.bot_id,
      })
    );
    navigate(url);
  };

  return (
    <>
      <div className="admin-ui-body-sec">
        <div className="admin-ui-your-bot-list-sec">
          {botsList.loading ? (
            <>
              <div className="onboarding-fine-tunings-files-header-sec">
                <Skeleton count={1} width={170} height={47} />
              </div>
              <div className="admin-your-bot-list-box">
                {[...Array(6)].map((i) => (
                  <Skeleton count={1} width={350} height={300} />
                ))}
              </div>
            </>
          ) : Object.keys(botsList.data).length > 0 &&
            botsList.data.bots.length > 0 ? (
            <>
              {profile.data.role == 1 && (
                <div className="onboarding-fine-tunings-files-header-sec">
                  <Button
                    className="onboarding-add-fine-tuning-btn"
                    onClick={() => setAddBot(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M6 12h12M12 18V6"
                      ></path>
                    </svg>
                    <span>{t("add_bot")}</span>
                  </Button>
                </div>
              )}
              <div className="admin-your-bot-list-box">
                {botsList.data.bots.map((bot, key) => (
                  <div className="admin-your-bot-list-card" key={key}>
                    <div className="admin-your-bot-list-header-sec">
                      <div className="admin-your-bot-list-header-left-sec">
                        <div
                          className={`admin-your-bot-list-user-img-sec ${getChatTypeBg(
                            bot.widget_type
                          )}`}
                        >
                          <Image
                            className="admin-your-bot-list-user-img"
                            src={
                              window.location.origin +
                              "/images/your-bot/your-bot-img.png"
                            }
                          />
                        </div>
                        <div className="admin-your-bot-list-user-info">
                          <h4>{bot.name}</h4>
                          <Link
                            to={bot.website}
                            target="_blank"
                            className="admin-your-bot-list-user-url-link"
                          >
                            {bot.website}
                          </Link>
                        </div>
                      </div>
                      <div className="admin-your-bot-list-header-right-sec">
                        <div className="admin-your-bot-list-footer-sec">
                          {bot.status ? <div className="admin-your-bot-list-btn-sec">
                            {profile.data.role == 1 && (
                              <OverlayTrigger
                                placement="top"
                                trigger={["hover"]}
                                overlay={
                                  <Tooltip id="tooltip">
                                    <strong>{t("delete")}</strong>
                                  </Tooltip>
                                }>
                                <Button
                                  className="admin-your-bot-list-btn"
                                  onClick={() => setDeleteBot(bot.bot_id)}
                                  disabled={botDelete.buttonDisable}
                                >
                                  {/* {botDelete.buttonDisable &&
                            botDelete.data.bot_id === bot.bot_id
                              ? t("loading")
                              : t("delete")} */}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      stroke="#171717"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.5"
                                      d="M21 5.98c-3.33-.33-6.68-.5-10.02-.5-1.98 0-3.96.1-5.94.3L3 5.98M8.5 4.97l.22-1.31C8.88 2.71 9 2 10.69 2h2.62c1.69 0 1.82.75 1.97 1.67l.22 1.3M18.85 9.14l-.65 10.07C18.09 20.78 18 22 15.21 22H8.79C6 22 5.91 20.78 5.8 19.21L5.15 9.14M10.33 16.5h3.33M9.5 12.5h5"
                                    ></path>
                                  </svg>
                                </Button>
                              </OverlayTrigger>
                            )}
                            {profile.data.role == 1 ||
                              (profile.data.role == 2 &&
                                (profile.data.role_access?.custom_training_doc ||
                                  profile.data.role_access?.custom_training_link ||
                                  profile.data.role_access?.faq ||
                                  profile.data.role_access?.conversational_data)) ? (
                              <OverlayTrigger
                                placement="top"
                                trigger={["hover"]}
                                overlay={
                                  <Tooltip id="tooltip">
                                    <strong>{t("train")}</strong>
                                  </Tooltip>
                                }>
                                <Button
                                  className="admin-your-bot-list-btn"
                                  onClick={() =>
                                    handleRedirect(bot, "/settings?tab=fine-tune")
                                  }
                                >
                                  {/* {t("train")} */}
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-settings-2"><path d="M19.875 6.27a2.225 2.225 0 0 1 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"></path><path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path></svg>
                                </Button>
                              </OverlayTrigger>
                            ) : null}
                            {profile.data.role == 1 ||
                              (profile.data.role == 2 &&
                                profile.data.role_access?.customize) ? (
                              <OverlayTrigger
                                placement="top"
                                trigger={["hover"]}
                                overlay={
                                  <Tooltip id="tooltip">
                                    <strong>{t("edit")}</strong>
                                  </Tooltip>
                                }>
                                <Button
                                  className="admin-your-bot-list-btn"
                                  onClick={() => handleRedirect(bot, "/settings")}
                                >
                                  {/* {t("edit")} */}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 25 24"
                                  >
                                    <path
                                      stroke="#171717"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeMiterlimit="10"
                                      strokeWidth="1.5"
                                      d="M13.76 3.6l-8.21 8.69c-.31.33-.61.98-.67 1.43l-.37 3.24c-.13 1.17.71 1.97 1.87 1.77l3.22-.55c.45-.08 1.08-.41 1.39-.75l8.21-8.69c1.42-1.5 2.06-3.21-.15-5.3-2.2-2.07-3.87-1.34-5.29.16zM12.39 5.05a6.126 6.126 0 005.45 5.15M3.5 22h18"
                                    ></path>
                                  </svg>
                                </Button>
                              </OverlayTrigger>
                            ) : null}
                          </div> :
                            <div className="admin-your-bot-list-declined-btn-sec">
                              <Row md={12}>
                                <Col md={9}>
                                  <div className="admin-your-bot-declined-sec">
                                    <p className="admin-your-bot-declined-text">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 512 512"
                                        fill="#FF916F"
                                      >
                                        <path d="M256 512a256 256 0 100-512 256 256 0 100 512zm0-384c13.3 0 24 10.7 24 24v112c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zm-32 224a32 32 0 1164 0 32 32 0 11-64 0z"></path>
                                      </svg>
                                      {t("declined")}
                                    </p>
                                  </div>
                                </Col>
                                <Col md={3}>
                                  {profile.data.role == 1 && (
                                    <Button
                                      className="admin-your-bot-list-btn"
                                      onClick={() => setDeleteBot(bot.bot_id)}
                                      disabled={botDelete.buttonDisable}
                                    >
                                      {botDelete.buttonDisable &&
                                        botDelete.data.bot_id === bot.bot_id
                                        ? t("loading")
                                        : t("delete")}
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </div>}
                        </div>
                      </div>
                    </div>
                    <div className="admin-your-bot-list-body-sec">
                      {Object.keys(botsList.data.subscription).length > 0 && (
                        <>
                          <div className="admin-your-bot-list-item">
                            <div className="admin-your-bot-list-item-left-sec">
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 18 18"
                              >
                                <path
                                  fill="#D5D5D5"
                                  d="M2.636 2.637a8.998 8.998 0 0112.727 0 8.99 8.99 0 011.691 10.377l.927 4.076a.74.74 0 01-.885.884l-4.077-.927C7.09 20.008 0 15.723 0 8.997c0-2.403.936-4.661 2.636-6.36zm2.609 8.6h3.918a.739.739 0 100-1.478H5.245a.739.739 0 100 1.478zm0-3.002h7.51a.74.74 0 100-1.478h-7.51a.739.739 0 100 1.478z"
                                ></path>
                              </svg> */}
                              {/* <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-brand-hipchat"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17.802 17.292s.077 -.055 .2 -.149c1.843 -1.425 3 -3.49 3 -5.789c0 -4.286 -4.03 -7.764 -9 -7.764c-4.97 0 -9 3.478 -9 7.764c0 4.288 4.03 7.646 9 7.646c.424 0 1.12 -.028 2.088 -.084c1.262 .82 3.104 1.493 4.716 1.493c.499 0 .734 -.41 .414 -.828c-.486 -.596 -1.156 -1.551 -1.416 -2.29z" /><path d="M7.5 13.5c2.5 2.5 6.5 2.5 9 0" /></svg> */}
                              <span>{t("bot_list.messages")}</span>
                            </div>
                            <div className="admin-your-bot-list-item-right-sec">
                              <div className="admin-your-bot-list-item-message-count">
                                {bot.chat_messages_count} Used
                              </div>
                            </div>
                          </div>
                          <div className="admin-your-bot-list-item">
                            <div className="admin-your-bot-list-item-left-sec">
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 19 19"
                              >
                                <path
                                  fill="#D5D5D5"
                                  fillRule="evenodd"
                                  d="M1.867 9.084l5.423-.001c.738 0 1.339-.593 1.339-1.322v-3.38c0-.84-.544-1.826-1.552-1.996a.417.417 0 01-.345-.48.423.423 0 01.488-.342 2.537 2.537 0 011.409.743V.512a2.336 2.336 0 00-2.453-.29 2.276 2.276 0 00-1.293 2.245 2.289 2.289 0 001.673 1.985.416.416 0 01.293.513.423.423 0 01-.52.29A3.128 3.128 0 014.043 2.54a3.037 3.037 0 01.006-.597 2.918 2.918 0 00-1.546 1.351 2.888 2.888 0 00-.057 2.621c.1.208.012.457-.199.556a.426.426 0 01-.563-.196 3.696 3.696 0 01-.34-1.158 2.077 2.077 0 00-1.228 1.25 2.027 2.027 0 00.468 2.096c.34.35.796.57 1.284.62zm2.65-3.553c.137-.187.4-.23.59-.095.189.134.232.395.096.581-.247.34-.28.782-.083 1.153.193.368.572.595.99.595h.02c.231 0 .42.182.423.41a.42.42 0 01-.417.423h-.025c-.736 0-1.4-.398-1.74-1.043a1.908 1.908 0 01.146-2.024zm-3.173 8.351c.049-.397.16-.788.339-1.157a.426.426 0 01.563-.196c.21.1.3.348.2.556-.402.829-.381 1.809.056 2.62.339.63.886 1.103 1.546 1.352a3.042 3.042 0 01-.006-.598 3.128 3.128 0 012.288-2.714.423.423 0 01.52.29.416.416 0 01-.294.513 2.288 2.288 0 00-1.673 1.985 2.275 2.275 0 001.293 2.245c.804.381 1.769.262 2.453-.29v-1.793c-.36.369-.832.644-1.41.742a.42.42 0 01-.488-.341.417.417 0 01.346-.48c1.008-.171 1.552-1.156 1.552-1.997v-3.38c0-.73-.6-1.322-1.34-1.322H1.87c-.49.05-.946.269-1.287.62a2.028 2.028 0 00-.468 2.096c.202.578.66 1.034 1.229 1.25zm3.027-2.438a1.956 1.956 0 011.765-1.041c.233.002.42.191.417.42a.42.42 0 01-.423.412h-.02c-.418 0-.797.227-.99.594-.196.371-.164.814.083 1.153a.413.413 0 01-.097.582.423.423 0 01-.589-.095 1.908 1.908 0 01-.146-2.025zm6.967 7.13H9.474v-4.431c.11.008.221.011.333.011 2.604 0 4.714-2.083 4.714-4.653 0-2.57-2.11-4.653-4.714-4.653-.112 0-.223.004-.334.011V.428h1.865l.364 1.824 1.957.801 1.566-1.036 2.165 2.136L16.34 5.7l.811 1.932L19 7.99v3.02l-1.849.36-.811 1.932 1.05 1.546-2.165 2.137-1.566-1.037-1.957.801-.364 1.825z"
                                  clipRule="evenodd"
                                ></path>
                              </svg> */}
                              {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0b0b12de" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-table-options"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 21h-7a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7"></path><path d="M3 10h18"></path><path d="M10 3v18"></path><path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path><path d="M19.001 15.5v1.5"></path><path d="M19.001 21v1.5"></path><path d="M22.032 17.25l-1.299 .75"></path><path d="M17.27 20l-1.3 .75"></path><path d="M15.97 17.25l1.3 .75"></path><path d="M20.733 20l1.3 .75"></path></svg> */}
                              <span>{t("bot_list.fine_tune")}</span>
                            </div>
                            <div className="admin-your-bot-list-item-right-sec">
                              <div className="admin-your-bot-list-item-message-count">
                                {bot.fine_tuning_models_count ?? 0} Used
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="admin-your-bot-list-item">
                        <div className="admin-your-bot-list-item-left-sec">
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 16 17"
                          >
                            <path
                              fill="#D5D5D5"
                              d="M0 5.833v-.667a3.337 3.337 0 013.333-3.333H4v-.667a.666.666 0 111.333 0v.667h5.334v-.667a.666.666 0 111.333 0v.667h.667A3.337 3.337 0 0116 5.166v.667H0zm16 1.333v6a3.337 3.337 0 01-3.333 3.334H3.333A3.337 3.337 0 010 13.166v-6h16zm-8 6a.666.666 0 00-.667-.666H4a.666.666 0 100 1.333h3.333A.667.667 0 008 13.166zm4.667-2.666A.667.667 0 0012 9.833H4a.667.667 0 100 1.333h8a.666.666 0 00.667-.666z"
                            ></path>
                          </svg> */}
                          {/* <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-calendar-event"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" /><path d="M16 3l0 4" /><path d="M8 3l0 4" /><path d="M4 11l16 0" /><path d="M8 15h2v2h-2z" /></svg> */}
                          <span>{t("bot_list.date")}</span>
                        </div>
                        <div className="admin-your-bot-list-item-right-sec">
                          <div className="admin-your-bot-list-item-created-date">
                            {bot.created_at}
                          </div>
                        </div>
                      </div>
                      <div className="admin-your-bot-list-item">
                        <div className="admin-your-bot-list-item-left-sec">
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 17 17"
                          >
                            <path
                              fill="#D5D5D5"
                              d="M17 5.666H0v-.708a3.546 3.546 0 013.542-3.542h.708V.708a.708.708 0 111.417 0v.708h5.666V.708a.708.708 0 111.417 0v.708h.708A3.546 3.546 0 0117 4.958v.708zM7.083 15.97c0-.632.133-1.242.38-1.803H4.25a.708.708 0 110-1.416h4.193l1.418-1.417H4.25a.708.708 0 110-1.417h7.028l1.942-1.941a3.024 3.024 0 012.152-.892H0v6.375A3.546 3.546 0 003.542 17h3.541v-1.03zm7.138-6.993a1.628 1.628 0 012.302 2.302L11.702 16.1a3.07 3.07 0 01-2.171.9H8.5v-1.03c0-.816.324-1.596.9-2.172l4.821-4.822z"
                            ></path>
                          </svg> */}
                          {/* <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-calendar-cog"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 21h-6a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5" /><path d="M16 3v4" /><path d="M8 3v4" /><path d="M4 11h16" /><path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M19.001 15.5v1.5" /><path d="M19.001 21v1.5" /><path d="M22.032 17.25l-1.299 .75" /><path d="M17.27 20l-1.3 .75" /><path d="M15.97 17.25l1.3 .75" /><path d="M20.733 20l1.3 .75" /></svg> */}
                          <span>{t("bot_list.last_edited")}</span>
                        </div>
                        <div className="admin-your-bot-list-item-right-sec">
                          <div className="admin-your-bot-list-item-created-date">
                            {bot.updated_at}
                          </div>
                        </div>
                      </div>
                      <div className="admin-your-bot-list-item">
                        <div className="admin-your-bot-list-item-left-sec">
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 17 18"
                          >
                            <path
                              fill="#D5D5D5"
                              d="M17 5.909V4.363C17 2.231 15.413.5 13.458.5H3.542C1.587.5 0 2.23 0 4.363V5.91h17zm-3.188-3.864c.588 0 1.063.518 1.063 1.16 0 .64-.475 1.158-1.063 1.158-.587 0-1.062-.517-1.062-1.159 0-.641.475-1.159 1.063-1.159zm-2.833 0c.588 0 1.063.518 1.063 1.16 0 .64-.475 1.158-1.063 1.158s-1.062-.517-1.062-1.159c0-.641.474-1.159 1.062-1.159zm-2.833 0c.588 0 1.062.518 1.062 1.16 0 .64-.474 1.158-1.062 1.158-.588 0-1.063-.517-1.063-1.159 0-.641.475-1.159 1.063-1.159zM17 7.455v6.181c0 2.133-1.587 3.864-3.542 3.864H3.542C1.587 17.5 0 15.769 0 13.636V7.454h17z"
                            ></path>
                          </svg> */}
                          {/* <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#0b0b12de"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-source-code"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14.5 4h2.5a3 3 0 0 1 3 3v10a3 3 0 0 1 -3 3h-10a3 3 0 0 1 -3 -3v-5" /><path d="M6 5l-2 2l2 2" /><path d="M10 9l2 -2l-2 -2" /></svg> */}
                          <span>{t("bot_list.source")}</span>
                        </div>
                        <div className="admin-your-bot-list-item-right-sec">
                          <div className={getChatTypeName(bot.widget_type)}>
                            {bot.widget_type_formatted}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <NoBotExists setAddBot={setAddBot} />
          )}
        </div>
      </div >
      {addBot && (
        <AddBotModal
          addBot={addBot}
          closeAddBotModal={closeAddBotModal}
          setAddBot={setAddBot}
        />
      )
      }
      {
        deleteBot && (
          <DeleteBotModal
            deleteBot={deleteBot}
            closeDeleteBotModal={closeDeleteBotModal}
            setDeleteBot={setDeleteBot}
          />
        )
      }
    </>
  );
};

export default withTranslation(YourBotIndex);
